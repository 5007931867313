export const it = {
    common: {
        PIN_CODE_LABEL: 'Codice PIN',
        comment: 'Commento',
        year: 'Anno',
        configuration: 'Configurazione',
        cancel: 'Annulla',
        search: 'Cerca',
        remove: 'Rimuovi',
        confirmationDialogTitle: 'Sei sicuro di rimuovere questo elemento?',
        selectDate: 'Seleziona data',
        select: 'Seleziona',
        notFound: 'Non trovato',
        equipmentPickerPlaceholder: 'Oggetto attrezzatura',
        searchPlaceholder: 'Cerca',
        equipmentTypePickerPlaceholder: 'Tipo di attrezzatura',
        equipmentTypePickerTitle: 'Seleziona tipo di attrezzatura',
        emptyTableMessage: 'Nessun dato',
        flowPikcerTitle: 'Seleziona flusso',
        selectIcon: 'Seleziona icona',
        locationPickerTitle: 'Seleziona posizione',
        locationPlaceholder: 'Posizione',
        noSublocations: 'Nessuna sottoposizione',
        locationsTitle: 'Posizioni',
        edit: 'Modifica',
        save: 'Salva',
        added: 'Aggiunto',
        updated: 'Aggiornato',
        add: 'Aggiungi',
        removed: 'Rimosso',
        reload: 'Ricarica',
        confirm: 'Conferma',
        rollBack: 'Annulla',
        images: 'Immagini',
        documents: 'Documenti',
        download: 'Scarica',
        saved: 'Salvato',
        date: 'Data',
        hour: 'Ora',
        preview: 'Anteprima',
        data: 'Dati',
        attributes: 'Attributi',
        checkList: 'Checklist',
        name: 'Nome',
        type: 'Tipo',
        status: 'Stato',
        designation: 'Designazione',
        symbol: 'Simbolo',
        serialNumber: 'Numero di serie',
        registryNumber: 'Numero di registro',
        machineNumber: 'Numero di macchina',
        udtNumber: 'Numero UDT',
        manufacturedYear: 'Anno di produzione',
        manufacturer: 'Produttore',
        supplier: 'Fornitore',
        mainSupplier: 'Fornitore principale',
        attribute: 'Attributo',
        nameRequired: 'Nome richiesto',
        yearGreaterThan: "L'anno deve essere maggiore di",
        location: 'Posizione',
        fieldRequired: 'Questo campo è richiesto',
        description: 'Descrizione',
        minimumQuantity: 'Quantità minima',
        optimalQuantity: 'Quantità ottimale',
        priority: 'Priorità',
        formatYYYY: 'Formato YYYY',
        storageQuantity: 'Quantità in magazzino',
        quantity: 'Quantità',
        quantityGreaterThan: 'La quantità deve essere maggiore di',
        machine: 'Macchina',
        tool: 'Strumento',
        order: 'Ordine',
        required: 'Richiesto',
        dateFormat: 'Formato data',
        selectDictionary: 'Seleziona dizionario',
        return: 'Ritorna',
        parameters: 'Parametri',
        editCategory: 'Modifica categoria',
        addCategory: 'Aggiungi nuova categoria',
        changeColor: 'Cambia colore',
        noColor: 'Nessun colore',
        shift: 'Turno',
        start: 'Inizio',
        end: 'Fine',
        shiftSchema: 'Schema turni',
        shifts: 'Turni',
        dictionary: 'Dizionario',
        subEntries: 'Voci secondarie',
        key: 'Chiave',
        value: 'Valore',
        moveUp: 'Sposta in su',
        moveDown: 'Sposta in giù',
        addLocation: 'Aggiungi nuova posizione',
        editLocation: 'Modifica posizione',
        companiesDatabase: 'Database delle aziende',
        flows: 'Flussi',
        read: 'Leggi',
        write: 'Scrivi',
        noImage: 'Nessuna immagine',
        email: 'E-mail',
        function: 'Funzione',
        active: 'Attivo',
        notActive: 'Non attivato',
        disabled: 'Disabilitato',
        entryNumber: 'Numero di entrata',
        issuedDate: 'Data di emissione',
        expiresDate: 'Data di scadenza',
        move: 'Spostare',
        separateForm: 'Separare da',
        separate: 'Separare',
        warehouse: 'Magazzino',
        actions: 'Azioni',
        putInCurrentLocation: 'Mettere nella posizione corrente',
        selectLocationFormRightMenu: 'Seleziona la posizione dal menu a destra',
        showUnassignedItems: 'Mostra elementi non assegnati',
        separated: 'Separato',
        moved: 'Spostato',
        categories: 'Categorie',
        welcome: 'Benvenuto',
        loginToSignaloTpm: 'Accedi a Signalo TPM',
        login: 'Accedi',
        loginByEmailAndPassword: 'Accedi con e-mail e password',
        loginByCode: 'Accedi con @:common.PIN_CODE_LABEL',
        accessCode: 'Codice di accesso',
        enterEmail: 'Inserisci email',
        enterPassword: 'Inserisci password',
        enterCode: 'Inserisci @:common.PIN_CODE_LABEL',
        userNotFound: 'Utente non trovato',
        userNotActivated: 'Utente non attivato',
        userDisabled: 'Utente disabilitato',
        wrongPassword: 'Password sbagliata',
        locations: 'Posizioni',
        pageNotFound: 'Pagina non trovata',
        group: 'Gruppo',
        yes: 'Sì',
        no: 'No',
        hourlyCost: 'Costo orario',
        price: 'Prezzo',
        newEntry: 'Nuova voce',
        predictedFinishTime: 'Tempo previsto di completamento',
        ok: 'Ok',
        archive: 'Archivio',
        costs: 'Costi',
        companyManagement: 'Gestione azienda',
        companyData: 'Dati aziendali',
        supportedLanguages: 'Lingue supportate',
        en: 'Inglese',
        pl: 'Polacco',
        es: 'Spagnolo',
        de: 'Tedesco',
        it: 'Italiano',
        fr: 'Francese',
        ru: 'Russo',
        uk: 'Ucraino',
        pt: 'Portoghese',
        tr: 'Turco',
        nl: 'Olandese',
        sr: 'Serbo',
        ar: 'Arabo',
        he: 'Ebraico',
        defaultLanguage: 'Lingua predefinita',
        companyLogo: "Logo dell'azienda",
        notificationsManagement: 'Gestione delle notifiche',
        category: 'Categoria',
        today: 'Oggi',
        month: 'Mese',
        week: 'Settimana',
        day: 'Giorno',
        close: 'Chiudi',
        show: 'Mostra',
        rollBackFormalConfirmation: 'Conferma formale del ripristino',
        signUp: 'Iscriviti',
        nameSurname: 'Nome e cognome',
        phone: 'Numero di telefono',
        password: 'Password',
        password_confirmation: 'Conferma password',
        unassignedItems: 'Elementi non assegnati',
        currency: 'Valuta',
        general: 'Generale',
        timezone: 'Fuso orario',
        users: 'Utenti',
        groups: 'Gruppi',
        productionLine: 'Linea di produzione',
        part: 'Parte',
        fittingParts: 'Parti adattabili',
        workSchema: 'Schema di lavoro',
        workSchemas: 'Schemari di lavoro',

        holiday: 'Vacanza',
        changePassword: 'Cambia password',
        showRest: 'Mostra il resto',
        pageNotPermitted: 'Pagina non consentita',
        waitingForActivation: 'In attesa di attivazione',
        editGroup: 'Modifica gruppo',
        addGroup: 'Aggiungi gruppo',
        reportProblem: 'Segnala un problema',
        describeOccuredIssue: "Descrivi l'errore che si è verificato",
        markAllAsRead: 'Segna tutti come letti',
        unitType: 'Tipo di unità',
        unit: 'Unità',
        unitPrice: 'Prezzo unitario',
        notConfigured: 'Non configurato',
        rowsPerPage: 'Righe per pagina',
        rowsOfPage: 'di',
        true: 'Vero',
        false: 'Falso',
        export: 'Esporta',
        chooseFileFormat: 'Scegli il formato di file',
        exported: 'Esportato',
        terminals: 'Terminali',
        terminal: 'Terminale',
        selectEquipment: 'Seleziona attrezzatura',
        futureIssues: 'Problemi futuri',
        scheduledOn: 'Programmato il',
        planned: 'Programmato',
        notPlanned: 'Non programmato',
        showOpenIssues: 'Mostra i problemi aperti',
        pin: 'PIN',
        assignedEquipment: 'Attrezzatura assegnata',
        terminalEquipment: 'Attrezzatura terminale',
        detach: 'Stacca',
        attach: 'Allega',
        dashboardConfig: 'Configurazione dashboard',
        addColumn: 'Aggiungi colonna',
        addRow: 'Aggiungi riga',
        removeRow: 'Rimuovi riga',
        registerTerminal: 'Registra terminale',
        issueGeneratesCost: 'Tempo di fermo genera costo',
        selectColor: 'Seleziona colore',
        belowMinimum: 'Sotto minimo',
        belowOptimum: 'Sotto ottimale',
        synchronization: 'Sincronizzazione',
        synchronizationExport: 'Esportazione parti in stock',
        synchronizationImport: 'Importazione parti in stock',
        mapping: 'Mappatura',
        returnTo: 'Torna a',
        jobStatusses: 'Processi',
        hidden: 'Nascosto',
        editEvent: 'Modifica evento',
        threeDays: '3 giorni',
        addEvent: 'Aggiungi evento',
        plannedIssues: 'Problemi pianificati',
        currentService: 'Servizio corrente',
        searchLocation: 'Cerca posizione',
        all: 'Tutti',
        unfinished: 'Incompleto',
        numberOfIssues: 'Quantità problemi',
        unknown: 'Sconosciuto',
        partEntry: 'Parte (ingresso)',
        foundItems: 'Elementi trovati',
        output: 'Uscita',
        copied: 'Copiato',
        link: 'Collegamento',
        authorizationRequired: 'Autorizzazione richiesta',
        authorizationRequiredDescriptionUser:
            "Sfortunatamente, non hai autorizzazione sufficiente. Chiedi a una persona autorizzata di approvare l'azione.",
        authorizationRequiredDescriptionTerminal:
            "Usa il tuo @:common.PIN_CODE_LABEL di accesso per autorizzare l'azione.",
        showAll: 'Mostra tutti',
        showClassicView: 'Mostra vista classica',
        noIssuesInCategory: 'Nessun problema in questa categoria.',
        numberOfIssuesInThisCategoryAndSubcategories:
            'Numero di problemi in questa categoria e sottocategorie.',
        numberOfIssuesInThisCategory: 'Numero di problemi in questa categoria.',
        minBarcodeLength: 'Lunghezza minima del codice a barre',
        currentPage: 'Pagina corrente',
        allPages: 'Tutte le pagine',
        selectFormat: 'Seleziona formato',
        provided_currency: 'Valuta fornita',
        provided_quantity: 'Quantità fornita',
        provided_unit: 'Unità fornita',
        provided_unit_price: 'Prezzo unitario fornito',
        unit_price: 'Prezzo unitario',
        quantity_available: 'Quantità disponibile',
        editBarcode: 'Modifica codice a barre',
        crews: 'Squadre',
        scheduleEventTypes: 'Tipi di eventi pianificati',
        crew: 'Squadra',
        scheduleEvent: 'Evento di pianificazione',
        available: 'Disponibile',
        'App\\Models\\Crew': 'Squadra',
        'App\\Models\\User': 'Utente',
        'App\\Models\\Equipment': 'Attrezzatura',
        Crew: 'Squadra',
        User: 'Utente',
        Equipment: 'Attrezzatura',
        scheduleEvents: 'Eventi pianificati',
        event: 'Evento',
        externalSystems: 'Sistemi esterni',
        user: 'Utente',
        availability: 'Disponibilità',
        inheritColorFromParentIfCustomNotSet:
            'Eredita il colore dal padre se non impostato personalmente',
        pickFromAllParts: 'Scegli da tutte le parti',
        takeTool: 'Prendi attrezzo',
        returnTool: 'Restituisci attrezzo',
        personWhoTake: 'Persona che prende',
        personWhoReturn: 'Persona che restituisce',
        takenAt: 'Preso a',
        takenBy: 'Preso da',
        toolIssuedBy: 'Attrezzo rilasciato da',
        commentTake: 'Commento preso',
        commentReturn: 'Commento restituito',
        change: 'Cambia',
        onlyWithActiveRMIssues: 'Solo con richieste RM attive',
        externalSystem: 'Sistema esterno',
        rejectIssue: 'Rifiuta problema',
        withdrawIssueRejection: 'Ritira il rifiuto del problema',
        rejectionReason: 'Motivo del rifiuto',
        rejected: 'Rifiutato',
        showCrewMembers: 'Mostra membri della squadra',
        crewMembers: 'Membri della squadra',
        helpGroup: 'Servizio',
        partsTakenByUser: "Parti prese dall'utente",
        pickParts: 'Preleva parti',
        selectPersonWhoTakes: 'Seleziona persona che prende',
        changePersonWhoTakes: 'Cambia persona che prende',
        quantityToPick: 'Quantità da prelevare',
        pickLocation: 'Posizione di ritiro',
        selectPartsToPick: 'Seleziona le parti da prelevare',
        partSelected: 'Parte selezionata',
        scanCodeOr: 'Scansiona codice o',
        returnPart: 'Restituisci parte',
        myTakenParts: 'Parti presi da me',
        takenFromLocation: 'Preso dalla posizione',
        takenQuantity: 'Quantità presa',
        providedUnit: 'Unità fornita',
        providedQuantity: 'Quantità fornita',
        selectFromStorage: 'Seleziona dallo storage',
        selectFromMyPickedParts: 'Seleziona dalle parti raccolte da me',
        exportProcessInProgressMessage:
            'Il processo di esportazione dei dati è appena iniziato, quando sarà completato vedrai una notifica corrispondente che contiene un link al file.',
        yourDocumentIsReady: 'Il tuo documento è pronto',
        rotateScreen: 'Ruota schermo',
        addRule: 'Aggiungi regola',
        editRule: 'Modifica regola',
        summary: 'Sintesi',
        onlyAvailableEmployees: 'Mostra solo i dipendenti disponibili',
        calculated_unit_price: 'Prezzo unitario convertito',
        calculated_quantity: 'Quantità convertita',
        next: 'Prossimo',
        maintenanceMode: 'Modalità di manutenzione',
        applicationMayBeUnavailableForFewMinutes:
            "L'applicazione potrebbe non essere disponibile per alcuni minuti",
        appWillRefreshIn: "L'applicazione si aggiornerà in",
        landscape: 'Paesaggio',
        portrait: 'Ritratto',
        orientation: 'Orientamento',
        format: 'Formato',
        service: 'Servitori',
        leader: 'Capisquadra',
        defaultView: 'Vista predefinita',
        view: 'Vista',
        oneDay: '1 giorno',
        object: 'Oggetto',
        finished: 'Finito',
        onlyNotFinished: 'Solo non finito',
        generate: 'Creare',
        error: 'Errore',
        cell: 'Cella',
        resolutionSummary: 'Fine Riepilogo',
        rejected_at: 'Data di rifiuto',
        rejected_by: 'Rifiutato da',
        finishedByUser: 'Completato da',
        formalConfirmByUser: 'Conferma formale di',
        formalConfirmAt: 'Data di conferma formale',
        valueDeleted: '[Valore eliminato]',
        hasNotPlannedIssue: 'Non ha problemi pianificati',
        showInStorage: 'Mostra in deposito',
        changeCode: 'Cambia il @:common.PIN_CODE_LABEL',
        staffProfiles: 'Profili dei dipendenti',
        profile: 'Profilo',
        user_bug: "Errore dell'applicazione",
        user_kaizen: 'Suggerimento di cambiamento',
        changeSuggestions: 'Suggerimenti di modifica',
        showOnlyCurrentlyAvailable: 'Mostra solo attualmente disponibile',
        resetPassword: 'Ricorda password',
        forgotPassword: 'Ha dimenticato la password',
        resetPasswordMailSent:
            "Abbiamo inviato un link per impostare una nuova password all'indirizzo e-mail fornito",
        manyEventsThisTime: 'Molti eventi in questo momento',
        importScheduleCheckboxLabel:
            'Elimina gli eventi pianificati nel mese selezionato per i dipendenti elencati nel file',
        inheritedEvent: 'Evento ereditato',
        inheritedModifiedEvent: 'Evento ereditato modificato',
        equipmentId: 'ID attrezzatura',
        entryId: 'ID di ingresso',
        partId: 'ID parte',
        suggestionsEmail:
            'Email a cui verranno inviati i suggerimenti di modifica',
        meters: 'Contatori',
        scanCardToAuth:
            'Avvicina la carta al lettore o inserisci il tuo @:common.PIN_CODE_LABEL di accesso per confermare la tua identità.',
        scanCard: 'Scansiona la cart',
        cart: 'Scatola di scambio',
        emptyCardMessage:
            "Avvia la scansione dei prodotti o selezionali dall'elenco per aggiungerli al carrello.",
        terminalMode: 'tipo terminale',
        askPermittedUserToConfirmPartsExchange:
            'Chiedi alla persona autorizzata di confermare il rilascio della parte scansionando la carta o inserendo il @:common.PIN_CODE_LABEL',
        partsIssued: 'Parti rilasciate',
        areYouSureAboutCancel: 'Sei sicuro di voler annullare?',
        areYouSureAboutRemoveFromCart:
            'Sei sicuro di voler rimuovere questo elemento dal carrello?',
        standard: 'Standard',
        idNumber: 'Numero di identificazione',
        takeParts: 'Prendi parti',
        returnParts: 'Restituisci parti',
        quantityToReturn: 'Quantità da restituire',
        partsPendingApproval: 'Parti in attesa di approvazione',
        partsReturned: 'Parti restituite',
        partsAddedToPendingApproval: 'Parti aggiunte a attesa di approvazione',
        approvePartsReturn: 'Approva il ritorno delle parti',
        partReturnApproved: 'Ritorno della parte approvato',
        partUsage: 'Uso della parte',
        awaitsReturn: 'Aspetta il ritorno',
        entrySelection: 'Selezione di ingresso',
        correctQuantity: 'Quantità corretta',
        quantityCorrection: 'Correzione della quantità',
        entrySelectionAsc: 'Selezione di ingresso (asc)',
        entrySelectionDesc: 'Selezione di ingresso (desc)',
        metersSettings: 'Impostazioni dei contatori',
        countableFields: 'Campi conteggiabili',
        equipmentTypes: 'Tipi di attrezzatura',
        fieldName: 'Nome del campo',
        countableField: 'Campo conteggiabile',
        partsStatusTaken: 'Parti prese',
        rejectPartsReturn: 'Rifiuta il ritorno delle parti',
        areYouSureAboutRejectPartsReturn:
            'Sei sicuro di voler rifiutare il ritorno delle parti?',
        partReturnRejected: 'Ritorno della parte rifiutato',
        counterFields: 'Campi contatore',
        counterName: 'Nome del contatore',
        counterFieldName: 'Nome del campo contatore',
        counterId: 'ID contatore',
        counterUUID: 'UUID contatore',
        counterPickerPlaceholder: 'Seleziona contatore',
        pickFromPartsList: 'Seleziona dalla lista delle parti',
        changeLocation: 'Cambia posizione',
        isPartStorageRoot: 'Radice del deposito delle parti',
        areYouSureAboutArchiveIssue:
            'Sei sicuro di voler archiviare questo problema?',
        ganttChart: 'Diagramma di Gantt',
        table: 'Tabella',
        fittingEquipment: 'Attrezzatura di adattamento',
        notAssigned: 'Non assegnato',
        counters: 'Contatori',
        machineHourlyCostNotProvided:
            'Il costo orario della macchina non è stato fornito',
        eventTypesMightBeDefinedHere:
            'I tipi di evento potrebbero essere definiti qui',
        toolsTakenByUser: "Strumenti presi dall'utente",
        predictedReturnDate: 'Data di ritorno prevista',
        estimatedTimeOfUsingToolInHours:
            'Tempo stimato di utilizzo dello strumento (in ore)',
        showOnlyAvailable: 'Mostra solo disponibile',
        toolTakeLog: 'Registro di utilizzo dello strumento',
        takeDate: 'Data di presa',
        returnDate: 'Data di ritorno',
        personWhoAcceptReturn: 'Persona che accetta il ritorno',
        statisticStrategy: "Strategia per l'accessibilità",
        EquipmentPlannedStrategy: 'Secondo il piano di lavoro',
        EquipmentConstantAvailabilityStrategy: 'Sempre disponibile',
        actionAfterIssueFinish: 'Azione dopo il completamento del problema',
        enabled: 'Abilitato',
        categoryOfNewIssue: 'Categoria del nuovo problema',
        workNotStarted: 'Lavoro non iniziato',
        colorInherited: 'Colore ereditato',
        abcClassification: 'Classificazione ABC',
        xyzClassification: 'Classificazione XYZ',
        classification: 'Classificazione',
        filters: 'Filtri',
        wipeFilters: 'Cancella filtri',
        takePartsFromUser: "Prendi parti dall'utente",
        selectPersonWhoReturns: 'Seleziona la persona che restituisce le parti',
        changePersonWhoReturns: 'Cambia la persona che restituisce le parti',
        returnLocation: 'Posizione di ritorno',
        returnQuantity: 'Quantità di ritorno',
        globalSettings: 'Impostazioni globali',
        delimiter: 'Delimitatore',
        fieldRules: 'Regole del campo',
        fieldValue: 'Valore del campo',
        user_email_regex: 'Regex e-mail',
        barcodeLength: 'Lunghezza del codice a barre',
        partLine: 'Linea di parti',
        partFamily: 'Famiglia di parti',
        partBatch: 'Parte del lotto',
        testValueValid: 'Valore di prova valido',
        testValueNotValid: 'Valore di prova non valido',
        canBePicked: 'Può essere scelto',
        issue_create_photos: 'Aggiunta di foto a un nuovo numero',
        issue_create_documents: 'Aggiunta di documenti a un nuovo fascicolo',
        propagateValues: 'Propagazione del valore',
        archived: 'Archiviato',
        escalations_tab: 'Escalation',
        minute: 'Minuto',
        days: 'Giorni',
        hours: 'Ore',
        minutes: 'Minuti',
        warnDays: 'Avviso prima della scadenza (giorni)',
        licenseType: 'Tipo di licenza',
        licenseGroup: 'Gruppo di licenze',
        attachments: 'Allegati',
        attachment: 'Allegato',
        revisionChanges: 'Modifiche introdotte',
        document: 'Documento',
        noInstructionSelected: 'Nessuna istruzione selezionata',
        instructionGroups: 'Gruppi di istruzioni',
        instructionGroup: 'Gruppo di istruzioni',
        addOption: "Aggiungi un'opzione",
        ordinal: 'Valore ordinale',
        parameterName: 'Nome del parametro',
        fontColor: 'Colore del carattere',
        backgroundColor: 'Colore di sfondo',
        columns: 'Numero di colonne',
        colspan: 'Larghezza (colonne)',
        products: 'Prodotti',
        controlsTemplates: 'Modelli di controllo',
        controlType: 'Tipo di controllo',
        controls: 'Ispezioni',
        controlTemplate: 'Modello di controllo',
        product: 'Prodotto',
        checklistsAttachedToControlTemplate:
            'Liste di controllo assegnate alle ispezioni',
        removeChecklistFromControlTitle:
            'Sei sicuro di voler rimuovere questo elenco dal tuo controllo?',
        attachChecklistToControl: 'Assegnare un elenco da controllare',
        pickedItem: 'Elemento selezionato',
        pick: 'Scegliere',
        fillControl: 'Completa il controllo',
        back: 'Ritorno',
        control: 'Controllo',
        duplicate: 'Duplicare',
        isStorageRoot: 'Posizione principale del magazzino',
        manageStorageAccess: "Gestire l'accesso ai magazzini",
        manageVisibleColumns: 'Regola la visibilità della colonna',
        columnName: 'Nome della colonna',
        visible: 'Visible',
        entriesLocation: 'posizione delle voci',
        details: 'Dettagli',
        otherData: 'Altri dati',
        moveAllEntriesFromCurrentLocation:
            'Sposta tutte le voci da questa posizione',
        searchInCurrentLocation: 'Cerca nella tua posizione attuale',
        transition: 'Transizione',
        notification: 'Notifica',
        emails: 'Indirizzi email',
        phones: 'Numeri di telefono',
        addAction: "Aggiungi un'azione",
        message: 'Messaggio',
        application: 'App',
        always: 'Sempre',
        sms: 'SMS',
        loginTo: 'Accedere',
        appName: 'Signalo TPM',
        index: 'Indice',
        showCodeOwnerNameDuringParing:
            "Mostra il nome del proprietario del @:common.PIN_CODE_LABEL durante l'associazione con la carta/tag",
        showUsernameInCart: 'Mostra il nome utente nel carrello',
        sampleButtonPlaceholder: 'Pulsante campione',
        preferences: 'Preferenze',
        buttonsSize: 'Dimensione del pulsante',
        erase: 'cancellare',
        selectDictionaryValue: 'Selezionare il valore del dizionario',
        conditions: 'Condizioni',
        partsCartTerminalRequireApproveOnPartsReturn:
            'Richiedere conferma o rifiuto quando si restituiscono parti scaricate',
        issueCreated: 'È stato creato un ticket',
        allowedIssuesCategories: 'Categorie di problemi consentiti',
        hiddenFields: 'Campi nascosti',
        defaultValues: 'Valori standard',
        paddingBottom: 'Spazio vuoto in basso',
        allVisibleFieldsRequired:
            'Richiedi che tutti i campi visibili siano completati',
        url: 'URL',
        kind: 'Tipo',
        forkfleetConfigId: 'Configurazione Forkfleet',
        create: 'Creare',
        terminate: 'Terminare',
        parent: 'Genitore',
        phoneNumbers: 'Numeri di telefono',
        storageDefaults: 'Valori di archiviazione predefiniti',
        clientSignatureEnabled: 'Firma della persona che ritira',
        warehousemanSignatureEnabled: 'Firma del magazziniere',
        storage_client_signature_enabled:
            'Firma della persona che preleva gli articoli dal magazzino',
        storage_client_signature_required:
            'Richiedere la firma della persona che preleva gli articoli dal magazzino',
        storage_warehouseman_signature_enabled:
            'Firma della persona che svincola gli articoli dal magazzino',
        storage_warehouseman_signature_required:
            'Richiedere la firma della persona che rilascia gli articoli dal magazzino',
        clientReturnSignatureEnabled: 'Firma della persona che ritorna',
        warehousemanReturnSignatureEnabled: 'Firma di chi accetta il reso',
        storage_client_return_signature_enabled:
            'Firma della persona che restituisce gli articoli',
        storage_client_return_signature_required:
            'Richiedere la firma della persona che restituisce gli articoli',
        storage_warehouseman_return_signature_enabled:
            "Firma della persona che accetta la restituzione dell'articolo",
        storage_warehouseman_return_signature_required:
            "Richiedere la firma della persona che accetta la restituzione dell'articolo",
        channels: 'Canali',
        manageState: 'Gestisci lo stato',
        assignWorker: 'Assegnare un dipendente',
        mergeEntriesOfTheSameLocation: 'Unisci le voci dalla stessa posizione',
        permission: 'Autorizzazione',
        areYouSureAboutMergeAllEntriesOfThisLocation:
            'Sei sicuro di voler unire tutte le voci in questa posizione?',
        manageFilters: 'Gestisci i filtri',
        responsible_assign_creator_when_no_rule:
            '[Rapporti] - Assegna il creatore come responsabile se la regola non corrisponde',
        recreateIssue: 'Ricreare il biglietto',
        addNewDictionary: 'Aggiungi un nuovo dizionario',
        label: 'Etichetta',
        issuesWithRelatedCategories: 'Includi sottocategorie',
        issuesLimit: 'Limite dei problemi',
        issuesLimits: 'Problemi - limiti',
        issueDefaults: 'Rapporti: valori predefiniti',
        flatCategoryPickerLabel: 'Struttura delle categorie piatta',
        statementsTemplates: 'Modelli di dichiarazioni',
        statementTemplate: 'Modello di dichiarazione',
        content: 'Contenuti',
        readonly: 'Lecture seulement',
        defaultValue: 'Valeur par défaut',
        fillParametersToChangeState:
            'Remplissez les valeurs des paramètres ci-dessous pour changer le statut',
        formatPattern: 'Modello di formato',
        countableIncFields: 'Configurazione della numerazione dei documenti',
        mainShift: 'Turno principale',
        singleEvent: 'Entrata singola',
        seriesOfEvents: 'Una serie di voci',
        omitWeekends: 'Evita i fine settimana',
        allEntriesSaved: 'Tutte le voci sono state aggiunte',
        surveyResults: "Risultati dell'indagine",
        qrActionsSettings: "Impostazioni dell'azione del codice QR",
        pendingStatements: 'Dichiarazioni da firmare',
        statementsResults: 'Dichiarazioni',
        statements: 'Dichiarazioni',
        statement: 'Dichiarazione',
        userCanChangeContent: "L'utente può modificare il contenuto",
        statementsEvents: 'Configurazione eventi - istruzioni',
        helpdesk: 'Helpdesk',
        terminalId: 'Identificativo terminale',
        operatorId: 'Identificativo operatore',
        newConversation: 'Nuovo thread',
        formula: 'Formula',
        fill: 'Completare',
        webMessage: 'Messaggio web',
        noResponsibleUser: 'Nessun utente responsabile',
        filledAtDate: 'Data di completamento',
        filledBy: 'Completato da',
        parts_quantity_alert_rule:
            '[Quantità di parti e materiali] - Avvertenza',
        employeeNumber: 'Impiegato numero',
        groupsAssignedToCategory: 'Gruppi assegnati a categorie',
        number: 'Numero',
        customers: 'Clienti',
        customer: 'Cliente',
        projects: 'Progetti',
        project: 'Progetto',
        report: 'Rapporto',
        reportGenerationInProgress: 'Il rapporto è in fase di generazione',
        storageStatementGroup: 'Gruppo dichiarazioni di magazzino',
        simplifiedHandling: 'Gestione semplificata delle notifiche',
        selectWorkArea: 'Seleziona la tua area di lavoro',
        workAreaSelection: 'Selezione di un`area di lavoro',
        access: 'Accesso',
        restrictAccess: 'Accesso limitato',
        anchor: 'Ancoraggio',
        issueCreator: 'La persona che crea il rapporto',
        hideSideMenu: 'Nascondi menu laterale',
        appendGroups: 'Gruppi da aggiungere',
        revokeGroups: 'Gruppi da rimuovere',
        override: 'Sovrascrivi',
        restrictGroupAccess: "Limitare l'accesso ai gruppi",
        flag: 'Bandiera',
        flags: 'Bandiere',
        manageFlags: 'Gestisci i flag',
        icon: 'Icona',
        appendFlags: 'Flag da aggiungere',
        revokeFlags: 'Bandiere da rimuovere',
        noFlagsConfigured: 'Nessun flag configurato',
        checkIsTerminal: 'Controllo del terminale',
        noConditionsDefined: 'Nessuna condizione definita',
        checkIsResponsible: 'Controllo di responsabilità',
        createdOn: 'Segnalato da',
        replaceTimespan: 'Periodo di validità dal momento del rilascio',
        months: 'Mesi',
        handoversTemplates: 'Modelli di consegne',
        handoverTemplate: 'Modello di consegna',
        employeeFunction: 'Funzione del dipendente',
        takeHandover: 'Prendi la consegna',
        handovers: 'Consegne',
        expiringHandovers: 'Articoli prossimi alla data di scadenza',
        years: 'Anni',
        taken_parts_expiry_warning:
            'Avviso prima della data di scadenza degli elementi scaricati',
        andonView: 'Andon vista',
        nfc: 'NFC',
        rfid: 'RFID',
        other: 'altro',
        tags: 'Carte / Tag',
        tag: 'Carta / Etichetta',
        userPinCode: "@:common.PIN_CODE_LABEL dell'utente della carta",
        emailConfirmation: "Conferma dell'indirizzo e-mail",
        from: 'Da',
        to: 'A',
        handoversHistory: 'Storia delle consegne',
        partUsagesHistory: 'Cronologia degli utilizzi delle parti',
        signaturePinTagConfirmationText1:
            'Firmare con il @:common.PIN_CODE_LABEL',
        signaturePinTagConfirmationText2: 'o scansiona la tua carta.',
        signedWithPinCode: 'Firmato con @:common.PIN_CODE_LABEL',
        signedWithTAG: 'Firmato con card/tag',
        test_mode: 'Modalità di prova',
        confirmWorkFinish: 'Conferma la fine del lavoro',
        workStarted: 'Inizio dei lavori',
        workFinished: 'Fine del lavoro',
        workTimeRegister: 'Registro delle ore di lavoro',
        measureTypes: 'Tipi di misurazioni',
        measureType: 'Tipo di misurazione',
        measurable: 'Indicatore misurabile',
        measurables: 'Indicatori misurabili',
        measurements: 'Misurazione',
        measurement: 'Misurazione',
        measuredAt: 'Data di misurazione',
        currentVersion: 'Versione attuale',
        employee: 'Dipendente',
        pin_code_label: 'Nome PIN',
        orders: 'Ordini',
        productionPlan: 'Piano di produzione',
        productionPlans: 'Piani di produzione',
        taktTime: 'Takt time',
        target: 'Bersaglio',
        seconds: 'Secondi',
        inheritValuesFromTenantSettings:
            'Eredita i valori predefiniti dalle impostazioni globali',
        matrices: 'Matrici',
        manageMatrice: 'Gestisci Matrice',
        deletionOfMatrixColumnWarnMessage:
            "L'eliminazione di una colonna elimina i valori.",
        removeColumn: 'Elimina una colonna',
        editColumn: 'Modifica colonna',
        column: 'Colonna',
        row: 'Riga',
        evaluated: 'valutato',
        of: 'Di',
        auto_pin_generation: '@:common.PIN_CODE_LABEL - Generazione automatica',
        or: 'O',
        rename: 'Rinominare',
        check_only_on_transition: 'Controlla solo quando lo stato cambia',
        license: 'Poteri',
        confirmWorkStart: 'Conferma inizio lavori',
        workStartAt: 'Inizi a lavorare da',
        workFinishAt: 'Finisci il tuo lavoro o',
        workStartedAt: 'Iniziare',
        workTimeMessage: 'Hai lavorato troppo',
        haveANiceDay: 'Buona giornata!',
        farewellText: 'Testo nell ultima schermata',
        namedayLabel: 'Festeggiano il loro onomastico',
        sportEventNearby: 'Il prossimo evento sportivo',
        autoTranslate: 'Traduzione automatica',
        auto_translation: 'Traduzione automatica',
        awaitingAutoTranslation: 'In attesa della traduzione automatica',
        inheritAllDefaultValues:
            'Eredita tutti i valori predefiniti dalle impostazioni globali',
        escalations: 'Escalation',
        escalation: 'Escalation',
        notTaken: 'Non iniziato',
        notFinished: 'Incompiuto',
        issueLastChange: 'Ultima modifica',
        mode: 'Modalità',
        repeatLastEscalation: "Ripetere l'ultimo valore di escalation",
        manageActions: 'Gestisci le tue azioni',
        productionLines: 'Linee di produzione',
        productionTerminal: 'Terminale di produzione',
        currently: 'Attualmente',
        currentPlan: 'Piano attuale',
        currentlyInProduction: 'Attualmente prodotto',
        setProduct: 'Imposta prodotto',
        second: 'Secondi',
        work: 'Lavoro',
        chartConfiguration: 'Configurazione grafico',
        revisionsToConsider: 'Revisioni da considerare',
        allInstructions: 'Tutte le istruzioni',
        decider: 'Determinatore',
        considerRevision: 'Prendere in considerazione la revisione',
        and: 'E',
        acceptInstruction: 'Accettare le istruzioni',
        accept: 'accettare',
        instructionsToAccept: 'Istruzioni da leggere',
        open: 'Aprire',
        assignedAt: 'Data di incarico',
        confirmationAt: 'Data di conferma',
        mainInstruction: 'Istruzione predefinita',
        setAsDefault: 'Imposta come predefinito',
        instructionsFlows: 'Istruzioni - flussi',
        newRevision: 'Nuova revisione',
        playlist: 'Elenco video',
        selectVideo: 'Seleziona un film',
        selectVideos: 'Seleziona film',
        uploadNewVideo: 'Carica nuovo video',
        upload_date: 'Data aggiunta',
        thumbnail: 'miniatura',
        duration: 'Durata',
        uploadFromDrive: 'Carica da disco',
        selectAssets: 'Scegli i materiali',
        assets: 'Materiali',
        video: 'Film',
        doYouWantToAddNewMarker: 'Vuoi aggiungere un nuovo tag qui?',
        areYouSureAboutRemoveMarker:
            'Sei sicuro di voler rimuovere questo tag?',
        plants: 'Impianti di fabbrica',
        plant: 'Fabbrica',
        buildings: 'Edifici',
        building: 'Edificio',
        floors: 'Piani',
        floor: 'Pavimento',
        map: 'Carta geografica',
        areYouSureAboutMoveMarker:
            'Sei sicuro di voler spostare questo indicatore?',
        marker: 'Marcatore',
        addNewMarkerTooltipMessage:
            'Per aggiungere un nuovo indicatore, fai clic con il pulsante destro del mouse sulla mappa.',
        skillmatrix: 'Skillmatrix',
        indirectProcess: 'Abilità non lineari',
        skillLevelConfig: 'Livelli di abilità',
        indirectSkillGroups: 'Gruppi di abilità non lineari',
        approved: 'Confermato',
        approvedBy: 'Approvatore',
        skillLevel: 'Livello di abilità',
        requiredSkillLevel: 'Livello di competenze richiesto',
        step: 'Fare un passo',
        image: 'Foto',
        skills: 'Competenze',
        visits: 'Visite',
        responsiblePerson: 'Persona responsabile',
        company: 'Azienda',
        contactData: 'Dettagli di contatto',
        visit: 'Visita',
        inProgress: 'In corso',
        gatehouse: 'portineria',
        confirmEnter: 'Conferma immissione',
        confirmExit: "Conferma l'uscita",
        enter: 'Inserimento',
        exit: 'Esci',
        entryGuard: 'La persona che fa entrare',
        exitGuard: 'La persona che lascia uscire',
        loginAsThisUser: 'Accedi come questo utente',
        minBrowserVersion: 'È richiesta la versione minima del browser',
        approve: 'Confermare',
        cancelApproval: 'Annulla il commit',
        workPermit: 'Permesso di lavoro',
        workPermits: 'Permessi di lavoro',
        visit_summary: 'Riepilogo della visita',
        createVisit: 'Creare una visita basata su un permesso',
        employeesAtThePlant: 'Lavoratori nello stabilimento',
        purpose: 'Bersaglio',
        allowedAreas: 'Aree consentite',
        switchEmployees: 'Sostituisci dipendente',
        localIssues: 'Reportistica locale',
        cmmsCategoriesSync: 'Sincronizzazione delle categorie CMMS',
        terminalsGroups: 'Gruppi terminali',
        isTree: 'Albero',
        isReporter: 'È il giornalista',
        isAnchored: 'È ancorato',
        closable: 'Chiusura ticket',
        areYouSureAboutCloseIssue: 'Sei sicuro di voler chiudere il ticket?',
        simplifiedIssuesRefreshLabel:
            'Tempo di aggiornamento dell`elenco: secondi',
        reloadApp: 'Ricaricare',
        hideWorkAreasButton:
            'Nascondi il pulsante di selezione dell’area di lavoro',
        hideClassicViewButton:
            'Nascondi il pulsante di visualizzazione classica',
        hideAddIssueButton:
            'Nascondi il pulsante per aggiungere un nuovo problema',
        filesManager: 'Gestore file',
        addFolder: 'Aggiungi cartella',
        upload: 'Carica',
        noFolders: 'Nessuna cartella',
        noFiles: 'Nessun file',
        folders: 'Cartelle',
        folder: 'Cartella',
        files: 'File',
        file: 'File',
        removeFolderFilesQuestion: 'Cosa fare con i file in questa cartella?',
        targetFolder: 'Cartella di destinazione',
        addInstruction: 'Aggiungi istruzione',
        goToFolder: 'Vai alla cartella',
        defaultProdLine: 'Linea di produzione predefinita',
        pin_code_field_type: 'Tipo di codice PIN',
        instructionsAccess: 'Permessi',
        noSections: 'Nessuna sezione',
        requireReadingInstruction: 'Richiedi la lettura dell’istruzione',
        hide_parameter_types: 'Non mostrare i tipi di parametri',
        unknownVersion: 'Sconosciuto',
        displayInstructionEveryTime: 'Mostra l’istruzione ogni volta',
        requireSigningInstructionEveryTime:
            'Richiedi la firma dell’istruzione ogni volta',
        signedInstructions: 'Istruzioni firmate',
        signedAt: 'Data della firma',
        signatures: 'Firme',
        signature: 'Firma',
        systemGroup: 'Gruppo di sistema',
        pickable: 'Selezionabile',
        leaf: 'Elemento selezionato',
        path: 'Percorso',
        displayMode: 'Modalità di visualizzazione',
        chart: 'Grafico',
        preSignedStatement: 'Dichiarazione firmata su carta (completa)',
        myOpenIssues: 'I miei problemi aperti',
        myAssignedIssues: 'Problemi a me assegnati',
        displayParametersOnMainScreen:
            'Visualizza i parametri nella schermata principale',
        lockPin: 'Blocca modifica PIN',
        pinLocked: 'PIN bloccato',
        sortChange: 'Ordine di visualizzazione',
        asc: 'Crescente',
        desc: 'Decrescente',
        alphanumeric: 'Alfanumerico',
        numeric: 'Numerico',
        modificationDate: 'Data di modifica',
        fromNewest: 'Dai più recenti',
        fromOldest: 'Dai più vecchi',
        negation: 'Negazione',
        sortOrderService: 'Servizio',
        newIssueIgnoreFilters:
            'Ignora i filtri applicati durante la creazione di un nuovo ticket',
        refreshInterval: 'Orario di aggiornamento dell`elenco',
        multiline: 'Più righe',
        expanded: 'Espanso',
    },
    placeholders: {
        part: 'Seleziona parte',
        nameSurname: 'Nome e cognome',
        phone: 'Numero di telefono',
        password: 'Password',
        password_confirmation: 'Conferma password',
        email: 'E-mail',
    },
    auth: {
        notYourAccountQuestion: 'Non è il tuo account?',
    },
    signup: {
        registered: 'Successo!',
        emailConfirmed: "L'e-mail è stata confermata.",
        registered_info: 'Chiedi al tuo supervisore di attivare il tuo account',
        confirmEmailAddress:
            'Il tuo account è stato creato, fai clic sul collegamento nell`e-mail per confermare il tuo indirizzo.',
        tokenNotFoundError:
            "L'e-mail è già stata confermata o il collegamento di conferma non è valido.",
    },
    paramTypes: {
        text: 'Testo',
        note: 'Nota',
        integer: 'Intero',
        decimal: 'Decimale',
        date: 'Data',
        time: 'Ora',
        datetime: 'Data e ora',
        color: 'Colore',
        icon: 'Icona',
        boolean: 'No / Sì',
        signature: 'Firma',
    },
    tableHeaders: {
        id: 'ID',
        name: 'Nome',
        path: 'Percorso del file',
        category: 'Categoria',
        equipment: 'Attrezzatura',
        criticality: 'Criticità',
        status: 'Stato',
        created: 'Creato',
        createdBy: 'Creato da',
        fp: 'FP',
        type: 'Tipo',
        designation: 'Denominazione',
        symbol: 'Simbolo',
        serialNumber: 'Numero di serie',
        registryNumber: 'Numero di registro',
        udtNumber: 'Numero UDT',
        manufacturedYear: 'Anno di produzione',
        actions: 'Azioni',
        title: 'Titolo',
        data: 'Data',
        archived: 'Archiviato',
        manufacturerSymbol: 'Simbolo del produttore',
        min: 'Min',
        opt: 'Ott',
        quantity: 'Quantità',
        priority: 'Priorità',
        price: 'Prezzo',
        kind: 'Tipo',
        description: 'Descrizione',
        default: 'Predefinito',
        warning: 'Avvertimento',
        problem: 'Problema',
        fixing: 'Risolvendo',
        changeOrder: 'Ordine di modifica',
        required: 'Necessario',
        icon: 'Icona',
        color: 'Colore',
        subCategories: 'Sottocategorie',
        barCode: 'Codice a barre',
        subLocations: 'Sottolocalizzazioni',
        categories: 'Categorie',
        image: 'Immagine',
        nameSurname: 'Nome e cognome',
        email: 'E-mail',
        phone: 'Numero di telefono',
        function: 'Funzione',
        department: 'Dipartimento',
        accountStatus: 'Stato account',
        shift: 'Turno',
        licenseNumber: 'Numero di licenza',
        issuedDate: 'Data di emissione',
        expiresDate: 'Data di scadenza',
        entries: 'Voci',
        entryNumber: 'Numero di ingresso',
        buyPrice: 'Prezzo di acquisto',
        currentLocationQuantity: 'Quantità nella posizione corrente',
        group: 'Gruppo',
        string: 'Testo',
        integer: 'Intero',
        toolsGroup: 'Gruppo di strumenti',
        manufacturer: 'Produttore',
        shortName: 'Nome breve',
        locationName: 'Nome posizione',
        subLocationsQuantity: 'Quantità sotto-località',
        isWorkingOnIssue: 'Lavorando su un problema',
        openIssues: 'Problemi aperti',
        notificationDate: 'Data di notifica',
        titleAndDescription: 'Titolo e descrizione',
        read: 'Letto',
        created_at: 'Creato',
        received_at: 'Ricevuto',
        started_at: 'Preso',
        arrived_at: 'Arrivato',
        finished_at: 'Finito',
        country: 'Paese',
        city: 'Città',
        progress: 'Progresso',
        deleted_at: 'Elimina data',
        about_issue: 'Si applica al rapporto.',
        action: 'Condividere',
    },
    searchConfig: {
        id: 'Id',
        name: 'Nome',
        shortName: 'Nome breve',
        country: 'Paese',
        email: 'Email',
        group: 'Gruppo',
        planned: 'Programmato',
        category: 'Categoria',
        object: 'Oggetto',
        criticality: 'Criticità',
        fp: 'FP',
        type: 'Tipo',
        designation: 'Denominazione',
        symbol: 'Simbolo',
        serialNumber: 'Numero di serie',
        registryNumber: 'Numero di registro',
        udtNumber: 'Numero UDT',
        manufacturedYear: 'Anno di fabbricazione',
        manufacturerSymbol: 'Simbolo del produttore',
        priority: 'Priorità',
        quantity: 'Quantità',
        buyPrice: 'Prezzo di acquisto',
        kind: 'Tipo',
        toolsGroup: 'Gruppo di strumenti',
        function: 'Funzione',
        department: 'Dipartimento',
        status: 'Stato',
        nameSurname: 'Nome e cognome',
        phone: 'Telefono',
        barCode: 'Codice a barre',
        color: 'Colore',
        icon: 'Icona',
        machine: 'Macchina',
        tool: 'Strumento',
        urgentAndOutdated: 'Urgente e scaduto',
        urgent: 'Urgente',
        expired: 'Scaduto',
        manufacturer: 'Produttore',
    },
    errors: {
        partMustHaveEntriesToCorrectQuantity:
            'La parte deve avere voci per correggere la quantità.',
        error: 'Si è verificato un errore',
        wrongEmail: 'Email non valida',
        wrongPasswordLength: 'La password deve avere almeno 8 caratteri.',
        wrongCodeLength:
            'Il @:common.PIN_CODE_LABEL deve avere almeno 5 caratteri di lunghezza.',
        passwordsNotMatch: 'Le password non corrispondono',
        codesNotMatch: 'I codici non corrispondono',
        codeAlreadyInUse: '@:common.PIN_CODE_LABEL già utilizzato',
        expiresDateLowerThanIssued:
            'La data di scadenza non può essere inferiore alla data di emissione.',
        cannotMoveToTheSameLocation:
            'Impossibile spostarsi nella stessa posizione.',
        locationRequired: 'La posizione è obbligatoria.',
        putQuantity: 'Inserisci la quantità da separare.',
        valueGreaterThanQuantity: 'Il valore è maggiore della quantità totale.',
        wholeCannotBeDistinguished: "L'intero non può essere distinto.",
        yearMustBeGreaterThan: "L'anno deve essere maggiore di ",
        passwordConfirmationNotMatch: 'Le password non corrispondono',
        mustBeInteger: 'Il valore deve essere un numero intero',
        valueTooLong: 'Valore troppo lungo',
        wrongPhoneNumber: 'Numero di telefono non corretto',
        phoneNumberAlreadyTaken: 'Numero di telefono già utilizzato',
        emailAlreadyTaken: 'Email già utilizzata',
        notFound: 'Non trovato',
        sessionExpired: 'Sessione scaduta',
        tenantNotFound:
            "La configurazione indicata non esiste. Controllare l'indirizzo del sito e riprovare.",
        selectEquipmentTypeToConfigureParameters:
            'Seleziona il tipo di attrezzatura per configurare i parametri.',
        noParametersToConfigure:
            'Il tipo di attrezzatura selezionato non ha parametri da configurare.',
        terminalAlreadyRegistered: 'Terminale già registrato.',
        invalidPin: '@:common.PIN_CODE_LABEL non valido',
        eventCollision:
            "Il tempo dell'evento che si vuole aggiungere si sovrappone al tempo di un altro evento.",
        mustStartWithSlash: 'Deve iniziare con /',
        mustBeBetween: 'Il valore deve essere compreso tra:',
        minLength: 'Lunghezza minima: ',
        maxLength: 'Lunghezza massima: ',
        invalidInputForCodeType:
            'Input non valido per il tipo di codice selezionato',
        invalidCodeOrNoPermission:
            'Il @:common.PIN_CODE_LABEL non è valido o non hai sufficienti autorizzazioni per eseguire questa azione.',
        endDateLowerThanStart:
            'La data di fine non può essere inferiore alla data di inizio',
        iconAlreadyTaken: "L'icona è già stata presa",
        notEnoughQuantityInStorage: 'Quantità insufficiente in magazzino.',
        valueTooLow: 'Valore troppo basso',
        valueTooHigh: 'Valore troppo alto',
        maxAvailableQuantity: 'Numero massimo',
        someFilesRejectedDueToSize:
            'Alcuni file sono stati rifiutati a causa delle dimensioni. Dimensione massima del singolo file:',
        cameraDeviceNotFound: 'Dispositivo fotocamera non trovato',
        cameraPermissionDenied:
            "L'accesso alla telecamera del dispositivo è stato bloccato nelle impostazioni del sito; ripristinare le impostazioni del sito e consentire l'utilizzo della telecamera.",
        passwordResetLinkExpired:
            'Il collegamento per reimpostare la password è scaduto',
        noInternetConnection: 'Nessuna connessione internet',
        minimum: 'Minimo',
        mustBeGreaterThan: 'Deve essere maggiore di',
        noEventTypesDefined: 'Nessun tipo di evento definito',
        regexPatternDelimiterError:
            'Il separatore deve essere omesso con il carattere \\ n, ad esempio: \\/',
        emailNotMatchPatternDefiniedInTenant:
            "L'email non corrisponde al modello definito nel tenant",
        max: 'Valore massimo',
        min: 'Valore minimo',
        allLanguagesRequired: 'Completa il valore in tutte le lingue',
        fileTooBigToOpenWillBeDownloaded:
            'Il file è troppo grande per essere aperto, il file verrà scaricato sul tuo dispositivo.',
        surveyNotFound: 'Sondaggio non trovato',
        actionNotEnabled: 'Il codice è inattivo',
        loginRequired: 'Accesso richiesto',
        noPermissionForSelectedFolder:
            "Non hai l'autorizzazione per visualizzare il contenuto di questa directory",
        noFoldersAssignedForTerminal:
            'Non ci sono directory assegnate con istruzioni',
        noChecklistsAssignedToControl:
            'Al controllo selezionato non è assegnata una lista di controllo',
        maximum: 'Massimo',
        versionErrorMessage:
            'Le tue modifiche recenti non sono state salvate perché qualcun altro ha apportato modifiche contemporaneamente. Aggiorna la pagina e riprova.',
        duplicateError: 'Questo valore esiste già',
        fillAllRequiredParameters: 'Completa tutti i parametri richiesti.',
        wrongValue: 'Valore non valido',
        noParametersDefined: 'Nessun parametro definito',
        noFlagsConfigured: 'Nessun flag definito',
        noEnoughParts: 'Articoli insufficienti',
        pinNotBelongsTo: 'Questo @:common.PIN_CODE_LABEL non appartiene a',
        tagNotBelongsTo: 'Questa carta/tag non appartiene a',
        noDictionaryEntries: 'Il dizionario selezionato non contiene voci',
        noConnectionWithServer: 'Nessuna connessione al server',
    },
    navDrawer: {
        dashboard: 'Bacheca',
        issues: 'Problemi',
        timetable: 'Orario',
        machines: 'Macchine',
        tools: 'Strumenti',
        parts: 'Parti',
        storage: 'Magazzino',
        staff: 'Personale',
        configuration: 'Configurazione',
        notes: 'Note',
        notifications: 'Notifiche',
        profile: 'Profilo',
        supervision: 'Supervisione',
    },
    barcodeScanner: {
        barCode: 'Codice a barre',
        id: 'ID',
    },
    categoryPicker: {
        categoryPlaceholder: 'Categoria',
        selectCategory: 'Seleziona categoria',
        noSubcategories: 'Nessuna sottocategoria',
    },
    companies: {
        addCompany: 'Aggiungi azienda',
        editCompany: 'Modifica azienda',
        fullName: 'Nome completo',
        shortName: 'Nome breve',
        group: 'Gruppo',
        country: 'Paese',
        city: 'Città',
        street: 'Strada',
        postCode: 'Codice postale',
        phone: 'Telefono',
        email: 'E-mail',
        website: 'Sito web',
        NIP: 'NIP',
        REGON: 'Registro nazionale delle imprese',
        active: 'Attivo',
        nameRequired: 'Il nome è obbligatorio',
        shortNameRequired: 'Il nome è obbligatorio',
    },
    issues: {
        tookAt: 'Preso alle',
        changeHistory: 'Cronologia modifiche',
        load: 'Carica',
        date: 'Data',
        createdBy: 'Creato da',
        action: 'Azione',
        data: 'Dati',
        created: 'Creato',
        updated: 'Aggiornato',
        deleted: 'Eliminato',
        restored: 'Ripristinato',
        issues: 'Problemi',
        issue: 'Problema',
        by: 'da',
        name: 'Nome',
        applicantsDescription: 'Descrizione del richiedente',
        accidentDescription: "Descrizione dell'accaduto",
        jobDescription: 'Descrizione del lavoro',
        jobSummary: 'Riepilogo del lavoro',
        category: 'Categoria',
        status: 'Stato',
        noFlow: '[Nessun flusso]',
        equipment: 'Attrezzatura',
        criticality: 'Criticità',
        hour: 'Ora',
        formalConfirmation: 'Conferma formale',
        formalConfirmationShortcut: 'CF',
        formalConfirmationRequired: 'CF richiesta',
        parameter: 'Parametro',
        value: 'Valore',
        addIssue: 'Aggiungi problema',
        expectedCompletionDate: 'Data prevista di completamento',
        issueDescription: 'Descrizione del problema',
        partsUsage: 'Utilizzo di parti',
        assignedEmployees: 'Dipendenti assegnati',
        requiredHelp: 'Aiuto richiesto',
        returnParts: 'Restituire parte',
        confirmUnassignEmployeeWhoTakenIssue:
            'Questo dipendente ha preso il problema selezionato. Vuoi rimuoverlo?',
        responsibleUser: 'Utente responsabile',
        responsibleRules: 'Utenti responsabili - regole',
        received_decision_at:
            'Data in cui il notificante è venuto a conoscenza della decisione',
        groupRules: 'Gruppi - regole',
        watchersRules: 'Osservatori - regole',
    },
    machines: {
        machines: 'Macchine',
        xIssuesNotPlanned: ' (non pianificate)',
        noIssues: 'Nessun problema',
    },
    maintenance: {
        maintenance: 'Manutenzione',
    },
    notes: {
        notes: 'Note',
        note: 'Nota',
        title: 'Titolo',
        archived: 'Archiviate',
    },
    parts: {
        parts: 'Pezzi di ricambio',
        warehouseOutgoings: 'Uscite dall' + "'" + ' magazzino',
        fittingEquipmentTypes: 'Tipi di attrezzature compatibili',
        goToEquipmentType: 'Vai al tipo di attrezzatura',
        selectPart: 'Seleziona parte',
        usageReason: 'Motivo',
        usageOtherReason: 'Altro',
        replacements: 'Sostituzioni',
        partEntry: 'Inserimento pezzo',
        goToPart: 'Vai alla parte',
    },
    costCenter: {
        costCenter: 'Centro di costo',
    },
    equipmentTypes: {
        equipment: 'Attrezzatura',
        newEquipmentType: 'Nuovo tipo di attrezzatura',
        fittingParts: 'Pezzi di ricambio compatibili',
        goToParts: 'Vai ai pezzi',
    },
    machinesAndToolsDefinitions: {
        machinesAndToolsDefinitions: 'Definizioni di macchine e attrezzi',
    },
    flows: {
        flow: 'Flusso',
        states: 'Stati',
        transitions: 'Transizioni',
        state: 'Stato',
        default: 'Predefinito',
        warning: 'Avviso',
        problem: 'Problema',
        fixing: 'Risoluzione',
        finished: 'Finito',
        access: {
            create: 'Creazione',
            read: 'Lettura',
            update: 'Modifica',
            delete: 'Rimozione',
            read_unassigned: 'Leggi non assegnato',
            assign: 'Incarico',
            unassign: 'Elimina un compito',
            close: 'Chiusura',
        },
        instructions: {
            default: 'Predefinito',
            fixing: 'Considerazione',
            finished: 'Finito',
        },
        accessLabels: {
            terminal: 'Il terminale che ha segnalato il problema',
            creator: 'L`utente che ha segnalato il problema',
            responsible: 'L`utente responsabile del problema',
            group: 'Il gruppo di utenti responsabile del problema',
            anchor: 'Il terminale a cui è aggiunto il report',
            watchers: 'Utenti che osservano il problema',
        },
        sync_returned_rejected: 'Sincronizzazione - rifiutata',
        sync_returned_finished: 'Sincronizzazione - completata',
        current: 'Attuale',
    },
    parameters: {
        categoryParameter: 'Parametro di categoria',
        equipmentTypeParameter: 'Parametro del tipo di attrezzatura',
    },
    staff: {
        staff: 'Staff',
        editUserAccess: 'Modifica accesso utente',
        permissionsUpdated: 'Permessi aggiornati',
        passwordUpdated: 'Password aggiornata',
        codeUpdated: '@:common.PIN_CODE_LABEL aggiornato',
        changePasswordAndCode: 'Cambia password e @:common.PIN_CODE_LABEL',
        goToLicenses: 'Vai alle licenze',
        editAccess: 'Modifica accesso',
        editUser: 'Modifica utente',
        addUser: 'Aggiungi un nuovo utente',
        nameAndLastname: 'Nome e cognome',
        accountStatus: "Stato dell'account",
        phoneNumber: 'Numero di telefono',
        department: 'Reparto',
        passwordChange: 'Cambio password',
        codeChange: 'Modifica del @:common.PIN_CODE_LABEL',
        newPassword: 'Nuova password',
        confirmNewPassword: 'Conferma nuova password',
        changePassword: 'Cambia password',
        newCode: 'Nuovo @:common.PIN_CODE_LABEL',
        confirmNewCode: 'Conferma il nuovo @:common.PIN_CODE_LABEL',
        changeCode: 'Cambia @:common.PIN_CODE_LABEL',
        addEntry: 'Aggiungi voce',
        editEntry: 'Modifica voce',
        editLicense: 'Modifica licenza',
        addLicense: 'Aggiungi licenza',
        licenseNumber: 'Numero di licenza',
        prefferedLocale: 'Locale preferito',
        oldPassword: 'Vecchia password',
        newPasswordConfirmation: 'Conferma la nuova password',
        reading: 'Visualizzazione',
        creating: 'Creazione',
        updating: 'Aggiornamento',
        deleting: 'Cancellazione',
        importScheduleFile: 'Importa una pianificazione da un file',
        importSchedule: {
            errors: {
                user_not_found: 'Utente non trovato [{user}]',
                user_found_many: '{count} utenti trovati [{user}]',
                event_collision: 'Collisione di eventi',
                hour_cell_parse: 'Errore di analisi della cella',
            },
        },
        oldCode: 'Vecchio @:common.PIN_CODE_LABEL',
        newCodeConfirmation: 'Conferma del nuovo @:common.PIN_CODE_LABEL',
        setAccessBasedOnProfile: "Concedi l'accesso in base al profilo",
        selectAccessMergeType: 'Scegli come combinare le autorizzazioni',
        noAccessesInProfileMessage:
            'Il profilo selezionato non dispone di autorizzazioni. Vuoi sovrascrivere comunque?',
    },
    breadCrumb: {
        configuration: 'Configuration',
        locations: 'Locations',
        home: 'Casa',
        machines: 'Macchine',
        tools: 'Strumenti',
        issues: 'Problemi',
        note: 'Note',
        maintenance: 'Manutenzione',
        parts: 'Parti',
        warehouse: 'Magazzino',
        dictionary: 'Dizionario',
        categories: 'Categorie',
        parameters: 'Parametri',
        costCenter: 'Centro di costo',
        flows: 'Flussi',
        states: 'Stati',
        transitions: 'Transizioni',
        shiftSchemas: 'Schemi dei turni',
        shifts: 'Turni',
        definitionsOfMachinesAndToolsTypes:
            'Definizioni dei tipi di macchine e strumenti',
        add: 'Aggiungi',
        edit: 'Modifica',
        staff: 'Staff',
        license: 'Licenze',
        entries: 'Voci',
        companiesDatabase: 'Database aziende',
        companyManagement: "Gestione dell'azienda",
        companyData: "Dati dell'azienda",
        supportedLanguages: 'Lingue supportate',
        en: 'Inglese',
        pl: 'Polacco',
        es: 'Spanish',
        it: 'Italiano',
        fr: 'Francese',
        ru: 'Russo',
        uk: 'Ucraino',
        pt: 'Portoghese',
        de: 'Tedesco',
        nl: 'Olandese',
        sr: 'Serbo',
        ar: 'Arabo',
        tr: 'Turco',
        he: 'Ebraico',
        zh: 'Cinese',
        defaultLanguage: 'Lingua predefinita',
        notifications: 'Notifiche',
        notificationsManagement: 'Gestione delle notifiche',
        myProfile: 'Il mio profilo',
    },
    attributeTypes: {
        text: 'Testo',
        note: 'Nota',
        integer: 'Integer',
        decimal: 'Decimal',
        date: 'Data',
        time: 'Ora',
        dateTime: 'Data e ora',
        color: 'Colore',
        icon: 'Icona',
        boolean: 'Sì/No',
    },
    costCalculator: {
        costCalculator: 'Calcolatore dei costi',
        waitTime: 'Tempo di attesa',
        serviceTime: 'Tempo di servizio',
        suspendTime: 'Tempo di sospensione',
        totalTime: 'Tempo totale',
        estimatedCost: 'Costo stimato',
        calculate: 'Calcola',
        withoutParts: 'Senza parti',
        timeOfReportingIssue: 'Ora di segnalazione del problema',
        timeOfServiceStart: 'Ora di inizio del servizio',
        timeOfReportingEnd: 'Ora di fine servizio',
        days: 'Giorni',
        ore: 'Ore',
        minutes: 'Minuti',
        enterNewTime: 'Inserisci la nuova ora',
        fromIssueReportToServiceStart:
            "Tempo dal rapporto di emissione all'avvio del servizio",
        fromServiceStartToServiceEnd:
            "Tempo dall'inizio del servizio alla fine del servizio",
        workTime: 'Tempo di lavoro',
        includeAwaitingTime: 'Include il tempo di attesa',
    },
    dashboard: {
        components: {
            myIssues: {
                title: 'I miei problemi',
                assignedToMe: 'Assegnato a me',
                assignedToGroup: 'Assegnato al mio gruppo',
                tookByMe: 'Assegnato da me',
                takeIssueBtn: 'Prendi',
                arriveIssueBtn: 'Sono arrivato',
                finishIssueBtn: 'Finisci',
                history: 'Storia',
                myResponsible: 'Sono responsabile di',
                unassignIssue: 'Annulla ripresa',
            },
        },
    },
    synchronization: {
        enabled: 'Enabled',
        hasHeaders: 'Ha intestazioni',
        leaveFile: 'Lascia il file',
        host: 'Host',
        port: 'Porta',
        protocol: 'Protocollo',
        username: 'Nome utente',
        password: 'Password',
        root: 'Root',
        encoding: 'Codifica',
        separator: 'Separator',
        externalSystemId: 'ID sistema esterno',
        cronExpression: 'Espressione cron',
        appendFullStock: "Aggiungi l'intero stock",
        includeHeaders: 'Includi intestazioni',
        withTrashed: 'Con dati rimossi',
        filePattern: 'Modello di file',
        categoriesRootCategoryId:
            'Categoria principale per categorie importate',
        systemKeyname: 'Identificatore del sistema',
        address: 'Indirizzo',
        token: 'Token',
    },
    processes: {
        created_at: 'Creato a',
        started_at: 'Avviato alle',
        updated_at: 'Aggiornato alle',
        finished_at: 'Finito alle',
        attempts: 'Tentativi',
        queue: 'Coda',
        job_id: 'ID lavoro',
        job_uuid: 'Job uuid',
        progress_max: 'Avanzamento massimo',
        progress_now: 'Avanzamento ora',
        outputMessage: 'Messaggio di uscita',
        status: {
            queued: 'queued',
            executing: 'executing',
            finished: 'finished',
            failed: 'failed',
            retrying: 'retrying',
            undefined: 'non definito',
        },
    },
    weekDays: {
        mo: 'Lunedì',
        tu: 'Martedì',
        we: 'Mercoledì',
        th: 'Giovedì',
        fr: 'Venerdì',
        sa: 'Sabato',
        su: 'Domenica',
    },
    reports: {
        reports: 'Rapporti',
        dateFrom: 'Data di inizio',
        dateTo: 'Data di fine',
        types: {
            operation_sheet: 'Foglio operativo',
            issue_summary: 'Riassunto della problematica',
            grouped_summary: 'Relazione di sintesi',
            storage_operations: 'Operazioni di magazzino',
            part_quantity_export: 'Quantità di parti',
        },
        reportFor: 'Segnala per',
        includeQuantityLeft0: 'Includere parti con quantità nulla',
        dayMinusN: 'Giorno meno n',
        currentWeek: 'Settimana corrente',
        lastWeek: 'Settimana scorsa',
        currentMonth: 'Mese corrente',
        lastMonth: 'Mese scorso',
        currentYear: 'Anno corrente',
        lastYear: 'Anno scorso',
        nDays: 'Numero di giorni',
        todayMinusN: "Oggi meno n"
    },
    logs: {
        comment: 'Commento',
        issues: {
            escalation: 'Escalation ({minutes} min) - notifica inviata',
        },
        issueEmployee: {
            assignedAt: 'Dipendente assegnato:',
            receivedAt: 'Dipendente ha ricevuto:',
            tookAt: 'Dipendente ha assunto:',
            finishedAt: 'Dipendente ha completato:',
            deletedAt: 'Dipendente rimosso dalla segnalazione',
        },
    },
    units: {
        no: 'Pezzo',
        st: 'Impostato',
        pr: 'Paio',
    },
    rrule: {
        frequency: 'Frequenza',
        dtstart: "Data d'inizio",
        tzid: 'Fuso orario',
        until: 'Fino a',
        count: 'Contare',
        interval: 'Intervallo',
        wkst: 'Giorno di inizio',
        byweekday: 'Nei giorni feriali',
        bymonth: 'Per mese',
        yearly: 'Annuale',
        monthly: 'Mensile',
        weekly: 'Settimanalmente',
        daily: 'Quotidiano',
        hourly: 'Ogni ora',
        minutely: 'Minuziosamente',
        secondly: 'In secondo luogo',
        infinitePeriodicityMessage:
            'La periodicità è infinita, a causa delle prestazioni, vengono visualizzate solo 500 righe',
    },
    months: {
        jan: 'Gennaio',
        feb: 'Febbraio',
        mar: 'Marzo',
        apr: 'Aprile',
        may: 'Maggio',
        jun: 'Giugno',
        jul: 'Luglio',
        aug: 'Agosto',
        sep: 'Settembre',
        oct: 'Ottobre',
        nov: 'Novembre',
        dec: 'Dicembre',
    },
    charts: {
        charts: 'Grafici',
        count: 'Numero di problemi',
        total: 'Tempo totale dei problemi (dal report alla fine)',
        serw: 'Tempo totale di riparazione (Ttotal - Twait)',
        mttr1: 'Tempo medio dalla relazione alla fine',
        mttr2: 'Tempo medio dalla ripresa alla fine',
        mtbf: 'Tempo di attività medio tra i problemi',
        mttf: 'Tempo medio al fallimento',
        type: 'Tipo di grafico',
        pie: 'Grafico a torta',
        bar: 'Un grafico a barre',
        query: 'L`ambito dei dati',
        groupBy: 'Raggruppa per',
        sum: 'Somma',
        avg: 'Significare',
        min: 'Minimo',
        max: 'Massimo',
        eq: 'È uguale a',
        neq: 'Non è uguale',
        gt: 'Più di',
        lt: 'Meno di',
        gte: 'Più o uguale',
        lte: 'Meno o uguale',
        in: 'Contiene',
        nin: 'Non contenere',
        btw: 'Fra',
        chartOptions: 'Opzioni del grafico',
        line: 'Grafico a linee',
        mixed: 'Misto',
        chartDisplayOptions: 'Opzioni di visualizzazione',
        fillGaps: 'Riempi gli spazi vuoti',
    },
    tpm: {
        history: 'Storia',
        standardObstacle: 'Ostacolo standard',
        customObstacle: 'Ostacolo personalizzato',
        bhp: 'Sicurezza sul lavoro',
        returnToMainPage: 'Torna alla pagina principale',
        bhpMessage:
            'Se noti dei pericoli per la sicurezza, segnalali immediatamente al tuo superiore diretto e utilizza il modulo di segnalazione situato sotto la bacheca.',
        ok: 'OK',
        send: 'Invia',
        description: 'Descrizione',
        title: 'Titolo',
        realization: 'Realizzazione',
        answer: 'Risposta',
        pendingSurveys: 'Indagini in corso',
        surveys: 'Indagini',
        systemSurvey: 'Indagine di sistema',
        addPage: 'Aggiungi pagina',
        createSurvey: 'Crea indagine',
        addSection: 'Aggiungi sezione',
        addQuestion: 'Aggiungi domanda',
        questionContent: 'Contenuto della domanda',
        answerOption: 'Opzione di risposta',
        addQuestionAnswer: 'Aggiungi opzione di risposta',
        surveyName: "Nome dell'indagine",
        sectionName: 'Nome della sezione',
        noSurveyAnswers: 'Nessuna risposta',
        removeSection: 'Rimuovi sezione',
        published: 'Pubblicato',
        unpublished: 'Non pubblicato',
        pendingPublication: 'Modifiche in attesa di pubblicazione',
        removeQuestion: 'Rimuovi domanda',
        removeAnswer: 'Rimuovi opzione di risposta',
        publish: 'Pubblica',
        unpublish: 'Annulla pubblicazione',
        previewResults: 'Anteprima dei risultati',
        supervisior: 'Responsabile',
        surveyResults: "Risultati dell'indagine",
        surveysEvents: 'Configurazione eventi - indagini',
        operatedBy: 'Gestito da',
        confirmReadInformations: 'Ho letto le informazioni',
        noticeContent: "Contenuto dell'avviso",
        notice: 'Avviso',
        surveyEventName: "Nome dell'evento",
        survey: 'Indagine',
        newIssue: 'Nuova segnalazione',
        surveyEventConfigurationAlreadyExists:
            'Questa configurazione esiste già',
        lastComment: 'Ultimo commento della persona responsabile',
        surveyNotConfigured: 'Indagine non configurata',
        fillSurvey: "Compila l'indagine",
        singleSurveyEntries: 'singoli risultati',
        groupedSurveyEntries: 'Risultati raggruppati',
        publicationId: 'ID pubblicazione',
        publicationDate: 'Data di pubblicazione',
        versionId: 'ID versione',
        answersCount: 'Numero di risposte',
        answers: 'Risposte',
        withoutSupervisor: 'Nessun supervisore',
        defaultSupervisor: 'Supervisore predefinito',
        flowStateFlags: 'Flag di stato',
        qrActionsSettings: "Impostazioni dell'azione del codice QR",
        loginRequired: 'Richiede il login',
        questionContext: 'Il contesto della domanda',
        currentSurveyVersionPreview:
            'Anteprima della versione attuale del sondaggio',
        surveyDescription: 'Descrizione del sondaggio',
        sectionDescription: 'Descrizione della sezione',
        instructions: 'Istruzioni',
        instruction: 'Istruzioni',
        instructionRevisions: 'Revisioni',
        instructionRevision: 'Revisione',
        checklists: 'Liste di controllo',
        checklist: 'Lista di controllo',
        checklistItem: 'Voce dell`elenco',
        manageChecklistFields: 'Gestisci gli elementi dell`elenco',
        checklistItemDisplayOptions: 'Articoli visualizzati',
        revisionAutoRejectedInfo:
            '[Sistema] Rifiutato: creata una nuova revisione',
    },
    accessMergeValues: {
        sum: 'Somma',
        product: 'Prodotto',
        overwrite: 'Sovrascrivere',
    },
    aggregates: {
        count: 'Somma',
        min: 'Minimo',
        max: 'Massimo',
    },
    checklists: {
        itemBasic: 'Elemento fondamentale',
        itemSeparator: 'Separatore',
        itemSection: 'Sezione',
        publish: 'Pubblicare',
        previewCurrentVersion: 'Anteprima della versione attuale',
    },
    cluer: {
        history: 'Storia',
        standardObstacle: 'Ostacolo standard',
        customObstacle: 'Ostacolo personalizzato',
        bhp: 'Sicurezza sul lavoro',
        returnToMainPage: 'Torna alla pagina principale',
        bhpMessage:
            'Se noti dei pericoli per la sicurezza, segnalali immediatamente al tuo superiore diretto e utilizza il modulo di segnalazione situato sotto la bacheca.',
        ok: 'OK',
        send: 'Invia',
        description: 'Descrizione',
        title: 'Titolo',
        realization: 'Realizzazione',
        answer: 'Risposta',
        pendingSurveys: 'Indagini in corso',
        surveys: 'Indagini',
        systemSurvey: 'Indagine di sistema',
        addPage: 'Aggiungi pagina',
        createSurvey: 'Crea indagine',
        addSection: 'Aggiungi sezione',
        addQuestion: 'Aggiungi domanda',
        questionContent: 'Contenuto della domanda',
        answerOption: 'Opzione di risposta',
        addQuestionAnswer: 'Aggiungi opzione di risposta',
        surveyName: "Nome dell'indagine",
        sectionName: 'Nome della sezione',
        noSurveyAnswers: 'Nessuna risposta',
        removeSection: 'Rimuovi sezione',
        published: 'Pubblicato',
        unpublished: 'Non pubblicato',
        pendingPublication: 'Modifiche in attesa di pubblicazione',
        removeQuestion: 'Rimuovi domanda',
        removeAnswer: 'Rimuovi opzione di risposta',
        publish: 'Pubblica',
        unpublish: 'Annulla pubblicazione',
        previewResults: 'Anteprima dei risultati',
        supervisior: 'Responsabile',
        surveyResults: "Risultati dell'indagine",
        surveysEvents: 'Configurazione eventi - indagini',
        operatedBy: 'Gestito da',
        confirmReadInformations: 'Ho letto le informazioni',
        noticeContent: "Contenuto dell'avviso",
        notice: 'Avviso',
        surveyEventName: "Nome dell'evento",
        survey: 'Indagine',
        newIssue: 'Nuova segnalazione',
        surveyEventConfigurationAlreadyExists:
            'Questa configurazione esiste già',
        lastComment: 'Ultimo commento della persona responsabile',
        surveyNotConfigured: 'Indagine non configurata',
        fillSurvey: "Compila l'indagine",
        singleSurveyEntries: 'singoli risultati',
        groupedSurveyEntries: 'Risultati raggruppati',
        publicationId: 'ID pubblicazione',
        publicationDate: 'Data di pubblicazione',
        versionId: 'ID versione',
        answersCount: 'Numero di risposte',
        answers: 'Risposte',
        withoutSupervisor: 'Nessun supervisore',
        defaultSupervisor: 'Supervisore predefinito',
        flowStateFlags: 'Flag di stato',
        qrActionsSettings: "Impostazioni dell'azione del codice QR",
        loginRequired: 'Richiede il login',
        questionContext: 'Il contesto della domanda',
        currentSurveyVersionPreview:
            'Anteprima della versione attuale del sondaggio',
        surveyDescription: 'Descrizione del sondaggio',
        sectionDescription: 'Descrizione della sezione',
    },
    orders: {
        order: 'Ordine',
        releaseDate: 'Data di completamento',
        orderDate: "data dell'ordine",
    },
    production: {
        good: 'Numero di buoni',
        bad: 'Numero di cattivi',
        productionRecords: 'Registro di produzione',
    },
};
