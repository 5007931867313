import Categories from './Categories/CategoriesView.vue';
import WorkSchemas from './WorkSchemas/WorkSchemasIndex.vue';
import TerminalsIndex from './Terminals/TerminalsIndex.vue';
import TerminalsEquipmentIndex from './Terminals/TerminalsEquipmentIndex.vue';
import DashboardConfig from './DashboardConfig/DashboardConfig.vue';
import SynchronizationIndex from './Synchronization/SynchronizationIndex.vue';
import SynchronizationImportsIndex from './Synchronization/Import/SynchronizationImportsIndex.vue';
import SynchronizationExportsIndex from './Synchronization/Export/SynchronizationExportsIndex.vue';
import ExternalSystemsIndex from './ExternalSystems/ExternalSystemsIndex';
import UserTemplatesIndex from './UserTemplates/UserTemplatesIndex';
import UserTemplateNotificationsPreferences from './UserTemplates/UserTemplateNotificationsPreferences';
import UserTemplateGroups from './UserTemplates/UserTemplateGroups';
import SynchronizationCMMSIndex from './Synchronization/CMMS/SynchronizationCMMSIndex.vue';
import InstructionsSettingsIndex from './Instructions/InstructionsSettingsIndex.vue';
import InstructionsAccessesIndex from './Instructions/InstructionsAccesses/InstructionsAccessesIndex.vue';

import {
    EquipmentIndex,
    EquipmentCreate,
    EquipmentShow,
    EquipmentEdit,
} from './EquipmentTypes/all';

export default {
    Categories,
    WorkSchemas,
    TerminalsIndex,
    TerminalsEquipmentIndex,
    DashboardConfig,
    SynchronizationIndex,
    SynchronizationImportsIndex,
    SynchronizationExportsIndex,
    ExternalSystemsIndex,
    UserTemplatesIndex,
    UserTemplateNotificationsPreferences,
    UserTemplateGroups,
    EquipmentIndex,
    EquipmentCreate,
    EquipmentShow,
    EquipmentEdit,
    SynchronizationCMMSIndex,
    InstructionsSettingsIndex,
    InstructionsAccessesIndex
};
