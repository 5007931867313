export const fr = {
    common: {
        PIN_CODE_LABEL: 'Code PIN',
        comment: 'Commentaire',
        year: 'Année',
        configuration: 'Configuration',
        cancel: 'Annuler',
        search: 'Rechercher',
        remove: 'Supprimer',
        confirmationDialogTitle:
            'Êtes-vous sûr de vouloir supprimer cet élément ?',
        selectDate: 'Sélectionnez une date',
        select: 'Sélectionner',
        notFound: 'Non trouvé',
        equipmentPickerPlaceholder: 'Objet équipement',
        searchPlaceholder: 'Rechercher',
        equipmentTypePickerPlaceholder: "Type d'équipement",
        equipmentTypePickerTitle: "Sélectionnez le type d'équipement",
        emptyTableMessage: 'Aucune donnée',
        flowPikcerTitle: 'Sélectionnez le flux',
        selectIcon: 'Sélectionner une icône',
        locationPickerTitle: "Sélectionnez l'emplacement",
        locationPlaceholder: 'Emplacement',
        noSublocations: 'Pas de sous-emplacements',
        locationsTitle: 'Emplacements',
        edit: 'Modifier',
        save: 'Enregistrer',
        added: 'Ajouté',
        updated: 'Mis à jour',
        add: 'Ajouter',
        removed: 'Supprimé',
        reload: 'Recharger',
        confirm: 'Confirmer',
        rollBack: 'Annuler',
        images: 'Images',
        documents: 'Documents',
        download: 'Télécharger',
        saved: 'Sauvegardé',
        date: 'Date',
        hour: 'Heure',
        preview: 'Aperçu',
        data: 'Données',
        attributes: 'Attributs',
        checkList: 'Liste de contrôle',
        name: 'Nom',
        type: 'Type',
        status: 'Statut',
        designation: 'Désignation',
        symbol: 'Symbole',
        serialNumber: 'Numéro de série',
        registryNumber: "Numéro d'enregistrement",
        machineNumber: 'Numéro de machine',
        udtNumber: 'Numéro UDT',
        manufacturedYear: 'Année de fabrication',
        manufacturer: 'Fabricant',
        supplier: 'Fournisseur',
        mainSupplier: 'Fournisseur principal',
        attribute: 'Attribut',
        nameRequired: 'Le nom est requis',
        yearGreaterThan: "L'année doit être supérieure à",
        location: 'Emplacement',
        fieldRequired: 'Ce champ est requis',
        description: 'Description',
        minimumQuantity: 'Quantité minimale',
        optimalQuantity: 'Quantité optimale',
        priority: 'Priorité',
        formatYYYY: 'Format YYYY',
        storageQuantity: 'Quantité en entrepôt',
        quantity: 'Quantité',
        quantityGreaterThan: 'La quantité doit être supérieure à',
        machine: 'Machine',
        tool: 'Outil',
        order: 'Commande',
        required: 'Requis',
        dateFormat: 'Format de date',
        selectDictionary: 'Sélectionner un dictionnaire',
        return: 'Retour',
        parameters: 'Paramètres',
        editCategory: 'Modifier la catégorie',
        addCategory: 'Ajouter une nouvelle catégorie',
        changeColor: 'Changer de couleur',
        noColor: 'Pas de couleur',
        shift: 'Quart de travail',
        start: 'Début',
        end: 'Fin',
        shiftSchema: 'Schéma de quart de travail',
        shifts: 'Quarts de travail',
        dictionary: 'Dictionnaire',
        subEntries: 'Sous-entrées',
        key: 'Clé',
        value: 'Valeur',
        moveUp: 'Monter',
        moveDown: 'Descendre',
        addLocation: 'Ajouter un nouvel emplacement',
        editLocation: "Modifier l'emplacement",
        companiesDatabase: 'Base de données des entreprises',
        flows: 'Flux',
        read: 'Lire',
        write: 'Écrire',
        noImage: "Pas d'image",
        email: 'E-mail',
        function: 'Fonction',
        active: 'Actif',
        notActive: 'Non activé',
        disabled: 'Désactivé',
        entryNumber: "Numéro d'entrée",
        issuedDate: "Date d'émission",
        expiresDate: "Date d'expiration",
        move: 'Déplacer',
        separateForm: 'Séparer de',
        separate: 'Séparer',
        warehouse: 'Entrepôt',
        actions: 'Actions',
        putInCurrentLocation: "Mettre à l'emplacement actuel",
        selectLocationFormRightMenu:
            "Sélectionnez l'emplacement depuis le menu de droite",
        showUnassignedItems: 'Afficher les articles non affectés',
        separated: 'Séparé',
        moved: 'Déplacé',
        categories: 'Catégories',
        welcome: 'Bienvenue',
        loginToSignaloTpm: 'Connectez-vous à Signalo TPM',
        login: 'Se connecter',
        loginByEmailAndPassword: 'Se connecter par email et mot de passe',
        loginByCode: 'Se connecter par @:common.PIN_CODE_LABEL',
        accessCode: "Code d'accès",
        enterEmail: "Entrez l'email",
        enterPassword: 'Entrez le mot de passe',
        enterCode: 'Entrez le @:common.PIN_CODE_LABEL',
        userNotFound: 'Utilisateur non trouvé',
        userNotActivated: 'Utilisateur non activé',
        userDisabled: 'Utilisateur désactivé',
        wrongPassword: 'Mot de passe incorrect',
        locations: 'Emplacements',
        pageNotFound: 'Page non trouvée',
        group: 'Groupe',
        yes: 'Oui',
        no: 'Non',
        hourlyCost: 'Coût horaire',
        price: 'Prix',
        newEntry: 'Nouvelle entrée',
        predictedFinishTime: 'Heure de fin prévue',
        ok: 'Ok',
        archive: 'Archive',
        costs: 'Coûts',
        companyManagement: "Gestion d'entreprise",
        companyData: "Données de l'entreprise",
        supportedLanguages: 'Langues prises en charge',
        en: 'Anglais',
        pl: 'Polonais',
        es: 'Espagnol',
        de: 'Allemand',
        it: 'Italien',
        fr: 'Français',
        ru: 'Russe',
        uk: 'Ukrainien',
        pt: 'Portugais',
        tr: 'Turc',
        nl: 'Néerlandais',
        sr: 'Serbe',
        ar: 'Arabe',
        he: 'Hébreu',
        zh: 'Chinois',
        defaultLanguage: 'Langue par défaut',
        companyLogo: "Logo de l'entreprise",
        notificationsManagement: 'Gestion des notifications',
        category: 'Catégorie',
        today: "Aujourd'hui",
        month: 'Mois',
        week: 'Semaine',
        day: 'Jour',
        close: 'Fermer',
        show: 'Montrer',
        rollBackFormalConfirmation: "Confirmation formelle d'annulation",
        signUp: "S'inscrire",
        nameSurname: 'Nom et prénom',
        phone: 'Numéro de téléphone',
        password: 'Mot de passe',
        password_confirmation: 'Confirmer le mot de passe',
        unassignedItems: 'Articles non assignés',
        currency: 'Devise',
        general: 'Général',
        timezone: 'Fuseau horaire',
        users: 'Utilisateurs',
        groups: 'Groupes',
        productionLine: 'Ligne de production',
        part: 'Pièce',
        fittingParts: 'Pièces adaptées',
        workSchema: 'Plan de travail',
        workSchemas: 'Plans de travail',
        holiday: 'Jour férié',
        changePassword: 'Changer le mot de passe',
        showRest: 'Afficher le reste',
        pageNotPermitted: 'Page non autorisée',
        waitingForActivation: "En attente d'activation",
        editGroup: 'Modifier le groupe',
        addGroup: 'Ajouter un groupe',
        reportProblem: 'Signaler un problème',
        describeOccuredIssue: 'Décrire le problème survenu',
        markAllAsRead: 'Marquer tout comme lu',
        unitType: "Type d'unité",
        unit: 'Unité',
        unitPrice: 'Prix ​​unitaire',
        notConfigured: 'Non configuré',
        rowsPerPage: 'Lignes par page',
        rowsOfPage: 'de',
        true: 'Vrai',
        false: 'Faux',
        export: 'Exportation',
        chooseFileFormat: 'Choisir le format de fichier',
        exported: 'Exporté',
        terminals: 'Terminaux',
        terminal: 'Terminal',
        selectEquipment: "Sélectionnez l'équipement",
        futureIssues: 'Problèmes futurs',
        scheduledOn: 'Prévu le',
        planned: 'Prévu',
        notPlanned: 'Non prévu',
        showOpenIssues: 'Afficher les problèmes ouverts',
        pin: 'NIP',
        assignedEquipment: 'Équipement assigné',
        terminalEquipment: 'Équipement terminal',
        detach: 'Détacher',
        attach: 'Attacher',
        dashboardConfig: 'Configuration du tableau de bord',
        addColumn: 'Ajouter une colonne',
        addRow: 'Ajouter une ligne',
        removeRow: 'Supprimer une ligne',
        registerTerminal: 'Enregistrer le terminal',
        issueGeneratesCost: "L'indisponibilité génère des coûts",
        selectColor: 'Sélectionner la couleur',
        belowMinimum: 'En dessous du minimum',
        belowOptimum: "En dessous de l'optimum",
        synchronization: 'Synchronisation',
        synchronizationExport: 'Exportation des pièces en stock',
        synchronizationImport: 'Importation des pièces en stock',
        mapping: 'Cartographie',
        returnTo: 'Retourner à',
        jobStatusses: 'Processus',
        hidden: 'Caché',
        editEvent: "Modifier l'événement",
        threeDays: '3 jours',
        addEvent: 'Ajouter un événement',
        plannedIssues: 'Problèmes planifiés',
        currentService: 'Service actuel',
        searchLocation: "Rechercher l'emplacement",
        all: 'Tous',
        unfinished: 'Inachevé',
        numberOfIssues: 'Quantité de problèmes',
        unknown: 'Inconnu',
        partEntry: 'Pièce (entrée)',
        foundItems: 'Articles trouvés',
        output: 'Sortie',
        copied: 'Copié',
        link: 'Lien',
        authorizationRequired: 'Autorisation requise',
        authorizationRequiredDescriptionUser:
            "Malheureusement, vous n'avez pas d'autorisation suffisante. Demandez à une personne autorisée d'approuver l'action.",
        authorizationRequiredDescriptionTerminal:
            "Utilisez votre @:common.PIN_CODE_LABEL d'accès pour autoriser l'action.",
        showAll: 'Afficher tout',
        showClassicView: 'Afficher la vue classique',
        noIssuesInCategory: 'Aucun problème dans cette catégorie.',
        numberOfIssuesInThisCategoryAndSubcategories:
            'Nombre de problèmes dans cette catégorie et ses sous-catégories.',
        numberOfIssuesInThisCategory:
            'Nombre de problèmes dans cette catégorie.',
        minBarcodeLength: 'Longueur minimale du code-barres',
        currentPage: 'Page actuelle',
        allPages: 'Toutes les pages',
        selectFormat: 'Sélectionner le format',
        provided_currency: 'Devise fournie',
        provided_quantity: 'Quantité fournie',
        provided_unit: 'Unité fournie',
        provided_unit_price: 'Prix ​​unitaire fourni',
        unit_price: 'Prix ​​unitaire',
        quantity_available: 'Quantité disponible',
        editBarcode: 'Modifier le code-barres',
        crews: 'Équipes',
        scheduleEventTypes: "Types d'événements de planification",
        crew: 'Équipe',
        scheduleEvent: 'Événement de planification',
        available: 'Disponible',
        'App\\Models\\Crew': 'Équipe',
        'App\\Models\\User': 'Utilisateur',
        'App\\Models\\Equipment': 'Équipement',
        Crew: 'Équipe',
        User: 'Utilisateur',
        Equipment: 'Équipement',
        scheduleEvents: 'Événements de planification',
        event: 'Événement',
        externalSystems: 'Systèmes externes',
        user: 'Utilisateur',
        availability: 'Disponibilité',
        inheritColorFromParentIfCustomNotSet:
            "Hériter de la couleur du parent si la couleur personnalisée n'est pas définie",
        pickFromAllParts: 'Choisir parmi toutes les pièces',
        takeTool: "Prendre l'outil",
        returnTool: "Rendre l'outil",
        personWhoTake: 'Personne qui prend',
        personWhoReturn: 'Personne qui rend',
        takenAt: 'Pris à',
        takenBy: 'Pris par',
        toolIssuedBy: 'Outil émis par',
        commentTake: 'Commentaire de prise',
        commentReturn: 'Commentaire de retour',
        change: 'Changer',
        onlyWithActiveRMIssues: 'Uniquement avec les demandes RM actives',
        externalSystem: 'Système externe',
        rejectIssue: 'Rejeter le problème',
        withdrawIssueRejection: 'Retirer le rejet du problème',
        rejectionReason: 'Raison du rejet',
        rejected: 'Rejeté',
        showCrewMembers: "Afficher les membres de l'équipe",
        crewMembers: "Membres de l'équipe",
        helpGroup: 'Service',
        partsTakenByUser: "Pièces prises par l'utilisateur",
        pickParts: 'Pièces de départ',
        selectPersonWhoTakes: 'Sélectionner la personne qui prend',
        changePersonWhoTakes: 'Modifier la personne qui prend',
        quantityToPick: 'Quantité à prendre',
        pickLocation: 'Emplacement de prise en charge',
        selectPartsToPick: 'Sélectionner les pièces à prendre',
        partSelected: 'Pièce sélectionnée',
        scanCodeOr: 'Numériser le code ou',
        returnPart: 'Rendre la pièce',
        myTakenParts: 'Pièces prises par moi',
        takenFromLocation: "Prise à partir de l'emplacement",
        takenQuantity: 'Quantité prise',
        providedUnit: 'Unité fournie',
        providedQuantity: 'Quantité fournie',
        selectFromStorage: 'Sélectionner à partir du stockage',
        selectFromMyPickedParts: 'Sélectionner à partir de mes pièces prises',
        exportProcessInProgressMessage:
            "Le processus d'exportation de données vient de démarrer, lorsqu'il sera terminé, vous verrez une notification correspondante contenant un lien vers le fichier.",
        yourDocumentIsReady: 'Votre document est prêt',
        rotateScreen: "Faire pivoter l'écran",
        addRule: 'Ajouter une règle',
        editRule: 'Modifier la règle',
        summary: 'Résumé',
        onlyAvailableEmployees: 'Afficher uniquement les employés disponibles',
        calculated_unit_price: 'Prix unitaire converti',
        calculated_quantity: 'Quantité convertie',
        next: 'Suivant',
        maintenanceMode: 'Mode de maintenance',
        applicationMayBeUnavailableForFewMinutes:
            "L'application peut être indisponible pendant quelques minutes",
        appWillRefreshIn: "L'application sera actualisée dans",
        landscape: 'Paysage',
        portrait: 'Portrait',
        orientation: 'Orientation',
        format: 'Format',
        service: 'Domestiques',
        leader: 'Contremaîtres',
        defaultView: 'Affichage par défaut',
        view: 'Vue',
        oneDay: '1 jour',
        object: 'Objet',
        finished: 'Terminé',
        onlyNotFinished: 'Seulement pas fini',
        generate: 'Générer',
        error: 'Error',
        cell: 'Celda',
        resolutionSummary: 'Résumé de fin',
        rejected_at: 'Date de rejet',
        rejected_by: 'Rejeté par',
        finishedByUser: 'Terminé par',
        formalConfirmByUser: 'Confirmation formelle par',
        formalConfirmAt: 'Date de confirmation formelle',
        valueDeleted: '[Valeur supprimée]',
        hasNotPlannedIssue: "N'a pas prévu de problèmes",
        showInStorage: 'Afficher dans le stockage',
        changeCode: 'Changer le @:common.PIN_CODE_LABEL',
        staffProfiles: 'Profils des employés',
        profile: 'Profil',
        user_bug: "Erreur d'application",
        user_kaizen: 'Suggestion de changement',
        changeSuggestions: 'Suggestions de modification',
        showOnlyCurrentlyAvailable:
            'Afficher uniquement actuellement disponible',
        resetPassword: 'Rappeler le mot de passe',
        forgotPassword: 'Mot de passe oublié',
        resetPasswordMailSent:
            "Nous avons envoyé un lien pour définir un nouveau mot de passe à l'adresse e-mail fournie",
        manyEventsThisTime: 'De nombreux événements en ce moment',
        importScheduleCheckboxLabel:
            'Supprimer les événements planifiés du mois sélectionné pour les employés répertoriés dans le fichier',
        inheritedEvent: 'Événement hérité',
        inheritedModifiedEvent: 'Événement hérité modifié',
        equipmentId: "ID de l'équipement",
        entryId: "ID d'entrée",
        partId: 'ID de la pièce',
        suggestionsEmail:
            'E-mail à laquelle les suggestions de modification seront envoyées',
        meters: 'Compteurs',
        scanCardToAuth:
            "Présentez la carte au lecteur ou saisissez votre @:common.PIN_CODE_LABEL d'accès pour confirmer votre identité.",
        scanCard: 'Scannez la carte',
        cart: "Boîte d'échange",
        emptyCardMessage:
            'Lancez la numérisation des produits ou sélectionnez-les dans la liste pour les ajouter au panier.',
        terminalMode: 'type de borne',
        askPermittedUserToConfirmPartsExchange:
            'Demandez à la personne autorisée de confirmer la libération de la pièce en scannant la carte ou en saisissant le @:common.PIN_CODE_LABEL',
        partsIssued: 'Pièces libérées',
        areYouSureAboutCancel: 'Êtes-vous sûr de vouloir annuler?',
        areYouSureAboutRemoveFromCart:
            "Êtes-vous sûr de vouloir supprimer cet article du Boîte d'échange?",
        standard: 'Standard',
        idNumber: "Numéro d'identification",
        takeParts: 'Prendre des pièces',
        returnParts: 'Rendre des pièces',
        quantityToReturn: 'Quantité à rendre',
        partsPendingApproval: "Pièces en attente d'approbation",
        partsReturned: 'Pièces rendues',
        partsAddedToPendingApproval:
            "Pièces ajoutées à l'approbation en attente",
        approvePartsReturn: 'Approuver le retour de pièces',
        partReturnApproved: 'Retour de pièce approuvé',
        partUsage: 'Utilisation de la pièce',
        awaitsReturn: 'En attente de retour',
        entrySelection: "Sélection d'entrée",
        correctQuantity: 'Quantité correcte',
        quantityCorrection: 'Correction de la quantité',
        entrySelectionAsc: "Sélection d'entrée (ascendant)",
        entrySelectionDesc: "Sélection d'entrée (descendant)",
        metersSettings: 'Paramètres des compteurs',
        countableFields: 'Champs comptables',
        equipmentTypes: "Types d'équipement",
        fieldName: 'Nom du champ',
        countableField: 'Champ comptable',
        partsStatusTaken: 'Pièces prises',
        rejectPartsReturn: 'Rejeter le retour de pièces',
        areYouSureAboutRejectPartsReturn:
            'Êtes-vous sûr de vouloir rejeter le retour de pièces?',
        partReturnRejected: 'Retour de pièce rejeté',
        counterFields: 'Champs de compteur',
        counterName: 'Nom du compteur',
        counterFieldName: 'Nom du champ de compteur',
        counterId: 'ID du compteur',
        counterUUID: 'UUID du compteur',
        counterPickerPlaceholder: 'Sélectionnez un compteur',
        pickFromPartsList: 'Choisir dans la liste des pièces',
        changeLocation: "Changer l'emplacement",
        isPartStorageRoot: 'Racine du stockage de pièces',
        areYouSureAboutArchiveIssue:
            'Êtes-vous sûr de vouloir archiver cette question?',
        ganttChart: 'Diagramme de Gantt',
        table: 'Tableau',
        fittingEquipment: 'Équipement de montage',
        notAssigned: 'Non assigné',
        counters: 'Compteurs',
        machineHourlyCostNotProvided:
            "Le coût horaire de la machine n'est pas fourni",
        eventTypesMightBeDefinedHere:
            "Les types d'événements peuvent être définis ici",
        toolsTakenByUser: "Outils pris par l'utilisateur",
        predictedReturnDate: 'Date de retour prévue',
        estimatedTimeOfUsingToolInHours:
            "Temps estimé d'utilisation de l'outil (en heures)",
        showOnlyAvailable: 'Afficher uniquement disponible',
        toolTakeLog: "Journal de prise d'outil",
        takeDate: 'Date de prise',
        returnDate: 'Date de retour',
        personWhoAcceptReturn: 'Personne qui accepte le retour',
        statisticStrategy: "Stratégie d'accessibilité",
        EquipmentPlannedStrategy: 'Selon le plan de travail',
        EquipmentConstantAvailabilityStrategy: 'Toujours disponible',
        actionAfterIssueFinish: 'Action après la fin de la question',
        enabled: 'Activé',
        categoryOfNewIssue: 'Catégorie de nouvelle question',
        workNotStarted: 'Travail non commencé',
        colorInherited: 'Couleur héritée',
        abcClassification: 'Classification ABC',
        xyzClassification: 'Classification XYZ',
        classification: 'Classification',
        filters: 'Filtres',
        wipeFilters: 'Effacer les filtres',
        takePartsFromUser: "Prendre des pièces à l'utilisateur",
        selectPersonWhoReturns: 'Sélectionnez la personne qui retourne',
        changePersonWhoReturns: 'Changer la personne qui retourne',
        returnLocation: 'Emplacement de retour',
        returnQuantity: 'Quantité de retour',
        globalSettings: 'Paramètres globaux',
        delimiter: 'Délimiteur',
        fieldRules: 'Règles de champ',
        fieldValue: 'Valeur du champ',
        user_email_regex: "Regex de l'e-mail de l'utilisateur",
        barcodeLength: 'Longueur du code à barres',
        partLine: 'Ligne de pièce',
        partFamily: 'Famille de pièces',
        partBatch: 'Lot de pièces',
        testValueValid: 'Valeur test valide',
        testValueNotValid: 'Valeur de test non valide',
        canBePicked: 'Peut être choisi',
        issue_create_photos: 'Ajouter des photos à un nouveau numéro',
        issue_create_documents: 'Ajouter des documents à un nouveau numéro',
        propagateValues: 'Propagation de la valeur',
        archived: 'Archivé',
        escalations_tab: 'Escalades',
        minute: 'Minute',
        days: 'Jours',
        hours: 'Heures',
        minutes: 'Minutes',
        warnDays: 'Alerte avant expiration (jours)',
        licenseType: 'Type de licence',
        licenseGroup: 'Groupe de licences',
        attachments: 'Pièces jointes',
        attachment: 'Pièce jointe',
        revisionChanges: 'Modifications introduites',
        document: 'Document',
        noInstructionSelected: 'Aucune instruction sélectionnée',
        instructionGroups: "Groupes d'instructions",
        instructionGroup: 'Groupe d’instruction',
        addOption: 'Ajouter une option',
        ordinal: 'Valeur ordinale',
        parameterName: 'Le nom du paramètre',
        fontColor: 'Couleur de la police',
        backgroundColor: "Couleur de l'arrière plan",
        columns: 'Le nombre de colonnes',
        colspan: 'Largeur (colonnes)',
        products: 'Des produits',
        controlsTemplates: 'Modèles de contrôle',
        controlType: 'Type de contrôle',
        controls: 'Inspections',
        controlTemplate: 'Modèle de contrôle',
        product: 'Produit',
        checklistsAttachedToControlTemplate:
            'Listes de contrôle affectées aux inspections',
        removeChecklistFromControlTitle:
            'Êtes-vous sûr de vouloir supprimer cette liste de votre contrôle?',
        attachChecklistToControl: 'Attribuer une liste à contrôler',
        pickedItem: 'Élément sélectionné',
        pick: 'Choisir',
        fillControl: 'Complétez le chèque',
        back: 'Retour',
        control: 'Contrôle',
        duplicate: 'Dupliquer',
        isStorageRoot: "Emplacement principal de l'entrepôt",
        manageStorageAccess: "Gérer l'accès aux entrepôts",
        manageVisibleColumns: 'Ajuster la visibilité des colonnes',
        columnName: 'Nom de colonne',
        visible: 'Visible',
        entriesLocation: 'emplacement des entrées',
        details: 'Détails',
        otherData: 'Autre informations',
        moveAllEntriesFromCurrentLocation:
            'Déplacer toutes les entrées de cet emplacement',
        searchInCurrentLocation: 'Rechercher dans votre emplacement actuel',
        transition: 'Transizione',
        notification: 'Notifica',
        emails: 'Indirizzi email',
        phones: 'Numeri di telefono',
        addAction: "Aggiungi un'azione",
        message: 'Messaggio',
        application: 'App',
        always: 'Sempre',
        sms: 'SMS',
        loginTo: 'Se connecter à',
        appName: 'Signalo TPM',
        index: 'Indice',
        showCodeOwnerNameDuringParing:
            "Afficher le nom du propriétaire du @:common.PIN_CODE_LABEL lors de l'association avec une carte/étiquette",
        showUsernameInCart: "Afficher le nom d'utilisateur dans le panier",
        sampleButtonPlaceholder: 'Exemple de bouton',
        preferences: 'Préférences',
        buttonsSize: 'Taille du bouton',
        erase: 'effacer',
        selectDictionaryValue: 'Sélectionner la valeur du dictionnaire',
        conditions: 'Conditions',
        partsCartTerminalRequireApproveOnPartsReturn:
            'Exiger une confirmation ou un rejet lors du retour des pièces téléchargées',
        issueCreated: 'Un ticket a été créé',
        allowedIssuesCategories: 'Catégories de problèmes autorisés',
        hiddenFields: 'Champs masqués',
        defaultValues: 'Les valeurs par défaut',
        paddingBottom: 'Espace vide en bas',
        allVisibleFieldsRequired:
            'Exiger que tous les champs visibles soient remplis',
        url: 'URL',
        kind: 'Gentil',
        forkfleetConfigId: 'Configuration Forkfleet',
        create: 'Créer',
        terminate: 'Mettre fin',
        parent: 'Parent',
        phoneNumbers: 'Les numéros de téléphone',
        storageDefaults: 'Valeurs de stockage par défaut',
        clientSignatureEnabled: 'Signature de la personne récupérante',
        warehousemanSignatureEnabled: 'Signature du magasinier',
        storage_client_signature_enabled:
            "Signature de la personne qui récupère les articles de l'entrepôt",
        storage_client_signature_required:
            "Exiger la signature de la personne qui récupère les articles de l'entrepôt",
        storage_warehouseman_signature_enabled:
            "Signature de la personne qui libère les articles de l'entrepôt",
        storage_warehouseman_signature_required:
            "Exiger la signature de la personne qui libère les articles de l'entrepôt",
        clientReturnSignatureEnabled: 'Signature de la personne qui revient',
        warehousemanReturnSignatureEnabled:
            'Signature de la personne acceptant le retour',
        storage_client_return_signature_enabled:
            'Signature de la personne qui retourne les articles',
        storage_client_return_signature_required:
            'Exiger une signature de la personne qui retourne les articles',
        storage_warehouseman_return_signature_enabled:
            "Signature de la personne acceptant le retour de l'article",
        storage_warehouseman_return_signature_required:
            "Exiger la signature de la personne acceptant le retour de l'article",
        channels: 'Canaux',
        manageState: "Gérer l'état",
        assignWorker: 'Affecter un employé',
        mergeEntriesOfTheSameLocation:
            'Fusionner les entrées du même emplacement',
        permission: 'Autorisation',
        areYouSureAboutMergeAllEntriesOfThisLocation:
            'Êtes-vous sûr de vouloir fusionner toutes les entrées à cet emplacement ?',
        manageFilters: 'Gérer les filtres',
        responsible_assign_creator_when_no_rule:
            '[Rapports] – Désigner le créateur comme responsable si la règle ne correspond pas',
        recreateIssue: 'Recréer le ticket',
        addNewDictionary: 'Ajouter un nouveau dictionnaire',
        label: 'Étiquette',
        issuesWithRelatedCategories: 'Inclure des sous-catégories',
        issuesLimit: 'Limite des problèmes',
        issuesLimits: 'Enjeux - limites',
        issueDefaults: 'Rapports - valeurs par défaut',
        flatCategoryPickerLabel: 'Structure de catégorie plate',
        statementsTemplates: 'Modèles de relevés',
        statementTemplate: 'Modèle de relevé',
        content: 'Contenu',
        readonly: 'Lecture seulement',
        defaultValue: 'Valeur par défaut',
        fillParametersToChangeState:
            'Remplissez les valeurs des paramètres ci-dessous pour changer le statut',
        formatPattern: 'Modèle de formatage',
        countableIncFields: 'Configuration de la numérotation des documents',
        mainShift: 'Changement principal',
        singleEvent: 'Entrée unique',
        seriesOfEvents: "Une série d'entrées",
        omitWeekends: 'Évitez les week-ends',
        allEntriesSaved: 'Toutes les entrées ont été ajoutées',
        surveyResults: "Résultats de l'enquête",
        qrActionsSettings: "Paramètres d'action du code QR",
        pendingStatements: 'Déclarations à signer',
        statementsResults: 'Déclarations',
        statements: 'Déclarations',
        statement: 'Déclaration',
        userCanChangeContent: "L'utilisateur peut modifier le contenu",
        statementsEvents: "Configuration d'événement - instructions",
        helpdesk: "Bureau d'aide",
        terminalId: 'Identifiant du terminal',
        operatorId: "Identifiant de l'opérateur",
        newConversation: 'Nouveau fil de discussion',
        formula: 'Formule',
        fill: 'Complet',
        webMessage: 'Message Web',
        noResponsibleUser: 'Aucun utilisateur responsable',
        filledAtDate: "Date d'achèvement",
        filledBy: 'Terminé par',
        parts_quantity_alert_rule:
            '[Quantité de pièces et matériaux] - Attention',
        employeeNumber: "Numéro d'employé",
        groupsAssignedToCategory: 'Groupes affectés à des catégories',
        number: 'Nombre',
        customers: 'Clients',
        customer: 'Client',
        projects: 'Projets',
        project: 'Projet',
        report: 'Rapport',
        reportGenerationInProgress: 'Le rapport génère',
        storageStatementGroup: 'Groupe de déclaration d`entrepôt',
        simplifiedHandling: 'Gestion simplifiée des notifications',
        selectWorkArea: 'Sélectionnez votre espace de travail',
        workAreaSelection: 'Sélection d`une zone de travail',
        access: 'Accéder',
        restrictAccess: 'Accès restreint',
        anchor: 'Ancrage',
        issueCreator: 'La personne qui crée le rapport',
        hideSideMenu: 'Masquer le menu latéral',
        appendGroups: 'Groupes à ajouter',
        revokeGroups: 'Groupes à supprimer',
        override: 'Écraser',
        restrictGroupAccess: "Restreindre l'accès aux groupes",
        flag: 'Drapeau',
        flags: 'Drapeaux',
        manageFlags: 'Gérer les drapeaux',
        icon: 'Icône',
        appendFlags: 'Drapeaux à ajouter',
        revokeFlags: 'Drapeaux à supprimer',
        noFlagsConfigured: 'No hay banderas definidas',
        checkIsTerminal: 'Vérification du terminal',
        noConditionsDefined: 'Aucune condition définie',
        checkIsResponsible: 'Contrôle de responsabilité',
        createdOn: 'Signalé depuis',
        replaceTimespan:
            "Durée de conservation à partir du moment de l'émission",
        months: 'Mois',
        handoversTemplates: 'Modèles de transferts',
        handoverTemplate: 'Modèle de transfert',
        employeeFunction: 'Fonction du salarié',
        takeHandover: 'Prendre le relais',
        handovers: 'Remises',
        expiringHandovers: "Articles qui approchent de leur date d'expiration",
        years: 'Années',
        taken_parts_expiry_warning:
            "Avertissement avant la date d'expiration des éléments téléchargés",
        andonView: "Vue d'Andon",
        nfc: 'NFC',
        rfid: 'RFID',
        other: 'autre',
        tags: 'Cartes / Mots clés',
        tag: 'Carte / Étiqueter',
        userPinCode: "@:common.PIN_CODE_LABEL de l'utilisateur de la carte",
        emailConfirmation: "Confirmation de l'adresse e-mail",
        from: 'Depuis',
        to: 'À',
        handoversHistory: 'Historique des transferts',
        partUsagesHistory: "Historique d'utilisation des pièces",
        signaturePinTagConfirmationText1:
            'Signer avec le @:common.PIN_CODE_LABEL',
        signaturePinTagConfirmationText2: 'ou scannez votre carte.',
        signedWithPinCode: 'Signé avec @:common.PIN_CODE_LABEL',
        signedWithTAG: 'Signé avec carte/étiquette',
        test_mode: "Mode d'essai",
        confirmWorkFinish: 'Confirmer la fin des travaux',
        workStarted: 'Début des travaux',
        workFinished: 'Fin de travail',
        workTimeRegister: 'Registre du temps de travail',
        measureTypes: 'Types de mesures',
        measureType: 'Type de mesure',
        measurable: 'Indicateur mesurable',
        measurables: 'Indicateurs mesurables',
        measurements: 'La mesure',
        measurement: 'La mesure',
        measuredAt: 'Date de mesure',
        currentVersion: 'Version actuelle',
        employee: 'Employé',
        pin_code_label: 'Nom du code PIN',
        orders: 'Ordres',
        productionPlan: 'Plan de production',
        productionPlans: 'Plans de production',
        taktTime: 'Temps de takt',
        target: 'Cible',
        seconds: 'Secondes',
        inheritValuesFromTenantSettings:
            'Hériter des valeurs par défaut des paramètres globaux',
        matrices: 'Matrices',
        manageMatrice: 'Gérer la matrice',
        deletionOfMatrixColumnWarnMessage:
            "La suppression d'une colonne supprime les valeurs.",
        removeColumn: 'Supprimer une colonne',
        editColumn: 'Modifier la colonne',
        column: 'Colonne',
        row: 'Rangée',
        evaluated: 'évalué',
        of: 'de',
        auto_pin_generation: '@:common.PIN_CODE_LABEL - Génération automatique',
        or: 'ou',
        rename: 'Renommer',
        check_only_on_transition:
            'Vérifiez uniquement lorsque le statut change',
        license: 'Pouvoirs',
        confirmWorkStart: 'Confirmer le début des travaux',
        workStartAt: 'Vous commencez à travailler à',
        workFinishAt: 'Tu finis ton travail o',
        workStartedAt: 'Commencer',
        workTimeMessage: 'Tu as surmené',
        haveANiceDay: 'Passe une bonne journée!',
        farewellText: 'Texte sur le dernier écran',
        namedayLabel: 'Ils célèbrent leur fête',
        sportEventNearby: 'Le prochain événement sportif',
        autoTranslate: 'Traduction automatique',
        auto_translation: 'Traduction automatique',
        awaitingAutoTranslation: 'En attente de traduction automatique',
        inheritAllDefaultValues:
            'Hériter de toutes les valeurs par défaut des paramètres globaux',
        escalations: 'Escalades',
        escalation: 'Escalade',
        notTaken: 'Pas commencé',
        notFinished: 'Inachevé',
        issueLastChange: 'Dernier changement',
        mode: 'Mode',
        repeatLastEscalation: "Répéter la dernière valeur d'escalade",
        manageActions: 'Gérez vos actions',
        productionLines: 'Lignes de production',
        productionTerminal: 'Terminal de production',
        currently: 'Actuellement',
        currentPlan: 'Plan actuel',
        currentlyInProduction: 'Actuellement produit',
        setProduct: 'Définir le produit',
        second: 'Secondes',
        work: 'Travail',
        chartConfiguration: 'Configuration du graphique',
        revisionsToConsider: 'Révisions à prendre en considération',
        allInstructions: 'Toutes les instructions',
        decider: 'Déterminant',
        considerRevision: 'Envisager une révision',
        and: 'et',
        acceptInstruction: 'Acceptez les instructions',
        accept: 'accepter',
        instructionsToAccept: 'Instructions à lire',
        open: 'Ouvrir',
        assignedAt: 'Date de mission',
        confirmationAt: 'Date de confirmation',
        mainInstruction: 'Instruction par défaut',
        setAsDefault: 'Définir par défaut',
        instructionsFlows: 'Instructions - flux',
        newRevision: 'Nouvelle révision',
        playlist: 'Liste des vidéos',
        selectVideo: 'Sélectionnez un film',
        selectVideos: 'Sélectionnez des films',
        uploadNewVideo: 'Téléchargez une nouvelle vidéo',
        upload_date: 'Date ajoutée',
        thumbnail: 'vignette',
        duration: 'Durée',
        uploadFromDrive: 'Télécharger depuis le disque',
        selectAssets: 'Choisissez des matériaux',
        assets: 'Matériaux',
        video: 'Film',
        doYouWantToAddNewMarker:
            'Souhaitez-vous ajouter une nouvelle balise ici ?',
        areYouSureAboutRemoveMarker:
            'Êtes-vous sûr de vouloir supprimer cette balise ?',
        plants: "Usines d'usine",
        plant: 'Usine',
        buildings: 'Bâtiments',
        building: 'Bâtiment',
        floors: 'Sols',
        floor: 'Sol',
        map: 'Carte',
        areYouSureAboutMoveMarker:
            'Êtes-vous sûr de vouloir déplacer ce marqueur ?',
        marker: 'Marqueur',
        addNewMarkerTooltipMessage:
            'Pour ajouter un nouveau marqueur, faites un clic droit sur la carte.',
        skillmatrix: 'Skillmatrix',
        indirectProcess: 'Compétences non linéaires',
        skillLevelConfig: 'Niveaux de compétence',
        indirectSkillGroups: 'Groupes de compétences non linéaires',
        approved: 'Confirmé',
        approvedBy: 'Approbateur',
        skillLevel: 'Niveau de compétence',
        requiredSkillLevel: 'Niveau de compétences requis',
        step: 'Étape',
        image: 'Photo',
        skills: 'Compétences',
        visits: 'Visites',
        responsiblePerson: 'Personne responsable',
        company: 'Société',
        contactData: 'Coordonnées',
        visit: 'Visite',
        inProgress: 'En cours',
        gatehouse: 'Gatehouse',
        confirmEnter: 'Confirmer la saisie',
        confirmExit: 'Confirmer la sortie',
        enter: 'Entrée',
        exit: 'Sortie',
        entryGuard: 'La personne qui laisse entrer',
        exitGuard: 'La personne qui laisse sortir',
        loginAsThisUser: "Connectez-vous en tant qu'utilisateur",
        minBrowserVersion: 'Version minimale du navigateur requise',
        approve: 'Confirmer',
        cancelApproval: 'Annuler la validation',
        workPermit: 'Permis de travail',
        workPermits: 'Permis de travail',
        visit_summary: 'Résumé de la visite',
        createVisit: 'Créer une visite basée sur un permis',
        employeesAtThePlant: 'Ouvriers de l`usine',
        purpose: 'Cible',
        allowedAreas: 'Zones autorisées',
        switchEmployees: 'Remplacer un employé',
        localIssues: 'Rapports locaux',
        cmmsCategoriesSync: 'Synchronisation des catégories GMAO',
        terminalsGroups: 'Groupes de terminaux',
        isTree: 'Arbre',
        isReporter: 'Le journaliste est-il',
        isAnchored: 'Est ancré',
        closable: 'Clôture des tickets',
        areYouSureAboutCloseIssue:
            'Etes-vous sûr de vouloir clôturer le ticket ?',
        simplifiedIssuesRefreshLabel:
            'Temps d`actualisation de la liste - secondes',
        reloadApp: 'Recharger',
        hideWorkAreasButton:
            'Masquer le bouton de sélection de la zone de travail',
        hideClassicViewButton: 'Masquer le bouton de vue classique',
        hideAddIssueButton: 'Masquer le bouton d’ajout de nouveau problème',
        filesManager: 'Gestionnaire de fichiers',
        addFolder: 'Ajouter un dossier',
        upload: 'Téléverser',
        noFolders: 'Aucun dossier',
        noFiles: 'Aucun fichier',
        folders: 'Dossiers',
        folder: 'Dossier',
        files: 'Fichiers',
        file: 'Fichier',
        removeFolderFilesQuestion: 'Que faire des fichiers dans ce dossier ?',
        targetFolder: 'Dossier de destination',
        addInstruction: 'Ajouter une instruction',
        goToFolder: 'Aller au dossier',
        defaultProdLine: 'Ligne de production par défaut',
        pin_code_field_type: 'Type de code PIN',
        instructionsAccess: 'Autorisations',
        noSections: 'Aucune section',
        requireReadingInstruction: 'Exiger la lecture de l’instruction',
        hide_parameter_types: 'Ne pas afficher les types de paramètres',
        unknownVersion: 'Inconnu',
        displayInstructionEveryTime: 'Afficher l’instruction à chaque fois',
        requireSigningInstructionEveryTime:
            'Exiger la signature de l’instruction à chaque fois',
        signedInstructions: 'Instructions signées',
        signedAt: 'Date de signature',
        signatures: 'Signatures',
        signature: 'Signature',
        systemGroup: 'Groupe système',
        pickable: 'Sélectionnable',
        leaf: 'Élément sélectionné',
        path: 'Chemin',
        displayMode: 'Mode d’affichage',
        chart: 'Graphique',
        preSignedStatement: 'Déclaration signée sur papier (complète)',
        myOpenIssues: 'Mes problèmes ouverts',
        myAssignedIssues: 'Problèmes qui me sont assignés',
        displayParametersOnMainScreen:
            "Afficher les paramètres sur l'écran principal",
        lockPin: 'Bloquer le changement de PIN',
        pinLocked: 'PIN verrouillé',
        sortChange: "Ordre d'affichage",
        asc: 'Croissant',
        desc: 'Décroissant',
        alphanumeric: 'Alphanumérique',
        numeric: 'Numérique',
        modificationDate: 'Date de modification',
        fromNewest: 'Des plus récents',
        fromOldest: 'Des plus anciens',
        negation: 'Négation',
        sortOrderService: 'Service',
        newIssueIgnoreFilters:
            "Ignorer les filtres appliqués lors de la création d'un nouveau ticket",
        refreshInterval: 'Heure d`actualisation de la liste',
        multiline: 'Multiligne',
        expanded: 'Développé',
    },
    placeholders: {
        part: 'Sélectionner une pièce',
        nameSurname: 'Nom et prénom',
        phone: 'Numéro de téléphone',
        password: 'Mot de passe',
        password_confirmation: 'Confirmer le mot de passe',
        email: 'E-mail',
    },
    auth: {
        notYourAccountQuestion: 'Pas votre compte?',
    },
    signup: {
        registered: 'Succès!',
        emailConfirmed: "L'e-mail a été confirmé.",
        registered_info: "Demande à ton superviseur d'activer ton compte",
        confirmEmailAddress:
            'Votre compte a été créé, cliquez sur le lien dans l`e-mail pour confirmer votre adresse.',
        tokenNotFoundError:
            "L'e-mail a déjà été confirmé ou le lien de confirmation n'est pas valide.",
    },
    paramTypes: {
        text: 'Texte',
        note: 'Note',
        integer: 'Entier',
        decimal: 'Décimal',
        date: 'Date',
        time: 'Heure',
        datetime: 'Date et heure',
        color: 'Couleur',
        icon: 'Icône',
        boolean: 'Non / Oui',
        signature: 'Signature',
    },
    tableHeaders: {
        id: 'ID',
        name: 'Nom',
        path: "Chemin d'accès au fichier",
        category: 'Catégorie',
        equipment: 'Équipement',
        criticality: 'Criticité',
        status: 'Statut',
        created: 'Créé',
        createdBy: 'Créé par',
        fp: 'FP',
        type: 'Type',
        designation: 'Désignation',
        symbol: 'Symbole',
        serialNumber: 'Numéro de série',
        registryNumber: "Numéro d'enregistrement",
        udtNumber: 'Numéro UDT',
        manufacturedYear: 'Année de fabrication',
        actions: 'Actions',
        title: 'Titre',
        data: 'Date',
        archived: 'Archivé',
        manufacturerSymbol: 'Symbole du fabricant',
        min: 'Min',
        opt: 'Opt',
        quantity: 'Quantité',
        priority: 'Priorité',
        price: 'Prix',
        kind: 'Type',
        description: 'Description',
        default: 'Défaut',
        warning: 'Avertissement',
        problem: 'Problème',
        fixing: 'Résolution',
        changeOrder: 'Ordre de modification',
        required: 'Requis',
        icon: 'Icône',
        color: 'Couleur',
        subCategories: 'Sous-catégories',
        barCode: 'Code barre',
        subLocations: 'Sous-emplacements',
        categories: 'Catégories',
        image: 'Image',
        nameSurname: 'Nom et prénom',
        email: 'Email',
        phone: 'Numéro de téléphone',
        function: 'Fonction',
        department: 'Département',
        accountStatus: 'Statut du compte',
        shift: 'Quart de travail',
        licenseNumber: 'Numéro de licence',
        issuedDate: "Date d'émission",
        expiresDate: "Date d'expiration",
        entries: 'Entrées',
        entryNumber: "Numéro d'entrée",
        buyPrice: "Prix d'achat",
        currentLocationQuantity: "Quantité à l'emplacement actuel",
        group: 'Groupe',
        string: 'Texte',
        integer: 'Entier',
        toolsGroup: "Groupe d'outils",
        manufacturer: 'Fabricant',
        shortName: 'Nom court',
        locationName: "Nom de l'emplacement",
        subLocationsQuantity: 'Quantité de sous-emplacements',
        isWorkingOnIssue: 'Travaille sur un problème',
        openIssues: 'Problèmes ouverts',
        notificationDate: 'Date de notification',
        titleAndDescription: 'Titre et description',
        read: 'Lu',
        created_at: 'Créé',
        received_at: 'Reçu',
        started_at: 'Pris',
        arrived_at: 'Arrivé',
        finished_at: 'Terminé',
        country: 'Pays',
        city: 'Ville',
        progress: 'Progression',
        deleted_at: 'Supprimer la date',
        about_issue: "S'applique au rapport.",
        action: 'Partager',
    },
    searchConfig: {
        id: 'Id',
        name: 'Nom',
        shortName: 'Nom court',
        country: 'Pays',
        email: 'Email',
        group: 'Groupe',
        planned: 'Prévu',
        category: 'Catégorie',
        object: 'Objet',
        criticality: 'Criticité',
        fp: 'FP',
        type: 'Type',
        designation: 'Désignation',
        symbol: 'Symbole',
        serialNumber: 'Numéro de série',
        registryNumber: "Numéro d'enregistrement",
        udtNumber: 'Numéro UDT',
        manufacturedYear: 'Année de fabrication',
        manufacturerSymbol: 'Symbole du fabricant',
        priority: 'Priorité',
        quantity: 'Quantité',
        buyPrice: "Prix d'achat",
        kind: 'Type',
        toolsGroup: "Groupe d'outils",
        function: 'Fonction',
        department: 'Département',
        status: 'Statut',
        nameSurname: 'Nom et prénom',
        phone: 'Téléphone',
        barCode: 'Code barre',
        color: 'Couleur',
        icon: 'Icône',
        machine: 'Machine',
        tool: 'Outil',
        urgentAndOutdated: 'Urgent et obsolète',
        urgent: 'Urgent',
        expired: 'Expiré',
        manufacturer: 'Fabricant',
    },
    errors: {
        partMustHaveEntriesToCorrectQuantity:
            'La pièce doit avoir des entrées pour corriger la quantité.',
        error: "Une erreur s'est produite",
        wrongEmail: 'Adresse email non valide',
        wrongPasswordLength:
            'Le mot de passe doit avoir au moins 8 caractères.',
        wrongCodeLength:
            'Le @:common.PIN_CODE_LABEL doit avoir au moins 5 caractères.',
        passwordsNotMatch: 'Les mots de passe ne correspondent pas',
        codesNotMatch: '@:common.PIN_CODE_LABEL ne correspondent pas',
        codeAlreadyInUse: '@:common.PIN_CODE_LABEL déjà utilisé',
        expiresDateLowerThanIssued:
            "La date d'expiration ne peut pas être inférieure à la date d'émission.",
        cannotMoveToTheSameLocation:
            'Impossible de déplacer vers la même emplacement.',
        locationRequired: "L'emplacement est requis.",
        putQuantity: 'Indiquez la quantité à séparer.',
        valueGreaterThanQuantity:
            'La valeur est supérieure à la quantité totale.',
        wholeCannotBeDistinguished: "L'ensemble ne peut pas être distingué.",
        yearMustBeGreaterThan: "L'année doit être supérieure à ",
        passwordConfirmationNotMatch: 'Les mots de passe ne correspondent pas',
        mustBeInteger: 'La valeur doit être un entier',
        valueTooLong: 'Valeur trop longue',
        wrongPhoneNumber: 'Numéro de téléphone incorrect',
        phoneNumberAlreadyTaken: 'Numéro de téléphone déjà pris',
        emailAlreadyTaken: 'Email déjà pris',
        notFound: 'Non trouvé',
        sessionExpired: 'Session expirée',
        tenantNotFound:
            "La configuration indiquée n'existe pas. Vérifiez l'adresse du site et réessayez.",
        selectEquipmentTypeToConfigureParameters:
            "Sélectionnez le type d'équipement pour configurer les paramètres.",
        noParametersToConfigure:
            "Le type d'équipement sélectionné n'a pas de paramètres à configurer.",
        terminalAlreadyRegistered: 'Terminal déjà enregistré.',
        invalidPin: '@:common.PIN_CODE_LABEL non valide',
        eventCollision:
            "L'heure de l'événement que vous voulez ajouter chevauche l'heure d'un autre événement.",
        mustStartWithSlash: 'Doit commencer par /',
        mustBeBetween: 'La valeur doit être entre:',
        minLength: 'Longueur minimale: ',
        maxLength: 'Longueur maximale: ',
        invalidInputForCodeType:
            'Entrée non valide pour le type de code sélectionné',
        invalidCodeOrNoPermission:
            "Le @:common.PIN_CODE_LABEL est invalide ou vous n'avez pas suffisamment de permission pour effectuer cette action.",
        endDateLowerThanStart:
            'La date de fin ne peut pas être inférieure à la date de début',
        iconAlreadyTaken: "L'icône a déjà été prise",
        notEnoughQuantityInStorage: 'Quantité insuffisante en stock.',
        valueTooLow: 'Valeur trop basse',
        valueTooHigh: 'Valeur trop élevée',
        maxAvailableQuantity: 'Nombre maximal',
        someFilesRejectedDueToSize:
            "Certains fichiers ont été rejetés en raison de leur taille. Taille maximale d'un fichier unique:",
        cameraDeviceNotFound: 'Appareil photo non trouvé',
        cameraPermissionDenied:
            "L'accès à la caméra du dispositif a été bloqué dans les paramètres du site, réinitialisez les paramètres du site et autorisez l'utilisation de la caméra.",
        passwordResetLinkExpired:
            'Le lien de réinitialisation du mot de passe a expiré',
        noInternetConnection: 'Pas de connexion Internet',
        minimum: 'Minimum',
        mustBeGreaterThan: 'Doit être supérieur à',
        noEventTypesDefined: "Aucun type d'événement défini",
        regexPatternDelimiterError:
            'Le séparateur doit être omis avec le caractère \\ Npar exemple : \\/',
        emailNotMatchPatternDefiniedInTenant:
            "L'email ne correspond pas au modèle défini dans le locataire",
        max: 'Valeur maximum',
        min: 'Valeur minimum',
        allLanguagesRequired: 'Complétez la valeur dans toutes les langues',
        fileTooBigToOpenWillBeDownloaded:
            'Le fichier est trop volumineux pour être ouvert, le fichier sera téléchargé sur votre appareil.',
        surveyNotFound: 'Enquête introuvable',
        actionNotEnabled: 'Le code est inactif',
        loginRequired: 'Connexion requise',
        noPermissionForSelectedFolder:
            "Vous n'êtes pas autorisé à afficher le contenu de ce répertoire",
        noFoldersAssignedForTerminal:
            "Il n'y a aucun répertoire attribué avec des instructions",
        noChecklistsAssignedToControl:
            "Le contrôle sélectionné n'a pas de liste de contrôle qui lui est attribuée",
        maximum: 'Maximum',
        versionErrorMessage:
            "Vos modifications récentes n'ont pas été enregistrées, car quelqu'un d'autre a effectué des modifications en même temps. Actualisez la page et réessayez.",
        duplicateError: 'Cette valeur existe déjà',
        fillAllRequiredParameters: 'Remplissez tous les paramètres requis.',
        wrongValue: 'Valeur invalide',
        noParametersDefined: 'Aucun paramètre défini',
        noFlagsConfigured: 'Aucun indicateur défini',
        noEnoughParts: "Pas assez d'objets",
        pinNotBelongsTo: 'Ce @:common.PIN_CODE_LABEL n`appartient pas à',
        tagNotBelongsTo: 'Cette carte/étiquette n`appartient pas à',
        noDictionaryEntries:
            'Le dictionnaire sélectionné ne contient aucune entrée',
        noConnectionWithServer: 'Aucune connexion au serveur',
    },
    navDrawer: {
        dashboard: 'Tableau de bord',
        issues: 'Problèmes',
        timetable: 'Emploi du temps',
        machines: 'Machines',
        tools: 'Outils',
        parts: 'Pièces',
        storage: 'Entrepôt',
        staff: 'Personnel',
        configuration: 'Configuration',
        notes: 'Notes',
        notifications: 'Notifications',
        profile: 'Profil',
        supervision: 'Supervision',
    },
    barcodeScanner: {
        barCode: 'Code barre',
        id: 'ID',
    },
    categoryPicker: {
        categoryPlaceholder: 'Catégorie',
        selectCategory: 'Sélectionner une catégorie',
        noSubcategories: 'Pas de sous-catégories',
    },
    companies: {
        addCompany: 'Ajouter une entreprise',
        editCompany: "Modifier l'entreprise",
        fullName: 'Nom complet',
        shortName: 'Nom abrégé',
        group: 'Groupe',
        country: 'Pays',
        city: 'Ville',
        street: 'Rue',
        postCode: 'Code postal',
        phone: 'Téléphone',
        email: 'Email',
        website: 'Site web',
        NIP: 'NIP',
        REGON: 'Registre national des entreprises',
        active: 'Actif',
        nameRequired: 'Le nom est requis',
        shortNameRequired: 'Le nom est requis',
    },
    issues: {
        tookAt: 'Pris à',
        changeHistory: 'Historique des modifications',
        load: 'Charger',
        date: 'Date',
        createdBy: 'Créé par',
        action: 'Action',
        data: 'Données',
        created: 'Créé',
        updated: 'Mis à jour',
        deleted: 'Supprimé',
        restored: 'Restauré',
        issues: 'Problèmes',
        issue: 'Problème',
        by: 'par',
        name: 'Nom',
        applicantsDescription: 'Description du demandeur',
        accidentDescription: "Description de l'accident",
        jobDescription: "Description de l'emploi",
        jobSummary: "Résumé de l'emploi",
        category: 'Catégorie',
        status: 'Statut',
        noFlow: '[Aucun flux]',
        equipment: 'Équipement',
        criticality: 'Criticité',
        hour: 'Heure',
        formalConfirmation: 'Confirmation formelle',
        formalConfirmationShortcut: 'FC',
        formalConfirmationRequired: 'FC requise',
        parameter: 'Paramètre',
        value: 'Valeur',
        addIssue: 'Ajouter un problème',
        expectedCompletionDate: 'Date prévue de fin',
        issueDescription: 'Description du problème',
        partsUsage: 'Élimination des pièces',
        assignedEmployees: 'Employés affectés',
        requiredHelp: 'Aide requise',
        returnParts: 'Retourner la pièce',
        confirmUnassignEmployeeWhoTakenIssue:
            'Cet employé a pris le problème sélectionné. Voulez-vous le retirer?',
        responsibleUser: 'Utilisateur responsable',
        responsibleRules: 'Utilisateurs responsables - règles',
        received_decision_at:
            'Date à laquelle le notifiant a eu connaissance de la décision',
        groupRules: 'Groupes - règles',
        watchersRules: 'Observateurs - règles',
    },
    machines: {
        machines: 'Machines',
        xIssuesNotPlanned: ' (non planifié)',
        noIssues: 'Aucun problème',
    },
    maintenance: {
        maintenance: 'Maintenance',
    },
    notes: {
        notes: 'Notes',
        note: 'Note',
        title: 'Titre',
        archived: 'Archivé',
    },
    parts: {
        parts: 'Pièces',
        warehouseOutgoings: "Sorties d'entrepôt",
        fittingEquipmentTypes: "Types d'équipement appropriés",
        goToEquipmentType: "Aller au type d'équipement",
        selectPart: 'Sélectionner une pièce',
        usageReason: 'Raison',
        usageOtherReason: 'Autre',
        replacements: 'Remplacements',
        partEntry: 'Entrée de pièce',
        goToPart: 'Aller à la pièce',
    },
    costCenter: {
        costCenter: 'Centre de coûts',
    },
    equipmentTypes: {
        equipment: 'Équipement',
        newEquipmentType: "Nouveau type d'équipement",
        fittingParts: 'Pièces adaptées',
        goToParts: 'Aller aux pièces',
    },
    machinesAndToolsDefinitions: {
        machinesAndToolsDefinitions: "Définitions de machines et d'outils",
    },
    flows: {
        flow: 'Flux',
        states: 'États',
        transitions: 'Transitions',
        state: 'État',
        default: 'Par défaut',
        warning: 'Avertissement',
        problem: 'Problème',
        fixing: 'Correction',
        finished: 'Fini',
        access: {
            create: 'Création',
            read: 'En lisant',
            update: 'Modification',
            delete: 'Suppression',
            read_unassigned: 'Lire non attribué',
            assign: 'Affectation',
            unassign: 'Supprimer un devoir',
            close: 'Clôture',
        },
        instructions: {
            default: 'Défaut',
            fixing: 'Considération',
            finished: 'Fini',
        },
        accessLabels: {
            terminal: 'Le terminal qui a signalé le problème',
            creator: 'L`utilisateur qui a signalé le problème',
            responsible: 'L`utilisateur responsable du problème',
            group: 'Le groupe d`utilisateurs responsable du problème',
            anchor: 'Le terminal sur lequel le rapport est épinglé',
            watchers: 'Utilisateurs observant le problème',
        },
        sync_returned_rejected: 'Synchronisation - rejetée',
        sync_returned_finished: 'Synchronisation - terminée',
        current: 'Actuel',
    },
    parameters: {
        categoryParameter: 'Paramètre de catégorie',
        equipmentTypeParameter: "Paramètre de type d'équipement",
    },
    staff: {
        staff: 'Personnel',
        editUserAccess: "Modifier l'accès utilisateur",
        permissionsUpdated: 'Autorisations mises à jour',
        passwordUpdated: 'Mot de passe mis à jour',
        codeUpdated: '@:common.PIN_CODE_LABEL mis à jour',
        changePasswordAndCode:
            'Changer le mot de passe et le @:common.PIN_CODE_LABEL',
        goToLicenses: 'Aller aux licences',
        editAccess: "Modifier l'accès",
        editUser: "Modifier l'utilisateur",
        addUser: 'Ajouter un nouvel utilisateur',
        nameAndLastname: 'Nom et prénom',
        accountStatus: 'Statut du compte',
        phoneNumber: 'Numéro de téléphone',
        department: 'Département',
        passwordChange: 'Changement de mot de passe',
        codeChange: 'Changement de @:common.PIN_CODE_LABEL',
        newPassword: 'Nouveau mot de passe',
        confirmNewPassword: 'Confirmer le nouveau mot de passe',
        changePassword: 'Changer le mot de passe',
        newCode: 'Nouveau @:common.PIN_CODE_LABEL',
        confirmNewCode: 'Confirmer le nouveau @:common.PIN_CODE_LABEL',
        changeCode: 'Changer le @:common.PIN_CODE_LABEL',
        addEntry: 'Ajouter une entrée',
        editEntry: "Modifier l'entrée",
        editLicense: 'Modifier la licence',
        addLicense: 'Ajouter une licence',
        licenseNumber: 'Numéro de licence',
        prefferedLocale: 'Langue préférée',
        oldPassword: 'Ancien mot de passe',
        newPasswordConfirmation: 'Confirmer le nouveau mot de passe',
        reading: 'Affichage',
        creating: 'Création',
        updating: 'Mise à jour',
        deleting: 'Suppression',
        importScheduleFile: 'Importer un planning depuis un fichier',
        importSchedule: {
            errors: {
                user_not_found: 'Utilisateur non trouvé [{user}]',
                user_found_many: '{count} utilisateurs trouvés [{user}]',
                event_collision: 'Collision d`événements',
                hour_cell_parse: 'Erreur d`analyse de cellule',
            },
        },
        oldCode: 'Ancien @:common.PIN_CODE_LABEL',
        newCodeConfirmation: 'Confirmation du nouveau @:common.PIN_CODE_LABEL',
        setAccessBasedOnProfile: "Accorder l'accès en fonction du profil",
        selectAccessMergeType: 'Choisissez comment combiner les autorisations',
        noAccessesInProfileMessage:
            "Le profil sélectionné n'a aucune autorisation. Voulez-vous quand même écraser?",
    },
    breadCrumb: {
        configuration: 'Configuration',
        locations: 'Emplacements',
        home: 'Accueil',
        machines: 'Machines',
        tools: 'Outils',
        issues: 'Problèmes',
        notes: 'Notes',
        maintenance: 'Maintenance',
        parts: 'Pièces',
        warehouse: 'Entrepôt',
        dictionary: 'Dictionnaire',
        categories: 'Catégories',
        parameters: 'Paramètres',
        costCenter: 'Centre de coût',
        flows: 'Flux',
        states: 'États',
        transitions: 'Transitions',
        shiftSchemas: 'Schémas de postes',
        shifts: 'Postes',
        definitionsOfMachinesAndToolsTypes:
            "Définitions des types de machines et d'outils",
        add: 'Ajouter',
        edit: 'Modifier',
        staff: 'Personnel',
        licenses: 'Licences',
        entries: 'Entrées',
        companiesDatabase: 'Base de données des entreprises',
        companyManagement: "Gestion d'entreprise",
        companyData: "Données de l'entreprise",
        supportedLanguages: 'Langues prises en charge',
        en: 'Anglais',
        pl: 'Polonais',
        es: 'Espagnol',
        de: 'Allemand',
        it: 'Italien',
        fr: 'Français',
        ru: 'Russe',
        uk: 'Ukrainien',
        pt: 'Portugais',
        defaultLanguage: 'Langue par défaut',
        notifications: 'Notifications',
        notificationsManagement: 'Gestion des notifications',
        myProfile: 'Mon profil',
    },
    attributeTypes: {
        text: 'Texte',
        note: 'Note',
        integer: 'Entier',
        decimal: 'Décimal',
        date: 'Date',
        time: 'Heure',
        dateTime: 'Date et heure',
        color: 'Couleur',
        icon: 'Icône',
        boolean: 'Oui/Non',
    },
    costCalculator: {
        costCalculator: 'Calculateur de coût',
        waitTime: "Temps d'attente",
        serviceTime: 'Temps de service',
        suspendTime: 'Temps de suspension',
        totalTime: 'Temps total',
        estimatedCost: 'Coût estimé',
        calculate: 'Calculer',
        withoutParts: 'Sans pièces',
        timeOfReportingIssue: 'Heure de déclaration du problème',
        timeOfServiceStart: 'Heure de début du service',
        timeOfReportingEnd: 'Heure de fin du service',
        days: 'Jours',
        hours: 'Heures',
        minutes: 'Minutes',
        enterNewTime: 'Entrer un nouveau temps',
        fromIssueReportToServiceStart:
            'Temps entre la déclaration du problème et le début du service',
        fromServiceStartToServiceEnd:
            'Temps entre le début et la fin du service',
        workTime: 'Temps de travail',
        includeAwaitingTime: "Inclure le temps d'attente",
    },
    dashboard: {
        components: {
            myIssues: {
                title: 'Mes problèmes',
                assignedToMe: 'Assigné à moi',
                assignedToGroup: 'Assigné à mon groupe',
                tookByMe: 'Prise par moi',
                takeIssueBtn: 'Prendre',
                arriveIssueBtn: 'Je suis arrivé',
                finishIssueBtn: 'Terminer',
                history: 'Historique',
                myResponsible: 'Je suis responsable de',
                unassignIssue: 'Annuler la prise',
            },
        },
    },
    synchronization: {
        enabled: 'Activé',
        hasHeaders: 'A des en-têtes',
        leaveFile: 'Laisser le fichier',
        host: 'Hôte',
        port: 'Port',
        protocol: 'Protocole',
        username: "Nom d'utilisateur",
        password: 'Mot de passe',
        root: 'Racine',
        encoding: 'Encodage',
        separator: 'Séparateur',
        externalSystemId: 'ID système externe',
        cronExpression: 'Expression cron',
        appendFullStock: 'Ajouter le stock complet',
        includeHeaders: 'Inclure les en-têtes',
        withTrashed: 'Avec les données supprimées',
        filePattern: 'Modèle de fichier',
        categoriesRootCategoryId:
            'Catégorie racine pour les catégories importées',
        systemKeyname: 'Identifiant du système',
        address: 'Adresse',
        token: 'Token',
    },
    processes: {
        created_at: 'Créé à',
        started_at: 'Commencé à',
        updated_at: 'Mis à jour à',
        finished_at: 'Terminé à',
        attempts: 'Tentatives',
        queue: "File d'attente",
        job_id: 'ID de travail',
        job_uuid: 'UUID de travail',
        progress_max: 'Progression max',
        progress_now: 'Progression maintenant',
        outputMessage: 'Message de sortie',
        status: {
            queued: "en file d'attente",
            executing: "en cours d'exécution",
            finished: 'terminé',
            failed: 'échoué',
            retrying: 'en train de réessayer',
            undefined: 'non défini',
        },
    },
    weekDays: {
        mo: 'Lundi',
        tu: 'Mardi',
        we: 'Mercredi',
        th: 'Jeudi',
        fr: 'Vendredi',
        sa: 'Samedi',
        su: 'Dimanche',
    },
    reports: {
        reports: 'Rapports',
        dateFrom: 'De la date',
        dateTo: 'À la date',
        types: {
            operation_sheet: 'Feuille de calcul',
            issue_summary: 'Résumé du problème',
            grouped_summary: 'Rapport sommaire',
            storage_operations: "Opérations d'entrepôt",
            part_quantity_export: 'Quantité de pièces',
        },
        reportFor: 'Rapport pour',
        includeQuantityLeft0: 'Inclure les pièces avec une quantité nulle',
        dayMinusN: 'Jour moins n',
        currentWeek: 'Semaine en cours',
        lastWeek: 'Semaine dernière',
        currentMonth: 'Mois en cours',
        lastMonth: 'Mois dernier',
        currentYear: 'Année en cours',
        lastYear: 'Année dernière',
        nDays: 'Nombre de jours',
        todayMinusN: "Aujourd'hui moins n"
    },
    logs: {
        comment: 'Commentaire',
        issues: {
            escalation: 'Escalade ({minutes} min) - notification envoyée',
        },
        issueEmployee: {
            assignedAt: 'Employé assigné :',
            receivedAt: 'Employé a reçu :',
            tookAt: 'Employé a pris :',
            finishedAt: 'Employé a terminé :',
            deletedAt: 'Employé supprimé de la déclaration',
        },
    },
    units: {
        no: 'Pièce',
        st: 'Ensemble',
        pr: 'Paire',
    },
    rrule: {
        frequency: 'Fréquence',
        dtstart: 'Date de début',
        tzid: 'Fuseau horaire',
        until: "Jusqu'à",
        count: 'Compter',
        interval: 'Intervalle',
        wkst: 'Commence jour',
        byweekday: 'En semaine',
        bymonth: 'Par mois',
        yearly: 'Annuel',
        monthly: 'Mensuel',
        weekly: 'Hebdomadaire',
        daily: 'Quotidien',
        hourly: 'Horaire',
        minutely: 'Minutieusement',
        secondly: 'Deuxièmement',
        infinitePeriodicityMessage:
            'La périodicité est infinie, du fait des performances, seules 500 lignes sont affichées',
    },
    months: {
        jan: 'Janvier',
        feb: 'Février',
        mar: 'Mars',
        apr: 'Avril',
        may: 'Peut',
        jun: 'Juin',
        jul: 'Juillet',
        aug: 'Août',
        sep: 'Septembre',
        oct: 'Octobre',
        nov: 'Novembre',
        dec: 'Décembre',
    },
    charts: {
        charts: 'Graphiques',
        count: 'Nombre de problèmes',
        total: 'Temps total des problèmes (du rapport à la fin)',
        serw: 'Temps total de réparation (Ttotal - Twait)',
        mttr1: 'Délai moyen du rapport à la fin',
        mttr2: 'Temps moyen de la prise à la fin',
        mtbf: 'Temps de disponibilité moyen entre les problèmes',
        mttf: "Temps moyen jusqu'à l'échec",
        type: 'Type de graphique',
        pie: 'Diagramme circulaire',
        bar: 'Un graphique à barres',
        query: 'La portée des données',
        groupBy: 'Par groupe',
        sum: 'Somme',
        avg: 'Signifier',
        min: 'Le minimum',
        max: 'Maximum',
        eq: 'Est égal à',
        neq: 'Ce n`est pas égal',
        gt: 'Plus que',
        lt: 'Moins que',
        gte: 'Plus ou égal',
        lte: 'Inférieur ou égal',
        in: 'Contient',
        nin: 'Ne contient pas',
        btw: 'Entre',
        chartOptions: 'Options du graphique',
        line: 'Graphique linéaire',
        mixed: 'Mixte',
        chartDisplayOptions: 'option d`affichage',
        fillGaps: 'Remplir les trous',
    },
    tpm: {
        history: 'Histoire',
        standardObstacle: 'Obstacle standard',
        customObstacle: 'Obstacle personnalisé',
        bhp: 'Sécurité et santé au travail',
        returnToMainPage: 'Retour à la page principale',
        bhpMessage:
            "Si vous constatez des risques pour la sécurité, veuillez les signaler immédiatement à votre supérieur hiérarchique et utiliser le formulaire de signalement situé sous le tableau d'affichage.",
        ok: 'OK',
        send: 'Envoyer',
        description: 'Description',
        title: 'Titre',
        realization: 'Réalisation',
        answer: 'Réponse',
        pendingSurveys: 'Enquêtes en cours',
        surveys: 'Enquêtes',
        systemSurvey: 'Enquête système',
        addPage: 'Ajouter une page',
        createSurvey: 'Créer une enquête',
        addSection: 'Ajouter une section',
        addQuestion: 'Ajouter une question',
        questionContent: 'Contenu de la question',
        answerOption: 'Option de réponse',
        addQuestionAnswer: 'Ajouter une option de réponse',
        surveyName: "Nom de l'enquête",
        sectionName: 'Nom de la section',
        noSurveyAnswers: 'Aucune réponse',
        removeSection: 'Supprimer la section',
        published: 'Publiée',
        unpublished: 'Non publiée',
        pendingPublication: 'Modifications en attente de publication',
        removeQuestion: 'Supprimer la question',
        removeAnswer: "Supprimer l'option de réponse",
        publish: 'Publier',
        unpublish: 'Dépublier',
        previewResults: 'Voir les résultats',
        supervisior: 'Superviseur',
        surveyResults: "Résultats de l'enquête",
        surveysEvents: 'Configuration des événements - enquêtes',
        operatedBy: 'Opéré par',
        confirmReadInformations: "J'ai pris connaissance des informations",
        noticeContent: "Contenu de l'avis",
        notice: 'Avis',
        surveyEventName: "Nom de l'événement",
        survey: 'Enquête',
        newIssue: 'Nouveau rapport',
        surveyEventConfigurationAlreadyExists:
            'Une telle configuration existe déjà',
        lastComment: 'Dernier commentaire de la personne responsable',
        surveyNotConfigured: 'Enquête non configurée',
        fillSurvey: "Remplir l'enquête",
        singleSurveyEntries: 'résultats uniques',
        groupedSurveyEntries: 'Résultats groupés',
        publicationId: 'Identifiant de publication',
        publicationDate: 'Date de publication',
        versionId: 'ID de version',
        answersCount: 'Nombre de réponses',
        answers: 'Réponses',
        withoutSupervisor: 'Pas de superviseur',
        defaultSupervisor: 'Superviseur par défaut',
        flowStateFlags: "Indicateurs d'état",
        qrActionsSettings: "Paramètres d'action du code QR",
        loginRequired: 'Nécessite une connexion',
        questionContext: 'Le contexte de la question',
        currentSurveyVersionPreview:
            "Aperçu de la version actuelle de l'enquête",
        surveyDescription: "Description de l'enquête",
        sectionDescription: 'Description de la rubrique',
        instructions: 'Instructions',
        instruction: 'Instruction',
        instructionRevisions: 'Révisions',
        instructionRevision: 'Révision',
        checklists: 'Listes de contrôle',
        checklist: 'Liste de contrôle',
        checklistItem: 'Élément de liste',
        manageChecklistFields: 'Gérer les éléments de la liste',
        checklistItemDisplayOptions: 'Éléments affichés',
        revisionAutoRejectedInfo: '[Système] Rejeté - nouvelle révision créée',
    },
    accessMergeValues: {
        sum: 'Somme',
        product: 'Produit',
        overwrite: 'écraser',
    },
    aggregates: {
        count: 'Somme',
        min: 'Le minimum',
        max: 'Maximum',
    },
    checklists: {
        itemBasic: 'Élément basique',
        itemSeparator: 'Séparateur',
        itemSection: 'Section',
        publish: 'Publier',
        previewCurrentVersion: 'Aperçu de la version actuelle',
    },
    cluer: {
        history: 'Histoire',
        standardObstacle: 'Obstacle standard',
        customObstacle: 'Obstacle personnalisé',
        bhp: 'Sécurité et santé au travail',
        returnToMainPage: 'Retour à la page principale',
        bhpMessage:
            "Si vous constatez des risques pour la sécurité, veuillez les signaler immédiatement à votre supérieur hiérarchique et utiliser le formulaire de signalement situé sous le tableau d'affichage.",
        ok: 'OK',
        send: 'Envoyer',
        description: 'Description',
        title: 'Titre',
        realization: 'Réalisation',
        answer: 'Réponse',
        pendingSurveys: 'Enquêtes en cours',
        surveys: 'Enquêtes',
        systemSurvey: 'Enquête système',
        addPage: 'Ajouter une page',
        createSurvey: 'Créer une enquête',
        addSection: 'Ajouter une section',
        addQuestion: 'Ajouter une question',
        questionContent: 'Contenu de la question',
        answerOption: 'Option de réponse',
        addQuestionAnswer: 'Ajouter une option de réponse',
        surveyName: "Nom de l'enquête",
        sectionName: 'Nom de la section',
        noSurveyAnswers: 'Aucune réponse',
        removeSection: 'Supprimer la section',
        published: 'Publiée',
        unpublished: 'Non publiée',
        pendingPublication: 'Modifications en attente de publication',
        removeQuestion: 'Supprimer la question',
        removeAnswer: "Supprimer l'option de réponse",
        publish: 'Publier',
        unpublish: 'Dépublier',
        previewResults: 'Voir les résultats',
        supervisior: 'Superviseur',
        surveyResults: "Résultats de l'enquête",
        surveysEvents: 'Configuration des événements - enquêtes',
        operatedBy: 'Opéré par',
        confirmReadInformations: "J'ai pris connaissance des informations",
        noticeContent: "Contenu de l'avis",
        notice: 'Avis',
        surveyEventName: "Nom de l'événement",
        survey: 'Enquête',
        newIssue: 'Nouveau rapport',
        surveyEventConfigurationAlreadyExists:
            'Une telle configuration existe déjà',
        lastComment: 'Dernier commentaire de la personne responsable',
        surveyNotConfigured: 'Enquête non configurée',
        fillSurvey: "Remplir l'enquête",
        singleSurveyEntries: 'résultats uniques',
        groupedSurveyEntries: 'Résultats groupés',
        publicationId: 'Identifiant de publication',
        publicationDate: 'Date de publication',
        versionId: 'ID de version',
        answersCount: 'Nombre de réponses',
        answers: 'Réponses',
        withoutSupervisor: 'Pas de superviseur',
        defaultSupervisor: 'Superviseur par défaut',
        flowStateFlags: "Indicateurs d'état",
        qrActionsSettings: "Paramètres d'action du code QR",
        loginRequired: 'Nécessite une connexion',
        questionContext: 'Le contexte de la question',
        currentSurveyVersionPreview:
            "Aperçu de la version actuelle de l'enquête",
        surveyDescription: "Description de l'enquête",
        sectionDescription: 'Description de la rubrique',
    },
    orders: {
        order: 'Commande',
        releaseDate: "Date d'achèvement",
        orderDate: 'Date de commande',
    },
    production: {
        good: 'Nombre de bons',
        bad: 'Nombre de mauvais',
        productionRecords: 'Registre de production',
    },
};
