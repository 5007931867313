<style>
@import './assets/main.css';
@import './assets/inputs.css';
@import './assets/easy-table-style.css';
</style>

<template>
    <SignaloBrowserVersionWarning />
    <SignaloQRAction v-if="$route?.query?.scan_qr" />
    <SignaloAuthLoadingView v-if="authLoading || !appMounted" />
    <PageAppVersionMismatch v-else-if="!helpersStore?.isCorrectAppVersion" />
    <PageMaintenance v-else-if="helpersStore.maintenance" />
    <PageError
        v-else-if="error"
        :errorMessage="errorMessage"
        :tenantError="true"
    />
    <SignaloSignUpView
        v-else-if="
            !isLoggedIn &&
            $route.path === '/signup' &&
            !$route.path.includes('terminal-device')
        "
    />
    <SignaloRemindPasswordView
        v-else-if="
            !isLoggedIn &&
            $route.path === '/remind_password' &&
            !$route.path.includes('terminal-device')
        "
    />
    <SignaloSignUpConfirmEmail
        v-else-if="
            !isLoggedIn &&
            $route.path === '/signup_confirm_email' &&
            !$route.path.includes('terminal-device')
        "
    />
    <!--WORKAROUND-->
    <!--SignaloLoginView Specjalnie rozdzielone na 2 komponenty żeby nie wyświetlało trusted users na terminalu na którym trzeba sie zalogować-->
    <SignaloSignUpView
        v-else-if="
            userLoginOnTerminalRequired &&
            $route.path === '/signup' &&
            !$route.path.includes('terminal-device')
        "
    />
    <SignaloRemindPasswordView
        v-else-if="
            userLoginOnTerminalRequired &&
            $route.path === '/remind_password' &&
            !$route.path.includes('terminal-device')
        "
    />
    <SignaloSignUpConfirmEmail
        v-else-if="
            userLoginOnTerminalRequired &&
            $route.path === '/signup_confirm_email' &&
            !$route.path.includes('terminal-device')
        "
    />
    <SignaloLoginView
        v-else-if="
            userLoginOnTerminalRequired &&
            !$route.path.includes('terminal-device')
        "
        :userLoginOnTerminalRequired="userLoginOnTerminalRequired"
    />
    <SignaloLoginView
        v-else-if="!isLoggedIn && !$route.path.includes('terminal-device')"
    />
    <v-app v-else>
        <SignaloLanguageChanger
            :followTerminalLanguage="true"
            class="hidden-language-changer"
            v-if="authStore?.tenant"
        />
        <SignaloNavDrawer
            v-model="drawer"
            :navType="navType"
            :navItems="navItems"
            :navActions="navActions"
            :redirectOnLogout="'dashboard'"
            :sidenavExpandedRule="!this.$isTablet()"
            v-if="
                authStore?.terminal?.attributes?.hideSideMenu !== true &&
                (!authStore.isTerminal ||
                    authStore?.user?.terminal_mode === 'instructions')
            "
        />

        <PageNotPermitted v-if="$route.meta.permitted === false" />
        <RouterView v-else />
    </v-app>
    <v-snackbar
        :timeout="helpersStore.snackbarTimeout"
        position="relative"
        location="bottom right"
        :color="helpersStore.snackbarColor"
        v-model="helpersStore.snackbarOn"
    >
        <div class="snackbar-message-container">
            <div class="error-icon-container">
                <v-icon v-if="helpersStore.error">mdi-alert-circle</v-icon>
            </div>
            <span>
                {{ helpersStore.snackbarText }}
            </span>
        </div>
        <template v-slot:actions>
            <v-btn
                class="report-problem-button"
                variant="elevated"
                v-if="helpersStore.error"
                @click="onErrorReport"
            >
                {{ $t('common.reportProblem') }}
            </v-btn>
            <v-btn
                class="handle-snackbar-action-button"
                variant="text"
                v-if="helpersStore.snackbarAction"
                @click="helpersStore.snackbarAction"
            >
                {{ $t('common.show') }}
            </v-btn>
            <v-btn variant="text" @click="() => helpersStore.clearSnackbar()">
                {{ $t('common.close') }}
            </v-btn>
        </template>
    </v-snackbar>
    <SignaloSingleAuthorizeDialog v-if="authStore?.sad?.open" />
    <SignaloErrorReportDialog
        :open="errorReportDialog"
        @save="onErrorReportSave"
        @close="onErrorReportClose"
        :error="helpersStore.errorMessage"
        :loading="errorReportLoading"
    />
    <SignaloBarcodeScanningInterceptor />
    <audio id="notification-player" class="notification-player">
        <source :src="require('./assets/notification.mp3')" type="audio/mpeg" />
    </audio>
    <SignaloManageTableColumnsVisibilityDialog
        v-if="helpersStore.isManageTableColumnsVisibilityDialogOpen"
        :tableName="helpersStore.tableName"
        @close="helpersStore.closeManageTableColumnsvisibilityDialog"
    />
    <SignaloSurveyDialogComponent
        v-if="!!helpersStore?.survey"
        :open="!!helpersStore?.survey"
        :surveyTemplate="helpersStore?.survey"
        :surveyAnswers="helpersStore?.surveyAnswers"
        :mySurveyId="helpersStore?.mySurveyId"
        :preview="helpersStore?.surveyPreview"
        :photos="helpersStore?.surveyPhotos"
        :userContext="helpersStore?.surveyUser"
        @close="onSurveyDialogClose"
    />
    <SignaloSocketService v-if="isLoggedIn" />
    <div
        class="app-version"
        v-if="appVersion && (!isLoggedIn || userLoginOnTerminalRequired)"
    >
        <div class="app-version-start">
            {{ appVersion }}
        </div>

        <span class="app-version-end">
            {{
                String(appVersion).substring(
                    String(appVersion).length - 6,
                    String(appVersion).length
                )
            }}
        </span>
    </div>
</template>

<script>
import { computed } from 'vue';
import { RouterView } from 'vue-router';

import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import * as HELPERS_STORE from 'signalo-vue-helpers-store';

import { CheckIsMobile } from 'signalo-vue-utils';
import { GetTerminalLoginData } from 'signalo-vue-utils';
import PageMaintenance from '@/components/global/PageMaintenance.vue';
import PageAppVersionMismatch from '@/components/global/PageAppVersionMismatch.vue';
import Buffer from 'buffer';

import NAVIGATION_ITEMS from './CONSTANTS/NAVIGATION_ITEMS';
import NAVIGATION_ACTIONS from './CONSTANTS/NAVIGATION_ACTIONS';
import { getCurrentInstance } from 'vue';
import { setCustomDimensionsCssVariables } from 'signalo-vue-utils';
import { createVuetifyTheme } from 'signalo-vue-utils';
import { setColorVariablesFromPalette } from 'signalo-vue-utils';
import { generateColorPalette } from 'signalo-vue-utils';

import SignaloSurveyDialogComponent from './components/Statements/SignaloSurveyDialogComponent.vue';

import * as SignaloSocketService from 'signalo-vue-socket-service';
const CHANNELS = SignaloSocketService.CHANNLES;
const EVENTS = SignaloSocketService.EVENTS;
import i18nTenantTranslations from './i18nTenantTranslations';
import RTL_LANGUAGES from './CONFIG/RTL_LANGUAGES';
const APP_VERSION = process.env.VUE_APP_VERSION;
export default {
    setup() {
        const authStore = AUTH_STORE.default();
        const dataStore = DATA_STORE.default();
        const helpersStore = HELPERS_STORE.default();

        window.hack = () => {
            helpersStore.hack();
        };
        return { authStore, dataStore, helpersStore };
    },
    components: {
        RouterView,
        PageMaintenance,
        PageAppVersionMismatch,
        SignaloSurveyDialogComponent,
    },
    data() {
        return {
            drawer:
                !this.helpersStore.isMobile ||
                /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
                    navigator.userAgent.toLowerCase()
                ),

            logoutInProgress: false,
            authLoading: true,
            navType: 'app',
            errorReportDialog: false,
            errorMessage: null,
            errorReportLoading: false,
            error: false,
            appInstance: null,

            dictionaryLoaded: false,
            appMounted: false,
            appVersion: APP_VERSION,
            debounceHandle: null,
        };
    },
    watch: {
        $route: function (val) {
            const topLocation = this.dataStore.locations.find(
                (l) => l?.is_part_storage_root
            );
            val?.fullPath?.includes(`/storage/`) &&
            !val?.fullPath?.includes(`/storage/${topLocation?.id}`)
                ? (this.navType = 'warehouse')
                : (this.navType = 'app');
        },
        '$i18n.locale': function (val) {
            if (val !== localStorage.getItem('locale')) {
                localStorage.setItem('locale', val);
                if (this.authStore.isLoggedIn) {
                    this.dataStore.dictionary.reload();
                }
            }
            const direction = RTL_LANGUAGES.includes(val) ? 'rtl' : 'ltr';
            this.helpersStore.setLanguageDirection(direction);
            this.setAppDirection(direction);
        },
        isLoggedIn: {
            handler(val) {
                if (val) {
                    if (val && !this.dictionaryLoaded) {
                        this.dataStore.dictionary.promise();
                        this.dictionaryLoaded = true;
                    }
                    this.$nextTick(() => {
                        this.listenSockets();
                    });
                }
            },
            deep: true,
        },
        'authStore.user': {
            handler(val, prevVal) {
                if (val?.id === prevVal?.id) return;
                this.$forceUpdate();
                if (val?.id) {
                    this.$nextTick(() => {
                        this.authStore.watchNotifications();
                        this.listenSockets();
                    });
                }
            },
            deep: true,
        },
        'authStore.terminal': {
            handler(val, prevVal) {
                if (val?.id === prevVal?.id) return;
                if (val?.id) {
                    this.$nextTick(() => {
                        this.listenSockets();
                    });
                }
                if (val?.attributes?.hideSideMenu) {
                    this.authStore.unsubscribeNorifications();
                }
            },
            deep: true,
        },
        'authStore.tenant.attributes.test_mode': {
            handler(value) {
                if (value) {
                    this.turnOnTestMode();
                } else {
                    this.turnOffTestMode();
                }
            },
            deep: true,
        },
    },
    provide() {
        return {
            navDrawer: computed(() => this.drawer),
        };
    },
    computed: {
        userLoginOnTerminalRequired() {
            const isUserLoginRequired =
                this.authStore?.terminal?.requires_user_login &&
                this.authStore?.user?.authenticatable !== 'User';
            return isUserLoginRequired;
        },
        isLoggedIn() {
            return this.authStore.isLoggedIn;
        },
        navItems() {
            const navItems = [...NAVIGATION_ITEMS];
            const myIssuesNavItemIndex = navItems.findIndex(
                (ni) => ni.value === 'my_issues'
            );
            if (myIssuesNavItemIndex > -1) {
                if (this.authStore.isTerminal) {
                    navItems.splice(myIssuesNavItemIndex, 1);
                }
            }
            if (
                this.authStore.isTerminal &&
                this.authStore?.user?.terminal_mode === 'instructions'
            ) {
                navItems.push({
                    value: 'terminal_instructions',
                    title: 'tpm.instructions',
                    to: { name: 'terminal-instructions' },
                    permission: null,
                    icon: 'mdi-clipboard-list',
                });
            }
            if (
                this.authStore?.user?.storage_access?.find(
                    //TODO nwm co z lokalizacjami które nie są magazynowe?
                    (sa) => sa.access === 'storage.read' && sa.allowed === true
                )
            ) {
                navItems.splice(navItems?.length - 1, 0, {
                    value: 'storage',
                    title: 'navDrawer.storage',
                    to: { name: 'storage' },
                    permission: null, //TODO sprawdzić magazynowe,
                    icon: 'mdi-warehouse',
                });
            }
            return navItems;
        },
        navActions() {
            const navActions = [...NAVIGATION_ACTIONS];
            if (this.authStore.isTerminal) {
                const myProfileIndex = navActions.findIndex(
                    (na) => na.value === 'profile'
                );
                if (myProfileIndex > -1) {
                    navActions.splice(myProfileIndex, 1);
                }
            }
            return navActions;
        },
    },
    async created() {
        this.loadDeviceId();
    },
    async mounted() {
        window.authStoreLog = () => {
            console.log(this.authStore);
        };
        console.log('HREF: ', window.location.href);
        if (window.location.href.includes('clear=true')) {
            console.log('CLEANING CACHE');
            const xtd = localStorage.getItem('xdt');
            localStorage.clear();
            if (xtd) {
                localStorage.setItem('xtd', xtd);
            }
            this.initApp();
        } else {
            this.initApp();
        }
        window.setIsCorrectAppVersion =
            this.helpersStore.setIsCorrectAppVersion;
    },
    methods: {
        debounce(func, delay = 250) {
            if (this.debounceHandle !== null) clearTimeout(this.debounceHandle);
            this.debounceHandle = setTimeout(func, delay);
        },
        isBot() {
            const userAgent = navigator?.userAgent?.toLowerCase();

            const botList = [
                'bot',
                'crawl',
                'slurp',
                'spider',
                'mediapartners',
                'facebook',
                'email',
                'outlook',
                'thunderbird',
                'mail',
                'curl',
                'wget',
                'postman',
                'python-requests',
            ];

            return botList.some((bot) => userAgent.includes(bot));
        },
        async checkOTL() {
            try {
                if (window.location.href.includes('terminal-device')) return;
                if (this.isBot()) return;
                if (!window.location.href.includes('otl')) return;
                const urlParams = new URLSearchParams(window.location.search);
                const OTLToken = urlParams.get('otl');
                if (!OTLToken) return;
                try {
                    const otlResponse = await this.axios.post(
                        'auth/login_by_otl',
                        {
                            otl: OTLToken,
                        }
                    );
                    if (otlResponse?.data?.user && otlResponse?.data?.token) {
                        if (this.isLoggedIn) {
                            await this.authStore.logout();
                        }

                        await this.authStore.login(otlResponse?.data);
                        this.authLoading = false;
                        const params = new URLSearchParams(
                            window.location.search
                        );
                        params.delete('otl');
                        window.location.search = `${params}`;
                        return;
                    }
                } catch (otlError) {
                    console.error(otlError);
                }
            } catch (err) {
                console.error(err);
            }
        },
        async initApp() {
            window.appMounted = false;

            localStorage.removeItem('workAreas');

            window.onSignaloRelayConnectionChanged = function () {}; //HOTFIX
            this.appInstance = getCurrentInstance();
            createVuetifyTheme(this.appInstance);
            this.watchInternetConnection();
            this.watchResize();
            this.emitter.on('toggle-sidebar', () => {
                this.drawer = !this.drawer;
            });
            const tenantRes = await this.axios.get('/tenant');
            this.authStore.setTenant(tenantRes?.data?.item);
            if (
                !(
                    window.location.href.includes('terminal-device') &&
                    window.location.href.includes('otl=')
                )
            ) {
                await this.handleAuth();
            }
            this.authLoading = false;
            await this.checkOTL();
            this.helpersStore.watchInteractionStarted();
            //TO MAKE VUETIFY WORK ON OLDER BROWSERS
            Element.prototype.animate = undefined;
            this.listenCtrlS();

            if (this.isLoggedIn) {
                this.listenSockets();
            }
            await this.removeTempAuth();
            window.check = () => {
                console.log('TERMINAL:', this.authStore.terminal);
                console.log('USER:', this.authStore.user);
                console.log('ACCESS:', this.authStore?.user?.access);
            };

            window.logout = this.authStore.fakeLogout;
            window.setMaintenance = this.helpersStore.setMaintenance;
            const direction = RTL_LANGUAGES.includes(this.$i18n.locale)
                ? 'rtl'
                : 'ltr';
            this.helpersStore.setLanguageDirection(direction);
            this.setAppDirection(direction);
            if (!localStorage.getItem('locale') && this.$i18n.locale) {
                localStorage.setItem('locale', this.$i18n.locale)
            }
            this.appMounted = true;
        },
        setTenantTranslations(tenant) {
            i18nTenantTranslations(tenant);
            this.$forceUpdate();
        },
        async removeTempAuth() {
            try {
                await this.axios.delete('auth/temporary');
            } catch (err) {
                console.error(err);
            }
        },
        onSurveyDialogClose() {
            this.helpersStore.closeSurvey();
        },
        watchInternetConnection() {
            window.addEventListener('online', () => {
                if (
                    this.errorMessage === this.$t('errors.noInternetConnection')
                ) {
                    if (typeof window?.FFApi?.reload === 'function') {
                        window.FFApi.reload();
                    } else {
                        location.reload();
                    }
                }
                this.helpersStore.setIsOnline(true);
                this.errorMessage = null;
                this.error = null;
            });
            window.addEventListener('offline', () => {
                this.helpersStore.setIsOnline(false);
                this.error = true;
                this.errorMessage = this.$t('errors.noInternetConnection');
            });
        },
        onErrorReport() {
            this.errorReportDialog = true;
        },
        onErrorReportClose() {
            this.helpersStore.clearErrorMessage();
            this.errorReportDialog = false;
            this.helpersStore.snackbarOn = false;
        },
        async onErrorReportSave(errorDescription) {
            this.errorReportLoading = true;
            try {
                const body = {
                    description: errorDescription,
                    error: [
                        {
                            frontUrl: window.location.href,
                            error: this.helpersStore.errorMessage,
                        },
                    ],
                };
                await this.dataStore.errorLog.send(body);
                this.helpersStore.clearErrorMessage();
                this.errorReportDialog = false;
                this.helpersStore.snackbarSaved();
            } catch (err) {
                console.error(err);
            }
            this.errorReportLoading = false;
        },
        watchResize() {
            this.helpersStore.setIsMobile(this.checkIsMobile());
            window.addEventListener('resize', () => {
                this.helpersStore.setIsMobile(this.checkIsMobile());
            });
        },
        checkIsMobile() {
            return CheckIsMobile();
        },
        async handleAuth() {
            let ptab = [];

            let token = window.localStorage.getItem('authToken');
            const terminalToken = localStorage.getItem('terminalToken');
            let id, code;
            if (window.location.href.includes('context=')) {
                const hex = window.location.href.split('context=')[1];
                const output = Buffer.Buffer.from(hex, 'hex').toString();
                if (output.includes(':')) {
                    [id, code] = output.split(':');
                    id = Number(id);
                }
            }
            if (Number.isInteger(id) && code) {
                const xtd = localStorage.getItem('xdt');
                localStorage.clear();
                if (xtd) {
                    localStorage.setItem('xtd', xtd);
                }
                ptab.push(
                    this.axios
                        .post(
                            '/auth/login_by_code',
                            { user_id: id, password: code },
                            { permitAll: true }
                        )
                        .then((x) => {
                            if (x?.data?.status == 'ok') {
                                this.authStore.login(x.data);
                            }
                        })
                );
            } else if (terminalToken) {
                let terminalLoginData;
                try {
                    //TUTAJ jest napierw strzał pod auth w odróżnieniu do CMMSA, bo user może być zalogowany na terminal.

                    terminalLoginData = await this.axios.get(`auth`);
                } catch (err) {
                    console.error(err);
                    if (
                        err?.response?.status === 401 ||
                        err?.response?.status === 400
                    ) {
                        try {
                            const secret = new TextEncoder().encode(
                                terminalToken
                            );
                            terminalLoginData = await GetTerminalLoginData(
                                secret,
                                this.axios
                            );
                        } catch (error) {
                            if (
                                error?.response?.status === 401 ||
                                error?.response?.status === 400
                            ) {
                                try {
                                    localStorage.removeItem('authToken');
                                    const secret = new TextEncoder().encode(
                                        terminalToken
                                    );
                                    terminalLoginData =
                                        await GetTerminalLoginData(
                                            secret,
                                            this.axios
                                        );
                                } catch (error2) {
                                    this.authStore.forget();
                                    localStorage.removeItem('terminalToken');
                                    localStorage.removeItem('authToken');
                                    if (
                                        typeof window?.FFApi?.reload ===
                                        'function'
                                    ) {
                                        window.FFApi.reload();
                                    } else {
                                        location.reload();
                                    }
                                    console.error(error2);
                                }
                            }
                            console.error(error);
                            this.authStore.forget();
                            this.authLoading = false;
                            return;
                        }
                    }
                }
                ptab.push(
                    new Promise((resolve) => {
                        resolve({
                            data: {
                                ...terminalLoginData,
                                status: 'ok',
                            },
                        });
                    })
                        .then((res) => {
                            this.setPrimaryColor(res?.data);
                            this.setToolbarTitle(res?.data);
                            this.setCustomDimensions(res?.data);

                            createVuetifyTheme(this.appInstance);
                            this.authStore.login(res?.data);
                            this.navigateToDefaultView(res?.data);
                        })
                        .catch((err) => {
                            console.error(err);
                        })
                );
            } else if (token) {
                ptab.push(
                    this.axios.get('/auth').then((res) => {
                        this.authStore.login(res?.data);
                    })
                );
            }

            Promise.allSettled(ptab)
                .then((promises) => {
                    if (
                        promises?.[0]?.status === 'rejected' &&
                        promises?.[0]?.reason.code === 'ERR_NETWORK'
                    ) {
                        this.helpersStore.snackbar(
                            'Serwer nie odpowiada',
                            'warning'
                        );
                    }
                    if (promises?.[0]?.reason?.response?.status === 503) {
                        this.helpersStore.setMaintenance(true);
                        this.authLoading = false;
                        return;
                    }
                    let ok = true;
                    if (promises?.[0]?.status === 'fulfilled') {
                        let res = promises[0].value;
                        if (res?.data?.status == 'ok') {
                            this.setTenantTranslations(res?.data?.item);
                            this.authStore.setTenant(res?.data?.item);
                        }
                    } else {
                        ok = false;
                    }
                    if (!this.authStore.tenant) {
                        console.log('ERROR! TENANT NOT FOUND!');
                        this.errorMessage = this.$t('errors.tenantNotFound');
                        this.authLoading = false;
                        this.error = true;
                        return;
                    }
                    if (!ok) {
                        this.authStore.forget();
                    }
                    this.authLoading = false;
                })
                .catch((err) => {
                    console.error(err);
                    this.authStore.forget();
                    this.authLoading = false;
                });
        },
        onCtrlS(e) {
            //workaround listener zapięty tutaj, bo w SignaloDialog ten event leciał wiele razy;
            if (e.ctrlKey && (e?.key === 's' || e?.code === 'KeyS')) {
                const actionsWrapper = document.getElementsByClassName(
                    'app-dialog-actions-container'
                )?.[0];
                if (actionsWrapper) {
                    const elevatedButtons =
                        actionsWrapper.getElementsByClassName(
                            'v-btn--elevated'
                        );
                    if (elevatedButtons?.length) {
                        elevatedButtons[elevatedButtons?.length - 1]?.click();
                    }
                }
            }
        },
        listenCtrlS() {
            document.addEventListener('keyup', this.onCtrlS);
        },
        loadDeviceId() {
            localStorage.removeItem('device_id');
            if (typeof window?.FFApi?.deviceId === 'function') {
                const deviceId = window?.FFApi?.deviceId();
                if (deviceId) {
                    localStorage.setItem('device_id', deviceId);
                }
            }
        },
        errorCaptured(err) {
            //WORKAROUND - error in vue-router;
            console.log('[errorCaptured]', err);
            if (err.toString().includes('Missing required param')) return;
            if (err.toString().includes('getBoundingClientRect')) return;
            if (err.toString().includes('SAD_CLOSE')) return;
            if (err?.status === 401 || err?.response?.status === 401) return;
            console.error(err);
            const error = {
                error: JSON.stringify(err),
                route: window.location.href,
            };
            this.$nextTick(() => {
                this.helpersStore.snackbarError(error);
            });
            return false;
        },
        setPrimaryColor(res) {
            const primaryColor =
                res?.data?.terminal?.attributes?.primaryColor ||
                res?.data?.user?.attributes?.primaryColor;
            if (primaryColor) {
                setColorVariablesFromPalette(
                    '--primary-',
                    generateColorPalette(res.data.user.attributes.primaryColor)
                );

                if (window?.FFApi?.setToolbarColor) {
                    window.FFApi.setToolbarColor(primaryColor);
                }
            }
        },
        setToolbarTitle(res) {
            const title =
                res?.data?.terminalUser?.name ||
                res?.data?.user?.name ||
                res?.data?.terminal?.name;
            if (title && typeof window?.FFApi?.setToolbarTitle === 'function') {
                window.FFApi.setToolbarTitle(
                    this.$getPropertyTranslation(title)
                );
            }
        },
        setCustomDimensions(res) {
            if (res?.data?.user?.attributes?.CUSTOM_DIMENSIONS) {
                setCustomDimensionsCssVariables(
                    res?.data?.user?.attributes?.CUSTOM_DIMENSIONS
                );
            }
        },
        navigateToDefaultView(res) {
            if (this.$route?.query?.lua) return;
            const defaultView =
                res?.data?.terminal?.attributes?.default_view?.name ||
                res?.data?.user?.attributes?.default_view?.name;
            if (defaultView) {
                this.$router.push({
                    name: defaultView,
                    replace: true,
                    force: true,
                });
            }
        },

        listenSockets() {
            this.debounce(() => {
                if (!this.$listenEvent) return;
                this.$listenEvent(CHANNELS.PUBLIC, EVENTS.NOTIFICATION, () => {
                    this.helpersStore.debounce(() => {
                        this.authStore.watchNotifications();
                    }, 200);
                });

                this.$listenEvent(CHANNELS.PUBLIC, EVENTS.APP_RELOAD, () => {
                    console.log('TERMINAL PRIVATE APP_RELOAD EVENT');
                    this.$clearWebviewCacheAndForceReloadApp();
                });

                if (
                    this.authStore?.terminal ||
                    this.authStore?.user?.authenticatable === 'Terminal'
                ) {
                    if (this.authStore.user.authenticatable === 'Terminal') {
                        this.$stopListen(CHANNELS.PRESENCE_USERS);
                    }
                    const terminalId =
                        this.authStore?.terminal?.id ||
                        this.authStore?.user?.id;
                    if (!terminalId) return;

                    const terminalPrivateChannel = `private-${terminalId}.terminal`;

                    this.$listenEvent(
                        terminalPrivateChannel,
                        EVENTS.NOTIFICATION,
                        () => {}
                    );

                    this.$listenEvent(
                        terminalPrivateChannel,
                        EVENTS.APP_RELOAD,
                        () => {
                            console.log('TERMINAL PRIVATE APP_RELOAD EVENT');
                            this.$clearWebviewCacheAndForceReloadApp();
                        }
                    );

                    this.$listenEvent(CHANNELS.PRESENCE_TERMINALS);

                    if (this.authStore.user.authenticatable === 'User') {
                        this.$listenEvent(CHANNELS.PRESENCE_USERS);
                    }
                } else {
                    const myId = this.authStore.user.id;
                    if (!Number.isInteger(myId)) {
                        this.$stopListen(CHANNELS.PRESENCE_USERS);
                        return;
                    }
                    const myPrivateChannel = `private-${myId}.notification`;
                    this.$listenEvent(
                        myPrivateChannel,
                        EVENTS.NOTIFICATION,
                        () => {
                            this.helpersStore.debounce(() => {
                                this.authStore.watchNotifications();
                            }, 200);
                        }
                    );

                    this.$listenEvent(
                        myPrivateChannel,
                        EVENTS.DOCUMENT_READY,
                        (data) => {
                            this.helpersStore.snackbar(
                                data?.data?.title,
                                'success',
                                5000,
                                false,
                                () => {
                                    window.open(
                                        data?.data?.description, //TAK description - backend jest upośledzony;
                                        '_blank'
                                    );
                                }
                            );
                        }
                    );

                    const myPrivateUserChannel = `private-${myId}.user`;
                    this.$listenEvent(
                        myPrivateUserChannel,
                        EVENTS.NOTIFICATION,
                        () => {
                            this.helpersStore.debounce(() => {
                                this.authStore.watchNotifications();
                            }, 200);
                        }
                    );
                    this.$listenEvent(CHANNELS.PRESENCE_USERS);
                }
            });
        },
        turnOnTestMode() {
            const body = document.getElementsByTagName('body')?.[0];

            Array.from(
                document.getElementsByClassName('test-app-warning-border')
            ).forEach((e) => {
                e.remove();
            });

            const borderElementTop = document.createElement('div');
            const borderElementLeft = document.createElement('div');
            const borderElementRight = document.createElement('div');
            const borderElementBottom = document.createElement('div');

            borderElementTop.classList.add(
                'test-app-warning-border',
                'test-app-warning-border-top'
            );
            borderElementLeft.classList.add(
                'test-app-warning-border',
                'test-app-warning-border-left'
            );
            borderElementRight.classList.add(
                'test-app-warning-border',
                'test-app-warning-border-right'
            );
            borderElementBottom.classList.add(
                'test-app-warning-border',
                'test-app-warning-border-bottom'
            );

            body.prepend(borderElementTop);
            body.prepend(borderElementLeft);
            body.prepend(borderElementRight);
            body.prepend(borderElementBottom);

            const appWrapper = document.getElementById('app');
            if (!appWrapper) return;
            appWrapper.classList.add('test-app-margin');
        },
        turnOffTestMode() {
            Array.from(
                document.getElementsByClassName('test-app-warning-border')
            ).forEach((e) => {
                e.remove();
            });
            const appWrapper = document.getElementById('app');
            if (!appWrapper) return;
            appWrapper.classList.remove('test-app-margin');
        },
        setAppDirection(direction) {
            const body = document.querySelector('body');
            if (direction === 'rtl') {
                body.classList.add('rtl');
            } else {
                body.classList.remove('rtl');
            }
        },
    },
};
</script>

<style>
.preview-header {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    padding: 4px 8px;
}
.preview-header {
    background-color: var(--greyscale-5-white) !important;
    padding: 4px 16px;
}
.preview-header .preview-header-icon {
    margin-right: 4px;
}
.menu-action {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.menu-action .operation-icon {
    margin-right: 8px;
}

.manage-modal-header {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    padding: 4px 8px;
}
.manage-modal-header .v-icon {
    margin-right: 4px;
}

.snackbar-message-container {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
}
.snackbar-message-container .error-icon-container {
    margin-right: 4px;
}

.v-btn.report-problem-button {
    background-color: var(--warning-60);
    color: white !important;
}

.notification-player,
.hidden-language-changer {
    position: absolute;
    top: -10000px;
    left: -10000px;
}
.hidden-language-changer {
    visibility: hidden;
}
.test-app-margin {
    margin: 16px;
}

.test-app-warning-border-top {
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 0px;
    border-top: 16px solid black;
    z-index: 999999;
}

.test-app-warning-border-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 0px;
    border-top: 16px solid black;
    z-index: 999999;
}

.test-app-warning-border-left {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 100vh;
    border-right: 16px solid black;
    z-index: 999999;
}

.test-app-warning-border-right {
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100vh;
    border-left: 16px solid black;
    z-index: 999999;
}

.test-app-warning-border-top::before {
    content: '';
    position: absolute;
    top: -16px;
    width: 100%;
    border-top: 16px dashed var(--warning-secondary);
}

.test-app-warning-border-bottom::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 16px dashed var(--warning-secondary);
}

.test-app-warning-border-left::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100vh;
    border-right: 16px dashed var(--warning-secondary);
}

.test-app-warning-border-right::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    border-left: 16px dashed var(--warning-secondary);
}

.test-app-margin .v-navigation-drawer {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 16px;
    height: calc(100% - 32px) !important;
}

.test-app-margin .v-toolbar {
    left: calc(256px + 16px) !important;
    margin-top: 16px;
    margin-right: 16px;
    width: calc(100% - 256px - 32px) !important;
}

.test-app-margin .maintenance-container {
    padding: 32px;
}

.test-app-margin
    + .v-overlay-container
    div.v-overlay__content.v-snackbar__wrapper.v-snackbar--variant-elevated {
    bottom: 16px !important;
    right: 16px !important;
}

.app-version {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;

    min-height: 20px;

    text-align: right;
    padding: 0 8px;
    font-size: 0.75rem;
    font-weight: bold;
    color: var(--ui-text-text-02);
}
.app-version:hover .app-version-start {
    width: auto;
}

.app-version:hover .app-version-end {
    display: none;
}

.app-version-start {
    width: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition-duration: 0.75s;
}
</style>
