export const ar = {
    common: {
        PIN_CODE_LABEL: 'رمز التعريف الشخصي',
        comment: 'تعليق',
        year: 'السنة',
        configuration: 'التكوين',
        cancel: 'إلغاء',
        search: 'بحث',
        remove: 'إزالة',
        confirmationDialogTitle: 'هل أنت متأكد من إزالة هذا العنصر؟',
        selectDate: 'اختر التاريخ',
        select: 'اختيار',
        notFound: 'غير موجود',
        equipmentPickerPlaceholder: 'كائن المعدات',
        searchPlaceholder: 'بحث',
        equipmentTypePickerPlaceholder: 'نوع المعدات',
        equipmentTypePickerTitle: 'اختر نوع المعدات',
        emptyTableMessage: 'لا توجد بيانات',
        flowPikcerTitle: 'اختر التدفق',
        selectIcon: 'اختر أيقونة',
        locationPickerTitle: 'اختر الموقع',
        locationPlaceholder: 'الموقع',
        noSublocations: 'لا توجد مواقع فرعية',
        locationsTitle: 'المواقع',
        edit: 'تعديل',
        save: 'حفظ',
        added: 'تمت الإضافة',
        updated: 'تم التحديث',
        add: 'إضافة',
        removed: 'تمت الإزالة',
        reload: 'إعادة تحميل',
        confirm: 'تأكيد',
        rollBack: 'تراجع',
        images: 'الصور',
        documents: 'المستندات',
        download: 'تنزيل',
        saved: 'تم الحفظ',
        date: 'التاريخ',
        hour: 'الساعة',
        preview: 'معاينة',
        data: 'البيانات',
        attributes: 'الخصائص',
        checkList: 'قائمة التحقق',
        name: 'الاسم',
        type: 'النوع',
        status: 'الحالة',
        designation: 'التسمية',
        symbol: 'الرمز',
        serialNumber: 'الرقم التسلسلي',
        registryNumber: 'رقم التسجيل',
        machineNumber: 'رقم الآلة',
        udtNumber: 'رقم UDT',
        manufacturedYear: 'سنة التصنيع',
        manufacturer: 'المصنِّع',
        supplier: 'المورِّد',
        mainSupplier: 'المورِّد الرئيسي',
        attribute: 'خاصية',
        nameRequired: 'الاسم مطلوب',
        yearGreaterThan: 'يجب أن تكون السنة أكبر من',
        location: 'الموقع',
        fieldRequired: 'هذه الخانة مطلوبة',
        description: 'الوصف',
        minimumQuantity: 'الكمية الدنيا',
        optimalQuantity: 'الكمية المثالية',
        priority: 'الأولوية',
        formatYYYY: 'التنسيق YYYY',
        storageQuantity: 'كمية المستودع',
        quantity: 'الكمية',
        quantityGreaterThan: 'يجب أن تكون الكمية أكبر من',
        machine: 'آلة',
        tool: 'أداة',
        order: 'طلب',
        required: 'مطلوب',
        dateFormat: 'تنسيق التاريخ',
        selectDictionary: 'اختر القاموس',
        return: 'عودة',
        parameters: 'المعلمات',
        editCategory: 'تعديل الفئة',
        addCategory: 'إضافة فئة جديدة',
        changeColor: 'تغيير اللون',
        noColor: 'بدون لون',
        shift: 'نوبة العمل',
        start: 'البداية',
        end: 'النهاية',
        shiftSchema: 'مخطط النوبة',
        shifts: 'نوبات العمل',
        dictionary: 'قاموس',
        subEntries: 'الإدخالات الفرعية',
        key: 'المفتاح',
        value: 'القيمة',
        moveUp: 'تحريك لأعلى',
        moveDown: 'تحريك لأسفل',
        addLocation: 'إضافة موقع جديد',
        editLocation: 'تعديل الموقع',
        companiesDatabase: 'قاعدة بيانات الشركات',
        flows: 'التدفقات',
        read: 'قراءة',
        write: 'كتابة',
        noImage: 'لا توجد صورة',
        email: 'البريد الإلكتروني',
        function: 'الوظيفة',
        active: 'نشط',
        notActive: 'غير مفعل',
        disabled: 'معطل',
        entryNumber: 'رقم الإدخال',
        issuedDate: 'تاريخ الإصدار',
        expiresDate: 'تاريخ الانتهاء',
        move: 'تحريك',
        separateForm: 'فصل من',
        separate: 'فصل',
        warehouse: 'مستودع',
        actions: 'الإجراءات',
        putInCurrentLocation: 'ضع في الموقع الحالي',
        selectLocationFormRightMenu: 'اختر الموقع من القائمة الجانبية اليمنى',
        showUnassignedItems: 'عرض العناصر غير المخصصة',
        separated: 'مفصول',
        moved: 'تم النقل',
        categories: 'الفئات',
        welcome: 'مرحبًا',
        loginToSignaloTpm: 'تسجيل الدخول إلى Signalo TPM',
        login: 'تسجيل الدخول',
        loginByEmailAndPassword: 'تسجيل الدخول بالبريد الإلكتروني وكلمة المرور',
        loginByCode: 'تسجيل الدخول عبر @:common.PIN_CODE_LABEL',
        accessCode: 'رمز الوصول',
        enterEmail: 'أدخل البريد الإلكتروني',
        enterPassword: 'أدخل كلمة المرور',
        enterCode: 'أدخل @:common.PIN_CODE_LABEL',
        userNotFound: 'المستخدم غير موجود',
        userNotActivated: 'المستخدم غير مفعل',
        userDisabled: 'المستخدم معطل',
        wrongPassword: 'كلمة المرور خاطئة',
        locations: 'المواقع',
        pageNotFound: 'الصفحة غير موجودة',
        group: 'مجموعة',
        yes: 'نعم',
        no: 'لا',
        hourlyCost: 'التكلفة بالساعة',
        price: 'السعر',
        newEntry: 'إدخال جديد',
        predictedFinishTime: 'الوقت المتوقع للانتهاء',
        ok: 'حسنًا',
        archive: 'أرشيف',
        costs: 'التكاليف',
        companyManagement: 'إدارة الشركة',
        companyData: 'بيانات الشركة',
        supportedLanguages: 'اللغات المدعومة',
        en: 'الإنجليزية',
        pl: 'البولندية',
        es: 'الإسبانية',
        de: 'الألمانية',
        it: 'الإيطالية',
        fr: 'الفرنسية',
        ru: 'الروسية',
        uk: 'الأوكرانية',
        pt: 'البرتغالية',
        tr: 'التركية',
        nl: 'الهولندية',
        sr: 'الصربية',
        ar: 'العربية',
        he: 'العبرية',
        defaultLanguage: 'اللغة الافتراضية',
        companyLogo: 'شعار الشركة',
        notificationsManagement: 'إدارة الإشعارات',
        category: 'فئة',
        today: 'اليوم',
        month: 'الشهر',
        week: 'الأسبوع',
        day: 'اليوم',
        close: 'إغلاق',
        show: 'عرض',
        rollBackFormalConfirmation: 'التراجع عن التأكيد الرسمي',
        signUp: 'التسجيل',
        nameSurname: 'الاسم واللقب',
        phone: 'رقم الهاتف',
        password: 'كلمة المرور',
        password_confirmation: 'تأكيد كلمة المرور',
        unassignedItems: 'العناصر غير المخصصة',
        currency: 'العملة',
        general: 'عام',
        timezone: 'المنطقة الزمنية',
        users: 'المستخدمون',
        groups: 'المجموعات',
        productionLine: 'خط الإنتاج',
        part: 'جزء',
        fittingParts: 'الأجزاء المناسبة',
        workSchema: 'مخطط العمل',
        workSchemas: 'مخططات العمل',
        holiday: 'عطلة',
        changePassword: 'تغيير كلمة المرور',
        showRest: 'عرض البقية',
        pageNotPermitted: 'الصفحة غير مسموح بها',
        waitingForActivation: 'في انتظار التفعيل',
        editGroup: 'تعديل المجموعة',
        addGroup: 'إضافة مجموعة',
        reportProblem: 'الإبلاغ عن مشكلة',
        describeOccuredIssue: 'وصف المشكلة التي حدثت',
        markAllAsRead: 'تحديد الكل كمقروء',
        unitType: 'نوع الوحدة',
        unit: 'الوحدة',
        unitPrice: 'سعر الوحدة',
        notConfigured: 'غير مُكوّن',
        rowsPerPage: 'عدد الصفوف لكل صفحة',
        rowsOfPage: 'من',
        true: 'صحيح',
        false: 'خاطئ',
        export: 'تصدير',
        chooseFileFormat: 'اختر تنسيق الملف',
        exported: 'تم التصدير',
        terminals: 'أجهزة طرفية',
        terminal: 'جهاز طرفي',
        selectEquipment: 'اختر المعدات',
        futureIssues: 'المشاكل المستقبلية',
        scheduledOn: 'مجدول في',
        planned: 'مخطط',
        notPlanned: 'غير مخطط',
        showOpenIssues: 'عرض المشاكل المفتوحة',
        pin: 'رمز PIN',
        assignedEquipment: 'المعدات المعينة',
        terminalEquipment: 'معدات الطرفية',
        detach: 'فصل',
        attach: 'إرفاق',
        dashboardConfig: 'إعدادات لوحة التحكم',
        addColumn: 'إضافة عمود',
        addRow: 'إضافة صف',
        removeRow: 'إزالة صف',
        registerTerminal: 'تسجيل جهاز طرفي',
        issueGeneratesCost: 'التوقف يسبب تكلفة',
        selectColor: 'اختر لونًا',
        belowMinimum: 'أقل من الحد الأدنى',
        belowOptimum: 'أقل من المستوى الأمثل',
        synchronization: 'مزامنة',
        synchronizationExport: 'تصدير قطع الغيار',
        synchronizationImport: 'استيراد قطع الغيار',
        mapping: 'رسم الخرائط',
        returnTo: 'العودة إلى',
        jobStatusses: 'العمليات',
        hidden: 'مخفي',
        editEvent: 'تعديل الحدث',
        threeDays: '3 أيام',
        addEvent: 'إضافة حدث',
        plannedIssues: 'المشاكل المخططة',
        currentService: 'الخدمة الحالية',
        searchLocation: 'البحث عن الموقع',
        all: 'الكل',
        unfinished: 'غير مكتمل',
        numberOfIssues: 'عدد المشاكل',
        unknown: 'غير معروف',
        partEntry: 'جزء (إدخال)',
        foundItems: 'العناصر التي تم العثور عليها',
        output: 'المخرجات',
        copied: 'تم النسخ',
        link: 'رابط',
        authorizationRequired: 'التفويض مطلوب',
        authorizationRequiredDescriptionUser:
            'للأسف، ليس لديك صلاحيات كافية. اطلب من شخص مخول الموافقة على الإجراء.',
        authorizationRequiredDescriptionTerminal:
            'استخدم @:common.PIN_CODE_LABEL الخاص بك لتفويض الإجراء.',
        showAll: 'عرض الكل',
        showClassicView: 'عرض العرض التقليدي',
        noIssuesInCategory: 'لا توجد مشاكل في هذه الفئة.',
        numberOfIssuesInThisCategoryAndSubcategories:
            'عدد المشاكل في هذه الفئة والفئات الفرعية.',
        numberOfIssuesInThisCategory: 'عدد المشاكل في هذه الفئة.',
        minBarcodeLength: 'الحد الأدنى لطول الباركود',
        currentPage: 'الصفحة الحالية',
        allPages: 'كل الصفحات',
        selectFormat: 'اختر التنسيق',
        provided_currency: 'العملة المقدمة',
        provided_quantity: 'الكمية المقدمة',
        provided_unit: 'الوحدة المقدمة',
        provided_unit_price: 'سعر الوحدة المقدم',
        unit_price: 'سعر الوحدة',
        quantity_available: 'الكمية المتاحة',
        editBarcode: 'تحرير الباركود',
        crews: 'الفرق',
        scheduleEventTypes: 'أنواع أحداث الجدول',
        crew: 'الفرقة',
        scheduleEvent: 'جدولة حدث',
        available: 'متاح',
        'App\\Models\\Crew': 'فرقة',
        'App\\Models\\User': 'مستخدم',
        'App\\Models\\Equipment': 'معدات',
        Crew: 'فرقة',
        User: 'مستخدم',
        Equipment: 'معدات',
        scheduleEvents: 'جدولة الأحداث',
        event: 'حدث',
        externalSystems: 'أنظمة خارجية',
        user: 'مستخدم',
        availability: 'التوافر',
        inheritColorFromParentIfCustomNotSet:
            'وراثة اللون من الأصل إذا لم يتم تعيين لون مخصص',
        pickFromAllParts: 'اختر من جميع الأجزاء',
        takeTool: 'أخذ الأداة',
        returnTool: 'إرجاع الأداة',
        personWhoTake: 'الشخص الذي أخذ',
        personWhoReturn: 'الشخص الذي أعاد',
        takenAt: 'أخذ في',
        takenBy: 'أخذ بواسطة',
        toolIssuedBy: 'تم إصدار الأداة من قبل',
        commentTake: 'تعليق أخذ',
        commentReturn: 'تعليق الإرجاع',
        change: 'تغيير',
        onlyWithActiveRMIssues: 'فقط مع مشاكل RM النشطة',
        externalSystem: 'نظام خارجي',
        rejectIssue: 'رفض المشكلة',
        withdrawIssueRejection: 'سحب رفض المشكلة',
        rejectionReason: 'سبب الرفض',
        rejected: 'مرفوض',
        showCrewMembers: 'عرض أعضاء الفرقة',
        crewMembers: 'أعضاء الفرقة',
        helpGroup: 'الخدمة',
        partsTakenByUser: 'الأجزاء التي تم أخذها من قبل المستخدم',
        pickParts: 'إصدار الأجزاء',
        selectPersonWhoTakes: 'اختر الشخص الذي يأخذ',
        changePersonWhoTakes: 'تغيير الشخص الذي يأخذ',
        quantityToPick: 'الكمية المراد أخذها',
        pickLocation: 'موقع الاستلام',
        selectPartsToPick: 'اختر الأجزاء المراد أخذها',
        partSelected: 'تم اختيار الجزء',
        scanCodeOr: 'مسح الرمز أو',
        returnPart: 'إرجاع الجزء',
        myTakenParts: 'الأجزاء التي أخذتها',
        takenFromLocation: 'أخذت من الموقع',
        takenQuantity: 'الكمية المأخوذة',
        providedUnit: 'الوحدة المقدمة',
        providedQuantity: 'الكمية المقدمة',
        selectFromStorage: 'اختر من المخزن',
        selectFromMyPickedParts: 'اختر من الأجزاء التي أخذتها',
        exportProcessInProgressMessage:
            'تم بدء عملية تصدير البيانات، عند الانتهاء سترى إشعارًا يحتوي على رابط إلى الملف.',
        yourDocumentIsReady: 'مستندك جاهز',
        rotateScreen: 'قم بتدوير الشاشة',
        addRule: 'إضافة قاعدة',
        editRule: 'تعديل القاعدة',
        summary: 'الملخص',
        onlyAvailableEmployees: 'الموظفون المتاحون فقط',
        calculated_unit_price: 'سعر الوحدة المحسوب',
        calculated_quantity: 'الكمية المحسوبة',
        next: 'التالي',
        maintenanceMode: 'وضع الصيانة',
        applicationMayBeUnavailableForFewMinutes:
            'قد تكون التطبيق غير متاح لبضع دقائق',
        appWillRefreshIn: 'سيتم إعادة تحميل التطبيق خلال',
        landscape: 'أفقي',
        portrait: 'عمودي',
        orientation: 'الاتجاه',
        format: 'التنسيق',
        service: 'الخدمة',
        leader: 'القادة',
        defaultView: 'العرض الافتراضي',
        view: 'عرض',
        oneDay: 'يوم واحد',
        object: 'كائن',
        finished: 'منتهي',
        onlyNotFinished: 'غير مكتمل فقط',
        generate: 'توليد',
        error: 'خطأ',
        cell: 'خلية',
        resolutionSummary: 'ملخص النهاية',
        rejected_at: 'تاريخ الرفض',
        rejected_by: 'تم الرفض بواسطة',
        finishedByUser: 'تم الانتهاء بواسطة',
        formalConfirmByUser: 'التأكيد الرسمي من قبل',
        formalConfirmAt: 'تاريخ التأكيد الرسمي',
        valueDeleted: '[القيمة محذوفة]',
        hasNotPlannedIssue: 'لا توجد مشاكل مخططة',
        showInStorage: 'عرض في المخزن',
        changeCode: 'تغيير @:common.PIN_CODE_LABEL',
        staffProfiles: 'ملفات الموظفين',
        profile: 'الملف الشخصي',
        user_bug: 'خطأ في التطبيق',
        user_kaizen: 'اقتراح التغيير',
        changeSuggestions: 'اقتراحات التغيير',
        showOnlyCurrentlyAvailable: 'عرض المتاحين حاليًا فقط',
        resetPassword: 'إعادة تعيين كلمة المرور',
        forgotPassword: 'نسيت كلمة المرور',
        resetPasswordMailSent:
            'تم إرسال رابط لإعادة تعيين كلمة المرور إلى البريد الإلكتروني المقدم',
        manyEventsThisTime: 'العديد من الأحداث في هذا الوقت',
        importScheduleCheckboxLabel:
            'حذف الأحداث المجدولة في الشهر المحدد للموظفين المدرجين في الملف',
        inheritedEvent: 'حدث موروث',
        inheritedModifiedEvent: 'حدث موروث معدّل',
        equipmentId: 'معرف المعدات',
        entryId: 'معرف الإدخال',
        partId: 'معرف الجزء',
        suggestionsEmail:
            'البريد الإلكتروني الذي سيتم إرسال اقتراحات التغيير إليه',
        meters: 'أمتار',
        scanCardToAuth:
            'مسح البطاقة أو أدخل @:common.PIN_CODE_LABEL لتأكيد هويتك',
        scanCard: 'مسح البطاقة',
        cart: 'صندوق الإسقاط',
        emptyCardMessage:
            'ابدأ بمسح المنتجات ضوئيًا أو اخترها من القائمة لإضافتها إلى صندوق الإسقاط.',
        terminalMode: 'نوع الجهاز',
        askPermittedUserToConfirmPartsExchange:
            'اطلب من الشخص المصرح له تأكيد الإفراج / إرجاع الجزء عن طريق مسح البطاقة أو إدخال @:common.PIN_CODE_LABEL',
        partsIssued: 'الأجزاء المُصدرة',
        areYouSureAboutCancel: 'هل أنت متأكد أنك تريد الإلغاء؟',
        areYouSureAboutRemoveFromCart:
            'هل أنت متأكد أنك تريد إزالة هذا العنصر من صندوق الإسقاط؟',
        standard: 'قياسي',
        idNumber: 'رقم التعريف',
        takeParts: 'أخذ الأجزاء',
        returnParts: 'إرجاع الأجزاء',
        quantityToReturn: 'الكمية المراد إرجاعها',
        partsPendingApproval: 'الأجزاء في انتظار الموافقة',
        partsReturned: 'الأجزاء المعادة',
        partsAddedToPendingApproval:
            'تمت إضافة الأجزاء إلى قائمة الموافقة المعلقة',
        approvePartsReturn: 'الموافقة على إرجاع الأجزاء',
        partReturnApproved: 'تمت الموافقة على إرجاع الجزء',
        partUsage: 'استخدام الجزء',
        awaitsReturn: 'ينتظر الإرجاع',
        entrySelection: 'اختيار الإدخال',
        correctQuantity: 'الكمية الصحيحة',
        quantityCorrection: 'تصحيح الكمية',
        entrySelectionAsc: 'اختيار الإدخال (تصاعدي)',
        entrySelectionDesc: 'اختيار الإدخال (تنازلي)',
        metersSettings: 'إعدادات العدادات',
        countableFields: 'الحقول القابلة للعد',
        equipmentTypes: 'أنواع المعدات',
        fieldName: 'اسم الحقل',
        countableField: 'حقل قابل للعد',
        partsStatusTaken: 'مأخوذ',
        rejectPartsReturn: 'رفض إرجاع الأجزاء',
        areYouSureAboutRejectPartsReturn:
            'هل أنت متأكد أنك تريد رفض إرجاع الأجزاء؟',
        partReturnRejected: 'تم رفض إرجاع الجزء',
        counterFields: 'حقول العدادات',
        counterName: 'اسم العداد',
        counterFieldName: 'اسم حقل العداد',
        counterId: 'معرف العداد',
        counterUUID: 'UUID العداد',
        counterPickerPlaceholder: 'اختر العداد',
        pickFromPartsList: 'اختر من قائمة الأجزاء',
        changeLocation: 'تغيير الموقع',
        isPartStorageRoot: 'جذر تخزين الأجزاء',
        areYouSureAboutArchiveIssue: 'هل أنت متأكد أنك تريد أرشفة هذه المشكلة؟',
        ganttChart: 'مخطط جانت',
        table: 'جدول',
        fittingEquipment: 'معدات التركيب',
        notAssigned: 'غير مخصص',
        counters: 'العدادات',
        machineHourlyCostNotProvided: 'لم يتم تعيين تكلفة الساعة للآلة',
        eventTypesMightBeDefinedHere: 'يمكن تعريف أنواع الأحداث هنا',
        toolsTakenByUser: 'الأدوات المأخوذة من قبل المستخدم',
        predictedReturnDate: 'تاريخ الإرجاع المتوقع',
        estimatedTimeOfUsingToolInHours:
            'الوقت المقدر لاستخدام الأداة (بالساعات)',
        showOnlyAvailable: 'عرض المتاح فقط',
        toolTakeLog: 'سجل الأخذ والإرجاع',
        takeDate: 'تاريخ الأخذ',
        returnDate: 'تاريخ الإرجاع',
        personWhoAcceptReturn: 'الشخص الذي يقبل الإرجاع',
        statisticStrategy: 'استراتيجية الوصول',
        EquipmentPlannedStrategy: 'وفقًا لخطة العمل',
        EquipmentConstantAvailabilityStrategy: 'توفر مستمر',
        actionAfterIssueFinish: 'الإجراء بعد الانتهاء من المشكلة',
        enabled: 'مفعل',
        categoryOfNewIssue: 'فئة المشكلة الجديدة',
        workNotStarted: 'العمل لم يبدأ',
        colorInherited: 'لون موروث',
        abcClassification: 'تصنيف ABC',
        xyzClassification: 'تصنيف XYZ',
        classification: 'تصنيف',
        filters: 'مرشحات',
        wipeFilters: 'مسح المرشحات',
        takePartsFromUser: 'أخذ الأجزاء من المستخدم',
        selectPersonWhoReturns: 'اختر الشخص الذي يعيد الأجزاء',
        changePersonWhoReturns: 'تغيير الشخص الذي يعيد الأجزاء',
        returnLocation: 'موقع الإرجاع',
        returnQuantity: 'الكمية المرجعة',
        globalSettings: 'الإعدادات العامة',
        delimiter: 'فاصل',
        fieldRules: 'قواعد الحقول',
        fieldValue: 'قيمة الحقل',
        user_email_regex: 'تعبير منتظم لبريد المستخدم الإلكتروني',
        barcodeLength: 'طول الباركود',
        partLine: 'خط الجزء',
        partFamily: 'عائلة الجزء',
        partBatch: 'دفعة الجزء',
        testValueValid: 'القيمة الاختبارية صالحة',
        testValueNotValid: 'القيمة الاختبارية غير صالحة',
        canBePicked: 'يمكن اختياره',
        issue_create_photos: 'إضافة صور لمشكلة جديدة',
        issue_create_documents: 'إضافة مستندات لمشكلة جديدة',
        propagateValues: 'نشر القيم',
        archived: 'مؤرشف',
        escalations_tab: 'تصعيد',
        minute: 'دقيقة',
        days: 'أيام',
        hours: 'ساعات',
        minutes: 'دقائق',
        warnDays: 'تنبيه قبل الانتهاء (أيام)',
        licenseType: 'نوع الترخيص',
        licenseGroup: 'مجموعة الترخيص',
        attachments: 'مرفقات',
        attachment: 'مرفق',
        revisionChanges: 'التغييرات المقدمة',
        document: 'وثيقة',
        noInstructionSelected: 'لا توجد تعليمات محددة',
        instructionGroups: 'مجموعات التعليمات',
        instructionGroup: 'مجموعة التعليمات',
        addOption: 'إضافة خيار',
        ordinal: 'القيمة الترتيبية',
        parameterName: 'اسم المعلمة',
        fontColor: 'لون الخط',
        backgroundColor: 'لون الخلفية',
        columns: 'عدد الأعمدة',
        colspan: 'عرض (أعمدة)',
        products: 'المنتجات',
        controlsTemplates: 'قوالب الفحص',
        controlType: 'نوع الفحص',
        controls: 'الفحوصات',
        controlTemplate: 'قالب الفحص',
        product: 'منتج',
        checklistsAttachedToControlTemplate: 'قوائم التحقق المعينة للفحوصات',
        removeChecklistFromControlTitle:
            'هل أنت متأكد أنك تريد إزالة هذه القائمة من فحصك؟',
        attachChecklistToControl: 'تعيين قائمة للتحكم',
        pickedItem: 'العنصر المحدد',
        pick: 'اختر العنصر',
        fillControl: 'إكمال الفحص',
        back: 'رجوع',
        control: 'تحكم',
        duplicate: 'تكرار',
        isStorageRoot: 'موقع الجذر للتخزين',
        manageStorageAccess: 'إدارة الوصول إلى المستودعات',
        manageVisibleColumns: 'تعديل رؤية الأعمدة',
        columnName: 'اسم العمود',
        visible: 'مرئي',
        entriesLocation: 'موقع الإدخالات',
        details: 'تفاصيل',
        otherData: 'بيانات أخرى',
        moveAllEntriesFromCurrentLocation: 'نقل جميع الإدخالات من هذا الموقع',
        searchInCurrentLocation: 'البحث في الموقع الحالي',
        transition: 'انتقال',
        notification: 'إشعار',
        emails: 'عناوين البريد الإلكتروني',
        phones: 'أرقام الهاتف',
        addAction: 'إضافة إجراء',
        message: 'رسالة',
        application: 'تطبيق',
        always: 'دائمًا',
        sms: 'رسالة نصية',
        loginTo: 'تسجيل الدخول إلى',
        appName: 'Signalo TPM',
        index: 'فهرس',
        showCodeOwnerNameDuringParing:
            'عرض اسم مالك @:common.PIN_CODE_LABEL عند الاقتران مع البطاقة / العلامة',
        showUsernameInCart: 'عرض اسم المستخدم في السلة',
        sampleButtonPlaceholder: 'زر تجريبي',
        preferences: 'تفضيلات',
        buttonsSize: 'حجم الأزرار',
        erase: 'مسح',
        selectDictionaryValue: 'اختر قيمة من القاموس',
        conditions: 'شروط',
        partsCartTerminalRequireApproveOnPartsReturn:
            'تطلب التأكيد أو الرفض عند إرجاع الأجزاء المحملة',
        issueCreated: 'تم إنشاء تذكرة',
        allowedIssuesCategories: 'فئات المشكلات المسموح بها',
        hiddenFields: 'الحقول المخفية',
        defaultValues: 'القيم الافتراضية',
        paddingBottom: 'فراغ فارغ من الأسفل',
        allVisibleFieldsRequired: 'يتطلب إكمال جميع الحقول المرئية',
        url: 'رابط',
        kind: 'نوع',
        forkfleetConfigId: 'تكوين Forkfleet',
        create: 'إنشاء',
        terminate: 'إنهاء',
        parent: 'أصل',
        phoneNumbers: 'أرقام الهاتف',
        storageDefaults: 'القيم الافتراضية للمستودع',
        clientSignatureEnabled: 'توقيع الشخص الذي يجمع',
        warehousemanSignatureEnabled: 'توقيع مسؤول المستودع',
        storage_client_signature_enabled:
            'توقيع الشخص الذي يأخذ العناصر من المستودع',
        storage_client_signature_required:
            'يتطلب توقيع الشخص الذي يأخذ العناصر من المستودع',
        storage_warehouseman_signature_enabled:
            'توقيع الشخص الذي يفرج عن العناصر من المستودع',
        storage_warehouseman_signature_required:
            'يتطلب توقيع الشخص الذي يفرج عن العناصر من المستودع',
        clientReturnSignatureEnabled: 'توقيع الشخص الذي يرجع',
        warehousemanReturnSignatureEnabled: 'توقيع الشخص الذي يقبل الإرجاع',
        storage_client_return_signature_enabled:
            'توقيع الشخص الذي يعيد العناصر',
        storage_client_return_signature_required:
            'يتطلب توقيع الشخص الذي يعيد العناصر',
        storage_warehouseman_return_signature_enabled:
            'توقيع الشخص الذي يقبل إرجاع العنصر',
        storage_warehouseman_return_signature_required:
            'يتطلب توقيع الشخص الذي يقبل إرجاع العنصر',
        channels: 'قنوات',
        manageState: 'إدارة الحالة',
        assignWorker: 'تعيين موظف',
        mergeEntriesOfTheSameLocation: 'دمج الإدخالات من نفس الموقع',
        permission: 'إذن',
        areYouSureAboutMergeAllEntriesOfThisLocation:
            'هل أنت متأكد أنك تريد دمج جميع الإدخالات في هذا الموقع؟',
        manageFilters: 'إدارة المرشحات',
        responsible_assign_creator_when_no_rule:
            '[مشكلات] - تعيين المنشئ كمسؤول إذا لم تتطابق القاعدة',
        recreateIssue: 'إعادة إنشاء المشكلة',
        addNewDictionary: 'إضافة قاموس جديد',
        label: 'تسمية',
        issuesWithRelatedCategories: 'تضمين الفئات الفرعية',
        issuesLimit: 'حد المشكلات',
        issuesLimits: 'المشكلات - الحدود',
        issueDefaults: 'المشكلات - القيم الافتراضية',
        flatCategoryPickerLabel: 'هيكل الفئات المسطح',
        statementsTemplates: 'قوالب البيانات',
        statementTemplate: 'قالب البيانات',
        content: 'محتوى',
        readonly: 'للقراءة فقط',
        defaultValue: 'القيمة الافتراضية',
        fillParametersToChangeState: 'املأ القيم للمعلمات أدناه لتغيير الحالة',
        formatPattern: 'نمط التنسيق',
        countableIncFields: 'تكوين ترقيم المستندات',
        mainShift: 'الوردية الرئيسية',
        singleEvent: 'إدخال واحد',
        seriesOfEvents: 'سلسلة من الإدخالات',
        omitWeekends: 'استبعاد عطلات نهاية الأسبوع',
        allEntriesSaved: 'تم إضافة جميع الإدخالات',
        surveyResults: 'نتائج الاستطلاع',
        qrActionsSettings: 'إعدادات إجراءات رمز الاستجابة السريعة.',
        pendingStatements: 'بيانات للتوقيع',
        statementsResults: 'البيانات',
        statements: 'بيانات',
        statement: 'بيان',
        userCanChangeContent: 'يمكن للمستخدم تغيير المحتوى',
        statementsEvents: 'تكوين الأحداث - البيانات',
        helpdesk: 'مكتب المساعدة',
        terminalId: 'معرف الجهاز',
        operatorId: 'معرف المشغل',
        newConversation: 'سلسلة جديدة',
        formula: 'صيغة',
        fill: 'املأ',
        webMessage: 'رسالة ويب',
        noResponsibleUser: 'لا يوجد مستخدم مسؤول',
        filledAtDate: 'تاريخ الاكتمال',
        filledBy: 'اكتمل بواسطة',
        parts_quantity_alert_rule: '[كمية الأجزاء والمواد] - تحذير',
        employeeNumber: 'رقم الموظف',
        groupsAssignedToCategory: 'المجموعات المعينة للفئات',
        number: 'رقم',
        customers: 'عملاء',
        customer: 'عميل',
        projects: 'مشاريع',
        project: 'مشروع',
        report: 'تقرير',
        reportGenerationInProgress: 'يتم إنشاء التقرير',
        storageStatementGroup: 'مجموعة بيانات المستودع',
        simplifiedHandling: 'إدارة الإشعارات المبسطة',
        selectWorkArea: 'اختر منطقة عملك',
        workAreaSelection: 'تحديد منطقة العمل',
        access: 'الوصول',
        restrictAccess: 'تقييد الوصول',
        anchor: 'تثبيت',
        issueCreator: 'منشئ المشكلة',
        hideSideMenu: 'إخفاء القائمة الجانبية',
        appendGroups: 'المجموعات لإضافتها',
        revokeGroups: 'المجموعات لإزالتها',
        override: 'تجاوز',
        restrictGroupAccess: 'تقييد الوصول إلى المجموعة',
        flag: 'علم',
        flags: 'أعلام',
        manageFlags: 'إدارة الأعلام',
        icon: 'رمز',
        appendFlags: 'الأعلام للإضافة',
        revokeFlags: 'الأعلام للإزالة',
        checkIsTerminal: 'التحقق من الجهاز',
        noConditionsDefined: 'لا توجد شروط محددة',
        checkIsResponsible: 'التحقق من المسؤولية',
        createdOn: 'تم الإبلاغ من',
        replaceTimespan: 'مدة الصلاحية من وقت الإصدار',
        months: 'أشهر',
        handoversTemplates: 'قوالب التسليم',
        handoverTemplate: 'قالب التسليم',
        employeeFunction: 'وظيفة الموظف',
        takeHandover: 'استلام التسليم',
        handovers: 'التسليمات',
        expiringHandovers: 'العناصر التي تقترب من تاريخ انتهاء الصلاحية',
        years: 'سنوات',
        taken_parts_expiry_warning:
            'تحذير قبل تاريخ انتهاء صلاحية العناصر المحملة',
        andonView: 'عرض أندون',
        nfc: 'NFC',
        rfid: 'RFID',
        other: 'آخر',
        tags: 'بطاقات / علامات',
        tag: 'بطاقة / علامة',
        userPinCode: 'رمز المستخدم @:common.PIN_CODE_LABEL',
        emailConfirmation: 'تأكيد عنوان البريد الإلكتروني',
        from: 'من',
        to: 'إلى',
        handoversHistory: 'تاريخ التسليمات',
        partUsagesHistory: 'تاريخ استخدام الأجزاء',
        signaturePinTagConfirmationText1:
            'وقع باستخدام @:common.PIN_CODE_LABEL',
        signaturePinTagConfirmationText2: 'أو امسح بطاقتك.',
        signedWithPinCode: 'وقع باستخدام @:common.PIN_CODE_LABEL',
        signedWithTAG: 'وقع باستخدام بطاقة / علامة',
        test_mode: 'وضع الاختبار',
        confirmWorkFinish: 'تأكيد انتهاء العمل',
        workStarted: 'بدء العمل',
        workFinished: 'انتهاء العمل',
        workTimeRegister: 'سجل وقت العمل',
        measureTypes: 'أنواع القياسات',
        measureType: 'نوع القياس',
        measurable: 'مؤشر قابل للقياس',
        measurables: 'مؤشرات قابلة للقياس',
        measurements: 'القياسات',
        measurement: 'قياس',
        measuredAt: 'تاريخ القياس',
        currentVersion: 'الإصدار الحالي',
        employee: 'موظف',
        pin_code_label: 'اسم الرمز السري',
        orders: 'طلبات',
        productionPlan: 'خطة الإنتاج',
        productionPlans: 'خطط الإنتاج',
        taktTime: 'وقت التحضير',
        target: 'هدف',
        seconds: 'ثواني',
        inheritValuesFromTenantSettings:
            'وراثة القيم الافتراضية من الإعدادات العامة',
        matrices: 'مصفوفات',
        manageMatrice: 'إدارة المصفوفة',
        deletionOfMatrixColumnWarnMessage: 'حذف عمود سيؤدي إلى حذف القيم.',
        removeColumn: 'حذف عمود',
        editColumn: 'تعديل العمود',
        column: 'عمود',
        row: 'صف',
        evaluated: 'تم التقييم',
        of: 'من',
        auto_pin_generation: '@:common.PIN_CODE_LABEL - التوليد التلقائي',
        or: 'أو',
        rename: 'إعادة تسمية',
        check_only_on_transition: 'فحص فقط عند تغيير الحالة',
        license: 'الصلاحيات',
        confirmWorkStart: 'تأكيد بدء العمل',
        workStartAt: 'تبدأ العمل في',
        workFinishAt: 'تنتهي من عملك في',
        workStartedAt: 'بدء العمل',
        workTimeMessage: 'لقد تجاوزت وقت العمل',
        haveANiceDay: 'نتمنى لك يوماً سعيداً!',
        farewellText: 'النص على الشاشة الأخيرة',
        namedayLabel: 'يحتفلون بعيد اسمهم',
        sportEventNearby: 'الحدث الرياضي القادم',
        autoTranslate: 'الترجمة التلقائية',
        auto_translation: 'الترجمة التلقائية',
        awaitingAutoTranslation: 'في انتظار الترجمة التلقائية',
        inheritAllDefaultValues:
            'وراثة جميع القيم الافتراضية من الإعدادات العامة',
        escalations: 'تصعيد',
        escalation: 'تصعيد',
        notTaken: 'لم يبدأ',
        notFinished: 'غير مكتمل',
        issueLastChange: 'آخر تغيير',
        mode: 'الوضع',
        repeatLastEscalation: 'تكرار آخر قيمة تصعيد',
        manageActions: 'إدارة الإجراءات',
        productionLines: 'خطوط الإنتاج',
        productionTerminal: 'محطة الإنتاج',
        currently: 'حاليا',
        currentPlan: 'الخطة الحالية',
        currentlyInProduction: 'يتم إنتاجه حالياً',
        setProduct: 'تعيين المنتج',
        second: 'ثوانٍ',
        work: 'عمل',
        chartConfiguration: 'تكوين الرسم البياني',
        revisionsToConsider: 'المراجعات للنظر فيها',
        allInstructions: 'جميع التعليمات',
        decider: 'محدد',
        considerRevision: 'اعتبار المراجعة',
        and: 'و',
        acceptInstruction: 'قبول التعليمات',
        accept: 'قبول',
        instructionsToAccept: 'تعليمات للقراءة',
        open: 'فتح',
        assignedAt: 'تاريخ التكليف',
        confirmationAt: 'تاريخ التأكيد',
        mainInstruction: 'التعليمات الافتراضية',
        setAsDefault: 'تعيين كافتراضي',
        instructionsFlows: 'التعليمات - التدفقات',
        newRevision: 'مراجعة جديدة',
        playlist: 'قائمة الفيديو',
        selectVideo: 'اختيار فيلم',
        selectVideos: 'اختيار أفلام',
        uploadNewVideo: 'رفع فيديو جديد',
        upload_date: 'تاريخ الرفع',
        thumbnail: 'صورة مصغرة',
        duration: 'مدة',
        uploadFromDrive: 'رفع من القرص',
        selectAssets: 'اختيار المواد',
        assets: 'مواد',
        video: 'فيديو',
        doYouWantToAddNewMarker: 'هل ترغب في إضافة علامة جديدة هنا؟',
        areYouSureAboutRemoveMarker: 'هل أنت متأكد أنك تريد إزالة هذه العلامة؟',
        plants: 'مصانع',
        plant: 'مصنع',
        buildings: 'مباني',
        building: 'بناء',
        floors: 'طوابق',
        floor: 'طابق',
        map: 'خريطة',
        areYouSureAboutMoveMarker: 'هل أنت متأكد أنك تريد نقل هذه العلامة؟',
        marker: 'علامة',
        addNewMarkerTooltipMessage:
            'لإضافة علامة جديدة، انقر بزر الفأرة الأيمن على الخريطة.',
        skillmatrix: 'مصفوفة المهارات',
        indirectProcess: 'مهارات غير خطية',
        skillLevelConfig: 'مستويات المهارة',
        indirectSkillGroups: 'مجموعات المهارات غير الخطية',
        approved: 'مؤكد',
        approvedBy: 'الموافق',
        skillLevel: 'مستوى المهارة',
        requiredSkillLevel: 'مستوى المهارة المطلوب',
        step: 'خطوة',
        image: 'صورة',
        skills: 'مهارات',
        visits: 'زيارات',
        responsiblePerson: 'الشخص المسؤول',
        company: 'شركة',
        contactData: 'تفاصيل الاتصال',
        visit: 'زيارة',
        inProgress: 'قيد التقدم',
        gatehouse: 'بوابة',
        confirmEnter: 'تأكيد الدخول',
        confirmExit: 'تأكيد الخروج',
        enter: 'دخول',
        exit: 'خروج',
        entryGuard: 'الشخص الذي يسمح بالدخول',
        exitGuard: 'الشخص الذي يسمح بالخروج',
        loginAsThisUser: 'تسجيل الدخول كـ هذا المستخدم',
        minBrowserVersion: 'أقل إصدار مطلوب من المتصفح',
        approve: 'الموافقة',
        cancelApproval: 'إلغاء الموافقة',
        workPermit: 'تصريح العمل',
        workPermits: 'تصاريح العمل',
        visit_summary: 'ملخص الزيارة',
        createVisit: 'إنشاء زيارة بناءً على التصريح',
        employeesAtThePlant: 'الموظفون في المصنع',
        purpose: 'الغرض',
        allowedAreas: 'المناطق المسموح بها',
        switchEmployees: 'تبديل الموظفين',
        localIssues: 'المشاكل المحلية',
        cmmsCategoriesSync: 'مزامنة فئات CMMS',
        terminalsGroups: 'مجموعات المحطات',
        isTree: 'شجرة',
        isReporter: 'هل هو مُبلغ',
        isAnchored: 'مؤمن',
        closable: 'إغلاق القضايا',
        areYouSureAboutCloseIssue: 'هل أنت متأكد أنك تريد إغلاق القضية؟',
        simplifiedIssuesRefreshLabel: 'قائمة وقت التحديث - ثواني',
        reloadApp: 'إعادة تحميل التطبيق',
        hideWorkAreasButton: 'إخفاء زر اختيار مناطق العمل',
        hideClassicViewButton: 'إخفاء زر العرض الكلاسيكي',
        hideAddIssueButton: 'إخفاء زر إضافة قضية جديدة',
        filesManager: 'مدير الملفات',
        addFolder: 'إضافة مجلد',
        upload: 'رفع',
        noFolders: 'لا توجد مجلدات',
        noFiles: 'لا توجد ملفات',
        folders: 'مجلدات',
        folder: 'مجلد',
        files: 'ملفات',
        file: 'ملف',
        removeFolderFilesQuestion: 'ماذا تريد أن تفعل بالملفات في هذا المجلد؟',
        targetFolder: 'المجلد المستهدف',
        addInstruction: 'إضافة تعليمات',
        goToFolder: 'اذهب إلى المجلد',
        defaultProdLine: 'خط الإنتاج الافتراضي',
        pin_code_field_type: 'نوع رمز PIN',
        instructionsAccess: 'الأذونات',
        noSections: 'لا توجد أقسام',
        requireReadingInstruction: 'تتطلب قراءة التعليمات',
        hide_parameter_types: 'عدم عرض أنواع المعلمات',
        unknownVersion: 'غير معروف',
        displayInstructionEveryTime: 'عرض التعليمات في كل مرة',
        requireSigningInstructionEveryTime: 'تتطلب توقيع التعليمات في كل مرة',
        signedInstructions: 'التعليمات الموقعة',
        signedAt: 'تاريخ التوقيع',
        signatures: 'التوقيعات',
        signature: 'توقيع',
        systemGroup: 'مجموعة النظام',
        pickable: 'قابل للاختيار',
        leaf: 'عنصر مختار',
        path: 'مسار',
        displayMode: 'وضع العرض',
        chart: 'مخطط',
        preSignedStatement: 'إعلان موقّع على الورق (كامل)',
        myOpenIssues: 'مشكلاتي المفتوحة',
        myAssignedIssues: 'المشكلات المعينة لي',
        displayParametersOnMainScreen: 'عرض المعلمات على الشاشة الرئيسية',
        lockPin: 'قفل تغيير رقم التعريف الشخصي',
        pinLocked: 'رقم التعريف الشخصي مقفل',
        sortChange: 'ترتيب العرض',
        asc: 'تصاعدي',
        desc: 'تنازلي',
        alphanumeric: 'أبجدي رقمي',
        numeric: 'رقمي',
        modificationDate: 'تاريخ التعديل',
        fromNewest: 'من الأحدث',
        fromOldest: 'من الأقدم',
        negation: 'النفي',
        sortOrderService: 'الخدمة',
        newIssueIgnoreFilters: 'تجاهل الفلاتر المطبقة عند إنشاء قضية جديدة',
        refreshInterval: 'قائمة وقت التحديث',
        multiline: 'متعدد الأسطر',
        expanded: 'موسع',
    },
    placeholders: {
        part: 'اختر الجزء',
        nameSurname: 'الاسم واللقب',
        phone: 'رقم الهاتف',
        password: 'كلمة المرور',
        password_confirmation: 'تأكيد كلمة المرور',
        email: 'البريد الإلكتروني',
    },
    auth: {
        notYourAccountQuestion: 'ليست حسابك؟',
    },
    signup: {
        registered: 'نجاح!',
        emailConfirmed: 'تم تأكيد البريد الإلكتروني.',
        registered_info: 'اطلب من المشرف تفعيل حسابك',
        confirmEmailAddress:
            'تم إنشاء حسابك، انقر على الرابط في البريد الإلكتروني لتأكيد عنوانك.',
        tokenNotFoundError:
            'تم تأكيد البريد الإلكتروني بالفعل أو أن رابط التأكيد غير صالح.',
    },
    paramTypes: {
        text: 'نص',
        note: 'ملاحظة',
        integer: 'عدد صحيح',
        decimal: 'عدد عشري',
        date: 'تاريخ',
        time: 'ساعة',
        datetime: 'تاريخ وساعة',
        color: 'لون',
        icon: 'أيقونة',
        boolean: 'لا / نعم',
        signature: 'توقيع',
    },
    tableHeaders: {
        id: 'معرف',
        name: 'اسم',
        path: 'مسار الملف',
        category: 'فئة',
        equipment: 'معدات',
        criticality: 'خطورة',
        status: 'حالة',
        created: 'أنشئ',
        createdBy: 'أنشأ بواسطة',
        fp: 'FP',
        type: 'نوع',
        designation: 'تعيين',
        symbol: 'رمز',
        serialNumber: 'رقم السيريال',
        registryNumber: 'رقم التسجيل',
        udtNumber: 'رقم UDT',
        manufacturedYear: 'سنة التصنيع',
        actions: 'إجراءات',
        title: 'عنوان',
        data: 'تاريخ',
        archived: 'أرشيف',
        manufacturerSymbol: 'رمز الشركة المصنعة',
        min: 'حد أدنى',
        opt: 'اختياري',
        quantity: 'كمية',
        priority: 'أولوية',
        price: 'سعر',
        kind: 'نوع',
        description: 'وصف',
        default: 'افتراضي',
        warning: 'تحذير',
        problem: 'مشكلة',
        fixing: 'حل',
        changeOrder: 'تغيير الطلب',
        required: 'مطلوب',
        icon: 'أيقونة',
        color: 'لون',
        subCategories: 'فئات فرعية',
        barCode: 'رمز شريطي',
        subLocations: 'مواقع فرعية',
        categories: 'فئات',
        image: 'صورة',
        photo: 'صورة',
        nameSurname: 'الاسم واللقب',
        email: 'البريد الإلكتروني',
        phone: 'رقم الهاتف',
        function: 'وظيفة',
        department: 'قسم',
        accountStatus: 'حالة الحساب',
        shift: 'نوبة',
        licenseNumber: 'رقم الترخيص',
        issuedDate: 'تاريخ الإصدار',
        expiresDate: 'تاريخ الانتهاء',
        entries: 'مدخلات',
        entryNumber: 'رقم المدخل',
        buyPrice: 'سعر الشراء',
        currentLocationQuantity: 'كمية في الموقع الحالي',
        group: 'مجموعة',
        string: 'نص',
        integer: 'عدد صحيح',
        toolsGroup: 'مجموعة الأدوات',
        manufacturer: 'شركة مصنعة',
        shortName: 'اسم مختصر',
        locationName: 'اسم الموقع',
        subLocationsQuantity: 'كمية المواقع الفرعية',
        isWorkingOnIssue: 'يعمل على المشكلة',
        openIssues: 'المشكلات المفتوحة',
        notificationDate: 'تاريخ الإشعارات',
        titleAndDescription: 'العنوان والوصف',
        read: 'اقرأ',
        created_at: 'أنشئ في',
        received_at: 'استلم في',
        started_at: 'بدأ في',
        arrived_at: 'وصل في',
        finished_at: 'انتهى في',
        country: 'بلد',
        city: 'مدينة',
        progress: 'تقدم',
        deleted_at: 'محذوف في',
        about_issue: 'حول المشكلة',
        action: 'إجراء',
    },
    searchConfig: {
        id: 'معرف',
        name: 'اسم',
        shortName: 'اسم مختصر',
        country: 'بلد',
        email: 'بريد إلكتروني',
        group: 'مجموعة',
        planned: 'مخطط',
        category: 'فئة',
        object: 'كائن',
        criticality: 'خطورة',
        fp: 'FP',
        type: 'نوع',
        designation: 'تعيين',
        symbol: 'رمز',
        serialNumber: 'رقم السيريال',
        registryNumber: 'رقم التسجيل',
        udtNumber: 'رقم UDT',
        manufacturedYear: 'سنة التصنيع',
        manufacturerSymbol: 'رمز الشركة المصنعة',
        priority: 'أولوية',
        quantity: 'كمية',
        buyPrice: 'سعر الشراء',
        kind: 'نوع',
        toolsGroup: 'مجموعة الأدوات',
        function: 'وظيفة',
        department: 'قسم',
        status: 'حالة',
        nameSurname: 'الاسم واللقب',
        phone: 'رقم الهاتف',
        barCode: 'رمز شريطي',
        color: 'لون',
        icon: 'أيقونة',
        machine: 'آلة',
        tool: 'أداة',
        urgentAndOutdated: 'عاجل وقديم',
        urgent: 'عاجل',
        expired: 'منتهي',
        manufacturer: 'شركة مصنعة',
    },
    errors: {
        partMustHaveEntriesToCorrectQuantity:
            'يجب أن تحتوي القطعة على إدخالات لتصحيح الكمية.',
        error: 'حدث خطأ',
        wrongEmail: 'بريد إلكتروني غير صالح',
        wrongPasswordLength: 'يجب أن تكون كلمة المرور بطول 8 أحرف على الأقل.',
        wrongCodeLength:
            'يجب أن يكون @:common.PIN_CODE_LABEL بطول 5 أحرف على الأقل.',
        passwordsNotMatch: 'كلمات المرور غير متطابقة',
        codesNotMatch: 'كلمات المرور غير متطابقة',
        codeAlreadyInUse: 'تم استخدام @:common.PIN_CODE_LABEL بالفعل',
        expiresDateLowerThanIssued:
            'لا يمكن أن تكون تاريخ الانتهاء أقل من تاريخ الإصدار.',
        cannotMoveToTheSameLocation: 'لا يمكن الانتقال إلى نفس الموقع.',
        locationRequired: 'الموقع مطلوب.',
        putQuantity: 'ضع كمية للتفريق.',
        valueGreaterThanQuantity: 'القيمة أكبر من الكمية الإجمالية.',
        wholeCannotBeDistinguished: 'لا يمكن تمييز الكل.',
        yearMustBeGreaterThan: 'يجب أن يكون العام أكبر من ',
        passwordConfirmationNotMatch: 'كلمات المرور غير متطابقة',
        mustBeInteger: 'يجب أن تكون القيمة عددًا صحيحًا',
        valueTooLong: 'القيمة طويلة جدًا',
        wrongPhoneNumber: 'رقم الهاتف خاطئ',
        phoneNumberAlreadyTaken: 'رقم الهاتف مستخدم بالفعل',
        emailAlreadyTaken: 'البريد الإلكتروني مستخدم بالفعل',
        notFound: 'غير موجود',
        sessionExpired: 'انتهت صلاحية الجلسة',
        tenantNotFound:
            'التكوين المحدد غير موجود. تحقق من عنوان الموقع وحاول مرة أخرى.',
        selectEquipmentTypeToConfigureParameters:
            'اختر نوع المعدات لتكوين المعلمات.',
        noParametersToConfigure: 'نوع المعدات المحدد ليس له معلمات لتكوينها.',
        terminalAlreadyRegistered: 'تم تسجيل الطرفية بالفعل.',
        invalidPin: 'رمز @:common.PIN_CODE_LABEL غير صالح',
        eventCollision: 'يتداخل وقت الحدث الذي تريد إضافته مع وقت حدث آخر.',
        mustStartWithSlash: 'يجب أن يبدأ بـ /',
        mustBeBetween: 'يجب أن تكون القيمة بين:',
        minLength: 'الحد الأدنى للطول: ',
        maxLength: 'الحد الأقصى للطول: ',
        invalidInputForCodeType: 'مدخل غير صالح لنوع الرمز المحدد',
        invalidCodeOrNoPermission:
            'الـ @:common.PIN_CODE_LABEL غير صالح أو ليس لديك صلاحيات كافية لتنفيذ هذا الإجراء.',
        endDateLowerThanStart:
            'لا يمكن أن يكون تاريخ الانتهاء أقل من تاريخ البدء',
        iconAlreadyTaken: 'تم أخذ الأيقونة بالفعل',
        notEnoughQuantityInStorage: 'الكمية غير كافية في المخزن.',
        valueTooLow: 'القيمة منخفضة جدًا',
        valueTooHigh: 'القيمة مرتفعة جدًا',
        maxAvailableQuantity: 'الحد الأقصى للكمية',
        someFilesRejectedDueToSize:
            'تم رفض بعض الملفات بسبب الحجم. الحد الأقصى لحجم الملف الفردي:',
        cameraDeviceNotFound: 'لم يتم العثور على جهاز الكاميرا',
        cameraPermissionDenied:
            'تم حظر الوصول إلى كاميرا الجهاز في إعدادات الموقع، قم بإعادة تعيين إعدادات الموقع واسمح باستخدام الكاميرا.',
        passwordResetLinkExpired: 'رابط إعادة تعيين كلمة المرور منتهي الصلاحية',
        noInternetConnection: 'لا يوجد اتصال بالإنترنت',
        minimum: 'الحد الأدنى',
        mustBeGreaterThan: 'يجب أن يكون أكبر من',
        noEventTypesDefined: 'لا توجد أنواع أحداث محددة',
        regexPatternDelimiterError:
            'يجب أن يكون الفاصل غير الهارب هاربًا باستخدام \\',
        emailNotMatchPatternDefiniedInTenant:
            'البريد الإلكتروني لا يتطابق مع النمط المحدد في المستأجر',
        max: 'الحد الأقصى للقيمة',
        min: 'الحد الأدنى للقيمة',
        allLanguagesRequired: 'املأ القيمة بجميع اللغات',
        fileTooBigToOpenWillBeDownloaded:
            'الملف كبير جدًا لفتحه، سيتم تنزيل الملف على جهازك.',
        surveyNotFound: 'استطلاع غير موجود',
        actionNotEnabled: 'الرمز غير نشط',
        loginRequired: 'تسجيل الدخول مطلوب',
        noPermissionForSelectedFolder: 'ليس لديك إذن لعرض محتويات هذا الدليل',
        noFoldersAssignedForTerminal: 'لا توجد أدلة مخصصة بالتعليمات',
        noChecklistsAssignedToControl:
            'لا تحتوي السيطرة المحددة على قائمة مراجعة مخصصة لها',
        maximum: 'الحد الأقصى',
        versionErrorMessage:
            'لم يتم حفظ التغييرات الأخيرة الخاصة بك لأن شخصًا آخر أجرى تغييرات في نفس الوقت. قم بتحديث الصفحة وحاول مرة أخرى.',
        duplicateError: 'توجد هذه القيمة بالفعل',
        fillAllRequiredParameters: 'املأ قيم جميع المعلمات المطلوبة.',
        wrongValue: 'قيمة خاطئة',
        noParametersDefined: 'لا توجد معلمات محددة',
        noFlagsConfigured: 'لا توجد علامات محددة',
        noEnoughParts: 'لا توجد قطع كافية',
        pinNotBelongsTo: 'هذا @:common.PIN_CODE_LABEL لا ينتمي إلى',
        tagNotBelongsTo: 'هذه البطاقة / العلامة لا تنتمي إلى',
        noDictionaryEntries: 'القاموس المحدد لا يحتوي على إدخالات',
        noConnectionWithServer: 'لا يوجد اتصال بالخادم',
    },
    navDrawer: {
        dashboard: 'لوحة القيادة',
        issues: 'المشاكل',
        timetable: 'الجدول الزمني',
        machines: 'الآلات',
        tools: 'الأدوات',
        parts: 'الأجزاء',
        storage: 'المستودع',
        staff: 'الموظفون',
        configuration: 'الإعدادات',
        notes: 'الملاحظات',
        notifications: 'الإشعارات',
        profile: 'الملف الشخصي',
        supervision: 'الإشراف',
    },
    barcodeScanner: {
        barCode: 'رمز شريطي',
        id: 'معرف',
    },
    categoryPicker: {
        categoryPlaceholder: 'الفئة',
        selectCategory: 'اختر الفئة',
        noSubcategories: 'لا توجد فئات فرعية',
    },
    companies: {
        addCompany: 'إضافة شركة',
        editCompany: 'تعديل الشركة',
        fullName: 'الاسم الكامل',
        shortName: 'الاسم القصير',
        group: 'المجموعة',
        country: 'الدولة',
        city: 'المدينة',
        street: 'الشارع',
        postCode: 'الرمز البريدي',
        phone: 'الهاتف',
        email: 'البريد الإلكتروني',
        website: 'الموقع الإلكتروني',
        NIP: 'NIP',
        REGON: 'السجل الوطني للأعمال',
        active: 'نشط',
        nameRequired: 'الاسم مطلوب',
        shortNameRequired: 'الاسم القصير مطلوب',
    },
    issues: {
        tookAt: 'تاريخ الالتقاط',
        changeHistory: 'سجل التغييرات',
        load: 'تحميل',
        date: 'التاريخ',
        createdBy: 'أنشأ بواسطة',
        action: 'الإجراء',
        data: 'البيانات',
        created: 'أنشئ',
        updated: 'تم التحديث',
        deleted: 'تم الإزالة',
        restored: 'تم الاستعادة',
        issues: 'المشاكل',
        issue: 'مشكلة',
        by: 'بواسطة',
        name: 'الاسم',
        applicantsDescription: 'وصف المتقدم',
        accidentDescription: 'وصف الحادث',
        jobDescription: 'وصف الوظيفة',
        jobSummary: 'ملخص الوظيفة',
        category: 'الفئة',
        status: 'الحالة',
        noFlow: '[لا توجد تدفق]',
        equipment: 'المعدات',
        criticality: 'الأهمية',
        hour: 'ساعة',
        formalConfirmation: 'التأكيد الرسمي',
        formalConfirmationShortcut: 'FC',
        formalConfirmationRequired: 'التأكيد الرسمي مطلوب',
        parameter: 'المعلمة',
        value: 'القيمة',
        addIssue: 'إضافة مشكلة',
        expectedCompletionDate: 'تاريخ الانتهاء المتوقع',
        issueDescription: 'وصف المشكلة',
        partsUsage: 'تخلص من الأجزاء',
        assignedEmployees: 'الموظفون المعينون',
        requiredHelp: 'المساعدة المطلوبة',
        returnParts: 'إرجاع الجزء',
        confirmUnassignEmployeeWhoTakenIssue:
            'هذا الموظف قد أخذ المشكلة المحددة. هل تريد إزالته؟',
        responsibleUser: 'المستخدم المسؤول',
        responsibleRules: 'المستخدمون المسؤولون - القواعد',
        received_decision_at: 'تاريخ إبلاغ المقرر',
        groupRules: 'المجموعات - القواعد',
        watchersRules: 'المراقبون - القواعد',
    },
    machines: {
        machines: 'الآلات',
        xIssuesNotPlanned: ' (غير مخطط)',
        noIssues: 'لا توجد مشاكل',
    },
    maintenance: {
        maintenance: 'الصيانة',
    },
    notes: {
        notes: 'ملاحظات',
        note: 'ملاحظة',
        title: 'عنوان',
        archived: 'أرشيف',
    },
    parts: {
        parts: 'قطع',
        warehouseOutgoings: 'خروجيات المخزن',
        fittingEquipmentTypes: 'أنواع معدات التركيب',
        goToEquipmentType: 'اذهب إلى نوع المعدات',
        selectPart: 'اختر جزءًا',
        usageReason: 'سبب الاستخدام',
        usageOtherReason: 'سبب آخر',
        replacements: 'استبدالات',
        partEntry: 'دخول الجزء',
        goToPart: 'اذهب إلى الجزء',
    },
    costCenter: {
        costCenter: 'مركز التكلفة',
    },
    equipmentTypes: {
        equipment: 'المعدات',
        newEquipmentType: 'نوع المعدات الجديد',
        fittingParts: 'أجزاء التركيب',
        goToParts: 'اذهب إلى الأجزاء',
    },
    machinesAndToolsDefinitions: {
        machinesAndToolsDefinitions: 'تعريفات الآلات والأدوات',
    },
    flows: {
        flow: 'تدفق',
        states: 'حالات',
        transitions: 'انتقالات',
        state: 'حالة',
        default: 'افتراضي',
        warning: 'تحذير',
        problem: 'مشكلة',
        fixing: 'إصلاح',
        finished: 'منتهى',

        access: {
            create: 'إنشاء',
            read: 'قراءة',
            update: 'تعديل',
            delete: 'حذف',
            read_unassigned: 'قراءة غير المعين',
            assign: 'تعيين',
            unassign: 'إلغاء التعيين',
            close: 'إغلاق',
        },
        instructions: {
            default: 'افتراضي',
            fixing: 'اعتبار',
            finished: 'منتهي',
        },
        accessLabels: {
            terminal: 'الطرف الذي أبلغ عن المشكلة',
            creator: 'المستخدم الذي أبلغ عن المشكلة',
            responsible: 'المستخدم المسؤول عن المشكلة',
            group: 'مجموعة المستخدمين المسؤولة عن المشكلة',
            anchor: 'الطرف الذي تم إرفاق المشكلة به',
            watchers: 'المستخدمون الذين يراقبون المشكلة',
        },
        sync_returned_rejected: 'المزامنة - مرفوضة',
        sync_returned_finished: 'المزامنة - منتهية',
        current: 'حاضِر',
    },
    parameters: {
        categoryParameter: 'معامل الفئة',
        equipmentTypeParameter: 'معامل نوع المعدات',
    },
    staff: {
        staff: 'الموظفين',
        editUserAccess: 'تعديل وصول المستخدم',
        permissionsUpdated: 'تم تحديث الأذونات',
        passwordUpdated: 'تم تحديث كلمة المرور',
        codeUpdated: 'تم تحديث @:common.PIN_CODE_LABEL',
        changePasswordAndCode: 'تغيير كلمة المرور و @:common.PIN_CODE_LABEL',
        goToLicenses: 'الذهاب إلى التراخيص',
        editAccess: 'تعديل الوصول',
        editUser: 'تعديل المستخدم',
        addUser: 'إضافة مستخدم جديد',
        nameAndLastname: 'الاسم واللقب',
        accountStatus: 'حالة الحساب',
        phoneNumber: 'رقم الهاتف',
        department: 'القسم',
        passwordChange: 'تغيير كلمة المرور',
        codeChange: 'تغيير @:common.PIN_CODE_LABEL',
        newPassword: 'كلمة المرور الجديدة',
        confirmNewPassword: 'تأكيد كلمة المرور الجديدة',
        changePassword: 'تغيير كلمة المرور',
        newCode: 'رمز جديد @:common.PIN_CODE_LABEL',
        confirmNewCode: 'تأكيد رمز جديد @:common.PIN_CODE_LABEL',
        changeCode: 'تغيير @:common.PIN_CODE_LABEL',
        addEntry: 'إضافة إدخال',
        editEntry: 'تعديل الإدخال',
        editLicense: 'تعديل الترخيص',
        addLicense: 'إضافة ترخيص',
        licenseNumber: 'رقم الترخيص',
        prefferedLocale: 'اللغة المفضلة',
        oldPassword: 'كلمة المرور القديمة',
        newPasswordConfirmation: 'تأكيد كلمة المرور الجديدة',
        reading: 'عرض',
        creating: 'إنشاء',
        updating: 'تحديث',
        deleting: 'حذف',
        importScheduleFile: 'استيراد جدول من ملف',

        importSchedule: {
            errors: {
                user_not_found: 'المستخدم غير موجود [{user}]',
                user_found_many: 'تم العثور على {count} مستخدمين [{user}]',
                event_collision: 'تداخل الأحداث',
                hour_cell_parse: 'خطأ في تحليل الخلية',
            },
        },
        oldCode: 'رمز @:common.PIN_CODE_LABEL القديم',
        newCodeConfirmation: 'تأكيد رمز @:common.PIN_CODE_LABEL الجديد',
        setAccessBasedOnProfile: 'منح الوصول بناءً على القالب',
        selectAccessMergeType: 'اختر كيفية دمج الوصولات',
        noAccessesInProfileMessage:
            'الملف الشخصي المحدد لا يحتوي على أذونات. هل تريد الكتابة فوقه على أي حال؟',
    },
    breadCrumb: {
        configuration: 'الإعدادات',
        locations: 'المواقع',
        home: 'الرئيسية',
        machines: 'الآلات',
        tools: 'الأدوات',
        issues: 'المشاكل',
        notes: 'الملاحظات',
        maintenance: 'الصيانة',
        parts: 'الأجزاء',
        warehouse: 'المخزن',
        dictionary: 'القاموس',
        categories: 'الفئات',
        parameters: 'المعلمات',
        costCenter: 'مركز التكلفة',
        flows: 'التدفقات',
        states: 'الحالات',
        transitions: 'الانتقالات',
        shiftSchemas: 'نظم المناوبات',
        shifts: 'المناوبات',
        definitionsOfMachinesAndToolsTypes: 'تعريفات أنواع الآلات والأدوات',
        add: 'إضافة',
        edit: 'تحرير',
        staff: 'الموظفون',
        licenses: 'التراخيص',
        entries: 'السجلات',
        companiesDatabase: 'قاعدة بيانات الشركات',
        companyManagement: 'إدارة الشركة',
        companyData: 'بيانات الشركة',
        supportedLanguages: 'اللغات المدعومة',
        en: 'الإنجليزية',
        pl: 'البولندية',
        es: 'الإسبانية',
        de: 'الألمانية',
        defaultLanguage: 'اللغة الافتراضية',
        notifications: 'الإشعارات',
        notificationsManagement: 'إدارة الإشعارات',
        myProfile: 'ملفي الشخصي',
    },
    attributeTypes: {
        text: 'نص',
        note: 'ملاحظة',
        integer: 'عدد صحيح',
        decimal: 'عدد عشري',
        date: 'تاريخ',
        time: 'وقت',
        dateTime: 'تاريخ ووقت',
        color: 'لون',
        icon: 'أيقونة',
        boolean: 'نعم/لا',
    },
    costCalculator: {
        costCalculator: 'حاسبة التكلفة',
        waitTime: 'وقت الانتظار',
        serviceTime: 'وقت الخدمة',
        suspendTime: 'وقت الإيقاف',
        totalTime: 'الوقت الكلي',
        estimatedCost: 'التكلفة المقدرة',
        calculate: 'احسب',
        withoutParts: 'بدون أجزاء',
        timeOfReportingIssue: 'وقت الإبلاغ عن المشكلة',
        timeOfServiceStart: 'وقت بدء الخدمة',
        timeOfReportingEnd: 'وقت انتهاء الخدمة',
        days: 'أيام',
        hours: 'ساعات',
        minutes: 'دقائق',
        enterNewTime: 'أدخل وقتًا جديدًا',
        fromIssueReportToServiceStart:
            'الوقت من الإبلاغ عن المشكلة إلى بدء الخدمة',
        fromServiceStartToServiceEnd: 'الوقت من بدء الخدمة إلى انتهاء الخدمة',
        workTime: 'وقت العمل',
        includeAwaitingTime: 'تضمين وقت الانتظار',
    },
    dashboard: {
        components: {
            myIssues: {
                title: 'مشاكلي',
                assignedToMe: 'مخصصة لي',
                assignedToGroup: 'مخصصة لمجموعتي',
                tookByMe: 'أخذت من قبلي',
                takeIssueBtn: 'أخذ',
                arriveIssueBtn: 'وصلت',
                finishIssueBtn: 'إنهاء',
                history: 'التاريخ',
                myResponsible: 'أنا مسؤول عن',
                unassignIssue: 'إلغاء التخصيص',
            },
        },
    },
    synchronization: {
        enabled: 'مفعل',
        hasHeaders: 'لديه رؤوس',
        leaveFile: 'ترك الملف',
        host: 'المضيف',
        port: 'المنفذ',
        protocol: 'بروتوكول',
        username: 'اسم المستخدم',
        password: 'كلمة المرور',
        root: 'الجذر',
        encoding: 'ترميز',
        separator: 'فاصل',
        externalSystemId: 'معرّف النظام الخارجي',
        cronExpression: 'تعليمات كرون',
        appendFullStock: 'إضافة المخزون الكامل',
        includeHeaders: 'تضمين الرؤوس',
        withTrashed: 'مع البيانات المحذوفة',
        filePattern: 'نمط الملف',
        categoriesRootCategoryId: 'فئة الجذر لفئات المستورد',
        systemKeyname: 'معرف النظام',
        address: 'العنوان',
        token: 'رمز',
    },
    processes: {
        created_at: 'تاريخ الإنشاء',
        started_at: 'تاريخ البدء',
        updated_at: 'تاريخ التحديث',
        finished_at: 'تاريخ الانتهاء',
        attempts: 'محاولات',
        queue: 'طابور',
        job_id: 'معرف الوظيفة',
        job_uuid: 'UUID الوظيفة',
        progress_max: 'أقصى تقدم',
        progress_now: 'التقدم الحالي',
        outputMessage: 'رسالة الإخراج',
        status: {
            queued: 'قيد الانتظار',
            executing: 'جارٍ التنفيذ',
            finished: 'منتهي',
            failed: 'فشل',
            retrying: 'إعادة المحاولة',
            undefined: 'غير محدد',
        },
    },
    weekDays: {
        mo: 'Monday',
        tu: 'Tuesday',
        we: 'Wednesday',
        th: 'Thursday',
        fr: 'Friday',
        sa: 'Saturday',
        su: 'Sunday',
    },
    reports: {
        reports: 'التقارير',
        dateFrom: 'تاريخ البدء',
        dateTo: 'تاريخ الانتهاء',

        types: {
            operation_sheet: 'ورقة التشغيل',
            issue_summary: 'ملخص القضايا',
            grouped_summary: 'تقرير ملخص',
            storage_operations: 'عمليات التخزين',
            part_quantity_export: 'كمية الأجزاء',
        },
        reportFor: 'تقرير لـ',
        includeQuantityLeft0: 'شمل الأجزاء ذات الكمية صفر',
        dayMinusN: 'يوم ناقص n',
        currentWeek: 'الأسبوع الحالي',
        lastWeek: 'الأسبوع الماضي',
        currentMonth: 'الشهر الحالي',
        lastMonth: 'الشهر الماضي',
        currentYear: 'السنة الحالية',
        lastYear: 'السنة الماضية',
        nDays: 'عدد الأيام',
        todayMinusN: "اليوم ناقص ن"
    },
    logs: {
        comment: 'تعليق',
        issues: {
            escalation: 'التحذيرات ({minutes} دقيقة) - تم إرسال الإشعار',
        },
        issueEmployee: {
            assignedAt: 'تم تعيين الموظف:',
            receivedAt: 'تم استلامه بواسطة الموظف في:',
            tookAt: 'استلمه الموظف في:',
            finishedAt: 'أنهى الموظف في:',
            deletedAt: 'تمت إزالة الموظف من هذه القضية في:',
        },
    },
    units: {
        no: 'قطعة',
        st: 'مجموعة',
        pr: 'زوج',
    },
    rrule: {
        frequency: 'دوري',
        dtstart: 'تاريخ البدء',
        tzid: 'المنطقة الزمنية',
        until: 'حتى',
        count: 'العدد',
        interval: 'الفاصل',
        wkst: 'يوم البدء',
        byweekday: 'حسب يوم الأسبوع',
        bymonth: 'حسب الشهر',
        yearly: 'سنوي',
        monthly: 'شهري',
        weekly: 'أسبوعي',
        daily: 'يومي',
        hourly: 'ساعة',
        minutely: 'دقيقة',
        secondly: 'ثانية',
        infinitePeriodicityMessage:
            'الفترة الزمنية غير محدودة، وللأداء، يتم عرض 500 صف فقط',
    },
    months: {
        jan: 'يناير',
        feb: 'فبراير',
        mar: 'مارس',
        apr: 'أبريل',
        may: 'مايو',
        jun: 'يونيو',
        jul: 'يوليو',
        aug: 'أغسطس',
        sep: 'سبتمبر',
        oct: 'أكتوبر',
        nov: 'نوفمبر',
        dec: 'ديسمبر',
    },
    charts: {
        charts: 'الرسوم البيانية',
        count: 'عدد المشاكل',
        total: 'إجمالي وقت المشاكل (من الإبلاغ إلى الانتهاء)',
        serw: 'إجمالي وقت الإصلاح (الإجمالي - الانتظار)',
        mttr1: 'متوسط الوقت من الإبلاغ إلى الانتهاء',
        mttr2: 'متوسط الوقت من الاستلام إلى الانتهاء',
        mtbf: 'متوسط الوقت بين الأعطال',
        mttf: 'متوسط الوقت حتى الفشل',
        type: 'نوع الرسم البياني',
        pie: 'رسم دائري',
        bar: 'رسم عمودي',
        query: 'نطاق البيانات',
        groupBy: 'تجميع بواسطة',
        sum: 'المجموع',
        avg: 'المتوسط',
        min: 'الحد الأدنى',
        max: 'الحد الأقصى',
        eq: 'يساوي',
        neq: 'لا يساوي',
        gt: 'أكثر من',
        lt: 'أقل من',
        gte: 'أكثر أو يساوي',
        lte: 'أقل أو يساوي',
        in: 'يحتوي على',
        nin: 'لا يحتوي على',
        btw: 'بين',
        chartOptions: 'خيارات الرسم البياني',
        line: 'رسم خطي',
        mixed: 'مختلط',
        chartDisplayOptions: 'خيارات العرض',
        fillGaps: 'املأ الفجوات',
    },
    regexBuilder: {
        regexValue: 'قيمة التعبير المنتظم',
        global: 'عالمي',
        multiline: '^ و $ تمثل بداية/نهاية السطر',
        insensitive: 'غير حساس لحالة الأحرف',
        extended: 'تجاهل المسافات البيضاء',
        singleline: 'النقطة تمثل سطرًا جديدًا',
        unicode: 'يونيكود',
        Ungreedy: 'كمّيات كسولة',
        Anchored: 'تثبيت عند بداية النمط أو نهاية آخر تطابق',
        Jchanged: 'السماح بأسماء فرعية متكررة',
        DollarEndOnly: '$ يطابق فقط نهاية النمط',
        testValue: 'قيمة الاختبار',
    },
    tpm: {
        history: 'التاريخ',
        standardObstacle: 'العائق القياسي',
        customObstacle: 'العائق المخصص',
        bhp: 'الصحة والسلامة',
        returnToMainPage: 'العودة إلى الصفحة الرئيسية',
        bhpMessage:
            'إذا كنت ترى أي مخاطر تتعلق بالسلامة، يرجى الإبلاغ عنها فورًا لمشرفك المباشر واستخدام نموذج الإبلاغ الموجود أسفل لوحة الإعلانات.',
        ok: 'حسناً',
        send: 'إرسال',
        description: 'الوصف',
        title: 'العنوان',
        realization: 'التنفيذ',
        answer: 'الإجابة',
        pendingSurveys: 'الاستبيانات المعلقة',
        surveys: 'الاستبيانات',
        systemSurvey: 'استبيان النظام',
        addPage: 'إضافة صفحة',
        createSurvey: 'إنشاء استبيان',
        addSection: 'إضافة قسم',
        addQuestion: 'إضافة سؤال',
        questionContent: 'محتوى السؤال',
        answerOption: 'خيار الإجابة',
        addQuestionAnswer: 'إضافة خيار إجابة',
        surveyName: 'اسم الاستبيان',
        sectionName: 'اسم القسم',
        noSurveyAnswers: 'لا توجد إجابات',
        removeSection: 'إزالة القسم',
        published: 'منشور',
        unpublished: 'غير منشور',
        pendingPublication: 'التغييرات المعلقة للنشر',
        removeQuestion: 'إزالة السؤال',
        removeAnswer: 'إزالة خيار الإجابة',
        publish: 'نشر',
        unpublish: 'إلغاء النشر',
        previewResults: 'معاينة النتائج',
        supervisior: 'المشرف',
        surveyResults: 'نتائج الاستبيان',
        surveysEvents: 'تكوين الأحداث - الاستبيانات',
        operatedBy: 'تم التشغيل بواسطة',
        confirmReadInformations: 'لقد قرأت المعلومات',
        noticeContent: 'محتوى الإشعار',
        notice: 'إشعار',
        surveyEventName: 'اسم الحدث',
        survey: 'استبيان',
        newIssue: 'تقرير جديد',
        surveyEventConfigurationAlreadyExists: 'تكوين مماثل موجود بالفعل',
        lastComment: 'آخر تعليق من الشخص المسؤول',
        surveyNotConfigured: 'لم يتم تكوين الاستبيان',
        fillSurvey: 'إكمال الاستبيان',
        singleSurveyEntries: 'نتائج فردية',
        groupedSurveyEntries: 'نتائج مجمعة',
        publicationId: 'معرّف النشر',
        publicationDate: 'تاريخ النشر',
        versionId: 'معرّف النسخة',
        answersCount: 'عدد الإجابات',
        answers: 'الإجابات',
        withoutSupervisor: 'بدون مشرف',
        defaultSupervisor: 'المشرف الافتراضي',
        flowStateFlags: 'علامات الحالة',
        qrActionsSettings: 'إعدادات إجراءات رمز الاستجابة السريعة.',
        loginRequired: 'يتطلب تسجيل الدخول',
        questionContext: 'سياق السؤال',
        currentSurveyVersionPreview: 'معاينة النسخة الحالية من الاستبيان',
        surveyDescription: 'وصف الاستبيان',
        sectionDescription: 'وصف القسم',
        instructions: 'تعليمات',
        instruction: 'تعليمات',
        instructionRevisions: 'المراجعات',
        instructionRevision: 'المراجعة',
        checklists: 'قوائم المراجعة',
        checklist: 'قائمة مراجعة',
        checklistItem: 'عنصر القائمة',
        manageChecklistFields: 'إدارة عناصر القائمة',
        checklistItemDisplayOptions: 'العناصر المعروضة',
        revisionAutoRejectedInfo: '[النظام] تم الرفض - تم إنشاء مراجعة جديدة',
    },
    accessMergeValues: {
        sum: 'مجموع',
        product: 'منتج',
        overwrite: 'استبدال',
    },
    aggregates: {
        count: 'مجموع',
        min: 'حد أدنى',
        max: 'حد أقصى',
    },
    checklists: {
        itemBasic: 'عنصر أساسي',
        itemSeparator: 'فاصل',
        itemSection: 'قسم',
        publish: 'نشر',
        previewCurrentVersion: 'معاينة النسخة الحالية',
    },
    cluer: {
        history: 'التاريخ',
        standardObstacle: 'عائق قياسي',
        customObstacle: 'عائق مخصص',
        bhp: 'الصحة والسلامة',
        returnToMainPage: 'العودة إلى الصفحة الرئيسية',
        bhpMessage:
            'إذا رأيت أي مخاطر تتعلق بالسلامة، يرجى الإبلاغ عنها على الفور لمشرفك المباشر واستخدام نموذج الإبلاغ الموجود أسفل لوحة الإعلانات.',
        ok: 'موافق',
        send: 'إرسال',
        description: 'الوصف',
        title: 'العنوان',
        realization: 'تحقيق',
        answer: 'الإجابة',
        pendingSurveys: 'استطلاعات معلقة',
        surveys: 'استطلاعات',
        systemSurvey: 'استطلاع النظام',
        addPage: 'إضافة صفحة',
        createSurvey: 'إنشاء استطلاع',
        addSection: 'إضافة قسم',
        addQuestion: 'إضافة سؤال',
        questionContent: 'محتوى السؤال',
        answerOption: 'خيار الإجابة',
        addQuestionAnswer: 'إضافة خيار إجابة',
        surveyName: 'اسم الاستطلاع',
        sectionName: 'اسم القسم',
        noSurveyAnswers: 'لا توجد إجابات',
        removeSection: 'إزالة القسم',
        published: 'تم النشر',
        unpublished: 'لم يتم النشر',
        pendingPublication: 'التغييرات في انتظار النشر',
        removeQuestion: 'إزالة السؤال',
        removeAnswer: 'إزالة خيار الإجابة',
        publish: 'نشر',
        unpublish: 'إلغاء النشر',
        previewResults: 'معاينة النتائج',
        supervisior: 'المشرف',
        surveyResults: 'نتائج الاستطلاع',
        surveysEvents: 'إعدادات الأحداث - الاستطلاعات',
        operatedBy: 'مُدار بواسطة',
        confirmReadInformations: 'لقد قرأت المعلومات',
        noticeContent: 'محتوى الإشعار',
        notice: 'إشعار',
        surveyEventName: 'اسم الحدث',
        survey: 'استطلاع',
        newIssue: 'تقرير جديد',
        surveyEventConfigurationAlreadyExists: 'مثل هذا التكوين موجود بالفعل',
        lastComment: 'آخر تعليق من الشخص المسؤول',
        surveyNotConfigured: 'لم يتم تكوين الاستطلاع',
        fillSurvey: 'إكمال الاستطلاع',
        singleSurveyEntries: 'نتائج فردية',
        groupedSurveyEntries: 'نتائج مجمعة',
        publicationId: 'معرف النشر',
        publicationDate: 'تاريخ النشر',
        versionId: 'معرف النسخة',
        answersCount: 'عدد الردود',
        answers: 'الإجابات',
        withoutSupervisor: 'بدون مشرف',
        defaultSupervisor: 'المشرف الافتراضي',
        flowStateFlags: 'أعلام الحالة',
        qrActionsSettings: 'إعدادات إجراءات رمز الاستجابة السريعة.',
        loginRequired: 'يتطلب تسجيل الدخول',
        questionContext: 'سياق السؤال',
        currentSurveyVersionPreview: 'معاينة النسخة الحالية من الاستطلاع',
        surveyDescription: 'وصف الاستطلاع',
        sectionDescription: 'وصف القسم',
    },
    orders: {
        order: 'طلب',
        releaseDate: 'تاريخ الإنجاز',
        orderDate: 'تاريخ الطلب',
    },
    production: {
        good: 'عدد الجيد',
        bad: 'عدد السيئ',
        productionRecords: 'سجل الإنتاج',
    },
};
