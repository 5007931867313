export const en = {
    common: {
        PIN_CODE_LABEL: 'PIN Code',
        comment: 'Comment',
        year: 'Year',
        configuration: 'Configuration',
        cancel: 'Cancel',
        search: 'Search',
        remove: 'Remove',
        confirmationDialogTitle: 'Are you sure to remove this item?',
        selectDate: 'Select date',
        select: 'Select',
        notFound: 'Not found',
        equipmentPickerPlaceholder: 'Equipment object',
        searchPlaceholder: 'Search',
        equipmentTypePickerPlaceholder: 'Equipment type',
        equipmentTypePickerTitle: 'Select equipment type',
        emptyTableMessage: 'No data',
        flowPikcerTitle: 'Select flow',
        selectIcon: 'Select icon',
        locationPickerTitle: 'Select location',
        locationPlaceholder: 'Location',
        noSublocations: 'No sublocations',
        locationsTitle: 'Locations',
        edit: 'Edit',
        save: 'Save',
        added: 'Added',
        updated: 'Updated',
        add: 'Add',
        removed: 'Removed',
        reload: 'Reload',
        confirm: 'Confirm',
        rollBack: 'Roll back',
        images: 'Images',
        documents: 'Documents',
        download: 'Download',
        saved: 'Saved',
        date: 'Date',
        hour: 'Hour',
        preview: 'Preview',
        data: 'Data',
        attributes: 'Attributes',
        checkList: 'Checklist',
        name: 'Name',
        type: 'Type',
        status: 'Status',
        designation: 'Designation',
        symbol: 'Symbol',
        serialNumber: 'Serial number',
        registryNumber: 'Registry number',
        machineNumber: 'Machine number',
        udtNumber: 'UDT number',
        manufacturedYear: 'Manufactured year',
        manufacturer: 'Manufacturer',
        supplier: 'Supplier',
        mainSupplier: 'Main supplier',
        attribute: 'Attribute',
        nameRequired: 'Name is required',
        yearGreaterThan: 'Year must be greater than',
        location: 'Location',
        fieldRequired: 'This field is required',
        description: 'Description',
        minimumQuantity: 'Minimum quantity',
        optimalQuantity: 'Optimal quantity',
        priority: 'Priority',
        formatYYYY: 'Format YYYY',
        storageQuantity: 'Warehouse quantity',
        quantity: 'Quantity',
        quantityGreaterThan: 'Quantity must be greater than',
        machine: 'Machine',
        tool: 'Tool',
        order: 'Order',
        required: 'Required',
        dateFormat: 'Date format',
        selectDictionary: 'Select dictionary',
        return: 'Return',
        parameters: 'Parameters',
        editCategory: 'Edit category',
        addCategory: 'Add new category',
        changeColor: 'Change color',
        noColor: 'No color',
        shift: 'Shift',
        start: 'Start',
        end: 'End',
        shiftSchema: 'Schift schema',
        shifts: 'Shifts',
        dictionary: 'Dictionary',
        subEntries: 'Subentries',
        key: 'Key',
        value: 'Value',
        moveUp: 'Move up',
        moveDown: 'Move down',
        addLocation: 'Add new location',
        editLocation: 'Edit location',
        companiesDatabase: 'Companies database',
        flows: 'Flows',
        read: 'Read',
        write: 'Write',
        noImage: 'No image',
        email: 'E-mail',
        function: 'Function',
        active: 'Active',
        notActive: 'Not activated',
        disabled: 'Disabled',
        entryNumber: 'Entry number',
        issuedDate: 'Issued date',
        expiresDate: 'Expires date',
        move: 'Move',
        separateForm: 'Separate from',
        separate: 'Separate',
        warehouse: 'Warehouse',
        actions: 'Actions',
        putInCurrentLocation: 'Put in current location',
        selectLocationFormRightMenu: 'Select location from right-side menu',
        showUnassignedItems: 'Show unassigned items',
        separated: 'Separated',
        moved: 'Moved',
        categories: 'Categories',
        welcome: 'Welcome',
        loginToSignaloTpm: 'Log in to Signalo TPM',
        login: 'Log in',
        loginByEmailAndPassword: 'Log in by email and password',
        loginByCode: 'Log in by @:common.PIN_CODE_LABEL',
        accessCode: 'Access code',
        enterEmail: 'Enter email',
        enterPassword: 'Enter password',
        enterCode: 'Enter @:common.PIN_CODE_LABEL',
        userNotFound: 'User not found',
        userNotActivated: 'User not activated',
        userDisabled: 'User disabled',
        wrongPassword: 'Wrong password',
        locations: 'Locations',
        pageNotFound: 'Page not found',
        group: 'Group',
        yes: 'Yes',
        no: 'No',
        hourlyCost: 'Hourly cost',
        price: 'Price',
        newEntry: 'New entry',
        predictedFinishTime: 'Predicted finish time',
        ok: 'Ok',
        archive: 'Archive',
        costs: 'Costs',
        companyManagement: 'Company management',
        companyData: 'Company data',
        supportedLanguages: 'Supported languages',
        en: 'English',
        pl: 'Polish',
        es: 'Spanish',
        de: 'German',
        it: 'Italian',
        fr: 'French',
        ru: 'Russian',
        uk: 'Ukrainian',
        pt: 'Portuguese',
        tr: 'Turkish',
        nl: 'Dutch',
        sr: 'Serbian',
        ar: 'Arabic',
        he: 'Hebrew',
        defaultLanguage: 'Default language',
        companyLogo: 'Company logo',
        notificationsManagement: 'Notifications management',
        category: 'Category',
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        close: 'Close',
        show: 'Show',
        rollBackFormalConfirmation: 'Rollback formal confirmation',
        signUp: 'Sign up',
        nameSurname: 'Name and surname',
        phone: 'Phone number',
        password: 'Password',
        password_confirmation: 'Confirm password',
        unassignedItems: 'Unassigned items',
        currency: 'Currency',
        general: 'General',
        timezone: 'Timezone',
        users: 'Users',
        groups: 'Groups',
        productionLine: 'Production line',
        part: 'Part',
        fittingParts: 'Fitting parts',
        workSchema: 'Work schema',
        workSchemas: 'Work schemas',
        holiday: 'Holiday',
        changePassword: 'Change password',
        showRest: 'Show rest',
        pageNotPermitted: 'Page not permitted',
        waitingForActivation: 'Waiting for activation',
        editGroup: 'Edit group',
        addGroup: 'Add group',
        reportProblem: 'Report problem',
        describeOccuredIssue: 'Describe occured issue',
        markAllAsRead: 'Mark all as read',
        unitType: 'Unit type',
        unit: 'Unit',
        unitPrice: 'Unit price',
        notConfigured: 'Not configured',
        rowsPerPage: 'Rows per page',
        rowsOfPage: 'of',
        true: 'True',
        false: 'False',
        export: 'Export',
        chooseFileFormat: 'Choose file format',
        exported: 'Exported',
        terminals: 'Terminals',
        terminal: 'Terminal',
        selectEquipment: 'Select equipment',
        futureIssues: 'Future issues',
        scheduledOn: 'Scheduled on',
        planned: 'Planned',
        notPlanned: 'Unplanned',
        showOpenIssues: 'Show open issues',
        pin: 'PIN',
        assignedEquipment: 'Assigned equipment',
        terminalEquipment: 'Terminal equipment',
        detach: 'Detach',
        attach: 'Attach',
        dashboardConfig: 'Dashboard config',
        addColumn: 'Add column',
        addRow: 'Add row',
        removeRow: 'Remove row',
        registerTerminal: 'Register terminal',
        issueGeneratesCost: 'Downtime generates cost',
        selectColor: 'Select color',
        belowMinimum: 'Below minumum',
        belowOptimum: 'Below optimum',
        synchronization: 'Synchronization',
        synchronizationExport: 'Export of stock parts',
        synchronizationImport: 'Import of stock parts',
        mapping: 'Mapping',
        returnTo: 'Return to',
        jobStatusses: 'Processes',
        hidden: 'Hidden',
        editEvent: 'Edit event',
        threeDays: '3 days',
        addEvent: 'Add event',
        plannedIssues: 'Planned issues',
        currentService: 'Current service',
        searchLocation: 'Find location',
        all: 'All',
        unfinished: 'Unfinished',
        numberOfIssues: 'Issues quantity',
        unknown: 'Unknown',
        partEntry: 'Part (entry)',
        foundItems: 'Found items',
        output: 'Output',
        copied: 'Copied',
        link: 'Link',
        authorizationRequired: 'Authorization required',
        authorizationRequiredDescriptionUser:
            'Unfortunately, you do not have sufficient authorization. Ask an authorized person to approve the action.',
        authorizationRequiredDescriptionTerminal:
            'Use your access @:common.PIN_CODE_LABEL to authorize action.',
        showAll: 'Show all',
        showClassicView: 'Show classic view',
        noIssuesInCategory: 'No issues in this category.',
        numberOfIssuesInThisCategoryAndSubcategories:
            'Number of issues in this category and subcategories.',
        numberOfIssuesInThisCategory: 'Number of issues in this category.',
        minBarcodeLength: 'Minimum barcode length',
        currentPage: 'Current page',
        allPages: 'All pages',
        selectFormat: 'Select format',
        provided_currency: 'Provided currency',
        provided_quantity: 'Provided quantity',
        provided_unit: 'Provided unit',
        provided_unit_price: 'Provided unit price',
        unit_price: 'Unit price',
        quantity_available: 'Quantity available',
        editBarcode: 'Edit barcode',
        crews: 'Crews',
        scheduleEventTypes: 'Schedule events types',
        crew: 'Crew',
        scheduleEvent: 'Schedule event',
        available: 'Available',
        'App\\Models\\Crew': 'Crew',
        'App\\Models\\User': 'User',
        'App\\Models\\Equipment': 'Equipment',
        Crew: 'Crew',
        User: 'User',
        Equipment: 'Equipment',
        scheduleEvents: 'Schedule events',
        event: 'Event',
        externalSystems: 'External systems',
        user: 'User',
        availability: 'Availability',
        inheritColorFromParentIfCustomNotSet:
            'Inherit color from parent if custom not set',
        pickFromAllParts: 'Pick from all parts',
        takeTool: 'Take tool',
        returnTool: 'Return tool',
        personWhoTake: 'Person who take',
        personWhoReturn: 'Person who return',
        takenAt: 'Taken at',
        takenBy: 'Taken by',
        toolIssuedBy: 'Tool issued by',
        commentTake: 'Take comment',
        commentReturn: 'Return comment',
        change: 'Change',
        onlyWithActiveRMIssues: 'Only with active RM issues',
        externalSystem: 'External system',
        rejectIssue: 'Reject issue',
        withdrawIssueRejection: 'Withdraw issue rejection',
        rejectionReason: 'Reason of rejection',
        rejected: 'Rejected',
        showCrewMembers: 'Show crew members',
        crewMembers: 'Crew members',
        helpGroup: 'Service',
        partsTakenByUser: 'Parts taken by user',
        pickParts: 'Issue parts',
        selectPersonWhoTakes: 'Select person who takes',
        changePersonWhoTakes: 'Change person who takes',
        quantityToPick: 'Quantity to pick',
        pickLocation: 'Pick-up location',
        selectPartsToPick: 'Select parts to pick',
        partSelected: 'Part selected',
        scanCodeOr: 'Scan code or',
        returnPart: 'Return part',
        myTakenParts: 'Parts taken by me',
        takenFromLocation: 'Taken from location',
        takenQuantity: 'Taken quantity',
        providedUnit: 'Provided unit',
        providedQuantity: 'Provided quantity',
        selectFromStorage: 'Select from storage',
        selectFromMyPickedParts: 'Select from my picked parts',
        exportProcessInProgressMessage:
            'The data export process has just started, when it is completed you will see a corresponding notification containing a link to the file.',
        yourDocumentIsReady: 'Your document is ready',
        rotateScreen: 'Rotate screen',
        addRule: 'Add rule',
        editRule: 'Edit rule',
        summary: 'Summary',
        onlyAvailableEmployees: 'Only available employees',
        calculated_unit_price: 'Calculated unit price',
        calculated_quantity: 'Calculated quantity',
        next: 'Next',
        maintenanceMode: 'Maintenance mode',
        applicationMayBeUnavailableForFewMinutes:
            'Application may be unavailable for few minutes',
        appWillRefreshIn: 'Application will reload in',
        landscape: 'Landscape',
        portrait: 'Portrait',
        orientation: 'Orientation',
        format: 'Format',
        service: 'Service',
        leader: 'Leaders',
        defaultView: 'Default view',
        view: 'View',
        oneDay: '1 day',
        object: 'Object',
        finished: 'Finished',
        onlyNotFinished: 'Only not finished',
        generate: 'Generate',
        error: 'Error',
        cell: 'Cell',
        resolutionSummary: 'End Summary',
        rejected_at: 'Rejection date',
        rejected_by: 'Rejected by',
        finishedByUser: 'Finished by',
        formalConfirmByUser: 'Formal confirm by',
        formalConfirmAt: 'Formal confirm date',
        valueDeleted: '[Value deleted]',
        hasNotPlannedIssue: 'Has not planned issues',
        showInStorage: 'Show in storage',
        changeCode: 'Change @:common.PIN_CODE_LABEL',
        staffProfiles: 'Staff profiles',
        profile: 'Profile',
        user_bug: 'Application Bug',
        user_kaizen: 'Change suggestion',
        changeSuggestions: 'Change suggestions',
        showOnlyCurrentlyAvailable: 'Show only currently available',
        resetPassword: 'Reset password',
        forgotPassword: 'Forgot password',
        resetPasswordMailSent:
            'We have sent a link to set a new password to the provided e-mail',
        manyEventsThisTime: 'Many events at this time',
        importScheduleCheckboxLabel:
            'Delete scheduled events in the selected month for employees listed in the file',
        inheritedEvent: 'Inherited event',
        inheritedModifiedEvent: 'Modified inherited event',
        equipmentId: 'Equipment ID',
        entryId: 'Entry ID',
        partId: 'Part ID',
        suggestionsEmail: 'E-mail to which change suggestions will be sent',
        meters: 'Meters',
        scanCardToAuth:
            'Scan card or enter your access @:common.PIN_CODE_LABEL to confirm your identity',
        scanCard: 'Scan card',
        cart: 'Drop box',
        emptyCardMessage:
            'Start scanning products or select them from the list to add them to the drop box.',
        terminalMode: 'Terminal type',
        askPermittedUserToConfirmPartsExchange:
            'Ask the authorized person to confirm the release / return of the part by scanning the card or entering the @:common.PIN_CODE_LABEL',
        partsIssued: 'Parts issued',
        areYouSureAboutCancel: 'Are you sure you want to cancel?',
        areYouSureAboutRemoveFromCart:
            'Are you sure you want to remove this item from the drop box?',
        standard: 'Standard',
        idNumber: 'Identification number',
        takeParts: 'Take parts',
        returnParts: 'Return parts',
        quantityToReturn: 'Quantity to return',
        partsPendingApproval: 'Parts pending approval',
        partsReturned: 'Parts returned',
        partsAddedToPendingApproval: 'Parts added to pending approval',
        approvePartsReturn: 'Approve parts return',
        partReturnApproved: 'Part return approved',
        partUsage: 'Part usage',
        awaitsReturn: 'Awaits return',
        entrySelection: 'Entry selection',
        correctQuantity: 'Correct quantity',
        quantityCorrection: 'Quantity correction',
        entrySelectionAsc: 'Entry selection (ascending)',
        entrySelectionDesc: 'Entry selection (descending)',
        metersSettings: 'Meters settings',
        countableFields: 'Countable fields',
        equipmentTypes: 'Equipment types',
        fieldName: 'Field name',
        countableField: 'Countable field',
        partsStatusTaken: 'Taken',
        rejectPartsReturn: 'Reject parts return',
        areYouSureAboutRejectPartsReturn:
            'Are you sure you want to reject parts return?',
        partReturnRejected: 'Part return rejected',
        counterFields: 'Counter fields',
        counterName: 'Counter name',
        counterFieldName: 'Counter field name',
        counterId: 'Counter ID',
        counterUUID: 'Counter UUID',
        counterPickerPlaceholder: 'Select counter',
        pickFromPartsList: 'Pick from parts list',
        changeLocation: 'Change location',
        isPartStorageRoot: 'Part storage root',
        areYouSureAboutArchiveIssue:
            'Are you sure you want to archive this issue?',
        ganttChart: 'Gantt chart',
        table: 'Table',
        fittingEquipment: 'Fitting equipment',
        notAssigned: 'Not assigned',
        counters: 'Counters',
        machineHourlyCostNotProvided: 'The machine has no hourly cost set',
        eventTypesMightBeDefinedHere: 'Event types might be defined here',
        toolsTakenByUser: 'Tools taken by user',
        predictedReturnDate: 'Predicted return date',
        estimatedTimeOfUsingToolInHours:
            'Estimated time of using tool (in hours)',
        showOnlyAvailable: 'Show only available',
        toolTakeLog: 'Log of takes and returns',
        takeDate: 'Take date',
        returnDate: 'Return date',
        personWhoAcceptReturn: 'Person who accept return',
        statisticStrategy: 'Accessibility strategy',
        EquipmentPlannedStrategy: 'According to work plan',
        EquipmentConstantAvailabilityStrategy: 'Constant availability',
        actionAfterIssueFinish: 'Action after issue finish',
        enabled: 'Enabled',
        categoryOfNewIssue: 'Category of new issue',
        workNotStarted: 'Work not started',
        colorInherited: 'Color inherited',
        abcClassification: 'ABC classification',
        xyzClassification: 'XYZ classification',
        classification: 'Classification',
        filters: 'Filters',
        wipeFilters: 'Wipe filters',
        takePartsFromUser: 'Take parts from user',
        selectPersonWhoReturns: 'Select person who returns parts',
        changePersonWhoReturns: 'Change person who returns parts',
        returnLocation: 'Return location',
        returnQuantity: 'Return quantity',
        globalSettings: 'Global Settings',
        delimiter: 'Delimiter',
        fieldRules: 'Field rules',
        fieldValue: 'Field value',
        user_email_regex: 'User e-mail regex',
        barcodeLength: 'Barcode length',
        partLine: 'Part line',
        partFamily: 'Part family',
        partBatch: 'Part batch',
        testValueValid: 'Test value valid',
        testValueNotValid: 'Test value not valid',
        canBePicked: 'Can be chosen',
        issue_create_photos: 'Adding photos to a new issue',
        issue_create_documents: 'Adding documents to a new issue',
        propagateValues: 'Propagate values',
        archived: 'Archived',
        escalations_tab: 'Escalations',
        minute: 'Minute',
        days: 'Days',
        hours: 'Hours',
        minutes: 'Minutes',
        warnDays: 'Alert before expiration (days)',
        licenseType: 'License type',
        licenseGroup: 'License group',
        attachments: 'Attachments',
        attachment: 'Attachment',
        revisionChanges: 'Changes introduced',
        document: 'Document',
        noInstructionSelected: 'No instruction selected',
        instructionGroups: 'Groups of instructions',
        instructionGroup: 'Instruction group',
        addOption: 'Add an option',
        ordinal: 'Ordinal value',
        parameterName: 'Parameter name',
        fontColor: 'Font color',
        backgroundColor: 'Background color',
        columns: 'Number of columns',
        colspan: 'Width (columns)',
        products: 'Products',
        controlsTemplates: 'Inspection templates',
        controlType: 'Inspection type',
        controls: 'Inspections',
        controlTemplate: 'Inspection template',
        product: 'Product',
        checklistsAttachedToControlTemplate:
            'Checklists assigned to inspections',
        removeChecklistFromControlTitle:
            'Are you sure you want to remove this list from your control?',
        attachChecklistToControl: 'Assign a list to control',
        pickedItem: 'Selected item',
        pick: 'Pick item',
        fillControl: 'Complete the check',
        back: 'Back',
        control: 'Control',
        duplicate: 'Duplicate',
        isStorageRoot: 'Storage root location',
        manageStorageAccess: 'Manage access to warehouses',
        manageVisibleColumns: 'Adjust column visibility',
        columnName: 'Column name',
        visible: 'Visible',
        entriesLocation: 'Entries location',
        details: 'Details',
        otherData: 'Other data',
        moveAllEntriesFromCurrentLocation:
            'Move all entries from this location',
        searchInCurrentLocation: 'Search in current location',
        transition: 'Transition',
        notification: 'Notification',
        emails: 'Email addresses',
        phones: 'Phone numbers',
        addAction: 'Add an action',
        message: 'Message',
        application: 'App',
        always: 'Always',
        sms: 'SMS',
        loginTo: 'Log in to',
        appName: 'Signalo TPM',
        index: 'Index',
        showCodeOwnerNameDuringParing:
            'Show @:common.PIN_CODE_LABEL owner name when pairing with card/tag',
        showUsernameInCart: 'Show username in cart',
        sampleButtonPlaceholder: 'Sample button',
        preferences: 'Preferences',
        buttonsSize: 'Buttons size',
        erase: 'Erase',
        selectDictionaryValue: 'Select dictionary value',
        conditions: 'Conditions',
        partsCartTerminalRequireApproveOnPartsReturn:
            'Require confirmation or rejection when returning downloaded parts',
        issueCreated: 'A ticket has been created',
        allowedIssuesCategories: 'Allowed issues categories',
        hiddenFields: 'Hidden fields',
        defaultValues: 'Default values',
        paddingBottom: 'Empty space from bottom',
        allVisibleFieldsRequired: 'Require all visible fields to be completed',
        url: 'URL',
        kind: 'Kind',
        forkfleetConfigId: 'Forkfleet configuration',
        create: 'Create',
        terminate: 'Terminate',
        parent: 'Parent',
        phoneNumbers: 'Phone numbers',
        storageDefaults: 'Warehouse default values',
        clientSignatureEnabled: 'Signature of the person collecting',
        warehousemanSignatureEnabled: "Warehouseman's signature",
        storage_client_signature_enabled:
            'Signature of the person taking items from the warehouse',
        storage_client_signature_required:
            'Require the signature of the person taking items from the warehouse',
        storage_warehouseman_signature_enabled:
            'Signature of the person releasing the items from the warehouse',
        storage_warehouseman_signature_required:
            'Require the signature of the person releasing the items from the warehouse',
        clientReturnSignatureEnabled: 'Signature of the returning person',
        warehousemanReturnSignatureEnabled:
            'Signature of the person accepting the return',
        storage_client_return_signature_enabled:
            'Signature of the person returning the items',
        storage_client_return_signature_required:
            'Require a signature from the person returning the items',
        storage_warehouseman_return_signature_enabled:
            'Signature of the person accepting the return of the item',
        storage_warehouseman_return_signature_required:
            'Require the signature of the person accepting the return of the item',
        channels: 'Channels',
        manageState: 'Manage state',
        assignWorker: 'Assign an employee',
        mergeEntriesOfTheSameLocation: 'Merge entries from the same location',
        permission: 'Permission',
        areYouSureAboutMergeAllEntriesOfThisLocation:
            'Are you sure you want to merge all entries in this location?',
        manageFilters: 'Manage filters',
        responsible_assign_creator_when_no_rule:
            '[Issues] - Assign creator as responsible if rule not matched',
        recreateIssue: 'Recreate the issue',
        addNewDictionary: 'Add a new dictionary',
        label: 'Label',
        issuesWithRelatedCategories: 'Include subcategories',
        issuesLimit: 'Limit of issues',
        issuesLimits: 'Issues - limits',
        issueDefaults: 'Issues - default values',
        flatCategoryPickerLabel: 'Flat categories structure',
        statementsTemplates: 'Statement templates',
        statementTemplate: 'Statement template',
        content: 'Content',
        readonly: 'Read-only',
        defaultValue: 'Default value',
        fillParametersToChangeState:
            'Fill in the values for the parameters below to change the status',
        formatPattern: 'Format pattern',
        countableIncFields: 'Documents numbering configuration',
        mainShift: 'Main shift',
        singleEvent: 'Single entry',
        seriesOfEvents: 'A series of entries',
        omitWeekends: 'Omit weekends',
        allEntriesSaved: 'All entries have been added',
        surveyResults: 'Survey results',
        qrActionsSettings: 'QR code actions settings.',
        pendingStatements: 'Statements to sign',
        statementsResults: 'Statements',
        statements: 'Statements',
        statement: 'Statement',
        userCanChangeContent: 'The user can change the content',
        statementsEvents: 'Event configuration - statements',
        helpdesk: 'Helpdesk',
        terminalId: 'Terminal Id',
        operatorId: 'Operator Id',
        newConversation: 'New thread',
        formula: 'Formula',
        fill: 'Fill',
        webMessage: 'Web message',
        noResponsibleUser: 'No responsible user',
        filledAtDate: 'Date of completion',
        filledBy: 'Completed by',
        parts_quantity_alert_rule:
            '[Quantity of parts and materials] - Warning',
        employeeNumber: 'Employee number',
        groupsAssignedToCategory: 'Groups assigned to categories',
        number: 'Number',
        customers: 'Customers',
        customer: 'Customer',
        projects: 'Projects',
        project: 'Project',
        report: 'Report',
        reportGenerationInProgress: 'The report is generating',
        storageStatementGroup: 'Warehouse declaration group',
        simplifiedHandling: 'Simplified notification handling',
        selectWorkArea: 'Select your work area',
        workAreaSelection: 'Selecting a work area',
        access: 'Access',
        restrictAccess: 'Restrict access',
        anchor: 'Anchoring',
        issueCreator: 'Issue creator',
        hideSideMenu: 'Hide side menu',
        appendGroups: 'Groups to add',
        revokeGroups: 'Groups to remove',
        override: 'Overwrite',
        restrictGroupAccess: 'Restrict group access',
        flag: 'Flag',
        flags: 'Flags',
        manageFlags: 'Manage flags',
        icon: 'Icon',
        appendFlags: 'Flags to add',
        revokeFlags: 'Flags to be removed',
        checkIsTerminal: 'Checking the terminal',
        noConditionsDefined: 'No conditions defined',
        checkIsResponsible: 'Responsibility check',
        createdOn: 'Reported from',
        replaceTimespan: 'Shelf life from the time of issue',
        months: 'Months',
        handoversTemplates: 'Handovers templates',
        handoverTemplate: 'Handover template',
        employeeFunction: 'Employee function',
        takeHandover: 'Take handover',
        handovers: 'Handovers',
        expiringHandovers: 'Items that are nearing their expiration date',
        years: 'Years',
        taken_parts_expiry_warning:
            'Warning before the expiration date of downloaded items',
        andonView: 'Andon view',
        nfc: 'NFC',
        rfid: 'RFID',
        other: 'Other',
        tags: 'Cards / Tags',
        tag: 'Card / Tag',
        userPinCode: 'Card user @:common.PIN_CODE_LABEL',
        emailConfirmation: 'Email address confirmation',
        from: 'From',
        to: 'To',
        handoversHistory: 'Handovers history',
        partUsagesHistory: 'Part usages history',
        signaturePinTagConfirmationText1: 'Sign with @:common.PIN_CODE_LABEL',
        signaturePinTagConfirmationText2: 'or scan your card.',
        signedWithPinCode: 'Signed with @:common.PIN_CODE_LABEL',
        signedWithTAG: 'Signed with card / tag',
        test_mode: 'Test mode',
        confirmWorkFinish: 'Confirm work finish',
        workStarted: 'Work start',
        workFinished: 'Work finish',
        workTimeRegister: 'Work time register',
        measureTypes: 'Types of measurements',
        measureType: 'Measurement type',
        measurable: 'Measurable indicator',
        measurables: 'Measurable indicators',
        measurements: 'Measurements',
        measurement: 'Measurement',
        measuredAt: 'Measurement date',
        currentVersion: 'Current version',
        employee: 'Employee',
        pin_code_label: 'PIN name',
        orders: 'Orders',
        productionPlan: 'Production plan',
        productionPlans: 'Production plans',
        taktTime: 'Takt time',
        target: 'Target',
        seconds: 'Seconds',
        inheritValuesFromTenantSettings:
            'Inherit default values from global settings',
        matrices: 'Matrices',
        manageMatrice: 'Manage Matrix',
        deletionOfMatrixColumnWarnMessage:
            'Deleting a column deletes the values.',
        removeColumn: 'Delete a column',
        editColumn: 'Edit column',
        column: 'Column',
        row: 'Row',
        evaluated: 'Evaluated',
        of: 'Of',
        auto_pin_generation: '@:common.PIN_CODE_LABEL - Automatic generation',
        or: 'or',
        rename: 'Rename',
        check_only_on_transition: 'Only check when status changes',
        license: 'Powers',
        confirmWorkStart: 'Confirm start of work',
        workStartAt: 'You start work at',
        workFinishAt: 'You finish your work at',
        workStartedAt: 'Getting started',
        workTimeMessage: 'You overworked',
        haveANiceDay: 'Have a nice day!',
        farewellText: 'Text on the last screen',
        namedayLabel: 'They celebrate their name day',
        sportEventNearby: 'The next sport event',
        autoTranslate: 'Automatic translation',
        auto_translation: 'Automatic translation',
        awaitingAutoTranslation: 'Waiting for automatic translation',
        inheritAllDefaultValues:
            'Inherit all default values from global settings',
        escalations: 'Escalations',
        escalation: 'Escalation',
        notTaken: 'Not started',
        notFinished: 'Unfinished',
        issueLastChange: 'Last change',
        mode: 'Mode',
        repeatLastEscalation: 'Repeat last escalation value',
        manageActions: 'Manage actions',
        productionLines: 'Production lines',
        productionTerminal: 'Production terminal',
        currently: 'Currently',
        currentPlan: 'Current plan',
        currentlyInProduction: 'Currently produced',
        setProduct: 'Set product',
        second: 'Seconds',
        work: 'Work',
        chartConfiguration: 'Chart Configuration',
        revisionsToConsider: 'Revisions for consideration',
        allInstructions: 'All instructions',
        decider: 'Determiner',
        considerRevision: 'Consider revision',
        and: 'and',
        acceptInstruction: 'Accept instruction',
        accept: 'Accept',
        instructionsToAccept: 'Instructions to read',
        open: 'Open',
        assignedAt: 'Date of assignment',
        confirmationAt: 'Confirmation date',
        mainInstruction: 'Default instruction',
        setAsDefault: 'Set as default',
        instructionsFlows: 'Instructions - flows',
        newRevision: 'New revision',
        playlist: 'Video List',
        selectVideo: 'Select a movie',
        selectVideos: 'Select Movies',
        uploadNewVideo: 'Upload new video',
        upload_date: 'Upload date',
        thumbnail: 'Thumbnail',
        duration: 'Duration',
        uploadFromDrive: 'Upload from disk',
        selectAssets: 'Choose materials',
        assets: 'Materials',
        video: 'Video',
        doYouWantToAddNewMarker: 'Would you like to add a new marker here?',
        areYouSureAboutRemoveMarker:
            'Are you sure you want to remove this marker?',
        plants: 'Factory plants',
        plant: 'Factory',
        buildings: 'Buildings',
        building: 'Building',
        floors: 'Floors',
        floor: 'Floor',
        map: 'Map',
        areYouSureAboutMoveMarker: 'Are you sure you want to move this marker?',
        marker: 'Marker',
        addNewMarkerTooltipMessage:
            'To add a new marker, right-click on the map.',
        skillmatrix: 'Skillmatrix',
        indirectProcess: 'Non-linear skills',
        skillLevelConfig: 'Skill levels',
        indirectSkillGroups: 'Non-linear skill groups',
        approved: 'Confirmed',
        approvedBy: 'Approver',
        skillLevel: 'Skill level',
        requiredSkillLevel: 'Required level of skills',
        step: 'Step',
        image: 'Photo',
        skills: 'Skills',
        visits: 'Visits',
        responsiblePerson: 'Responsible person',
        company: 'Company',
        contactData: 'Contact details',
        visit: 'Visit',
        inProgress: 'In progress',
        gatehouse: 'Gatehouse',
        confirmEnter: 'Confirm entry',
        confirmExit: 'Confirm exit',
        enter: 'Entry',
        exit: 'Exit',
        entryGuard: 'The person letting in',
        exitGuard: 'The person letting out',
        loginAsThisUser: 'Log in as this user',
        minBrowserVersion: 'Minimum required browser version',
        approve: 'Approve',
        cancelApproval: 'Cancel approval',
        workPermit: 'Work permit',
        workPermits: 'Work permits',
        visit_summary: 'Visit summary',
        createVisit: 'Create visit based on permit',
        employeesAtThePlant: 'Employees at the plant',
        purpose: 'Purpose',
        allowedAreas: 'Allowed areas',
        switchEmployees: 'Switch employees',
        localIssues: 'Local issues',
        cmmsCategoriesSync: 'CMMS categories synchronization',
        terminalsGroups: 'Terminals groups',
        isTree: 'Tree',
        isReporter: 'Is reporter',
        isAnchored: 'Is anchored',
        closable: 'Closing issues',
        areYouSureAboutCloseIssue: 'Are you sure you want to close the issue?',
        simplifiedIssuesRefreshLabel: 'List refresh time - seconds',
        reloadApp: 'Reload app',
        hideWorkAreasButton: 'Hide work area selection button',
        hideClassicViewButton: 'Hide classic view button',
        hideAddIssueButton: 'Hide add new issue button',
        filesManager: 'File Manager',
        addFolder: 'Add folder',
        upload: 'Upload',
        noFolders: 'No folders',
        noFiles: 'No files',
        folders: 'Folders',
        folder: 'Folder',
        files: 'Files',
        file: 'File',
        removeFolderFilesQuestion: 'What to do with the files in this folder?',
        targetFolder: 'Target folder',
        addInstruction: 'Add instruction',
        goToFolder: 'Go to folder',
        defaultProdLine: 'Default production line',
        pin_code_field_type: 'PIN code type',
        instructionsAccess: 'Permissions',
        noSections: 'No sections',
        requireReadingInstruction: 'Require reading the instruction',
        hide_parameter_types: 'Do not display parameter types',
        unknownVersion: 'Unknown',
        displayInstructionEveryTime: 'Display the instruction every time',
        requireSigningInstructionEveryTime:
            'Require signing the instruction every time',
        signedInstructions: 'Signed instructions',
        signedAt: 'Signature date',
        signatures: 'Signatures',
        signature: 'Signature',
        systemGroup: 'System group',
        pickable: 'Selectable',
        leaf: 'Selected item',
        path: 'Path',
        displayMode: 'Display mode',
        chart: 'Chart',
        preSignedStatement: 'Declaration signed on paper (complete)',
        myOpenIssues: 'My open issues',
        myAssignedIssues: 'Issues assigned to me',
        displayParametersOnMainScreen: 'Display parameters on the main screen',
        sortChange: 'Sort change',
        asc: 'Ascending',
        desc: 'Descending',
        alphanumeric: 'Alphanumeric',
        numeric: 'Numeric',
        modificationDate: 'Modification date',
        fromNewest: 'From newest',
        fromOldest: 'From oldest',
        negation: 'Negation',
        sortOrderService: 'Service',
        newIssueIgnoreFilters:
            'Ignore applied filters when creating a new issue',
        refreshInterval: 'List refresh time',
        multiline: 'Multiline',
        expanded: 'Expanded',
    },
    placeholders: {
        part: 'Select part',
        nameSurname: 'Name and surname',
        phone: 'Phone number',
        password: 'Password',
        password_confirmation: 'Confirm password',
        email: 'E-mail',
    },
    auth: {
        notYourAccountQuestion: 'Not your account?',
    },
    signup: {
        registered: 'Success!',
        emailConfirmed: 'The email has been confirmed.',
        registered_info: 'Ask your supervisor to activate your account',
        confirmEmailAddress:
            'Your account has been created, click the link in the email to confirm your address.',
        tokenNotFoundError:
            'The email has already been confirmed or the confirmation link is invalid.',
    },
    paramTypes: {
        text: 'Text',
        note: 'Note',
        integer: 'Integer',
        decimal: 'Decimal',
        date: 'Date',
        time: 'Hour',
        datetime: 'Date and hour',
        color: 'Color',
        icon: 'Icon',
        boolean: 'No / Yes',
        signature: 'Signature',
    },
    tableHeaders: {
        id: 'ID',
        name: 'Name',
        path: 'Path to file',
        category: 'Category',
        equipment: 'Equipment',
        criticality: 'Criticality',
        status: 'Status',
        created: 'Created',
        createdBy: 'Created by',
        fp: 'FP',
        type: 'Type',
        designation: 'Designation',
        symbol: 'Symbol',
        serialNumber: 'Serial number',
        registryNumber: 'Registry number',
        udtNumber: 'UDT number',
        manufacturedYear: 'Manufactured year',
        actions: 'Actions',
        title: 'Title',
        data: 'Date',
        archived: 'Archived',
        manufacturerSymbol: 'Manufacturer symbol',
        min: 'Min',
        opt: 'Opt',
        quantity: 'Quantity',
        priority: 'Priority',
        price: 'Price',
        kind: 'Kind',
        description: 'Description',
        default: 'Default',
        warning: 'Warning',
        problem: 'Problem',
        fixing: 'Solving',
        changeOrder: 'Change order',
        required: 'Required',
        icon: 'Icon',
        color: 'Color',
        subCategories: 'Subcategories',
        barCode: 'Bar code',
        subLocations: 'Sublocations',
        categories: 'Categories',
        image: 'Image',
        photo: 'Photo',
        nameSurname: 'Name and surname',
        email: 'Email',
        phone: 'Phone number',
        function: 'Function',
        department: 'Department',
        accountStatus: 'Account status',
        shift: 'Shift',
        licenseNumber: 'License number',
        issuedDate: 'Issued date',
        expiresDate: 'Expires date',
        entries: 'Entries',
        entryNumber: 'Entry number',
        buyPrice: 'Buy price',
        currentLocationQuantity: 'Quantity in current location',
        group: 'Group',
        string: 'Text',
        integer: 'Integer',
        toolsGroup: 'Tools group',
        manufacturer: 'Manufacturer',
        shortName: 'Short name',
        locationName: 'Location name',
        subLocationsQuantity: 'Sublocations quantity',
        isWorkingOnIssue: 'Working on issue',
        openIssues: 'Open issues',
        notificationDate: 'Notifications date',
        titleAndDescription: 'Title and description',
        read: 'Read',
        created_at: 'Created',
        received_at: 'Received',
        started_at: 'Took',
        arrived_at: 'Arrived',
        finished_at: 'Finished',
        country: 'Country',
        city: 'City',
        progress: 'Progress',
        deleted_at: 'Deleted at',
        about_issue: 'About issue',
        action: 'Action',
    },
    searchConfig: {
        id: 'Id',
        name: 'Name',
        shortName: 'Short name',
        country: 'Country',
        email: 'Email',
        group: 'Group',
        planned: 'Planned',
        category: 'Category',
        object: 'Object',
        criticality: 'Criticality',
        fp: 'FP',
        type: 'Type',
        designation: 'Designation',
        symbol: 'Symbol',
        serialNumber: 'Serial number',
        registryNumber: 'Registry number',
        udtNumber: 'UDT number',
        manufacturedYear: 'Manufactured year',
        manufacturerSymbol: 'Manufacuter symbol',
        priority: 'Priority',
        quantity: 'Quantity',
        buyPrice: 'Buy price',
        kind: 'Kind',
        toolsGroup: 'Tools group',
        function: 'Function',
        department: 'Department',
        status: 'Status',
        nameSurname: 'Name and surname',
        phone: 'Phone',
        barCode: 'Bar code',
        color: 'Color',
        icon: 'Icon',
        machine: 'Machine',
        tool: 'Tool',
        urgentAndOutdated: 'Urgent and outdated',
        urgent: 'Urgent',
        expired: 'Expired',
        manufacturer: 'Manufacturer',
    },
    errors: {
        partMustHaveEntriesToCorrectQuantity:
            'The part must have entries to correct the quantity.',
        error: 'An error occured',
        wrongEmail: 'Invalid email',
        wrongPasswordLength: 'Password must be at least 8 characters length.',
        wrongCodeLength:
            '@:common.PIN_CODE_LABEL must be at least 5 characters length.',
        passwordsNotMatch: 'Passwords not match',
        codesNotMatch: '@:common.PIN_CODE_LABEL not match',
        codeAlreadyInUse: '@:common.PIN_CODE_LABEL already used',
        expiresDateLowerThanIssued:
            'Expires date cannot be lower than issued date.',
        cannotMoveToTheSameLocation: 'Cannot move to the same location.',
        locationRequired: 'Location is required.',
        putQuantity: 'Put quantity to separate.',
        valueGreaterThanQuantity: 'Value is greater than total quantity.',
        wholeCannotBeDistinguished: 'Whole cannot be distinguished.',
        yearMustBeGreaterThan: 'Year must be greater than ',
        passwordConfirmationNotMatch: 'Passwords not match',
        mustBeInteger: 'Value must be an integer',
        valueTooLong: 'Value too long',
        wrongPhoneNumber: 'Wrong phone number',
        phoneNumberAlreadyTaken: 'Phone number already taken',
        emailAlreadyTaken: 'Email already taken',
        notFound: 'Not found',
        sessionExpired: 'Session expired',
        tenantNotFound:
            'The indicated configuration does not exist. Check the site address and try again.',
        selectEquipmentTypeToConfigureParameters:
            'Select equipment type to configure parameters.',
        noParametersToConfigure:
            'Selected equipment type has no parameters to configure.',
        terminalAlreadyRegistered: 'Terminal already registered.',
        invalidPin: 'Invalid @:common.PIN_CODE_LABEL',
        eventCollision:
            'The time of the event you want to add overlaps with the time of another event.',
        mustStartWithSlash: 'Must start with /',
        mustBeBetween: 'Value must be between:',
        minLength: 'Mininum length: ',
        maxLength: 'Maximium length: ',
        invalidInputForCodeType: 'Invalid input for selected code type',
        invalidCodeOrNoPermission:
            'The @:common.PIN_CODE_LABEL is invalid or you do not have sufficient permissions to perform this action.',
        endDateLowerThanStart: 'End date cannot be lower than start date',
        iconAlreadyTaken: 'Icon has already been taken',
        notEnoughQuantityInStorage: 'Insufficient quantity in storage.',
        valueTooLow: 'Value too low',
        valueTooHigh: 'Value too high',
        maxAvailableQuantity: 'Maximum quantity',
        someFilesRejectedDueToSize:
            'Some files were rejected due to size. Max single file size:',
        cameraDeviceNotFound: 'Camera device not found',
        cameraPermissionDenied:
            "Access to the device's camera has been blocked in the site settings, reset the site settings and allow the camera to be used.",
        passwordResetLinkExpired:
            'Der Link zum Zurücksetzen des Passworts ist abgelaufen',
        noInternetConnection: 'No internet connection',
        minimum: 'Minimum',
        mustBeGreaterThan: 'Must be greater than',
        noEventTypesDefined: 'No event types defined',
        regexPatternDelimiterError:
            'An unescaped delimiter must be escaped with \\ character',
        emailNotMatchPatternDefiniedInTenant:
            "Email doesn't match pattern definied in tenant",
        max: 'Maximum value',
        min: 'Minimum value',
        allLanguagesRequired: 'Fill the value in all languages',
        fileTooBigToOpenWillBeDownloaded:
            'The file is too big to open, the file will be downloaded to your device.',
        surveyNotFound: 'Survey not found',
        actionNotEnabled: 'The code is inactive',
        loginRequired: 'Login required',
        noPermissionForSelectedFolder:
            'You do not have permission to view the contents of this directory',
        noFoldersAssignedForTerminal:
            'There are no assigned directories with instructions',
        noChecklistsAssignedToControl:
            'The selected control does not have a checklist assigned to it',
        maximum: 'Maxiumum',
        versionErrorMessage:
            "Your recent changes weren't saved because someone else made changes at the same time. Refresh the page and try again.",
        duplicateError: 'This value already exists',
        fillAllRequiredParameters: 'Fill values of all required parameters.',
        wrongValue: 'Wrong value',
        noParametersDefined: 'No parameters defined',
        noFlagsConfigured: 'No flags defined',
        noEnoughParts: 'No enough parts',
        pinNotBelongsTo: 'This @:common.PIN_CODE_LABEL does not belong to',
        tagNotBelongsTo: 'This Card/Tag does not belong to',
        noDictionaryEntries: 'The selected dictionary contains no entries',
        noConnectionWithServer: 'No connection to the server',
    },
    navDrawer: {
        dashboard: 'Dashboard',
        issues: 'Issues',
        timetable: 'Timetable',
        machines: 'Machines',
        tools: 'Tools',
        parts: 'Parts',
        storage: 'Warehouse',
        staff: 'Staff',
        configuration: 'Configuration',
        notes: 'Notes',
        notifications: 'Notifications',
        profile: 'Profile',
        supervision: 'Supervision',
    },
    barcodeScanner: {
        barCode: 'Bar code',
        id: 'ID',
    },
    categoryPicker: {
        categoryPlaceholder: 'Cateogory',
        selectCategory: 'Select category',
        noSubcategories: 'No subcategories',
    },
    companies: {
        addCompany: 'Add company',
        editCompany: 'Edit company',
        fullName: 'Full name',
        shortName: 'Short name',
        group: 'Group',
        country: 'Country',
        city: 'City',
        street: 'Street',
        postCode: 'Post code',
        phone: 'Phone',
        email: 'Email',
        website: 'Website',
        NIP: 'NIP',
        REGON: 'National Business Registry',
        active: 'Active',
        nameRequired: 'Name is required',
        shortNameRequired: 'Name is required',
    },
    issues: {
        tookAt: 'Taken at',
        changeHistory: 'Change history',
        load: 'Load',
        date: 'Date',
        createdBy: 'Created by',
        action: 'Action',
        data: 'Data',
        created: 'Created',
        updated: 'Updated',
        deleted: 'Removed',
        restored: 'Restored',
        issues: 'Issues',
        issue: 'Issue',
        by: 'by',
        name: 'Name',
        applicantsDescription: 'Applicant`s description',
        accidentDescription: 'Accident description',
        jobDescription: "Job's description",
        jobSummary: "Job's summary",
        category: 'Category',
        status: 'Status',
        noFlow: '[No flow]',
        equipment: 'Equipment',
        criticality: 'Criticality',
        hour: 'Hour',
        formalConfirmation: 'Formal confirmation',
        formalConfirmationShortcut: 'FC',
        formalConfirmationRequired: 'FC required',
        parameter: 'Parameter',
        value: 'Value',
        addIssue: 'Add issue',
        expectedCompletionDate: 'Expected completion date',
        issueDescription: 'Issue description',
        partsUsage: 'Disposal of parts',
        assignedEmployees: 'Assigned employees',
        requiredHelp: 'Required help',
        returnParts: 'Return part',
        confirmUnassignEmployeeWhoTakenIssue:
            'This employee has taken selected issue. Do you want to remove him?',
        responsibleUser: 'Responsible user',
        responsibleRules: 'Responsible users - rules',
        received_decision_at:
            "Date of notifier's acquaintance with the decision",
        groupRules: 'Groups - rules',
        watchersRules: 'Watchers - rules',
    },
    machines: {
        machines: 'Machines',
        xIssuesNotPlanned: ' (not planned)',
        noIssues: 'No issues',
    },
    maintenance: {
        maintenance: 'Maintenance',
    },
    notes: {
        notes: 'Notes',
        note: 'Note',
        title: 'Title',
        archived: 'Archived',
    },
    parts: {
        parts: 'Parts',
        warehouseOutgoings: 'Warehouse outgoings',
        fittingEquipmentTypes: 'Fitting equipment types',
        goToEquipmentType: 'Go to equipment type',
        selectPart: 'Select part',
        usageReason: 'Reason',
        usageOtherReason: 'Other',
        replacements: 'Replacements',
        partEntry: 'Part entry',
        goToPart: 'Go to part',
    },
    costCenter: {
        costCenter: 'Cost center',
    },
    equipmentTypes: {
        equipment: 'Equipment',
        newEquipmentType: 'New equipment type',
        fittingParts: 'Fitting parts',
        goToParts: 'Go to part',
    },
    machinesAndToolsDefinitions: {
        machinesAndToolsDefinitions: 'Machines and tools definitions',
    },
    flows: {
        flow: 'Flow',
        states: 'States',
        transitions: 'Transitions',
        state: 'State',
        default: 'Default',
        warning: 'Warning',
        problem: 'Problem',
        fixing: 'Fixing',
        finished: 'Finished',
        access: {
            create: 'Creation',
            read: 'Reading',
            update: 'Modification',
            delete: 'Removal',
            read_unassigned: 'Read unassigned',
            assign: 'Assignment',
            unassign: 'Delete an assignment',
            close: 'Close',
        },
        instructions: {
            default: 'Default',
            fixing: 'Consideration',
            finished: 'Finished',
        },
        accessLabels: {
            terminal: 'Terminal that reported the problem',
            creator: 'User that reported the problem',
            responsible: 'User who is responsible for the problem',
            group: 'User group that is responsible for the problem',
            anchor: 'Terminal to which the issue is attached',
            watchers: 'Users observing the problem',
        },
        sync_returned_rejected: 'Synchronization - rejected',
        sync_returned_finished: 'Synchronization - finished',
        current: 'Current',
    },
    parameters: {
        categoryParameter: 'Category parameter',
        equipmentTypeParameter: 'Equipment type parameter',
    },
    staff: {
        staff: 'Staff',
        editUserAccess: 'Edit user access',
        permissionsUpdated: 'Permissions updated',
        passwordUpdated: 'Password updated',
        codeUpdated: '@:common.PIN_CODE_LABEL updated',
        changePasswordAndCode: 'Change password and @:common.PIN_CODE_LABEL',
        goToLicenses: 'Go to licenses',
        editAccess: 'Edit access',
        editUser: 'Edit user',
        addUser: 'Add new user',
        nameAndLastname: 'Name and surname',
        accountStatus: 'Account status',
        phoneNumber: 'Phone number',
        department: 'Department',
        passwordChange: 'Password change',
        codeChange: '@:common.PIN_CODE_LABEL change',
        newPassword: 'New password',
        confirmNewPassword: 'Confirm new password',
        changePassword: 'Change password',
        newCode: 'New @:common.PIN_CODE_LABEL',
        confirmNewCode: 'Confirm new @:common.PIN_CODE_LABEL',
        changeCode: 'Change @:common.PIN_CODE_LABEL',
        addEntry: 'Add entry',
        editEntry: 'Edit entry',
        editLicense: 'Edit license',
        addLicense: 'Add license',
        licenseNumber: 'License number',
        prefferedLocale: 'Preffered locale',
        oldPassword: 'Old password',
        newPasswordConfirmation: 'Confirm new password',
        reading: 'Displaying',
        creating: 'Creating',
        updating: 'Updating',
        deleting: 'Deleting',
        importScheduleFile: 'Import a schedule from a file',
        importSchedule: {
            errors: {
                user_not_found: 'User not found [{user}]',
                user_found_many: '{count} users found [{user}]',
                event_collision: 'Collision of events',
                hour_cell_parse: 'Cell parsing error',
            },
        },
        oldCode: 'Old @:common.PIN_CODE_LABEL',
        newCodeConfirmation: 'New @:common.PIN_CODE_LABEL confirmation',
        setAccessBasedOnProfile: 'Grant access based on templete',
        selectAccessMergeType: 'Choose how to combine accesses',
        noAccessesInProfileMessage:
            'The selected profile has no permissions. Do you want to overwrite anyway?',
    },
    breadCrumb: {
        configuration: 'Configuration',
        locations: 'Locations',
        home: 'Home',
        machines: 'Machines',
        tools: 'Tools',
        issues: 'Issues',
        notes: 'Notes',
        maintenance: 'Maintenance',
        parts: 'Parts',
        warehouse: 'Warehouse',
        dictionary: 'Dictionary',
        categories: 'Categories',
        parameters: 'Parameters',
        costCenter: 'Cost center',
        flows: 'Flows',
        states: 'States',
        transitions: 'Transitions',
        shiftSchemas: 'Shifts schemas',
        shifts: 'Shifts',
        definitionsOfMachinesAndToolsTypes:
            'Machines and tools types definitions',
        add: 'Add',
        edit: 'Edit',
        staff: 'Staff',
        licenses: 'Licenses',
        entries: 'Entries',
        companiesDatabase: 'Companies database',
        companyManagement: 'Company management',
        companyData: 'Company data',
        supportedLanguages: 'Supported languages',
        en: 'English',
        pl: 'Polish',
        es: 'Spanish',
        de: 'German',
        fr: 'French',
        it: 'Italian',
        pt: 'Portuguese',
        ru: 'Russian',
        tr: 'Turkish',
        uk: 'Ukrainian',
        nl: 'Dutch',
        sr: 'Serbian',
        ar: 'Arabic',
        he: 'Hebrew',
        zh: 'Chinese',
        defaultLanguage: 'Default language',
        notifications: 'Notifications',
        notificationsManagement: 'Notifications management',
        myProfile: 'My profile',
    },
    attributeTypes: {
        text: 'Text',
        note: 'Note',
        integer: 'Integer',
        decimal: 'Decimal',
        date: 'Date',
        time: 'Time',
        dateTime: 'Date and time',
        color: 'Color',
        icon: 'Icon',
        boolean: 'Yes/No',
    },
    costCalculator: {
        costCalculator: 'Cost calculator',
        waitTime: 'Wait time',
        serviceTime: 'Service time',
        suspendTime: 'Suspend time',
        totalTime: 'Total time',
        estimatedCost: 'Estimated cost',
        calculate: 'Calculate',
        withoutParts: 'Without parts',
        timeOfReportingIssue: 'Time of reporting issue',
        timeOfServiceStart: 'Time of service start',
        timeOfReportingEnd: 'Time of service end',
        days: 'Days',
        hours: 'Hours',
        minutes: 'Minutes',
        enterNewTime: 'Enter new time',
        fromIssueReportToServiceStart:
            'Time from issue report to service start',
        fromServiceStartToServiceEnd: 'Time from service start to service end',
        workTime: 'Work time',
        includeAwaitingTime: 'Include awaiting time',
    },
    dashboard: {
        components: {
            myIssues: {
                title: 'My issues',
                assignedToMe: 'Assigned to me',
                assignedToGroup: 'Assigned to my group',
                tookByMe: 'Taken by me',
                takeIssueBtn: 'Take',
                arriveIssueBtn: 'I arrived',
                finishIssueBtn: 'Finish',
                history: 'History',
                myResponsible: "I'm responsible for",
                unassignIssue: 'Unassign',
            },
        },
    },
    synchronization: {
        enabled: 'Enabled',
        hasHeaders: 'Has headers',
        leaveFile: 'Leave file',
        host: 'Host',
        port: 'Port',
        protocol: 'Protocol',
        username: 'Username',
        password: 'Password',
        root: 'Root',
        encoding: 'Encoding',
        separator: 'Separator',
        externalSystemId: 'External system ID',
        cronExpression: 'Cron expression',
        appendFullStock: 'Append full stock',
        includeHeaders: 'Include headers',
        withTrashed: 'With removed data',
        filePattern: 'File pattern',
        categoriesRootCategoryId: 'Root category for imported categories',
        systemKeyname: 'System identifier',
        address: 'Address',
        token: 'Token',
    },
    processes: {
        created_at: 'Created at',
        started_at: 'Started at',
        updated_at: 'Updated at',
        finished_at: 'Finished at',
        attempts: 'Attempts',
        queue: 'Queue',
        job_id: 'Job id',
        job_uuid: 'Job uuid',
        progress_max: 'Progress max',
        progress_now: 'Progress now',
        outputMessage: 'Output message',
        status: {
            queued: 'queued',
            executing: 'executing',
            finished: 'finished',
            failed: 'failed',
            retrying: 'retrying',
            undefined: 'undefined',
        },
    },
    weekDays: {
        mo: 'Monday',
        tu: 'Tuesday',
        we: 'Wednesday',
        th: 'Thursday',
        fr: 'Friday',
        sa: 'Saturday',
        su: 'Sunday',
    },
    reports: {
        reports: 'Reports',
        dateFrom: 'Start date',
        dateTo: 'End date',
        types: {
            operation_sheet: 'Operating sheet',
            issue_summary: 'Issues summary',
            grouped_summary: 'Summary report',
            storage_operations: 'Warehouse operations',
            part_quantity_export: 'Parts quantity',
        },
        reportFor: 'Report for',
        includeQuantityLeft0: 'Include parts with null quantity',
        dayMinusN: 'Day minus n',
        currentWeek: 'Current week',
        lastWeek: 'Last week',
        currentMonth: 'Current month',
        lastMonth: 'Last month',
        currentYear: 'Current year',
        lastYear: 'Last year',
        nDays: 'Number of days',
        todayMinusN: "Today minus n"
    },
    logs: {
        comment: 'Comment',
        issues: {
            escalation: 'Escalations ({minutes} min) - notification sent',
        },
        issueEmployee: {
            assignedAt: 'Employee przypisany:',
            receivedAt: 'Employee read at:',
            tookAt: 'Employee took at',
            finishedAt: 'Employee finished at',
            deletedAt: 'Employee removed from this issue at',
        },
    },
    units: {
        no: 'Piece',
        st: 'Set',
        pr: 'Pair',
    },
    rrule: {
        frequency: 'Periodic',
        dtstart: 'Start date',
        tzid: 'Timezone',
        until: 'Until',
        count: 'Count',
        interval: 'Interval',
        wkst: 'Start day',
        byweekday: 'By weekday',
        bymonth: 'By month',
        yearly: 'Yearly',
        monthly: 'Monthly',
        weekly: 'Weekly',
        daily: 'Daily',
        hourly: 'Hourly',
        minutely: 'Minutely',
        secondly: 'Secondly',
        infinitePeriodicityMessage:
            'Periodicity is infinite, due to performance, only 500 rows are displayed',
    },
    months: {
        jan: 'January',
        feb: 'February',
        mar: 'March',
        apr: 'April',
        may: 'May',
        jun: 'June',
        jul: 'July',
        aug: 'August',
        sep: 'September',
        oct: 'October',
        nov: 'November',
        dec: 'December',
    },
    charts: {
        charts: 'Charts',
        count: 'Number of issues',
        total: 'Total issues time (from report to finish)',
        serw: 'Total repair time (Ttotal - Twait)',
        mttr1: 'Mean time from report to finish',
        mttr2: 'Mean time from take to finish',
        mtbf: 'Mean time between failures',
        mttf: 'Mean time to failure',
        type: 'Chart type',
        pie: 'Pie chart',
        bar: 'A bar graph',
        query: 'The scope of data',
        groupBy: 'Group by',
        sum: 'Sum',
        avg: 'Mean',
        min: 'Minimum',
        max: 'Maximum',
        eq: 'Is equal to',
        neq: 'It doesnt equal',
        gt: 'More than',
        lt: 'Less than',
        gte: 'More or equal',
        lte: 'Less or equal',
        in: 'Contains',
        nin: 'Do not contain',
        btw: 'Between',
        chartOptions: 'Chart options',
        line: 'Line graph',
        mixed: 'Mixed',
        chartDisplayOptions: 'Display options',
        fillGaps: 'Fill in the gaps',
    },
    regexBuilder: {
        regexValue: 'Regex value',
        global: 'global',
        multiline: '^ and $ correspond to start/end of line',
        insensitive: 'Not case sensitive',
        extended: 'Ignore whitespace',
        singleline: 'The dot corresponds to a new line',
        unicode: 'Unicode',
        Ungreedy: 'Lazy quantifiers',
        Anchored:
            'Anchor at the beginning of the pattern or at the end of the last match',
        Jchanged: 'Allow duplicate subpattern names',
        DollarEndOnly: '$ only matches the end of the pattern',
        testValue: 'Test value',
    },
    tpm: {
        history: 'History',
        standardObstacle: 'Standard obstacle',
        customObstacle: 'Custom obstacle',
        bhp: 'Health and Safety',
        returnToMainPage: 'Return to main page',
        bhpMessage:
            'If you see any safety hazards, please report them immediately to your direct supervisor and use the reporting form located below the notice board.',
        ok: 'Ok',
        send: 'Send',
        description: 'Description',
        title: 'Title',
        realization: 'Realization',
        answer: 'Answer',
        pendingSurveys: 'Pending surveys',
        surveys: 'Surveys',
        systemSurvey: 'System survey',
        addPage: 'Add page',
        createSurvey: 'Create survey',
        addSection: 'Add section',
        addQuestion: 'Add question',
        questionContent: 'Question content',
        answerOption: 'Answer option',
        addQuestionAnswer: 'Add answer option',
        surveyName: 'Survey name',
        sectionName: 'Section name',
        noSurveyAnswers: 'No answers',
        removeSection: 'Remove section',
        published: 'Published',
        unpublished: 'Unpublished',
        pendingPublication: 'Changes pending publication',
        removeQuestion: 'Remove question',
        removeAnswer: 'Remove answer option',
        publish: 'Publish',
        unpublish: 'Unpublish',
        previewResults: 'Preview results',
        supervisior: 'Supervisor',
        surveyResults: 'Survey results',
        surveysEvents: 'Events configuration - surveys',
        operatedBy: 'Operated by',
        confirmReadInformations: 'I have read the information',
        noticeContent: 'Notice content',
        notice: 'Notice',
        surveyEventName: 'Event name',
        survey: 'Survey',
        newIssue: 'New report',
        surveyEventConfigurationAlreadyExists:
            'Such configuration already exists',
        lastComment: 'Last comment by responsible person',
        surveyNotConfigured: 'Survey not configured',
        fillSurvey: 'Complete survey',
        singleSurveyEntries: 'single results',
        groupedSurveyEntries: 'Grouped results',
        publicationId: 'Publication ID',
        publicationDate: 'Date of publication',
        versionId: 'Version ID',
        answersCount: 'Number of responses',
        answers: 'Answers',
        withoutSupervisor: 'Without supervisor',
        defaultSupervisor: 'Default Supervisor',
        flowStateFlags: 'Status flags',
        qrActionsSettings: 'QR code actions settings.',
        loginRequired: 'Requires login',
        questionContext: 'Question context',
        currentSurveyVersionPreview:
            'Preview of the current version of the survey',
        surveyDescription: 'Survey description',
        sectionDescription: 'Section description',
        instructions: 'Instructions',
        instruction: 'Instruction',
        instructionRevisions: 'Revisions',
        instructionRevision: 'Revision',
        checklists: 'Checklists',
        checklist: 'Checklist',
        checklistItem: 'List item',
        manageChecklistFields: 'Manage list items',
        checklistItemDisplayOptions: 'Displayed items',
        revisionAutoRejectedInfo: '[System] Rejected - new revision created',
    },
    accessMergeValues: {
        sum: 'Sum',
        product: 'Product',
        overwrite: 'Overwrite',
    },
    aggregates: {
        count: 'Sum',
        min: 'Minimum',
        max: 'Maximum',
    },
    checklists: {
        itemBasic: 'Basic element',
        itemSeparator: 'Separator',
        itemSection: 'Section',
        publish: 'Publish',
        previewCurrentVersion: 'Preview of the current version',
    },
    cluer: {
        history: 'History',
        standardObstacle: 'Standard obstacle',
        customObstacle: 'Custom obstacle',
        bhp: 'Health and Safety',
        returnToMainPage: 'Return to main page',
        bhpMessage:
            'If you see any safety hazards, please report them immediately to your direct supervisor and use the reporting form located below the notice board.',
        ok: 'Ok',
        send: 'Send',
        description: 'Description',
        title: 'Title',
        realization: 'Realization',
        answer: 'Answer',
        pendingSurveys: 'Pending surveys',
        surveys: 'Surveys',
        systemSurvey: 'System survey',
        addPage: 'Add page',
        createSurvey: 'Create survey',
        addSection: 'Add section',
        addQuestion: 'Add question',
        questionContent: 'Question content',
        answerOption: 'Answer option',
        addQuestionAnswer: 'Add answer option',
        surveyName: 'Survey name',
        sectionName: 'Section name',
        noSurveyAnswers: 'No answers',
        removeSection: 'Remove section',
        published: 'Published',
        unpublished: 'Unpublished',
        pendingPublication: 'Changes pending publication',
        removeQuestion: 'Remove question',
        removeAnswer: 'Remove answer option',
        publish: 'Publish',
        unpublish: 'Unpublish',
        previewResults: 'Preview results',
        supervisior: 'Supervisor',
        surveyResults: 'Survey results',
        surveysEvents: 'Events configuration - surveys',
        operatedBy: 'Operated by',
        confirmReadInformations: 'I have read the information',
        noticeContent: 'Notice content',
        notice: 'Notice',
        surveyEventName: 'Event name',
        survey: 'Survey',
        newIssue: 'New report',
        surveyEventConfigurationAlreadyExists:
            'Such configuration already exists',
        lastComment: 'Last comment by responsible person',
        surveyNotConfigured: 'Survey not configured',
        fillSurvey: 'Complete survey',
        singleSurveyEntries: 'single results',
        groupedSurveyEntries: 'Grouped results',
        publicationId: 'Publication ID',
        publicationDate: 'Date of publication',
        versionId: 'Version ID',
        answersCount: 'Number of responses',
        answers: 'Answers',
        withoutSupervisor: 'Without supervisor',
        defaultSupervisor: 'Default Supervisor',
        flowStateFlags: 'Status flags',
        qrActionsSettings: 'QR code actions settings.',
        loginRequired: 'Requires login',
        questionContext: 'Question context',
        currentSurveyVersionPreview:
            'Preview of the current version of the survey',
        surveyDescription: 'Survey description',
        sectionDescription: 'Section description',
    },
    orders: {
        order: 'Order',
        releaseDate: 'Completion date',
        orderDate: 'date of order',
    },
    production: {
        good: 'Number of good ones',
        bad: 'Number of bad ones',
        productionRecords: 'Production register',
    },
};
