<template>
    <div
        :key="JSON.stringify(issue)"
        v-if="issue"
        class="signalo-andon-request-tile-container"
        :style="`background-color: ${background};`"
        :class="{ unread: !read }"
        @click="onTileClick"
        ref="tileContainer"
    >
        <SignaloTableItemFlagsComponent
            v-if="issue?.flags?.length"
            class="signalo-andon-request-tile-flag"
            :flags="issue.flags"
            :key="issue.id + JSON.stringify(issue.flags)"
        />
        <div class="signalo-andon-request-tile-data-container">
            <v-row class="signalo-andon-request-tile-data-container-v-row">
                <v-col cols="12" sm="8" md="8" class="data-column">
                    <div
                        class="destination-name"
                        style="display: flex; align-items: center"
                    >
                        <SignaloCategoryPicker
                            v-if="details"
                            :alwaysOpened="true"
                            class="issue-category-picker"
                            v-slot="props"
                            @update:modelValue="onCategoryChange"
                            :source="dataStore.categories_alter_structure.items"
                        >
                            <v-icon
                                color="primary"
                                v-if="details && issue?.access?.update"
                                @click="() => props.onClick()"
                                >mdi-pencil</v-icon
                            >
                            <div v-else></div>
                            <!--PUSTY v-else bo inaczej sie defaulowy input z SignaloCategoryPicker pokazuje-->
                        </SignaloCategoryPicker>

                        <div
                            class="category-dot"
                            :style="`background-color: ${category?.color}`"
                        ></div>
                        <v-icon
                            class="ml-1"
                            :icon="category?.icon"
                            size="20"
                        ></v-icon>
                        <div class="ml-2 issue-title-header">
                            <v-icon
                                color="primary"
                                v-if="details && issue?.access?.update"
                                @click="
                                    () => onUpdateValue(issue?.title, 'title')
                                "
                                >mdi-pencil</v-icon
                            >
                            <v-tooltip
                                :text="`ID: ${issue?.id}, v: ${issue?.version}`"
                                open-on-click
                                open-on-focus
                                location="bottom"
                            >
                                <template v-slot:activator="{ props }">
                                    <span v-bind="props">
                                        <span
                                            v-if="
                                                authStore?.tenant?.attributes
                                                    ?.auto_translation
                                            "
                                            v-html="
                                                (currentLocale
                                                    ? $getPropertyTranslation(
                                                          issue?.attributes
                                                              ?.translations
                                                              ?.title,
                                                          currentLocale,
                                                          issue?.attributes
                                                              ?.auto_translate,
                                                          'title'
                                                      )
                                                    : issue.title) ||
                                                issue?.title ||
                                                '-'
                                            "
                                        ></span>
                                        <span v-else>{{ issue?.title }}</span>
                                    </span>
                                    <!-- <v-checkbox
                                        v-bind="props"
                                        color="primary"
                                        @click.stop="select(item)"
                                        single-line
                                        hide-details
                                        :modelValue="modelValue?.id == item.id"
                                    ></v-checkbox> -->
                                </template>
                            </v-tooltip>
                        </div>
                    </div>
                    <br />
                    <div>
                        <div
                            class="data"
                            v-if="issue?.created_by_user_name || details"
                        >
                            {{ $t('tableHeaders.createdBy') }}:&nbsp;{{
                                $getPropertyTranslation(
                                    issue?.created_by_user_name
                                )
                            }}
                        </div>
                        <div
                            class="data"
                            v-if="
                                issue?.prod_line || issue?.created_on || issue?.created_on_terminal_name || details
                            "
                        >
                            {{ $t('common.createdOn') }}:&nbsp;{{
                                issue?.prod_line
                                    ? $getDictionaryItemName(
                                          'prod_lines',
                                          issue?.prod_line
                                      )
                                    : $getPropertyTranslation(issue?.created_on_terminal_name || issue?.created_on)
                            }}
                        </div>
                        <div
                            class="data"
                            v-if="
                                issue?.flow_state_name ||
                                issue?.flow_state ||
                                details
                            "
                        >
                            {{ $t('flows.state') }}:&nbsp;{{
                                $getPropertyTranslation(
                                    issue.flow_state_name ||
                                        issue?.flow_state?.name
                                )
                            }}
                        </div>
                        <div class="data" v-if="issue?.department || details">
                            <v-icon
                                color="primary"
                                v-if="details && issue?.access?.update"
                                @click="
                                    () =>
                                        onUpdateValue(
                                            issue?.department,
                                            'department'
                                        )
                                "
                                >mdi-pencil</v-icon
                            >
                            {{ $t('tableHeaders.department') }}:&nbsp;{{
                                $getDictionaryItemName(
                                    'departments',
                                    issue?.department
                                )
                            }}
                        </div>

                        <div class="data" v-if="issue?.kind || details">
                            <v-icon
                                color="primary"
                                v-if="details && issue?.access?.update"
                                @click="
                                    () => onUpdateValue(issue?.kind, 'kind')
                                "
                                >mdi-pencil</v-icon
                            >
                            {{ $t('common.kind') }}:&nbsp;{{
                                $getDictionaryItemName(
                                    'issue_kind',
                                    issue?.kind
                                )
                            }}
                        </div>
                        <div class="data" v-if="issue?.criticality || details">
                            <v-icon
                                color="primary"
                                v-if="details && issue?.access?.update"
                                @click="
                                    () =>
                                        onUpdateValue(
                                            issue?.criticality,
                                            'issue_criticality'
                                        )
                                "
                                >mdi-pencil</v-icon
                            >
                            {{ $t('tableHeaders.criticality') }}:&nbsp;{{
                                $getDictionaryItemName(
                                    'issue_criticality',
                                    issue?.criticality
                                )
                            }}
                        </div>
                        <div
                            class="issue-working-employees mr-2"
                            v-if="
                                issue?.responsible_user_id ||
                                issue?.responsible_user?.id ||
                                details
                            "
                        >
                            <SignaloUserPicker
                                v-if="
                                    details &&
                                    issue?.access?.update &&
                                    authStore.isAllowed('staff', 'read')
                                "
                                class="mb-3"
                                @update:modelValue="
                                    (val) =>
                                        assignAsResponsible(
                                            null,
                                            issue,
                                            null,
                                            val
                                        )
                                "
                                v-slot="props"
                                :ormWithcountEnabled="false"
                                :filteredItems="[issue?.responsible_user_id]"
                            >
                                <v-icon
                                    color="primary"
                                    v-if="details && issue?.access?.update"
                                    @click="() => props.onClick()"
                                    size="24"
                                    >mdi-pencil</v-icon
                                >
                            </SignaloUserPicker>
                            <v-tooltip
                                :text="`${$t('issues.responsibleUser')}: ${
                                    issue?.responsible_user?.name ?? ''
                                }`"
                                open-on-click
                                open-on-focus
                                class="simple-issue-card-content-margin-right"
                                location="bottom"
                            >
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props">mdi-account</v-icon>
                                    <span
                                        >&nbsp;{{
                                            $t('issues.responsibleUser')
                                        }}:&nbsp;{{
                                            issue?.responsible_user?.name
                                        }}</span
                                    >
                                </template>
                            </v-tooltip>
                        </div>
                    </div>
                </v-col>
                <div class="date-column">
                    <div class="date">
                        {{ $displayDateWithTimezone(issue?.created_at) }}
                    </div>
                    <div class="icons">
                        <div
                            v-if="issue.criticality"
                            class="issue-criticality-container simple-issue-card-content-margin-right"
                        >
                            <div class="issue-criticality">
                                {{ issue.criticality }}
                            </div>
                        </div>
                        <SignaloDynamicMenu
                            ref="dynamicMenu"
                            :rowItem="issue"
                            :menuItems="getTableActions(issue)"
                            class="issue-card-actions-menu-container"
                        />
                    </div>
                    <div class="date-from-now">{{ fromNow }}</div>
                    <div class="date-duration">{{ duration }}</div>
                </div>
            </v-row>
            <div
                :class="
                    expanded
                        ? 'issue-card-footer  expanded'
                        : 'issue-card-footer'
                "
            >
                <div class="issue-card-footer-info">
                    <div
                        class="issue-card-footer-issue-description"
                        ref="issueFooterDescriptionRef"
                    >
                        <v-icon
                            color="primary"
                            v-if="details && issue?.access?.update"
                            @click="
                                () =>
                                    onUpdateValue(
                                        issue.user_description,
                                        'user_description'
                                    )
                            "
                            size="24"
                            >mdi-pencil</v-icon
                        >
                        <span v-if="details" style="font-size: 16px"
                            >{{
                                $t('issues.applicantsDescription')
                            }}:&nbsp;</span
                        >
                        <br v-if="details && shouldDisplayFieldMultiline('user_description')" />
                        <span
                            v-if="
                                authStore?.tenant?.attributes?.auto_translation
                            "
                            :class="shouldDisplayFieldMultiline('user_description') ? 'multiline' : ''"
                            v-html="
                                (currentLocale
                                    ? $getPropertyTranslation(
                                          issue?.attributes?.translations
                                              ?.user_description,
                                          currentLocale,
                                          issue?.attributes?.auto_translate,
                                          'user_description',
                                          true
                                      )
                                    : issue?.user_description) ||
                                issue?.user_description ||
                                '-'
                            "
                        ></span>
                        <span v-else :class="shouldDisplayFieldMultiline('user_description') ? 'multiline' : ''"> {{ issue?.user_description }}</span>
                    </div>
                </div>
                <div
                    class="issue-card-footer-action"
                    v-if="issue?.user_description && expandButtonVisible"
                >
                    <div
                        v-if="!details"
                        @click="
                            ($event) => {
                                $event.stopPropagation();
                                $event.preventDefault();
                                expanded = !expanded;
                            }
                        "
                    >
                        <span v-if="!expanded">...</span>
                        <v-icon v-if="!expanded">mdi-chevron-down</v-icon>
                        <v-icon v-else>mdi-chevron-up</v-icon>
                    </div>
                </div>
            </div>
        </div>
        <SignaloItemParametersManage
            v-if="
                (details || shouldDisplayParameters) &&
                issue?.parameters?.filter((p) => !p.hidden)?.length
            "
            :categoryId="issue.category_id"
            :modelValue="issue.parameters"
            :allowClose="true"
            @update:modelValue="singleValueDialogUpdateHandler"
            :disabled="
                !issue?.access?.update || (!details && shouldDisplayParameters)
            "
            :dialogSearchable="true"
        />
        <div
            class="signalo-andon-request-tile-actions-container"
            :style="
                chipsVisible &&
                (issue?.access?.assign ||
                    issue?.access?.unassign ||
                    issue?.access?.close ||
                    transitions?.length)
                    ? 'max-height: 180px; transition: all .75s;'
                    : 'max-height: 0px; transition: all .25s; visibility: hidden'
            "
        >
            <div
                v-if="
                    !actionsLoading &&
                    issue?.access?.assign &&
                    (issue?.responsible_user_id != authStore.user.id ||
                        issue?.responsible_user?.id != authStore.user.id)
                "
                class="tile-chip tile-chip-reversed"
                @click="
                    ($event) => assignAsResponsible($event, issue, 'assign')
                "
            >
                {{ $t('dashboard.components.myIssues.takeIssueBtn') }}
            </div>
            <div
                v-else-if="
                    !actionsLoading &&
                    issue?.access?.unassign &&
                    (issue?.responsible_user_id == authStore.user.id ||
                        issue?.responsible_user?.id == authStore.user.id)
                "
                class="tile-chip tile-chip-reversed"
                @click="
                    ($event) => assignAsResponsible($event, issue, 'unassign')
                "
            >
                {{ $t('dashboard.components.myIssues.unassignIssue') }}
            </div>
            <SignaloPageLoading v-if="actionsLoading" class="actions-loader" />
            <div
                v-else
                class="tile-chip"
                v-for="transition in transitions"
                :key="transition.id"
                @click="($event) => onChipClick($event, transition)"
            >
                {{ transition?.name }}
            </div>
            <div
                v-if="issue?.access?.close"
                class="tile-chip tile-chip-close-issue"
                @click="($event) => closeIssue($event, issue)"
            >
                {{ $t('common.close') }}
            </div>
        </div>
        <StateChangeParametersDialog
            v-if="stateChangeParametersDialogOpened"
            @close="onCloseStateChangeParametersDialog"
            :parameters="stateParameters"
            @save="(params) => patchState(stateToChangeId, params)"
        />

        <SignaloSingleValueDialog
            v-if="editingProperty && editingProperty !== 'category_id'"
            :valueType="editingPropertyType"
            :modelValue="editingPropertyValue"
            :dictionaryId="editingPropertyDictionaryId"
            @update:modelValue="patchProperty"
            @update:open="clearEditingProperty"
            :open="true"
        />

        <ManageItemFlags
            v-if="manageItemFlagsDialogOpened"
            :type="'issue'"
            :item="data"
            @close="onManageItemFlagsDialogClose"
        />

        <SignaloConfirmationDialog
            v-if="closeIssueDialog"
            :dialogOpened="closeIssueDialog"
            @update:dialogOpened="(value) => closeIssue(value)"
            :text="$t('common.areYouSureAboutCloseIssue')"
            :confirmButtonText="$t('common.yes')"
        />
    </div>
</template>

<script>
import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
import * as DATA_STORE from 'signalo-vue-data-store';
import StateChangeParametersDialog from '../Issues/StateChangeParametersDialog.vue';
import moment from 'moment';
moment.locale('pl');
moment().format();
export default {
    emits: [
        'updateIssue',
        'toggleChipsActionsVisibility',
        'markAsRead',
        'reload',
        'dispatchEvent',
    ],
    setup() {
        const helpersStore = HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        const dataStore = DATA_STORE.default();
        return { helpersStore, authStore, dataStore };
    },
    name: 'SignaloAndonRequestTile',
    props: {
        data: {
            type: Object,
            default() {
                return {};
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
        chipsVisible: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: null,
        },
        details: {
            type: Boolean,
            default: false,
        },
        read: {
            type: Boolean,
            default: false,
        },
        currentLocale: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            stateToChangeId: null,
            stateChangeParametersDialogOpened: false,
            stateParameters: [],
            longPressTimeout: null,
            expanded: false,
            actionsLoading: false,
            isMounted: false,
            issue: {},
            editingProperty: null,
            editingPropertyType: null,
            editingPropertyValue: null,
            editingPropertyDictionaryId: null,
            debounceHandle: null,
            manageItemFlagsDialogOpened: false,
            closeIssueDialog: false,
            issueToClose: null,
            fromNow: null,
            refreshFromNowIntervalId: null,
            duration: null,
            refreshDurationIntervalId: null,
        };
    },
    async mounted() {
        this.issue = { ...this.data };
        this.watchFromNow();
        this.watchDuration();
        this.$nextTick(() => {
            this.isMounted = true;
        });
        this.expanded = this.details || this.shouldDescriptionBeExpanded('user_description')
    },
    unmounted() {
        if (this.refreshFromNowIntervalId) {
            clearInterval(this.refreshFromNowIntervalId);
        }
        if (this.refreshDurationIntervalId) {
            clearInterval(this.refreshDurationIntervalId);
        }
    },
    computed: {
        shouldDisplayParameters() {
            return (
                this.authStore?.terminal?.attributes
                    ?.simplified_display_params_on_main_tile ||
                this.authStore?.user?.attributes
                    ?.simplified_display_params_on_main_tile
            );
        },
        expandButtonVisible() {
            if (!this.isMounted) return;
            return Boolean(
                this.$refs?.issueFooterDescriptionRef?.clientHeight > 18
            );
        },
        transitions() {
            return (
                this.issue?.transitions
                    ?.map((x) => {
                        return {
                            ...x,
                            name: this.$getPropertyTranslation(x.name),
                        };
                    })
                    ?.sort((x, y) => {
                        if (x.sort > y.sort) return 1;
                        if (x.sort < y.sort) return -1;
                        if (x.id > y.id) return 1;
                        if (x.id < y.id) return -1;
                        return 0;
                    }) || []
            );
        },
        category() {
            return this.dataStore.categories.getById(this.issue.category_id);
        },
    },
    methods: {
        shouldDescriptionBeExpanded(fieldName) {
            const category = this.dataStore.categories.getById(this.issue.category_id)
            return Boolean(
                this.authStore.tenant?.attributes?.issueDefaults[
                    category?.path
                ]?.displayOptions?.[fieldName]?.expanded
            );
        },
        shouldDisplayFieldMultiline(fieldName) {
            const category = this.dataStore.categories.getById(this.issue.category_id)
            return Boolean(
                this.authStore.tenant?.attributes?.issueDefaults?.[
                    category?.path
                ]?.displayOptions?.[fieldName]?.multiline
            );
        },
        watchFromNow() {
            if (this.refreshFromNowIntervalId) {
                clearInterval(this.refreshFromNowIntervalId);
            }
            this.setFromNow();
            this.refreshFromNowIntervalId = setInterval(() => {
                this.setFromNow();
            }, 60 * 1000);
        },
        watchDuration() {
            if (this.refreshDurationIntervalId) {
                clearInterval(this.refreshDurationIntervalId);
            }
            this.setDuration();
            this.refreshDurationIntervalId = setInterval(() => {
                this.setDuration();
            }, 1000);
        },
        setFromNow() {
            if (!this.issue?.updated_at) {
                this.fromNow = '';
                return;
            }
            this.fromNow = moment(new Date(this.issue?.updated_at))
                .locale(this.$i18n.locale || localStorage.getItem('locale'))
                .startOf('minutes')
                .fromNow();
        },
        setDuration() {
            let date = moment(this.issue?.updated_at);
            if (
                new Date(this.issue?.updated_at).getTime() >
                new Date().getTime()
            ) {
                date = moment(new Date());
            }
            const end = moment(new Date());

            const duration = moment.duration(end.diff(date));
            const days = Math.floor(duration.asDays());

            duration.subtract(moment.duration(days, 'days'));
            const hours = duration.hours();

            duration.subtract(moment.duration(hours, 'hours'));
            const minutes = duration.minutes();

            duration.subtract(moment.duration(minutes, 'minutes'));
            const seconds = duration.seconds();

            this.duration = `${days}${this.$t(
                'common.day'
            )[0].toLocaleLowerCase()} ${this.pad(hours, 2)}:${this.pad(
                minutes,
                2
            )}:${this.pad(seconds, 2)}`;
        },
        debounce(func, delay = 1000) {
            if (this.debounceHandle !== null) clearTimeout(this.debounceHandle);
            this.debounceHandle = setTimeout(func, delay);
        },
        pad(num, size) {
            num = num.toString();
            while (num.length < size) num = '0' + num;
            return num;
        },
        onChipClick($event, transition) {
            $event.preventDefault();
            $event.stopPropagation();
            if (this.actionsLoading) return;
            this.setState(transition.id);
        },
        onTileClick() {
            this.$emit('markAsRead', this.issue.id);
            this.$emit('toggleChipsActionsVisibility', this.issue.id);
        },
        async setState(state_id) {
            this.actionsLoading = true;
            this.stateToChangeId = state_id;
            const anyParametersToFill = this.issue?.transitions?.find(
                (t) => t.id === state_id
            )?.parameters;
            if (anyParametersToFill?.length) {
                this.stateParameters = anyParametersToFill;
                this.stateChangeParametersDialogOpened = true;
                return;
            }
            await this.patchState(state_id, null);
        },
        async patchState(
            state_id = this.stateToChangeId,
            parameters = this.stateParameters
        ) {
            if (!state_id) {
                throw new Error('state_id is required');
            }
            let issueVersion = this.issue.version;

            try {
                this.actionsLoading = true;

                if (parameters?.length) {
                    const updatedIssue = await this.axios.patch(
                        `/issues/${this.issue.id}`,
                        {
                            version: issueVersion,
                            parameters: [
                                ...(this.issue?.parameters || [])?.map((p) => {
                                    return {
                                        parameter_id: p.id,
                                        value: p.value || p.default_value,
                                    };
                                }),
                                ...parameters,
                            ],
                        }
                    );
                    issueVersion = updatedIssue?.data?.item?.version;
                }
                const patch = {
                    flow_state_id: state_id,
                    version: issueVersion,
                };
                const updatedIssue = await this.axios.patch(
                    `/issues/${this.issue.id}`,
                    patch
                );
                this.onCloseStateChangeParametersDialog();
                this.$emit('updateIssue', this.index, updatedIssue?.data?.item);
                this.actionsLoading = false;
            } catch (err) {
                const modelError = 'Model has been changed during update';
                if (
                    !String(err).includes(modelError) &&
                    err?.message.includes(modelError)
                ) {
                    this.helpersStore.snackbarError(err);
                }
                this.onCloseStateChangeParametersDialog();
                console.error(err);
                this.actionsLoading = false;
            }
        },
        onCloseStateChangeParametersDialog() {
            this.actionsLoading = false;
            this.stateChangeParametersDialogOpened = false;
            this.stateToChangeId = null;
            this.stateParameters = null;
        },
        startPressTimer() {
            this.longPressTimeout = setTimeout(() => {
                console.log('longpress');
                // this.read = true;
            }, 500);
        },
        handleOnMouseDown() {
            this.startPressTimer();
        },
        handleOnTouchStart() {
            this.startPressTimer();
        },
        handleOnMouseUp() {
            clearTimeout(this.longPressTimeout);
        },
        handleOnTouchEnd() {
            clearTimeout(this.longPressTimeout);
        },
        showIssue(issue) {
            if (location.href.includes('production_terminal')) {
                this.$router.push({
                    name: 'production_terminal.issue',
                    params: { id: issue.id },
                });
            } else {
                this.$router.push({
                    name: 'issues.show_simple',
                    params: { id: issue.id },
                });
            }
        },
        getTableActions(issue) {
            let actions = [];

            if (!this.details) {
                actions.push({
                    icon: 'mdi-eye',
                    tooltipText: this.$t('common.preview'),
                    label: this.$t('common.preview'),
                    action: this.showIssue,
                });
            }

            if (this.details) {
                actions.push({
                    icon: 'mdi-flag',
                    tooltipText: this.$t('common.manageFlags'),
                    label: this.$t('common.manageFlags'),
                    action: this.onManageFlags,
                    access: [issue?.access?.update],
                });
            }

            return actions;
        },
        onManageFlags() {
            this.manageItemFlagsDialogOpened = true;
        },

        async closeIssue($event, issue) {
            $event.preventDefault();
            $event.stopPropagation();
            try {
                await this.axios.post(`issues/${issue.id}/close`);
                this.$emit('dispatchEvent', {
                    data: {
                        data: {
                            id: issue.id,
                            operation: 'deleted',
                            type: 'issueclosed',
                        },
                    },
                });
                this.helpersStore.snackbarSaved();
            } catch (err) {
                console.error(err);
            }
        },
        async assignAsResponsible(
            $event,
            issue,
            action,
            selectedUserId = null
        ) {
            if ($event) {
                $event.preventDefault();
                $event.stopPropagation();
            }
            try {
                let userId;
                if (selectedUserId) {
                    userId = selectedUserId;
                } else {
                    action === 'assign'
                        ? (userId = this.authStore.user.id)
                        : (userId = null);
                }
                this.actionsLoading = true;
                const updatedIssue = await this.axios.patch(
                    `issues/${issue.id}`,
                    {
                        id: issue.id,
                        responsible_user_id: userId,
                        version: issue.version,
                    }
                );
                this.$emit('updateIssue', this.index, updatedIssue?.data?.item);
                this.actionsLoading = false;
            } catch (err) {
                const modelError = 'Model has been changed during update';
                if (
                    !String(err).includes(modelError) &&
                    err?.message.includes(modelError)
                ) {
                    this.helpersStore.snackbarError(err);
                }
                this.actionsLoading = false;
                console.error(err);
            }
        },

        singleValueDialogUpdateHandler(val) {
            this.dataStore.issues
                .upsertParameter(
                    this.$route?.params?.id,
                    val?.id,
                    val?.dictionary_id ? val.value?.id : val.value
                )
                .then((res) => {
                    if (res.data?.status === 'ok') {
                        this.helpersStore.snackbar(
                            this.$t('common.saved'),
                            'success'
                        );
                    }
                })
                .catch((e) => {
                    if (e.response?.status == 401) return;
                });
        },
        onUpdateValue(value, property) {
            let dictionaryGroupToSearch;
            const dictionaryProperties = ['kind', 'department', 'criticality'];
            const isDictionaryProperty =
                dictionaryProperties.indexOf(property) > -1;
            if (isDictionaryProperty) {
                this.editingPropertyType = 'dictionary';
                if (property === 'kind') {
                    dictionaryGroupToSearch = 'issue_kind';
                } else if (property === 'department') {
                    dictionaryGroupToSearch = 'departments';
                } else if (property === 'criticality') {
                    dictionaryGroupToSearch = 'issue_criticality';
                }

                this.editingPropertyDictionaryId =
                    this.dataStore.dictionary.group(
                        dictionaryGroupToSearch
                    )?.[0]?.dictionary_id;

                if (!this.editingPropertyDictionaryId) {
                    this.clearEditingProperty();
                    return;
                }
            } else if (property === 'user_description') {
                this.editingPropertyType = 'note';
            } else if (property === 'title') {
                this.editingPropertyType = 'text';
            }
            this.editingProperty = property;
            this.editingPropertyValue = value;
        },
        async patchProperty(value) {
            if (!this.editingProperty) {
                this.clearEditingProperty();
                return;
            }
            let v;
            if (this.editingPropertyDictionaryId) {
                v = value?.keyname;
            } else {
                v = value;
            }
            const patch = { version: this.issue.version };
            patch[this.editingProperty] = v;
            try {
                const updatedIssue = await this.axios.patch(
                    `issues/${this.$route.params.id}`,
                    patch
                );
                this.$emit('updateIssue', this.index, updatedIssue?.data?.item);
                this.clearEditingProperty();
            } catch (err) {
                console.error(err);
            }
        },
        clearEditingProperty() {
            this.editingPropertyType = null;
            this.editingPropertyValue = null;
            this.editingPropertyDictionaryId = null;
            this.editingProperty = null;
        },
        async onCategoryChange(val) {
            this.editingProperty = 'category_id';
            await this.patchProperty(val);
        },
        markAsRead() {
            this.debounce(() => {
                this.$emit('markAsRead', this.issue.id);
            }, 3000);
        },
        async onManageItemFlagsDialogClose(shouldRefetch) {
            if (shouldRefetch) {
                await this.$emit('reload');
            }
            this.manageItemFlagsDialogOpened = false;
        },
    },
    watch: {
        loading(val) {
            this.actionsLoading = val;
        },
        '$i18n.locale': {
            handler() {
                this.setFromNow();
            },
            deep: true,
        },
    },
    components: { StateChangeParametersDialog },
};
</script>

<style scoped>
.signalo-andon-request-tile-data-container {
    position: relative;
}
.signalo-andon-request-tile-data-container-v-row {
    position: relative;
}
.data-column {
    text-align: left;
}
.date-column {
    text-align: right;
    justify-content: flex-end;
    font-size: 14px;
    position: absolute;
    right: 12px;
    min-width: 152px;
}

.date-column .date {
    margin-bottom: 8px;
}
.date-column .date-from-now {
    color: var(--primary-main);
    margin-bottom: 8px;
}
.date-column .date-duration,
.date-column .date {
    color: #9e9e9e;
}
.signalo-andon-request-tile-data-container {
    padding: 16px;
    color: #848484;
}
.signalo-andon-request-tile-container {
    padding-top: 8px;
    background-color: white;
    border-bottom: 2px solid lightgrey;
    cursor: pointer;
    position: relative;
}

.category-dot {
    height: 12px;
    width: 12px;
    min-height: 12px;
    min-width: 12px;
    border-radius: 50%;
}

.signalo-andon-request-tile-actions-container {
    background-color: #f7f6f6;
    padding: 0 16px;
    position: relative;
    padding-bottom: 8px;
    overflow: auto;
}
.signalo-andon-request-tile-actions-container {
    display: block;
}

.signalo-andon-request-tile-actions-container .tile-chip {
    display: flex;
    align-items: center;
    float: left;
    color: var(--primary-main);
    background: white;
    padding: 8px;
    border-radius: 16px;
    font-size: 14px;
    margin-top: 8px;
    margin-right: 8px;
}

.signalo-andon-request-tile-actions-container .tile-chip.tile-chip-reversed {
    color: white;
    background: var(--primary-main);
}
.signalo-andon-request-tile-actions-container .tile-chip.tile-chip-close-issue {
    color: white;
    background: var(--warning-main);
}
.unread {
    font-weight: bold;
}
.category-color-dot-column {
    width: 12px;
    flex-grow: unset;
}

.issue-criticality-container {
    width: 38px;
    height: 24px;
    border-radius: 80px;
    border: solid 1px #ffa0cd;
    background-color: #ffceeb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #a92147;
}

.simple-issue-card-content-margin-right {
    margin-right: 8px;
}

.issue-card-footer {
    height: 18px;
    overflow: hidden;
    max-width: calc(100% - 24px);
    margin-top: 12px;
}

.issue-card-footer.expanded {
    height: auto;
}
.issue-card-footer-action {
    position: absolute;
    right: 12px;
    bottom: 12px;
}

.issue-card-footer-issue-description {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.actions-loader {
    min-height: 56px;
}

.issue-card-actions-menu-container {
    display: flex;
    justify-content: flex-end;
}
.issue-card-content {
    width: 100%;
    position: relative;
    min-height: 36px;
    display: flex;
    align-items: center;
}

.issue-title-header {
    font-weight: bold;
    color: var(--ui-text-text-01);
    width: calc(100% - 112px);
}

.icons {
    display: flex;
    justify-content: flex-end;
}

</style>
<style>
.signalo-andon-request-tile-flag.flags-container {
    z-index: 99;
}
</style>
