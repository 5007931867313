export default [
    {
        path: '/storage-cart',
        name: 'storageCart',
        component: () =>
            import('@/views/Terminals/StorageCart/StorageCartIndex.vue'),
        meta: {
            availableAsTerminalDefaultView: true,
            currentAccessName: 'terminal.parts-exchange',
        },
    },
    {
        path: '/terminal-issues',
        name: 'terminal_issues',
        component: () =>
            import('@/views/Terminals/IssuesTerminal/IssuesTerminalIndex.vue'),
        meta: {
            permitAll: true, //TODO
        },
    },
    {
        path: '/work-time-register',
        name: 'work_time_register',
        component: () =>
            import('@/views/Terminals/WorkTimeRegister/WorkTimeRegister.vue'),
        meta: {
            permitAll: true, //TODO
        },
    },
    {
        path: '/production_terminal',
        name: 'production_terminal',
        component: () =>
            import(
                '@/views/Terminals/ProductionTerminal/ProductionTerminalIndex.vue'
            ),
        meta: {
            permitAll: true, //TODO
        },
        children: [
            {
                name: 'production_terminal.issues',
                path: 'issues',
                // name: 'production_terminal_issues',
                component: () =>
                    import(
                        '@/views/SimplifiedHandling/SimplifiedHandlingIndex.vue'
                    ),
                meta: {
                    permitAll: true, //TODO
                },
            },
            {
                name: 'production_terminal.issue',
                path: 'issue/:id',
                // name: 'production_terminal_issues',
                component: () =>
                    import(
                        '@/views/SimplifiedHandling/IssueShowSimple.vue'
                    ),
                meta: {
                    permitAll: true, //TODO 
                },
            },
        ],
    },
    {
        path: '/gatehouse',
        name: 'gatehouse',
        component: () =>
            import('@/views/GateHouse/GateHouseIndex.vue'),
        meta: {
            currentAccessName: 'visits_guard',
            bc: [
                {
                    text: 'common.gatehouse',
                    to: { name: 'gatehouse' },
                },
            ],
            // permitAll: true, //TODO
        },
    },
];
