import Settings from '@/views/settings/all.js';

export default [
    {
        path: '/settings/dictionary',
        name: 'settings.dictionary',
        component: async () =>
            await (
                await import('signalo-vue-settings-dictionaries-view')
            ).DictionaryView,
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'breadCrumb.dictionary',
                    to: { name: 'settings.dictionary' },
                },
            ],
        },
    },
    {
        path: '/settings/categories',
        name: 'settings.categories',
        component: Settings.Categories,
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'breadCrumb.categories',
                    to: {
                        name: 'settings.categories',
                    },
                    disabled: true,
                    href: '#',
                },
            ],
        },
    },
    {
        path: '/settings/categories/:id/groups',
        name: 'settings.categories-groups',
        component: () =>
            import('@/views/settings/Categories/CategoryGroupsIndex.vue'),
        meta: {
            currentAccessName: 'settings.categories',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'breadCrumb.categories',
                    to: {
                        name: 'settings.categories',
                    },
                },
                {
                    key: ':id',
                },
                {
                    text: 'common.groupsAssignedToCategory',
                    to: {
                        name: 'settings.categories-groups',
                    },
                },
            ],
        },
    },

    {
        path: '/settings/cost-centers',
        name: 'settings.cost-centers',
        component: Settings.CostCenters,
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'breadCrumb.costCenter',
                    to: { name: 'settings.cost-centers' },
                },
            ],
        },
    },
    {
        path: '/settings/work-schemas',
        name: 'settings.work-schemas',
        component: Settings.WorkSchemas,
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.workSchemas',
                    to: { name: 'settings.work-schemas' },
                },
            ],
        },
    },
    {
        path: '/settings/shifts-schemas',
        name: 'settings.shifts-schemas',
        component: Settings.ShiftSchemas,
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'breadCrumb.shiftSchemas',
                    to: { name: 'settings.shifts-schemas' },
                },
            ],
        },
    },
    {
        path: '/settings/shifts-schemas/:id',
        name: 'settings.shifts-schemas.show',
        component: Settings.Shifts,
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'breadCrumb.shiftSchemas',
                    to: { name: 'settings.shifts-schemas' },
                },
                {
                    text: 'breadCrumb.shifts',
                    to: { name: 'settings.shifts-schemas.show' },
                },
            ],
        },
    },
    {
        path: '/settings/equipment_types',
        name: 'settings.equipment-types',
        component: Settings.EquipmentIndex,
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'breadCrumb.definitionsOfMachinesAndToolsTypes',
                    to: { name: 'settings.equipment-types' },
                },
            ],
        },
    },
    {
        path: '/settings/equipment_types/create',
        name: 'settings.equipment-types.create',
        component: Settings.EquipmentCreate,
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'breadCrumb.definitionsOfMachinesAndToolsTypes',
                    to: { name: 'settings.equipment-types' },
                },
                {
                    text: 'breadCrumb.add',
                    to: { name: 'settings.equipment-types.create' },
                },
            ],
        },
    },
    {
        path: '/settings/equipment_types/:id/edit',
        name: 'settings.equipment-types.edit',
        component: Settings.EquipmentEdit,
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'breadCrumb.definitionsOfMachinesAndToolsTypes',
                    to: { name: 'settings.equipment-types' },
                },
                {
                    key: ':id',
                    to: {
                        name: 'settings.equipment-types.edit',
                        disabled: true,
                        href: '#',
                    },
                },
                {
                    text: 'breadCrumb.edit',
                    to: { name: 'settings.equipment-types.edit' },
                },
            ],
        },
    },
    {
        path: '/settings/company-management',
        name: 'settings.companyManagement',
        // component: () => import('signalo-vue-settings-company-management-view'),
        component: () =>
            import('@/views/temp/CompanyManagement/CompanyManagementIndex.vue'),
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.companyData',
                    to: { name: 'settings.companyManagement' },
                },
            ],
        },
    },
    {
        path: '/settings/synchronization',
        name: 'settings.synchronization',
        component: Settings.SynchronizationIndex,
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.synchronization',
                    to: { name: 'settings.synchronization' },
                },
            ],
        },
    },
    {
        path: '/settings/synchronization/imports',
        name: 'settings.synchronization.parts-import',
        component: Settings.SynchronizationImportsIndex,
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.synchronization',
                    to: { name: 'settings.synchronization' },
                },
                {
                    text: 'common.synchronizationImport',
                    to: { name: 'settings.synchronization.parts-import' },
                },
            ],
        },
    },
    {
        path: '/settings/synchronization/exports',
        name: 'settings.synchronization.parts-export',
        component: Settings.SynchronizationExportsIndex,
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.synchronization',
                    to: { name: 'settings.synchronization' },
                },
                {
                    text: 'common.synchronizationExport',
                    to: { name: 'settings.synchronization.parts-export' },
                },
            ],
        },
    },
    {
        path: '/settings/user_templates',
        name: 'settings.userProfiles',
        component: Settings.UserTemplatesIndex,
        meta: {
            currentAccessName: 'settings.staff',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.staffProfiles',
                    to: { name: 'settings.userProfiles' },
                },
            ],
        },
    },
    {
        path: '/settings/user_templates/:id/notification_preferences',
        name: 'settings.userProfilesNotificationPreferences',
        component: Settings.UserTemplateNotificationsPreferences,
        meta: {
            currentAccessName: 'settings.staff',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.staffProfiles',
                    to: { name: 'settings.userProfiles' },
                },
                {
                    key: ':id',
                },
                {
                    text: 'common.notificationsManagement',
                    to: {
                        name: 'settings.userProfilesNotificationPreferences',
                    },
                },
            ],
        },
    },
    {
        path: '/settings/user_templates/:id/groups',
        name: 'settings.userProfilesGroups',
        component: Settings.UserTemplateGroups,
        meta: {
            currentAccessName: 'settings.staff',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.staffProfiles',
                    to: { name: 'settings.userProfiles' },
                },
                {
                    key: ':id',
                },
                {
                    text: 'common.groups',
                    to: { name: 'settings.userProfilesGroups' },
                },
            ],
        },
    },
    {
        path: '/settings/terminals',
        name: 'terminals',
        component: Settings.TerminalsIndex,
        meta: {
            currentAccessName: 'terminals',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.terminals',
                    to: { name: 'terminals' },
                },
            ],
        },
    },

    {
        path: '/settings/terminals/:id/equipment',
        name: 'settings.terminalEquipment',
        component: Settings.TerminalsEquipmentIndex,
        meta: {
            currentAccessName: 'terminals.edit',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.terminals',
                    to: { name: 'terminals' },
                },
                {
                    text: 'common.assignedEquipment',
                    to: { name: 'settings.terminalEquipment' },
                },
            ],
        },
    },

    {
        path: '/settings/schedule_types',
        name: 'settings.schedule-types',
        component: Settings.ScheduleEventTypesIndex,
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.scheduleEventTypes',
                    to: { name: 'settings.schedule-types' },
                },
            ],
        },
    },
    {
        path: '/settings/external_systems',
        name: 'settings.systems',
        component: Settings.ExternalSystemsIndex,
        meta: {
            currentAccessName: 'settings.systems',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.externalSystems',
                    to: { name: 'settings.systems' },
                },
            ],
        },
    },
    {
        path: '/settings/statements-templates',
        name: 'settings.statements_templates',
        component: () =>
            import(
                '@/views/settings/StatementsTemplates/StatementsTemplatesIndex.vue'
            ),
        meta: {
            currentAccessName: 'statement-templates',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.statementsTemplates',
                    to: { name: 'settings.statements_templates' },
                },
            ],
        },
    },
    {
        path: '/settings/statements-templates/:id/content',
        name: 'settings.statements_template_content',
        component: () =>
            import(
                '@/views/settings/StatementsTemplates/StatementTemplateContent/StatementTemplateContentIndex.vue'
            ),
        meta: {
            currentAccessName: 'statement-templates',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.statementsTemplates',
                    to: { name: 'settings.statements_templates' },
                },
                { key: ':id', to: { name: 'settings.statements_templates' } },
                {
                    text: 'common.content',
                    to: { name: 'settings.statements_template_content' },
                },
            ],
        },
    },
    {
        path: '/settings/controls-template',
        name: 'settings.controls_templates',
        component: () =>
            import(
                '@/views/settings/ControlsTemplates/ControlsTemplatesIndex.vue'
            ),
        meta: {
            currentAccessName: 'settings.controls',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.controlsTemplates',
                    to: { name: 'settings.controls_templates' },
                },
            ],
        },
    },
    {
        path: '/settings/controls-template/:id/checklists',
        name: 'settings.controls_templates.checklists',
        component: () =>
            import(
                '@/views/settings/ControlTemplateChecklists/ControlTemplateChecklistsIndex.vue'
            ),
        meta: {
            currentAccessName: 'settings.controls',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.controlsTemplates',
                    to: { name: 'settings.controls_templates' },
                },
                { key: ':id', to: { name: 'settings.controls_templates' } },
                {
                    text: 'common.checklistsAttachedToControlTemplate',
                    to: { name: 'settings.controls_templates.checklists' },
                },
            ],
        },
    },
    {
        path: '/settings/locations/:id?',
        name: 'settings.locations',
        component: () => import('signalo-vue-settings-locations-view'),
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'breadCrumb.locations',
                    to: { name: 'settings.locations' },
                },
            ],
        },
    },
    {
        path: '/settings/counters-settings',
        name: 'settings.counters-settings',
        component: async () =>
            await (
                await import('signalo-vue-settings-counters-view')
            ).CountersSettings,
        meta: {
            currentAccessName: 'settings.counters',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.metersSettings',
                    to: { name: 'settings.counters-settings' },
                },
            ],
        },
    },
    {
        path: '/settings/counters',
        name: 'settings.counters',
        component: async () =>
            await (
                await import('signalo-vue-settings-counters-view')
            ).CountersIndex,
        meta: {
            currentAccessName: 'settings.counters',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.metersSettings',
                    to: { name: 'settings.counters-settings' },
                },
                {
                    text: 'common.counters',
                    to: { name: 'settings.counters' },
                },
            ],
        },
    },
    {
        path: '/settings/counters-countable-fields',
        name: 'settings.counters-countable-fields',
        component: async () =>
            await (
                await import('signalo-vue-settings-counters-view')
            ).CountersCountableFields,
        meta: {
            currentAccessName: 'settings.counters',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.metersSettings',
                    to: { name: 'settings.counters-settings' },
                },
                {
                    text: 'common.countableFields',
                    to: { name: 'settings.counters-countable-fields' },
                },
            ],
        },
    },
    {
        path: '/settings/counters-inc-fields',
        name: 'settings.counters-inc-fields',
        component: async () =>
            await (
                await import('signalo-vue-settings-counters-view')
            ).CountersIncFields,
        meta: {
            currentAccessName: 'settings.counters',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.metersSettings',
                    to: { name: 'settings.counters-settings' },
                },
                {
                    text: 'common.countableIncFields',
                    to: { name: 'settings.counters-inc-fields' },
                },
            ],
        },
    },
    {
        path: '/settings/cost-centers',
        name: 'settings.cost-centers',
        component: async () =>
            await (
                await import('signalo-vue-settings-cost-centers-view')
            ).CostCentersView,
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'breadCrumb.costCenter',
                    to: { name: 'settings.cost-centers' },
                },
            ],
        },
    },
    {
        path: '/settings/handover-templates',
        name: 'settings.handover_templates',
        component: async () =>
            import(
                '@/views/settings/HandoversTemplates/HandoversTemplatesIndex.vue'
            ),
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.handoversTemplates',
                    to: { name: 'settings.handover_templates' },
                },
            ],
        },
    },
    {
        path: '/settings/handover-template/:id/entries',
        name: 'settings.handover_template_entries',
        component: async () =>
            import(
                '@/views/settings/HandoversTemplates/HandoverTemplateEntries/HandoverTemplateEntriesIndex.vue'
            ),
        meta: {
            currentAccessName: 'settings.handover_templates',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.handoversTemplates',
                    to: { name: 'settings.handover_templates' },
                },
                { key: ':id' },
                {
                    text: 'tableHeaders.entries',
                    disabled: true,
                    href: '#',
                },
            ],
        },
    },
    {
        path: '/settings/issues/defaults/:cid?',
        name: 'settings.issues.defaults',
        // component: async () =>
        //     await (
        //         await import('signalo-vue-settings-issues-view')
        //     ).IssuesDefaults,
        component: async () =>
            await await import(
                '@/views/temp/IssuesDefaults/IssuesDefaultsIndex.vue'
            ),
        meta: {
            currentAccessName: 'settings.issues.edit',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'issues.issues',
                    to: { name: 'settings.issues' },
                },
                {
                    text: 'common.issueDefaults',
                    to: { name: 'settings.issues.defaults' },
                },
            ],
        },
    },
    {
        path: '/settings/matrices',
        name: 'settings.matrices',
        component: async () =>
            import('@/views/settings/Matrices/MatricesIndex.vue'),
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.matrices',
                    to: { name: 'settings.matrices' },
                },
            ],
        },
    },
    {
        path: '/settings/matrices/:id/attributes',
        name: 'settings.matrices_attributes',
        component: async () =>
            import(
                '@/views/settings/Matrices/MatriceAttributes/MatriceAttributesIndex.vue'
            ),
        meta: {
            currentAccessName: 'settings.matrices',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.matrices',
                    to: { name: 'settings.matrices' },
                },
                { key: ':id' },
            ],
        },
    },
    {
        path: '/settings/issues/escalations',
        name: 'settings.issues.escalations',
        component: async () =>
            await await import(
                '@/views/settings/Escalations/EscalationsIndex.vue'
            ),
        meta: {
            currentAccessName: 'settings.issues.edit',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'issues.issues',
                    to: { name: 'settings.issues' },
                },
                {
                    text: 'common.escalations',
                    to: { name: 'settings.issues.escalations' },
                },
            ],
        },
    },
    //temp
    {
        path: '/settings/plants',
        name: 'settings.plants',
        component: () => import('@/views/settings/Plants/PlantsIndex.vue'),
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.plants',
                    to: { name: 'settings.plants' },
                },
            ],
        },
    },
    {
        path: '/settings/plants/:id/buildings',
        name: 'settings.buildings',
        component: () =>
            import('@/views/settings/Plants/Buildings/BuildingsIndex.vue'),
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.plants',
                    to: { name: 'settings.plants' },
                },
                {
                    key: ':id',
                    to: { name: 'settings.plants' },
                },
                {
                    text: 'common.buildings',
                    to: { name: 'settings.buildings' },
                },
            ],
        },
    },
    {
        path: '/settings/plants/:id/buildings/:bid/floors',
        name: 'settings.floors',
        component: () =>
            import('@/views/settings/Plants/Buildings/Floors/FloorsIndex.vue'),
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.plants',
                    to: { name: 'settings.plants' },
                },
                {
                    key: ':id',
                    to: { name: 'settings.plants' },
                },
                {
                    text: 'common.buildings',
                    to: { name: 'settings.buildings' },
                },
                {
                    key: ':bid',
                    to: { name: 'settings.buildings' },
                },
                {
                    text: 'common.floors',
                    to: { name: 'settings.floors' },
                },
            ],
        },
    },
    {
        path: '/settings/plants/:id/buildings/:bid/floors/:fid/map',
        name: 'settings.floor_map',
        component: () =>
            import('@/views/settings/Plants/Buildings/Floors/FloorMap.vue'),
        meta: {
            currentAccessName: 'settings.floors',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.plants',
                    to: { name: 'settings.plants' },
                },
                {
                    key: ':id',
                    to: { name: 'settings.plants' },
                },
                {
                    text: 'common.buildings',
                    to: { name: 'settings.buildings' },
                },
                {
                    key: ':bid',
                    to: { name: 'settings.buildings' },
                },
                {
                    text: 'common.floors',
                    to: { name: 'settings.floors' },
                },
                {
                    key: ':fid',
                    to: { name: 'settings.floors' },
                },
                {
                    text: 'common.map',
                    to: { name: 'settings.floor_map' },
                },
            ],
        },
    },

    {
        path: '/settings/synchronization',
        name: 'settings.synchronization',
        component: Settings.SynchronizationIndex,
        meta: {
            currentAccessName: 'settings.synchronization',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.synchronization',
                    to: { name: 'settings.synchronization' },
                },
            ],
        },
    },
    {
        path: '/settings/synchronization/cmms',
        name: 'settings.synchronization.cmms',
        component: Settings.SynchronizationCMMSIndex,
        meta: {
            currentAccessName: 'settings.synchronization',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.synchronization',
                    to: { name: 'settings.synchronization' },
                },
                {
                    text: 'CMMS',
                    to: { name: 'settings.synchronization.cmms' },
                },
            ],
        },
    },
    {
        path: '/settings/synchronization/cmms/:id/cmms-categories-sync',
        name: 'settings.synchronization.cmms.cmms_categories_sync',

        component: async () =>
            await import(
                '@/views/settings/Synchronization/CMMS/CMMSCategoriesSync/CMMSCategoriesSyncIndex.vue'
            ),
        meta: {
            currentAccessName: 'settings.synchronization',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.synchronization',
                    to: { name: 'settings.synchronization' },
                },
                {
                    text: 'CMMS',
                    to: { name: 'settings.synchronization.cmms' },
                },

                {
                    key: ':id',
                    to: { name: 'settings.synchronization.cmms' },
                },
                {
                    text: 'common.cmmsCategoriesSync',
                    to: {
                        name: 'settings.synchronization.cmms.cmms_categories_sync',
                    },
                },
            ],
        },
    },
    {
        path: '/settings/synchronization/cmms/:id/cmms-category-structure',
        name: 'settings.synchronization.cmms.cmms_category_structure',

        component: async () =>
            await import(
                '@/views/settings/Synchronization/CMMS/CategoriesStructure/CategoriesStructureIndex.vue'
            ),
        meta: {
            currentAccessName: 'settings.synchronization',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'common.synchronization',
                    to: { name: 'settings.synchronization' },
                },
                {
                    text: 'CMMS',
                    to: { name: 'settings.synchronization.cmms' },
                },

                {
                    key: ':id',
                    to: { name: 'settings.synchronization.cmms' },
                },
                {
                    text: 'common.categoryStructure',
                    to: {
                        name: 'settings.synchronization.cmms.cmms_category_structure',
                    },
                },
            ],
        },
    },
    {
        path: '/settings/instructions',
        name: 'settings.instructions',
        component: Settings.InstructionsSettingsIndex,
        meta: {
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'tpm.instructions',
                    to: { name: 'settings.instructions' },
                },
            ],
        },
    },
    {
        path: '/settings/instructions/accessess',
        name: 'settings.instructions.accesses',
        component: Settings.InstructionsAccessesIndex,
        meta: {
            currentAccessName: 'settings.instruction_access',
            bc: [
                {
                    text: 'breadCrumb.configuration',
                    to: { name: 'settings' },
                },
                {
                    text: 'tpm.instructions',
                    to: { name: 'settings.instructions' },
                },
                {
                    text: 'common.instructionsAccess',
                    to: { name: 'settings.instructions.accesses' },
                },
            ],
        },
    },
];
