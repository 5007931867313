export const ru = {
    common: {
        PIN_CODE_LABEL: 'Пин-код',
        comment: 'Комментарий',
        year: 'Год',
        configuration: 'Конфигурация',
        cancel: 'Отмена',
        search: 'Поиск',
        remove: 'Удалить',
        confirmationDialogTitle: 'Вы уверены, что хотите удалить этот элемент?',
        selectDate: 'Выберите дату',
        select: 'Выберите',
        notFound: 'Не найдено',
        equipmentPickerPlaceholder: 'Объект оборудования',
        searchPlaceholder: 'Поиск',
        equipmentTypePickerPlaceholder: 'Тип оборудования',
        equipmentTypePickerTitle: 'Выберите тип оборудования',
        emptyTableMessage: 'Нет данных',
        flowPikcerTitle: 'Выберите поток',
        selectIcon: 'Выберите иконку',
        locationPickerTitle: 'Выберите местоположение',
        locationPlaceholder: 'Местоположение',
        noSublocations: 'Нет подразделений',
        locationsTitle: 'Местоположения',
        edit: 'Редактировать',
        save: 'Сохранить',
        added: 'Добавлено',
        updated: 'Обновлено',
        add: 'Добавить',
        removed: 'Удалено',
        reload: 'Обновить',
        confirm: 'Подтвердить',
        rollBack: 'Отменить',
        images: 'Изображения',
        documents: 'Документы',
        download: 'Загрузить',
        saved: 'Сохранено',
        date: 'Дата',
        hour: 'Час',
        preview: 'Предпросмотр',
        data: 'Данные',
        attributes: 'Атрибуты',
        checkList: 'Список проверки',
        name: 'Имя',
        type: 'Тип',
        status: 'Статус',
        designation: 'Обозначение',
        symbol: 'Символ',
        serialNumber: 'Серийный номер',
        registryNumber: 'Регистрационный номер',
        machineNumber: 'Номер машины',
        udtNumber: 'Номер UDT',
        manufacturedYear: 'Год производства',
        manufacturer: 'Производитель',
        supplier: 'Поставщик',
        mainSupplier: 'Главный поставщик',
        attribute: 'Атрибут',
        nameRequired: 'Требуется имя',
        yearGreaterThan: 'Год должен быть больше',
        location: 'Расположение',
        fieldRequired: 'Это поле является обязательным',
        description: 'Описание',
        minimumQuantity: 'Минимальное количество',
        optimalQuantity: 'Оптимальное количество',
        priority: 'Приоритет',
        formatYYYY: 'Формат YYYY',
        storageQuantity: 'Количество на складе',
        quantity: 'Количество',
        quantityGreaterThan: 'Количество должно быть больше',
        machine: 'Машина',
        tool: 'Инструмент',
        order: 'Заказ',
        required: 'Обязательный',
        dateFormat: 'Формат даты',
        selectDictionary: 'Выбрать словарь',
        return: 'Вернуть',
        parameters: 'Параметры',
        editCategory: 'Редактировать категорию',
        addCategory: 'Добавить новую категорию',
        changeColor: 'Изменить цвет',
        noColor: 'Нет цвета',
        shift: 'Смена',
        start: 'Начало',
        end: 'Конец',
        shiftSchema: 'Схема смен',
        shifts: 'Смены',
        dictionary: 'Словарь',
        subEntries: 'Подзаписи',
        key: 'Ключ',
        value: 'Значение',
        moveUp: 'Переместить вверх',
        moveDown: 'Переместить вниз',
        addLocation: 'Добавить новое местоположение',
        editLocation: 'Редактировать местоположение',
        companiesDatabase: 'База данных компаний',
        flows: 'Потоки',
        read: 'Чтение',
        write: 'Запись',
        noImage: 'Нет изображения',
        email: 'Электронная почта',
        function: 'Функция',
        active: 'Активный',
        notActive: 'Не активирован',
        disabled: 'Отключен',
        entryNumber: 'Номер записи',
        issuedDate: 'Дата выдачи',
        expiresDate: 'Дата истечения',
        move: 'Переместить',
        separateForm: 'Разделить от',
        separate: 'Разделить',
        warehouse: 'Склад',
        actions: 'Действия',
        putInCurrentLocation: 'Поместить в текущее местоположение',
        selectLocationFormRightMenu: 'Выбрать местоположение из правого меню',
        showUnassignedItems: 'Показать неназначенные элементы',
        separated: 'Разделено',
        moved: 'Перемещено',
        categories: 'Категории',
        welcome: 'Добро пожаловать',
        loginToSignaloTpm: 'Войдите в Signalo TPM',
        login: 'Войти',
        loginByEmailAndPassword: 'Вход с помощью электронной почты и пароля',
        loginByCode: 'Вход по "@:common.PIN_CODE_LABEL',
        accessCode: 'Код доступа',
        enterEmail: 'Введите электронную почту',
        enterPassword: 'Введите пароль',
        enterCode: 'Введите "@:common.PIN_CODE_LABEL',
        userNotFound: 'Пользователь не найден',
        userNotActivated: 'Пользователь не активирован',
        userDisabled: 'Пользователь отключен',
        wrongPassword: 'Неверный пароль',
        locations: 'Местоположения',
        pageNotFound: 'Страница не найдена',
        group: 'Группа',
        yes: 'Да',
        no: 'Нет',
        hourlyCost: 'Почасовая стоимость',
        price: 'Цена',
        newEntry: 'Новая запись',
        predictedFinishTime: 'Предполагаемое время завершения',
        ok: 'Ок',
        archive: 'Архив',
        costs: 'Расходы',
        companyManagement: 'Управление компанией',
        companyData: 'Данные компании',
        supportedLanguages: 'Поддерживаемые языки',
        en: 'Английский',
        pl: 'Польский',
        es: 'Испанский',
        de: 'Немецкий',
        it: 'итальянский',
        fr: 'французский',
        ru: 'русский',
        uk: 'украинский',
        pt: 'португальский',
        tr: 'турецкий',
        nl: 'нидерландский',
        sr: 'сербский',
        ar: 'арабский',
        he: 'иврит',
        zh: 'китайский',
        defaultLanguage: 'Язык по умолчанию',
        companyLogo: 'Логотип компании',
        notificationsManagement: 'Управление уведомлениями',
        category: 'Категория',
        today: 'Сегодня',
        month: 'Месяц',
        week: 'Неделя',
        day: 'День',
        close: 'Закрыть',
        show: 'Показать',
        rollBackFormalConfirmation: 'Формальное подтверждение отката',
        signUp: 'Зарегистрироваться',
        nameSurname: 'Имя и фамилия',
        phone: 'Номер телефона',
        password: 'Пароль',
        password_confirmation: 'Подтвердите пароль',
        unassignedItems: 'Неназначенные элементы',
        currency: 'Валюта',
        general: 'Общий',
        timezone: 'Часовой пояс',
        users: 'Пользователи',
        groups: 'Группы',
        productionLine: 'Производственная линия',
        part: 'Часть',
        fittingParts: 'Соответствующие части',
        workSchema: 'Рабочая схема',
        workSchemas: 'Рабочие схемы',
        holiday: 'Праздник',
        changePassword: 'Изменить пароль',
        showRest: 'Показать остаток',
        pageNotPermitted: 'Страница не разрешена',
        waitingForActivation: 'Ожидание активации',
        editGroup: 'Редактировать группу',
        addGroup: 'Добавить группу',
        reportProblem: 'Сообщить о проблеме',
        describeOccuredIssue: 'Опишите возникшую проблему',
        markAllAsRead: 'Отметить все как прочитанные',
        unitType: 'Тип единицы',
        unit: 'Единица',
        unitPrice: 'Цена за единицу',
        notConfigured: 'Не настроено',
        rowsPerPage: 'Строк на странице',
        rowsOfPage: 'из',
        true: 'Истина',
        false: 'Ложь',
        export: 'Экспортировать',
        chooseFileFormat: 'Выберите формат файла',
        exported: 'Экспортировано',
        terminals: 'Терминалы',
        terminal: 'Терминал',
        selectEquipment: 'Выберите оборудование',
        futureIssues: 'Будущие проблемы',
        scheduledOn: 'Запланировано на',
        planned: 'Запланировано',
        notPlanned: 'Не запланировано',
        showOpenIssues: 'Показать открытые проблемы',
        pin: 'PIN',
        assignedEquipment: 'Назначенное оборудование',
        terminalEquipment: 'Оборудование терминала',
        detach: 'Отсоединить',
        attach: 'Присоединить',
        dashboardConfig: 'Конфигурация панели управления',
        addColumn: 'Добавить столбец',
        addRow: 'Добавить строку',
        removeRow: 'Удалить строку',
        registerTerminal: 'Зарегистрировать терминал',
        issueGeneratesCost: 'Время простоя генерирует затраты',
        selectColor: 'Выберите цвет',
        belowMinimum: 'Ниже минимума',
        belowOptimum: 'Ниже оптимума',
        synchronization: 'Синхронизация',
        synchronizationExport: 'Экспорт запасных частей',
        synchronizationImport: 'Импорт запасных частей',
        mapping: 'Отображение',
        returnTo: 'Вернуться',
        jobStatusses: 'Процессы',
        hidden: 'Скрытый',
        editEvent: 'Редактировать событие',
        threeDays: '3 дня',
        addEvent: 'Добавить событие',
        plannedIssues: 'Запланированные проблемы',
        currentService: 'Текущая обслуживаемость',
        searchLocation: 'Найти местоположение',
        all: 'Все',
        unfinished: 'Незавершенный',
        numberOfIssues: 'Количество проблем',
        unknown: 'Неизвестный',
        partEntry: 'Часть (вход)',
        foundItems: 'Найденные элементы',
        output: 'Выход',
        copied: 'Скопировано',
        link: 'Ссылка',
        authorizationRequired: 'Требуется авторизация',
        authorizationRequiredDescriptionUser:
            'К сожалению, у вас недостаточно полномочий. Попросите авторизованного лица утвердить действие.',
        authorizationRequiredDescriptionTerminal:
            'Используйте свой "@:common.PIN_CODE_LABEL доступа, чтобы авторизовать действие.',
        showAll: 'Показать все',
        showClassicView: 'Показать классический вид',
        noIssuesInCategory: 'В этой категории нет проблем.',
        numberOfIssuesInThisCategoryAndSubcategories:
            'Количество проблем в этой категории и подкатегориях.',
        numberOfIssuesInThisCategory: 'Количество проблем в этой категории.',
        minBarcodeLength: 'Минимальная длина штрих-кода',
        currentPage: 'Текущая страница',
        allPages: 'Все страницы',
        selectFormat: 'Выберите формат',
        provided_currency: 'Предоставленная валюта',
        provided_quantity: 'Предоставленное количество',
        provided_unit: 'Предоставленный единица',
        provided_unit_price: 'Предоставленная цена за единицу',
        unit_price: 'Цена за единицу',
        quantity_available: 'Доступное количество',
        editBarcode: 'Изменить штрих-код',
        crews: 'Команды',
        scheduleEventTypes: 'Типы графика событий',
        crew: 'Команда',
        scheduleEvent: 'Событие в графике',
        available: 'Доступный',
        'App\\Models\\Crew': 'Команда',
        'App\\Models\\User': 'Пользователь',
        'App\\Models\\Equipment': 'Оборудование',
        Crew: 'Команда',
        User: 'Пользователь',
        Equipment: 'Оборудование',
        scheduleEvents: 'События в графике',
        event: 'Событие',
        externalSystems: 'Внешние системы',
        user: 'Пользователь',
        availability: 'Доступность',
        inheritColorFromParentIfCustomNotSet:
            'Наследовать цвет от родителя, если не установлен свой',
        pickFromAllParts: 'Выбрать из всех частей',
        takeTool: 'Взять инструмент',
        returnTool: 'Вернуть инструмент',
        personWhoTake: 'Человек, взявший',
        personWhoReturn: 'Человек, вернувший',
        takenAt: 'Взято в',
        takenBy: 'Взято',
        toolIssuedBy: 'Инструмент выдан',
        commentTake: 'Комментарий при взятии',
        commentReturn: 'Комментарий при возврате',
        change: 'Изменить',
        onlyWithActiveRMIssues: 'Только с активными запросами RM',
        externalSystem: 'Внешняя система',
        rejectIssue: 'Отклонить проблему',
        withdrawIssueRejection: 'Отменить отклонение проблемы',
        rejectionReason: 'Причина отклонения',
        rejected: 'Отклонено',
        showCrewMembers: 'Показать участников команды',
        crewMembers: 'Участники команды',
        helpGroup: 'Служба',
        partsTakenByUser: 'Части, взятые пользователем',
        pickParts: 'Выдать части',
        selectPersonWhoTakes: 'Выбрать человека, взявшего',
        changePersonWhoTakes: 'Изменить человека, взявшего',
        quantityToPick: 'Количество для выдачи',
        pickLocation: 'Место выдачи',
        selectPartsToPick: 'Выберите детали для выдачи',
        partSelected: 'Выбранная деталь',
        scanCodeOr: 'Сканируйте код или',
        returnPart: 'Вернуть деталь',
        myTakenParts: 'Детали, выданные мне',
        takenFromLocation: 'Взято из места',
        takenQuantity: 'Количество, взятое',
        providedUnit: 'Единица измерения',
        providedQuantity: 'Предоставленное количество',
        selectFromStorage: 'Выбрать из склада',
        selectFromMyPickedParts: 'Выбрать из моих выданных деталей',
        exportProcessInProgressMessage:
            'Процесс экспорта данных только начался, по завершении вы получите соответствующее уведомление со ссылкой на файл.',
        yourDocumentIsReady: 'Ваш документ готов',
        rotateScreen: 'Повернуть экран',
        addRule: 'Добавить правило',
        editRule: 'Изменить правило',
        summary: 'Итог',
        onlyAvailableEmployees: 'Показать только доступных сотрудников',
        calculated_unit_price: 'Конвертированная цена единицы продукции',
        calculated_quantity: 'Пересчитанное количество',
        next: 'следующий',
        maintenanceMode: 'Режим технического обслуживания',
        applicationMayBeUnavailableForFewMinutes:
            'Приложение может быть недоступно в течение нескольких минут',
        appWillRefreshIn: 'Приложение перезагрузится в',
        landscape: 'альбомная',
        portrait: 'портретная',
        orientation: 'ориентация',
        format: 'формат',
        service: 'слуги',
        leader: 'бригадиры',
        defaultView: 'вид по умолчанию',
        view: 'вид',
        oneDay: 'один день',
        object: 'объект',
        finished: 'готово',
        onlyNotFinished: 'Только не закончено',
        generate: 'генерировать',
        error: 'ошибка',
        cell: 'ячейка',
        resolutionSummary: 'Конец резюме',
        rejected_at: 'дата отказа',
        rejected_by: 'Отклонено',
        finishedByUser: 'Завершено',
        formalConfirmByUser: 'Официальное подтверждение',
        formalConfirmAt: 'Дата официального подтверждения',
        valueDeleted: '[Значение удалено]',
        hasNotPlannedIssue: 'Не имеет запланированных проблем',
        showInStorage: 'Показать в хранилище',
        changeCode: 'Изменить @:common.PIN_CODE_LABEL',
        staffProfiles: 'Профили сотрудников',
        profile: 'Профиль',
        user_bug: 'Ошибка приложения',
        user_kaizen: 'Предложение об изменении',
        changeSuggestions: 'Изменить предложения',
        showOnlyCurrentlyAvailable:
            'показывать только доступные в данный момент',
        resetPassword: 'Напомнить пароль',
        forgotPassword: 'Забыли пароль',
        resetPasswordMailSent:
            'Мы отправили ссылку для установки нового пароля на указанный адрес электронной почты',
        manyEventsThisTime: 'Многие события в это время',
        importScheduleCheckboxLabel:
            'Удалить запланированные события в выбранном месяце для сотрудников, перечисленных в файле',
        inheritedEvent: 'Унаследованное событие',
        inheritedModifiedEvent: 'Модифицированное унаследованное событие',
        equipmentId: 'Идентификатор оборудования',
        entryId: 'Идентификатор записи',
        partId: 'Идентификатор детали',
        suggestionsEmail:
            'Электронная почта, на которую будут отправлены предложения по изменению',
        meters: 'счетчики',
        scanCardToAuth:
            'Прикоснитесь картой к считывателю или введите свой @:common.PIN_CODE_LABEL доступа, чтобы подтвердить свою личность.',
        scanCard: 'Отсканируйте карту',
        cart: 'Ящик для обмена',
        emptyCardMessage:
            'Начните сканировать товары или выберите их из списка, чтобы добавить в корзину.',
        terminalMode: 'тип терминала',
        askPermittedUserToConfirmPartsExchange:
            'Попросите уполномоченное лицо подтвердить выпуск детали, отсканировав карту или введя @:common.PIN_CODE_LABEL',
        partsIssued: 'Выпущено частей',
        areYouSureAboutCancel: 'Вы уверены, что хотите отменить этот заказ?',
        areYouSureAboutRemoveFromCart:
            'Вы уверены, что хотите удалить этот товар из корзины?',
        standard: 'стандарт',
        idNumber: 'Идентификационный номер',
        takeParts: 'Взять детали',
        returnParts: 'Вернуть детали',
        quantityToReturn: 'Количество для возврата',
        partsPendingApproval: 'Детали, ожидающие утверждения',
        partsReturned: 'Возвращенные детали',
        partsAddedToPendingApproval: 'Детали добавлены в ожидающие утверждения',
        approvePartsReturn: 'Утвердить возврат деталей',
        partReturnApproved: 'Возврат деталей утвержден',
        partUsage: 'Использование деталей',
        awaitsReturn: 'Ожидает возврата',
        entrySelection: 'Выбор записи',
        correctQuantity: 'Исправить количество',
        quantityCorrection: 'Корректировка количества',
        entrySelectionAsc: 'Выбор записи (по возрастанию)',
        entrySelectionDesc: 'Выбор записи (по убыванию)',
        metersSettings: 'Настройки счетчиков',
        countableFields: 'Подсчитываемые поля',
        equipmentTypes: 'Типы оборудования',
        fieldName: 'Название поля',
        countableField: 'Подсчитываемое поле',
        partsStatusTaken: 'Взятые детали',
        rejectPartsReturn: 'Отклонить возврат деталей',
        areYouSureAboutRejectPartsReturn:
            'Вы уверены, что хотите отклонить возврат деталей?',
        partReturnRejected: 'Возврат деталей отклонен',
        counterFields: 'Поля счетчиков',
        counterName: 'Название счетчика',
        counterFieldName: 'Название поля счетчика',
        counterId: 'Идентификатор счетчика',
        counterUUID: 'UUID счетчика',
        counterPickerPlaceholder: 'Выберите счетчик',
        pickFromPartsList: 'Выбрать из списка деталей',
        changeLocation: 'Изменить местоположение',
        isPartStorageRoot: 'корневым хранилищем деталей',
        areYouSureAboutArchiveIssue:
            'Вы уверены, что хотите архивировать эту проблему?',
        ganttChart: 'Диаграмма Ганта',
        table: 'Таблица',
        fittingEquipment: 'Оборудование для установки',
        notAssigned: 'Не назначено',
        counters: 'Счетчики',
        machineHourlyCostNotProvided: 'Почасовая стоимость машины не указана',
        eventTypesMightBeDefinedHere: 'Здесь можно определить типы событий',
        toolsTakenByUser: 'Инструменты, взятые пользователем',
        predictedReturnDate: 'Предполагаемая дата возврата',
        estimatedTimeOfUsingToolInHours:
            'Предполагаемое время использования инструмента в часах',
        showOnlyAvailable: 'Показать только доступные',
        toolTakeLog: 'Журнал взятия инструмента',
        takeDate: 'Дата взятия',
        returnDate: 'Дата возврата',
        personWhoAcceptReturn: 'Человек, принявший возврат',
        statisticStrategy: 'Стратегия доступности',
        EquipmentPlannedStrategy: 'По плану работы',
        EquipmentConstantAvailabilityStrategy: 'Всегда доступен',
        actionAfterIssueFinish: 'Действие после завершения выпуска',
        enabled: 'Включено',
        categoryOfNewIssue: 'Категория новой проблемы',
        workNotStarted: 'Работа не начата',
        colorInherited: 'Цвет унаследован',
        abcClassification: 'Классификация ABC',
        xyzClassification: 'Классификация XYZ',
        classification: 'Классификация',
        filters: 'Фильтры',
        wipeFilters: 'Сбросить фильтры',
        takePartsFromUser: 'Взять детали у пользователя',
        selectPersonWhoReturns: 'Выберите человека, который вернет детали',
        changePersonWhoReturns: 'Изменить человека, который вернет детали',
        returnLocation: 'Местоположение возврата',
        returnQuantity: 'Количество возврата',
        globalSettings: 'Глобальные настройки',
        delimiter: 'Разделитель',
        fieldRules: 'Правила поля',
        fieldValue: 'Значение поля',
        user_email_regex:
            'Регулярное выражение для электронной почты пользователя',
        barcodeLength: 'Длина штрих-кода',
        partLine: 'Линия деталей',
        partFamily: 'Семейство деталей',
        partBatch: 'Партия деталей',
        testValueValid: 'Тестовое значение действительно',
        testValueNotValid: 'Тестовое значение недействительно',
        canBePicked: 'Может быть выбран',
        issue_create_photos: 'Добавление фотографий в новый выпуск',
        issue_create_documents: 'Добавление документов в новый выпуск',
        propagateValues: 'Распространение значения',
        archived: 'в архиве',
        escalations_tab: 'Эскалации',
        minute: 'Минута',
        days: 'Дни',
        hours: 'Часы',
        minutes: 'Минуты',
        warnDays: 'Оповещение до истечения срока действия (дней)',
        licenseType: 'Тип лицензии',
        licenseGroup: 'Группа лицензий',
        attachments: 'Вложения',
        attachment: 'Вложение',
        revisionChanges: 'Внесенные изменения',
        document: 'документ',
        noInstructionSelected: 'Инструкция не выбрана',
        instructionGroups: 'Группы инструкций',
        instructionGroup: 'Инструктаж группы',
        addOption: 'Добавить вариант',
        ordinal: 'Порядковый номер',
        parameterName: 'Имя параметра',
        fontColor: 'Цвет шрифта',
        backgroundColor: 'Фоновый цвет',
        columns: 'Число столбцов',
        colspan: 'Ширина (столбцы)',
        products: 'Продукты',
        controlsTemplates: 'Шаблоны управления',
        controlType: 'Тип управления',
        controls: 'Инспекции',
        controlTemplate: 'Шаблон управления',
        product: 'Продукт',
        checklistsAttachedToControlTemplate:
            'Контрольные списки, назначенные для проверок',
        removeChecklistFromControlTitle:
            'Вы уверены, что хотите удалить этот список из-под вашего контроля?',
        attachChecklistToControl: 'Назначьте список для управления',
        pickedItem: 'Выбранный элемент',
        pick: 'Выбирать',
        fillControl: 'Завершить проверку',
        back: 'возвращаться',
        control: 'контроль',
        duplicate: 'Дублировать',
        isStorageRoot: 'Основное расположение склада',
        manageStorageAccess: 'Управляйте доступом к складам',
        manageVisibleColumns: 'Настройка видимости столбца',
        columnName: 'Имя столбца',
        visible: 'Видимый',
        entriesLocation: 'расположение записей',
        details: 'Подробности',
        otherData: 'Другие данные',
        moveAllEntriesFromCurrentLocation:
            'Переместить все записи из этого места',
        searchInCurrentLocation: 'Искать в вашем текущем местоположении',
        transition: 'Переход',
        notification: 'Уведомление',
        emails: 'Адрес электронной почты',
        phones: 'Телефонные номера',
        addAction: 'Добавить действие',
        message: 'Сообщение',
        application: 'Приложение',
        always: 'Всегда',
        sms: 'SMS',
        loginTo: 'Авторизоваться',
        appName: 'Signalo TPM',
        index: 'Индекс',
        showCodeOwnerNameDuringParing:
            'Показывать имя владельца @:common.PIN_CODE_LABEL при сопряжении с картой/тегом',
        showUsernameInCart: 'Показывать имя пользователя в корзине',
        sampleButtonPlaceholder: 'Образец кнопки',
        preferences: 'Предпочтения',
        buttonsSize: 'Размер кнопки',
        erase: 'стереть',
        selectDictionaryValue: 'выбрать значение словаря',
        conditions: 'Условия',
        partsCartTerminalRequireApproveOnPartsReturn:
            'Требовать подтверждения или отклонения при возврате загруженных частей',
        issueCreated: 'Билет создан.',
        allowedIssuesCategories: 'Разрешенные категории проблем',
        hiddenFields: 'Скрытые поля',
        defaultValues: 'Значения по умолчанию',
        paddingBottom: 'Пустое место внизу',
        allVisibleFieldsRequired: 'Требовать заполнения всех видимых полей',
        url: 'URL-адрес',
        kind: 'Добрый',
        forkfleetConfigId: 'Конфигурация Forkfleet',
        create: 'создавать',
        terminate: 'прекратить',
        parent: 'Родитель',
        phoneNumbers: 'Телефонные номера',
        storageDefaults: 'Значения хранилища по умолчанию',
        clientSignatureEnabled: 'Подпись лица, собирающего',
        warehousemanSignatureEnabled: 'Подпись кладовщика',
        storage_client_signature_enabled:
            'Подпись лица, принимающего товары со склада',
        storage_client_signature_required:
            'Требовать подпись лица, принимающего товары со склада',
        storage_warehouseman_signature_enabled:
            'Подпись лица, отпускающего товары со склада',
        storage_warehouseman_signature_required:
            'Требовать подпись лица, сдающего товары со склада.',
        clientReturnSignatureEnabled: 'Подпись возвращающегося лица',
        warehousemanReturnSignatureEnabled:
            'Подпись лица, принимающего возврат',
        storage_client_return_signature_enabled:
            'Подпись лица, возвращающего товары',
        storage_client_return_signature_required:
            'Требовать подпись от лица, возвращающего товары',
        storage_warehouseman_return_signature_enabled:
            'Подпись лица, принимающего возврат товара',
        storage_warehouseman_return_signature_required:
            'Требуйте подписи лица, принимающего возврат товара.',
        channels: 'каналы',
        manageState: 'Управление состоянием',
        assignWorker: 'Назначить сотрудника',
        mergeEntriesOfTheSameLocation:
            'Объединить записи из одного и того же места',
        permission: 'Разрешение',
        areYouSureAboutMergeAllEntriesOfThisLocation:
            'Вы уверены, что хотите объединить все записи в этом месте?',
        manageFilters: 'Управление фильтрами',
        responsible_assign_creator_when_no_rule:
            '[Отчеты] – назначить создателя ответственным, если правило не соблюдается.',
        recreateIssue: 'Восстановить билет',
        addNewDictionary: 'Добавить новый словарь',
        label: 'Этикетка',
        issuesWithRelatedCategories: 'Включить подкатегории',
        issuesLimit: 'Лимит вопросов',
        issuesLimits: 'Проблемы – ограничения',
        issueDefaults: 'Отчеты – значения по умолчанию',
        flatCategoryPickerLabel: 'Плоская структура категорий',
        statementsTemplates: 'Шаблоны заявлений',
        statementTemplate: 'Образец заявления',
        content: 'Содержание',
        readonly: 'Только чтение',
        defaultValue: 'Значение по умолчанию',
        fillParametersToChangeState:
            'Заполните значения параметров ниже, чтобы изменить статус',
        formatPattern: 'шаблон формата',
        countableIncFields: 'Настройка нумерации документов',
        mainShift: 'Основная смена',
        singleEvent: 'Однократная',
        seriesOfEvents: 'Серия записей',
        omitWeekends: 'Избегайте выходных',
        allEntriesSaved: 'Все записи добавлены',
        surveyResults: 'Результаты опроса',
        qrActionsSettings: 'Настройки действия QR-кода',
        pendingStatements: 'Декларации для подписания',
        statementsResults: 'Заявления',
        statements: 'Заявления',
        statement: 'Заявление',
        userCanChangeContent: 'Пользователь может изменить содержимое',
        statementsEvents: 'Конфигурация событий — операторы',
        helpdesk: 'служба поддержки',
        terminalId: 'Идентификатор терминала',
        operatorId: 'Идентификатор оператора',
        newConversation: 'Новый поток',
        formula: 'Формула',
        fill: 'Полный',
        webMessage: 'Веб-сообщение',
        noResponsibleUser: 'Нет ответственного пользователя',
        filledAtDate: 'Дата завершения',
        filledBy: 'Завершено',
        parts_quantity_alert_rule:
            '[Количество деталей и материалов] - Внимание',
        employeeNumber: 'Количество работников',
        groupsAssignedToCategory: 'Группы, присвоенные категориям',
        number: 'Число',
        customers: 'Клиенты',
        customer: 'Клиент',
        projects: 'Проекты',
        project: 'Проект',
        report: 'Отчет',
        reportGenerationInProgress: 'Отчет генерируется',
        storageStatementGroup: 'Группа складских деклараций',
        simplifiedHandling: 'Упрощенная обработка уведомлений',
        selectWorkArea: 'Выберите свое рабочее место',
        workAreaSelection: 'Выбор рабочей зоны',
        access: 'Доступ',
        restrictAccess: 'Ограничить доступ',
        anchor: 'Закрепление',
        issueCreator: 'Лицо, создающее отчет',
        hideSideMenu: 'Скрыть боковое меню',
        appendGroups: 'Группы для добавления',
        revokeGroups: 'Группы для удаления',
        override: 'Перезаписать',
        restrictGroupAccess: 'Ограничить доступ к группам',
        flag: 'Флаг',
        flags: 'Флаги',
        manageFlags: 'Управление флагами',
        icon: 'Иконка',
        appendFlags: 'Флаги для добавления',
        revokeFlags: 'Флаги, которые нужно удалить',
        noFlagsConfigured: 'Флаги не определены',
        checkIsTerminal: 'Проверка терминала',
        noConditionsDefined: 'Условия не определены',
        checkIsResponsible: 'Проверка ответственности',
        createdOn: 'Сообщено из',
        replaceTimespan: 'Срок годности с момента выпуска',
        months: 'Месяцы',
        handoversTemplates: 'Шаблоны передачи',
        handoverTemplate: 'Шаблон передачи',
        employeeFunction: 'Функция сотрудника',
        takeHandover: 'Принять передачу',
        handovers: 'Передача',
        expiringHandovers: 'Товары, срок годности которых подходит к концу',
        years: 'Годы',
        taken_parts_expiry_warning:
            'Предупреждение перед истечением срока действия загруженных элементов',
        andonView: 'Вид на Андон',
        nfc: 'NFC',
        rfid: 'RFID',
        other: 'другой',
        tags: 'карты',
        tag: 'карта',
        userPinCode: '@:common.PIN_CODE_LABEL пользователя карты',
        emailConfirmation: 'Подтверждение адреса электронной почты',
        from: 'От',
        to: 'К',
        handoversHistory: 'История передач',
        partUsagesHistory: 'История использования деталей',
        signaturePinTagConfirmationText1:
            'Подписаться с помощью @:common.PIN_CODE_LABEL',
        signaturePinTagConfirmationText2: 'или отсканируйте свою карту.',
        signedWithPinCode: 'Подписано @:common.PIN_CODE_LABEL',
        signedWithTAG: 'Подписано картой/биркой',
        test_mode: 'Режим тестирования',
        confirmWorkFinish: 'Подтвердите окончание работы',
        workStarted: 'Начало работы',
        workFinished: 'Окончание работы',
        workTimeRegister: 'Журнал учета рабочего времени',
        measureTypes: 'Виды измерений',
        measureType: 'Тип измерения',
        measurable: 'Измеримый показатель',
        measurables: 'Измеримые показатели',
        measurements: 'Измерение',
        measurement: 'Измерение',
        measuredAt: 'Дата измерения',
        currentVersion: 'Текущая версия',
        employee: 'Сотрудник',
        pin_code_label: 'ПИН-имя',
        orders: 'Заказы',
        productionPlan: 'План производства',
        productionPlans: 'Планы производства',
        taktTime: 'время такта',
        target: 'Цель',
        seconds: 'Секунды',
        inheritValuesFromTenantSettings:
            'Наследовать значения по умолчанию из глобальных настроек.',
        matrices: 'Матрицы',
        manageMatrice: 'Управление матрицей',
        deletionOfMatrixColumnWarnMessage:
            'Удаление столбца приводит к удалению значений.',
        removeColumn: 'Удалить столбец',
        editColumn: 'Изменить столбец',
        column: 'Столбец',
        row: 'Ряд',
        evaluated: 'оценивается',
        of: 'из',
        auto_pin_generation:
            '@:common.PIN_CODE_LABEL - Автоматическая генерация',
        or: 'или',
        rename: 'Переименовать',
        check_only_on_transition: 'Проверяйте только при изменении статуса',
        license: 'Полномочия',
        confirmWorkStart: 'Подтвердить начало работы',
        workStartAt: 'Вы начинаете работу в',
        workFinishAt: 'Ты заканчиваешь свою работу о',
        workStartedAt: 'Начиная',
        workTimeMessage: 'Ты переутомился',
        haveANiceDay: 'Хорошего дня!',
        farewellText: 'Текст на последнем экране',
        namedayLabel: 'Они празднуют свои именины',
        sportEventNearby: 'Следующее спортивное событие',
        autoTranslate: 'Автоматический перевод',
        auto_translation: 'Автоматический перевод',
        awaitingAutoTranslation: 'Ожидание автоматического перевода',
        inheritAllDefaultValues:
            'Наследовать все значения по умолчанию из глобальных настроек',
        escalations: 'Эскалация',
        escalation: 'Эскалация',
        notTaken: 'Не начался',
        notFinished: 'Незаконченный',
        issueLastChange: 'Последнее изменение',
        mode: 'Режим',
        repeatLastEscalation: 'Повторить последнее значение эскалации',
        manageActions: 'Управляйте своими действиями',
        productionLines: 'Производственные линии',
        productionTerminal: 'Производственный терминал',
        currently: 'В настоящее время',
        currentPlan: 'Текущий план',
        currentlyInProduction: 'Производится в настоящее время',
        setProduct: 'Установить продукт',
        second: 'Секунды',
        work: 'Работа',
        chartConfiguration: 'Конфигурация диаграммы',
        revisionsToConsider: 'Изменения на рассмотрение',
        allInstructions: 'Все инструкции',
        decider: 'Определитель',
        considerRevision: 'Рассмотрим доработку',
        and: 'и',
        acceptInstruction: 'Примите инструкции',
        accept: 'принимать',
        instructionsToAccept: 'Инструкцию к прочтению',
        open: 'Открыть',
        assignedAt: 'Дата назначения',
        confirmationAt: 'Дата подтверждения',
        mainInstruction: 'Инструкция по умолчанию',
        setAsDefault: 'Установить по умолчанию',
        instructionsFlows: 'Инструкции - потоки',
        newRevision: 'Новая редакция',
        playlist: 'Список видео',
        selectVideo: 'Выберите фильм',
        selectVideos: 'Выберите фильмы',
        uploadNewVideo: 'Загрузите новое видео',
        upload_date: 'Дата Добавлена',
        thumbnail: 'миниатюра',
        duration: 'Продолжительность',
        uploadFromDrive: 'Загрузить с диска',
        selectAssets: 'Выберите материалы',
        assets: 'Материалы',
        video: 'Фильм',
        doYouWantToAddNewMarker: 'Хотите добавить сюда новый тег?',
        areYouSureAboutRemoveMarker: 'Вы уверены, что хотите удалить этот тег?',
        plants: 'Фабричные заводы',
        plant: 'Фабрика',
        buildings: 'Здания',
        building: 'Здание',
        floors: 'Этажи',
        floor: 'Пол',
        map: 'карта',
        areYouSureAboutMoveMarker:
            'Вы уверены, что хотите переместить этот маркер?',
        marker: 'Маркер',
        addNewMarkerTooltipMessage:
            'Чтобы добавить новый маркер, щелкните правой кнопкой мыши на карте.',
        skillmatrix: 'Skillmatrix',
        indirectProcess: 'Нелинейные навыки',
        skillLevelConfig: 'Уровни навыков',
        indirectSkillGroups: 'Нелинейные группы навыков',
        approved: 'Подтвержденный',
        approvedBy: 'утверждающий',
        skillLevel: 'Уровень квалификации',
        requiredSkillLevel: 'Требуемый уровень навыков',
        step: 'Шаг',
        image: 'Фото',
        skills: 'Навыки',
        visits: 'Посещения',
        responsiblePerson: 'Ответственное лицо',
        company: 'Компания',
        contactData: 'Контактные данные',
        visit: 'Посетить',
        inProgress: 'В процессе',
        gatehouse: 'Сторожка',
        confirmEnter: 'Подтвердить ввод',
        confirmExit: 'Подтвердить выход',
        enter: 'Вход',
        exit: 'Выход',
        entryGuard: 'Человек, впускающий',
        exitGuard: 'Человек выпускает',
        loginAsThisUser: 'Войдите как этот пользователь',
        minBrowserVersion: 'Требуется минимальная версия браузера',
        approve: 'Подтверждать',
        cancelApproval: 'Отменить фиксацию',
        workPermit: 'Разрешение на работу',
        workPermits: 'Разрешения на работу',
        visit_summary: 'Итог визита',
        createVisit: 'Создать посещение на основе разрешения',
        employeesAtThePlant: 'Рабочие на заводе',
        purpose: 'Цель',
        allowedAreas: 'Разрешенные зоны',
        switchEmployees: 'Заменить сотрудника',
        localIssues: 'Локальная отчетность',
        cmmsCategoriesSync: 'Синхронизация категорий CMMS',
        terminalsGroups: 'Группы клемм',
        isTree: 'Дерево',
        isReporter: 'Репортер',
        isAnchored: 'Закреплен',
        closable: 'Закрытие билетов',
        areYouSureAboutCloseIssue: 'Вы уверены, что хотите закрыть тикет?',
        simplifiedIssuesRefreshLabel: 'Время обновления списка, секунды',
        reloadApp: 'Перезагрузить',
        hideWorkAreasButton: 'Скрыть кнопку выбора рабочей области',
        hideClassicViewButton: 'Скрыть кнопку классического вида',
        hideAddIssueButton: 'Скрыть кнопку добавления новой проблемы',
        filesManager: 'Менеджер файлов',
        addFolder: 'Добавить папку',
        upload: 'Загрузить',
        noFolders: 'Нет папок',
        noFiles: 'Нет файлов',
        folders: 'Папки',
        folder: 'Папка',
        files: 'Файлы',
        file: 'Файл',
        removeFolderFilesQuestion: 'Что делать с файлами в этой папке?',
        targetFolder: 'Целевая папка',
        addInstruction: 'Добавить инструкцию',
        goToFolder: 'Перейти к папке',
        defaultProdLine: 'Линия производства по умолчанию',
        pin_code_field_type: 'Тип PIN-кода',
        instructionsAccess: 'Разрешения',
        noSections: 'Без секций',
        requireReadingInstruction: 'Требовать ознакомление с инструкцией',
        hide_parameter_types: 'Не отображать типы параметров',
        unknownVersion: 'Неизвестно',
        displayInstructionEveryTime: 'Отображать инструкцию каждый раз',
        requireSigningInstructionEveryTime:
            'Требовать подпись инструкции каждый раз',
        signedInstructions: 'Подписанные инструкции',
        signedAt: 'Дата подписи',
        signatures: 'Подписи',
        signature: 'Подпись',
        systemGroup: 'Системная группа',
        pickable: 'Выбираемый',
        leaf: 'Выбранный элемент',
        path: 'Путь',
        displayMode: 'Режим отображения',
        chart: 'диаграмма',
        preSignedStatement: 'Декларация, подписанная на бумаге (полная)',
        myOpenIssues: 'Мои открытые проблемы',
        myAssignedIssues: 'Проблемы, назначенные мне',
        displayParametersOnMainScreen: 'Просмотр параметров на главном экране',
        lockPin: 'Блокировка изменения PIN',
        pinLocked: 'PIN заблокирован',
        sortChange: 'Порядок отображения',
        asc: 'По возрастанию',
        desc: 'По убыванию',
        alphanumeric: 'Алфавитно-цифровой',
        numeric: 'Числовой',
        modificationDate: 'Дата изменения',
        fromNewest: 'С новейших',
        fromOldest: 'С самых старых',
        negation: 'Отрицание',
        sortOrderService: 'Сервис',
        newIssueIgnoreFilters:
            'Игнорировать примененные фильтры при создании новой заявки',
        refreshInterval: 'Время обновления списка',
        multiline: 'Многострочный',
        expanded: 'Расширенный',
    },
    placeholders: {
        part: 'Выберите деталь',
        nameSurname: 'Имя и фамилия',
        phone: 'Номер телефона',
        password: 'Пароль',
        password_confirmation: 'Подтвердите пароль',
        email: 'Электронный адрес',
    },
    auth: {
        notYourAccountQuestion: 'Не ваш аккаунт?',
    },
    signup: {
        registered: 'Успешно!',
        emailConfirmed: 'Электронная почта была подтверждена.',
        registered_info:
            'Попросите своего руководителя активировать ваш аккаунт',
        confirmEmailAddress:
            'Ваша учетная запись создана. Нажмите ссылку в электронном письме, чтобы подтвердить свой адрес.',
        tokenNotFoundError:
            'Адрес электронной почты уже подтвержден или ссылка для подтверждения недействительна.',
    },
    paramTypes: {
        text: 'Текст',
        note: 'Заметка',
        integer: 'Целое число',
        decimal: 'Десятичное число',
        date: 'Дата',
        time: 'Время',
        datetime: 'Дата и время',
        color: 'Цвет',
        icon: 'Иконка',
        boolean: 'Нет / Да',
        signature: 'подпись',
    },
    tableHeaders: {
        id: 'ID',
        name: 'Имя',
        path: 'Путь к файлу',
        category: 'Категория',
        equipment: 'Оборудование',
        criticality: 'Критичность',
        status: 'Статус',
        created: 'Создано',
        createdBy: 'Создано',
        fp: 'FP',
        type: 'Тип',
        designation: 'Обозначение',
        symbol: 'Символ',
        serialNumber: 'Серийный номер',
        registryNumber: 'Регистрационный номер',
        udtNumber: 'UDT номер',
        manufacturedYear: 'Год производства',
        actions: 'Действия',
        title: 'Название',
        data: 'Дата',
        archived: 'В архиве',
        manufacturerSymbol: 'Символ производителя',
        min: 'Мин',
        opt: 'Опт',
        quantity: 'Количество',
        priority: 'Приоритет',
        price: 'Цена',
        kind: 'Вид',
        description: 'Описание',
        default: 'По умолчанию',
        warning: 'Предупреждение',
        problem: 'Проблема',
        fixing: 'Решение',
        changeOrder: 'Изменить заказ',
        required: 'Обязательно',
        icon: 'Иконка',
        color: 'Цвет',
        subCategories: 'Подкатегории',
        barCode: 'Штрих-код',
        subLocations: 'Подлокации',
        categories: 'Категории',
        image: 'Изображение',
        nameSurname: 'Имя и фамилия',
        email: 'Электронная почта',
        phone: 'Номер телефона',
        function: 'Функция',
        department: 'Отдел',
        accountStatus: 'Статус аккаунта',
        shift: 'Смена',
        licenseNumber: 'Номер лицензии',
        issuedDate: 'Дата выдачи',
        expiresDate: 'Дата истечения',
        entries: 'Записи',
        entryNumber: 'Номер записи',
        buyPrice: 'Цена покупки',
        currentLocationQuantity: 'Количество в текущем местоположении',
        group: 'Группа',
        string: 'Текст',
        integer: 'Целое число',
        toolsGroup: 'Группа инструментов',
        manufacturer: 'Производитель',
        shortName: 'Короткое название',
        locationName: 'Название местоположения',
        subLocationsQuantity: 'Количество подлокаций',
        isWorkingOnIssue: 'Работает над проблемой',
        openIssues: 'Открытые проблемы',
        notificationDate: 'Дата уведомления',
        titleAndDescription: 'Заголовок и описание',
        read: 'Прочитано',
        created_at: 'Создано',
        received_at: 'Получено',
        started_at: 'Принято',
        arrived_at: 'Прибыло',
        finished_at: 'Закончено',
        country: 'Страна',
        city: 'Город',
        progress: 'Прогресс',
        deleted_at: 'Удалить дату',
        about_issue: 'Применяется к уведомлению.',
        action: 'Делиться',
    },
    searchConfig: {
        id: 'ID',
        name: 'Имя',
        shortName: 'Короткое имя',
        country: 'Страна',
        email: 'Электронная почта',
        group: 'Группа',
        planned: 'Запланировано',
        category: 'Категория',
        object: 'Объект',
        criticality: 'Критичность',
        fp: 'FP',
        type: 'Тип',
        designation: 'Обозначение',
        symbol: 'Символ',
        serialNumber: 'Серийный номер',
        registryNumber: 'Регистрационный номер',
        udtNumber: 'Номер UDT',
        manufacturedYear: 'Год производства',
        manufacturerSymbol: 'Символ производителя',
        priority: 'Приоритет',
        quantity: 'Количество',
        buyPrice: 'Цена покупки',
        kind: 'Тип',
        toolsGroup: 'Группа инструментов',
        function: 'Функция',
        department: 'Отдел',
        status: 'Статус',
        nameSurname: 'Имя и фамилия',
        phone: 'Телефон',
        barCode: 'Штрих-код',
        color: 'Цвет',
        icon: 'Иконка',
        machine: 'Машина',
        tool: 'Инструмент',
        urgentAndOutdated: 'Срочный и устаревший',
        urgent: 'Срочный',
        expired: 'Истекший',
        manufacturer: 'Производитель',
    },
    errors: {
        partMustHaveEntriesToCorrectQuantity:
            'Деталь должна иметь записи для корректировки количества.',
        error: 'Произошла ошибка',
        wrongEmail: 'Недопустимый адрес электронной почты',
        wrongPasswordLength: 'Длина пароля должна быть не менее 8 символов.',
        wrongCodeLength:
            'Длина @:common.PIN_CODE_LABEL должна быть не менее 5 символов.',
        passwordsNotMatch: 'Пароли не совпадают',
        codesNotMatch: '@:common.PIN_CODE_LABEL не совпадают',
        codeAlreadyInUse: '@:common.PIN_CODE_LABEL уже используется',
        expiresDateLowerThanIssued:
            'Дата истечения не может быть меньше, чем дата выдачи.',
        cannotMoveToTheSameLocation:
            'Нельзя переместить в то же местоположение.',
        locationRequired: 'Требуется местоположение.',
        putQuantity: 'Укажите количество для разделения.',
        valueGreaterThanQuantity: 'Значение больше, чем общее количество.',
        wholeCannotBeDistinguished: 'Целое не может быть разделено.',
        yearMustBeGreaterThan: 'Год должен быть больше ',
        passwordConfirmationNotMatch: 'Пароли не совпадают',
        mustBeInteger: 'Значение должно быть целым числом',
        valueTooLong: 'Значение слишком длинное',
        wrongPhoneNumber: 'Неверный номер телефона',
        phoneNumberAlreadyTaken: 'Номер телефона уже занят',
        emailAlreadyTaken: 'Email уже занят',
        notFound: 'Не найдено',
        sessionExpired: 'Сессия истекла',
        tenantNotFound:
            'Указанная конфигурация не существует. Проверьте адрес сайта и повторите попытку.',
        selectEquipmentTypeToConfigureParameters:
            'Выберите тип оборудования для настройки параметров.',
        noParametersToConfigure:
            'Выбранный тип оборудования не имеет параметров для настройки.',
        terminalAlreadyRegistered: 'Терминал уже зарегистрирован.',
        invalidPin: 'Недействительный @:common.PIN_CODE_LABEL',
        eventCollision:
            'Время события, которое вы хотите добавить, пересекается с временем другого события.',
        mustStartWithSlash: 'Должен начинаться с /',
        mustBeBetween: 'Значение должно быть между:',
        minLength: 'Минимальная длина: ',
        maxLength: 'Максимальная длина: ',
        invalidInputForCodeType: 'Недопустимый ввод для выбранного типа кода',
        invalidCodeOrNoPermission:
            '@:common.PIN_CODE_LABEL недействителен или у вас недостаточно полномочий для выполнения этого действия.',
        endDateLowerThanStart:
            'Дата окончания не может быть меньше даты начала',
        iconAlreadyTaken: 'Иконка уже занята',
        notEnoughQuantityInStorage: 'Недостаточное количество в хранилище.',
        valueTooLow: 'Значение слишком низкое',
        valueTooHigh: 'Значение слишком высокое',
        maxAvailableQuantity: 'Максимальное количество',
        someFilesRejectedDueToSize:
            'Некоторые файлы были отклонены из-за размера. Максимальный размер одного файла:',
        cameraDeviceNotFound: 'Yстройство камеры не найдено',
        cameraPermissionDenied:
            'Доступ к камере устройства был заблокирован в настройках сайта, сбросьте настройки сайта и разрешите использование камеры.',
        passwordResetLinkExpired:
            'Срок действия ссылки для сброса пароля истек',
        noInternetConnection: 'Нет соединения с интернетом',
        minimum: 'Минимум',
        mustBeGreaterThan: 'Должно быть больше',
        noEventTypesDefined: 'Не определено ни одного типа события',
        regexPatternDelimiterError:
            'Разделитель должен быть опущен с помощью символа \\ n например: \\/',
        emailNotMatchPatternDefiniedInTenant:
            'Электронная почта не соответствует шаблону, определенному в конфигурации',
        max: 'Максимальное значение',
        min: 'минимальное значение',
        allLanguagesRequired: 'Завершите значение на всех языках',
        fileTooBigToOpenWillBeDownloaded:
            'Файл слишком большой для открытия, файл будет загружен на ваше устройство.',
        surveyNotFound: 'Опрос не найден',
        actionNotEnabled: 'Код неактивен',
        loginRequired: 'Требуется логин',
        noPermissionForSelectedFolder:
            'У вас нет разрешения на просмотр содержимого этого каталога.',
        noFoldersAssignedForTerminal:
            'Нет назначенных каталогов с инструкциями',
        noChecklistsAssignedToControl:
            'Для выбранного элемента управления не назначен контрольный список.',
        maximum: 'Максимум',
        versionErrorMessage:
            'Ваши недавние изменения не были сохранены, поскольку в это же время изменения внес кто-то другой. Обновите страницу и повторите попытку.',
        duplicateError: 'Это значение уже существует',
        fillAllRequiredParameters: 'Заполните все необходимые параметры.',
        wrongValue: 'Неверное значение',
        noParametersDefined: 'Параметры не определены',
        noEnoughParts: 'Недостаточно предметов',
        pinNotBelongsTo: 'Этот @:common.PIN_CODE_LABEL не принадлежит',
        tagNotBelongsTo: 'Эта карта/тег не принадлежит',
        noDictionaryEntries: 'Выбранный словарь не содержит записей',
        noConnectionWithServer: 'Нет соединения с сервером',
    },
    navDrawer: {
        dashboard: 'Панель управления',
        issues: 'Проблемы',
        timetable: 'Расписание',
        machines: 'Машины',
        tools: 'Инструменты',
        parts: 'Части',
        storage: 'Склад',
        staff: 'Персонал',
        configuration: 'Конфигурация',
        notes: 'Заметки',
        notifications: 'Уведомления',
        profile: 'Профиль',
        supervision: 'Наблюдение',
    },
    barcodeScanner: {
        barCode: 'Штрих-код',
        id: 'ID',
    },
    categoryPicker: {
        categoryPlaceholder: 'Категория',
        selectCategory: 'Выберите категорию',
        noSubcategories: 'Нет подкатегорий',
    },
    companies: {
        addCompany: 'Добавить компанию',
        editCompany: 'Редактировать компанию',
        fullName: 'Полное название',
        shortName: 'Краткое название',
        group: 'Группа',
        country: 'Страна',
        city: 'Город',
        street: 'Улица',
        postCode: 'Почтовый код',
        phone: 'Телефон',
        email: 'Электронная почта',
        website: 'Веб-сайт',
        NIP: 'NIP',
        REGON: 'Национальный реестр деловых субъектов',
        active: 'Активный',
        nameRequired: 'Требуется название',
        shortNameRequired: 'Требуется название',
    },
    issues: {
        tookAt: 'Принято в',
        changeHistory: 'История изменений',
        load: 'Загрузить',
        date: 'Дата',
        createdBy: 'Создано',
        action: 'Действие',
        data: 'Данные',
        created: 'Создано',
        updated: 'Обновлено',
        deleted: 'Удалено',
        restored: 'Восстановлено',
        issues: 'Проблемы',
        issue: 'Проблема',
        by: 'от',
        name: 'Имя',
        applicantsDescription: 'Описание заявителя',
        accidentDescription: 'Описание аварии',
        jobDescription: 'Описание работы',
        jobSummary: 'Сводка работы',
        category: 'Категория',
        status: 'Статус',
        noFlow: '[Нет потока]',
        equipment: 'Оборудование',
        criticality: 'Критичность',
        hour: 'Час',
        formalConfirmation: 'Официальное подтверждение',
        formalConfirmationShortcut: 'ОП',
        formalConfirmationRequired: 'Требуется ОП',
        parameter: 'Параметр',
        value: 'Значение',
        addIssue: 'Добавить проблему',
        expectedCompletionDate: 'Ожидаемая дата завершения',
        issueDescription: 'Описание проблемы',
        partsUsage: 'Использование деталей',
        assignedEmployees: 'Назначенные сотрудники',
        requiredHelp: 'Требуемая помощь',
        returnParts: 'Вернуть деталь',
        confirmUnassignEmployeeWhoTakenIssue:
            'Этот сотрудник взял выбранную проблему. Вы хотите удалить его?',
        responsibleUser: 'Ответственный пользователь',
        responsibleRules: 'Ответственные пользователи - правила',
        received_decision_at: 'Дата уведомления о принятом решении',
        groupRules: 'Группы - правила',
        watchersRules: 'Наблюдатели - правила',
    },
    machines: {
        machines: 'Машины',
        xIssuesNotPlanned: ' (не запланировано)',
        noIssues: 'Нет проблем',
    },
    maintenance: {
        maintenance: 'Техническое обслуживание',
    },
    notes: {
        notes: 'Заметки',
        note: 'Заметка',
        title: 'Название',
        archived: 'Архивировано',
    },
    parts: {
        parts: 'Детали',
        warehouseOutgoings: 'Исходящие из склада',
        fittingEquipmentTypes: 'Подходящие типы оборудования',
        goToEquipmentType: 'Перейти к типу оборудования',
        selectPart: 'Выберите деталь',
        usageReason: 'Причина',
        usageOtherReason: 'Другое',
        replacements: 'Замены',
        partEntry: 'Вход детали',
        goToPart: 'Перейти к детали',
    },
    costCenter: {
        costCenter: 'Центр расходов',
    },
    equipmentTypes: {
        equipment: 'Оборудование',
        newEquipmentType: 'Новый тип оборудования',
        fittingParts: 'Подходящие детали',
        goToParts: 'Перейти к детали',
    },
    machinesAndToolsDefinitions: {
        machinesAndToolsDefinitions: 'Определения машин и инструментов',
    },
    flows: {
        flow: 'Поток',
        states: 'Состояния',
        transitions: 'Переходы',
        state: 'Состояние',
        default: 'По умолчанию',
        warning: 'Предупреждение',
        problem: 'Проблема',
        fixing: 'Исправление',
        finished: 'Законченный',
        access: {
            create: 'Создание',
            read: 'Чтение',
            update: 'Модификация',
            delete: 'Удаление',
            read_unassigned: 'Чтение неназначенных',
            assign: 'Назначение',
            unassign: 'Удаление задания',
            close: 'Закрытие',
        },
        instructions: {
            default: 'По умолчанию',
            fixing: 'Рассмотрение',
            finished: 'Законченный',
        },
        accessLabels: {
            terminal: 'Терминал, сообщивший о проблеме',
            creator: 'Пользователь, сообщивший о проблеме',
            responsible: 'Пользователь, ответственный за проблему',
            group: 'Группа пользователей, ответственная за проблему',
            anchor: 'Терминал, к которому прикреплен отчет',
            watchers: 'Пользователи, наблюдающие за проблемой',
        },
        sync_returned_rejected: 'Синхронизация - отклонено',
        sync_returned_finished: 'Синхронизация - завершено',
        current: 'текущий',
    },
    parameters: {
        categoryParameter: 'Параметр категории',
        equipmentTypeParameter: 'Параметр типа оборудования',
    },
    staff: {
        staff: 'Сотрудники',
        editUserAccess: 'Изменить доступ пользователя',
        permissionsUpdated: 'Права доступа обновлены',
        passwordUpdated: 'Пароль обновлен',
        codeUpdated: '@:common.PIN_CODE_LABEL обновлен',
        changePasswordAndCode: 'Изменить пароль и @:common.PIN_CODE_LABEL',
        goToLicenses: 'Перейти к лицензиям',
        editAccess: 'Изменить доступ',
        editUser: 'Изменить пользователя',
        addUser: 'Добавить нового пользователя',
        nameAndLastname: 'Имя и фамилия',
        accountStatus: 'Статус учетной записи',
        phoneNumber: 'Номер телефона',
        department: 'Отдел',
        passwordChange: 'Изменить пароль',
        codeChange: 'Изменить @:common.PIN_CODE_LABEL',
        newPassword: 'Новый пароль',
        confirmNewPassword: 'Подтвердите новый пароль',
        changePassword: 'Изменить пароль',
        newCode: 'Новый @:common.PIN_CODE_LABEL',
        confirmNewCode: 'Подтвердите новый @:common.PIN_CODE_LABEL',
        changeCode: 'Изменить @:common.PIN_CODE_LABEL',
        addEntry: 'Добавить запись',
        editEntry: 'Изменить запись',
        editLicense: 'Изменить лицензию',
        addLicense: 'Добавить лицензию',
        licenseNumber: 'Номер лицензии',
        prefferedLocale: 'Предпочитаемый язык',
        oldPassword: 'Старый пароль',
        newPasswordConfirmation: 'Подтвердите новый пароль',
        reading: 'Отображение',
        creating: 'Создание',
        updating: 'Обновление',
        deleting: 'Удаление',
        importScheduleFile: 'Импорт расписания из файла',
        importSchedule: {
            errors: {
                user_not_found: 'Пользователь не найден [{user}]',
                user_found_many: '{count} пользователя найдено [{user}]',
                event_collision: 'столкновение событий',
                hour_cell_parse: 'Ошибка синтаксического анализа ячейки',
            },
        },
        oldCode: 'старый @:common.PIN_CODE_LABEL',
        newCodeConfirmation: 'Подтверждение нового @:common.PIN_CODE_LABEL',
        setAccessBasedOnProfile: 'Предоставить доступ на основе профиля',
        selectAccessMergeType: 'Выберите способ объединения разрешений',
        noAccessesInProfileMessage:
            'Выбранный профиль не имеет разрешений. Вы все равно хотите перезаписать?',
    },
    breadCrumb: {
        configuration: 'Конфигурация',
        locations: 'Местоположения',
        home: 'Главная',
        machines: 'Машины',
        tools: 'Инструменты',
        issues: 'Проблемы',
        notes: 'Заметки',
        maintenance: 'Техобслуживание',
        parts: 'Запчасти',
        warehouse: 'Склад',
        dictionary: 'Словарь',
        categories: 'Категории',
        parameters: 'Параметры',
        costCenter: 'Центр затрат',
        flows: 'Потоки',
        states: 'Состояния',
        transitions: 'Переходы',
        shiftSchemas: 'Схемы смен',
        shifts: 'Смены',
        definitionsOfMachinesAndToolsTypes:
            'Определения типов машин и инструментов',
        add: 'Добавить',
        edit: 'Редактировать',
        staff: 'Персонал',
        licenses: 'Лицензии',
        entries: 'Записи',
        companiesDatabase: 'База компаний',
        companyManagement: 'Управление компанией',
        companyData: 'Данные компании',
        supportedLanguages: 'Поддерживаемые языки',
        en: 'Английский',
        pl: 'Польский',
        es: 'Испанский',
        de: 'Немецкий',
        it: 'Итальянский',
        fr: 'Французский',
        ru: 'Русский',
        uk: 'Украинский',
        pt: 'Португальский',
        defaultLanguage: 'Язык по умолчанию',
        notifications: 'Уведомления',
        notificationsManagement: 'Управление уведомлениями',
        myProfile: 'Мой профиль',
    },
    attributeTypes: {
        text: 'Текст',
        note: 'Заметка',
        integer: 'Целое число',
        decimal: 'Десятичное число',
        date: 'Дата',
        time: 'Время',
        dateTime: 'Дата и время',
        color: 'Цвет',
        icon: 'Иконка',
        boolean: 'Да/Нет',
    },
    costCalculator: {
        costCalculator: 'Калькулятор стоимости',
        waitTime: 'Время ожидания',
        serviceTime: 'Время обслуживания',
        suspendTime: 'Время приостановления',
        totalTime: 'Общее время',
        estimatedCost: 'Расчетная стоимость',
        calculate: 'Рассчитать',
        withoutParts: 'Без запчастей',
        timeOfReportingIssue: 'Время сообщения о неисправности',
        timeOfServiceStart: 'Время начала обслуживания',
        timeOfReportingEnd: 'Время окончания обслуживания',
        days: 'Дни',
        hours: 'Часы',
        minutes: 'Минуты',
        enterNewTime: 'Ввести новое время',
        fromIssueReportToServiceStart:
            'Время с момента заявки до начала обслуживания',
        fromServiceStartToServiceEnd:
            'Время с начала обслуживания до его окончания',
        workTime: 'Рабочее время',
        includeAwaitingTime: 'Включить время ожидания',
    },
    dashboard: {
        components: {
            myIssues: {
                title: 'Мои задачи',
                assignedToMe: 'Назначенные мне',
                assignedToGroup: 'Назначенные моей группе',
                tookByMe: 'Взятые мной',
                takeIssueBtn: 'Взять',
                arriveIssueBtn: 'Я приехал',
                finishIssueBtn: 'Закончить',
                history: 'История',
                myResponsible: 'я несу ответственность за',
                unassignIssue: 'Отменить съемку',
            },
        },
    },
    synchronization: {
        enabled: 'Включено',
        hasHeaders: 'Есть заголовки',
        leaveFile: 'Оставить файл',
        host: 'Хост',
        port: 'Порт',
        protocol: 'Протокол',
        username: 'Имя пользователя',
        password: 'Пароль',
        root: 'Корень',
        encoding: 'Кодировка',
        separator: 'Разделитель',
        externalSystemId: 'ID внешней системы',
        cronExpression: 'Выражение Cron',
        appendFullStock: 'Добавить полный склад',
        includeHeaders: 'Включить заголовки',
        withTrashed: 'С удаленными данными',
        filePattern: 'Шаблон файла',
        categoriesRootCategoryId:
            'Корневая категория для импортированных категорий',
        systemKeyname: 'Идентификатор системы',
        address: 'Адрес',
        token: 'Токен',
    },
    processes: {
        created_at: 'Создано',
        started_at: 'Начато',
        updated_at: 'Обновлено',
        finished_at: 'Завершено',
        attempts: 'Попыток',
        queue: 'Очередь',
        job_id: 'ID задачи',
        job_uuid: 'UUID задачи',
        progress_max: 'Макс. прогресс',
        progress_now: 'Текущий прогресс',
        outputMessage: 'Сообщение вывода',
        status: {
            queued: 'в очереди',
            executing: 'выполняется',
            finished: 'завершено',
            failed: 'не удалось',
            retrying: 'повторная попытка',
            undefined: 'неопределенный',
        },
    },
    weekDays: {
        mo: 'Понедельник',
        tu: 'Вторник',
        we: 'Среда',
        th: 'Четверг',
        fr: 'Пятница',
        sa: 'Суббота',
        su: 'Воскресенье',
    },
    reports: {
        reports: 'Отчеты',
        dateFrom: 'Дата начала',
        dateTo: 'Дата окончания',
        types: {
            operation_sheet: 'Операционный лист',
            issue_summary: 'краткое изложение проблемы',
            grouped_summary: 'Сводный отчет',
            storage_operations: 'Складские операции',
            part_quantity_export: 'Количество деталей',
        },
        reportFor: 'Отчет за',
        includeQuantityLeft0: 'Включить детали с нулевым количеством',
        dayMinusN: 'День минус n',
        currentWeek: 'Текущая неделя',
        lastWeek: 'Прошлая неделя',
        currentMonth: 'Текущий месяц',
        lastMonth: 'Прошлый месяц',
        currentYear: 'Текущий год',
        lastYear: 'Прошлый год',
        nDays: 'Количество дней',
        todayMinusN: "Сегодня минус н"
    },
    logs: {
        comment: 'Комментарий',
        issues: {
            escalation: 'Эскалация ({minutes} мин) - уведомление отправлено',
        },
        issueEmployee: {
            assignedAt: 'Сотрудник назначен:',
            receivedAt: 'Сотрудник получил:',
            tookAt: 'Сотрудник принял',
            finishedAt: 'Сотрудник завершил',
            deletedAt: 'Сотрудник удален из обращения',
        },
    },
    units: {
        no: 'кусок',
        st: 'набор',
        pr: 'пара',
    },
    rrule: {
        frequency: 'Частота',
        dtstart: 'Дата начала',
        tzid: 'Часовой пояс',
        until: 'До',
        count: 'Считать',
        interval: 'интервал',
        wkst: 'День начала',
        byweekday: 'К будням',
        bymonth: 'По месяцам',
        yearly: 'Ежегодн',
        monthly: 'Ежемесячно',
        weekly: 'Еженедельно',
        daily: 'Ежедневно',
        hourly: 'Почасовая',
        minutely: 'Минутно',
        secondly: 'Во-вторых',
        infinitePeriodicityMessage:
            'Периодичность бесконечна, из-за производительности отображается только 500 строк',
    },
    months: {
        jan: 'январь',
        feb: 'февраль',
        mar: 'Маршировать',
        apr: 'Апрель',
        may: 'Может',
        jun: 'Июнь',
        jul: 'Июль',
        aug: 'Август',
        sep: 'Сентябрь',
        oct: 'октябрь',
        nov: 'ноябрь',
        dec: 'Декабрь',
    },
    charts: {
        charts: 'графики',
        count: 'Количество выпусков',
        total: 'Общее время выпуска (от отчета до завершения)',
        serw: 'Общее время ремонта (Ttotal - Twait)',
        mttr1: 'Среднее время от отчета до завершени',
        mttr2: 'Среднее время от дубля до финиш',
        mtbf: 'Среднее время безотказной работы между проблемами',
        mttf: 'Среднее время до отказа',
        type: 'Тип диаграммы',
        pie: 'Круговая диаграмма',
        bar: 'Гистограмма',
        query: 'Объем данных',
        groupBy: 'Группа по',
        sum: 'Сумма',
        avg: 'Иметь в виду',
        min: 'Минимум',
        max: 'Максимум',
        eq: 'Равно',
        neq: 'Это не равно',
        gt: 'Больше, чем',
        lt: 'Меньше, чем',
        gte: 'Больше или равно',
        lte: 'Меньше или равно',
        in: 'Содержит',
        nin: 'Не содержать',
        btw: 'Между',
        chartOptions: 'Параметры диаграммы',
        line: 'Линейный график',
        mixed: 'Смешанный',
        chartDisplayOptions: 'Показать варианты',
        fillGaps: 'Заполнить пробелы',
    },
    tpm: {
        history: 'История',
        standardObstacle: 'Стандартное препятствие',
        customObstacle: 'Пользовательское препятствие',
        bhp: 'Безопасность и охрана труда',
        returnToMainPage: 'Вернуться на главную страницу',
        bhpMessage:
            'Если вы замечаете риски безопасности, немедленно сообщите об этом своему непосредственному руководителю и используйте форму уведомления, находящуюся под доской объявлений.',
        ok: 'OK',
        send: 'Отправить',
        description: 'Описание',
        title: 'Заголовок',
        realization: 'Реализация',
        answer: 'Ответ',
        pendingSurveys: 'Ожидающие опросы',
        surveys: 'Опросы',
        systemSurvey: 'Системный опрос',
        addPage: 'Добавить страницу',
        createSurvey: 'Создать опрос',
        addSection: 'Добавить раздел',
        addQuestion: 'Добавить вопрос',
        questionContent: 'Содержание вопроса',
        answerOption: 'Вариант ответа',
        addQuestionAnswer: 'Добавить вариант ответа',
        surveyName: 'Название опроса',
        sectionName: 'Название раздела',
        noSurveyAnswers: 'Нет ответов',
        removeSection: 'Удалить раздел',
        published: 'Опубликовано',
        unpublished: 'Неопубликовано',
        pendingPublication: 'Ожидают публикации',
        removeQuestion: 'Удалить вопрос',
        removeAnswer: 'Удалить вариант ответа',
        publish: 'Опубликовать',
        unpublish: 'Отменить публикацию',
        previewResults: 'Просмотреть результаты',
        supervisior: 'Руководитель',
        surveyResults: 'Результаты опроса',
        surveysEvents: 'Конфигурация событий - опросы',
        operatedBy: 'Управляется',
        confirmReadInformations: 'Я подтверждаю, что ознакомился с информацией',
        noticeContent: 'Содержание уведомления',
        notice: 'Уведомление',
        surveyEventName: 'Название события',
        survey: 'Опрос',
        newIssue: 'Новая проблема',
        surveyEventConfigurationAlreadyExists:
            'Конфигурация события уже существует',
        lastComment: 'Последний комментарий ответственного лица',
        surveyNotConfigured: 'Опрос не настроен',
        fillSurvey: 'Заполнить опрос',
        singleSurveyEntries: 'единичные результаты',
        groupedSurveyEntries: 'Сгруппированные результаты',
        publicationId: 'Идентификатор публикации',
        publicationDate: 'Дата публикации',
        versionId: 'идентификатор версии',
        answersCount: 'Количество ответов',
        answers: 'Ответы',
        withoutSupervisor: 'Нет супервайзера',
        defaultSupervisor: 'Супервайзер по умолчанию',
        flowStateFlags: 'Флаги состояния',
        qrActionsSettings: 'Настройки действия QR-кода',
        loginRequired: 'Требуется авторизация',
        questionContext: 'контекст вопроса',
        currentSurveyVersionPreview:
            'Предварительный просмотр текущей версии опроса',
        surveyDescription: 'Описание опроса',
        sectionDescription: 'Описание раздела',
        instructions: 'инструкции',
        instruction: 'инструкция',
        instructionRevisions: 'Редакции',
        instructionRevision: 'пересмотр',
        checklists: 'Контрольные списки',
        checklist: 'Контрольный список',
        checklistItem: 'Пункт списка',
        manageChecklistFields: 'Управление элементами списка',
        checklistItemDisplayOptions: 'Отображаемые элементы',
        revisionAutoRejectedInfo:
            '[Система] Отклонено — создана новая редакция',
    },
    accessMergeValues: {
        sum: 'Сумма',
        product: 'Продукт',
        overwrite: 'перезаписать',
    },
    aggregates: {
        count: 'Сумма',
        min: 'Минимум',
        max: 'Максимум',
    },
    checklists: {
        itemBasic: 'Базовый элемент',
        itemSeparator: 'Разделитель',
        itemSection: 'Раздел',
        publish: 'Публиковать',
        previewCurrentVersion: 'Предварительный просмотр текущей версии',
    },
    cluer: {
        history: 'История',
        standardObstacle: 'Стандартное препятствие',
        customObstacle: 'Пользовательское препятствие',
        bhp: 'Безопасность и охрана труда',
        returnToMainPage: 'Вернуться на главную страницу',
        bhpMessage:
            'Если вы замечаете риски безопасности, немедленно сообщите об этом своему непосредственному руководителю и используйте форму уведомления, находящуюся под доской объявлений.',
        ok: 'OK',
        send: 'Отправить',
        description: 'Описание',
        title: 'Заголовок',
        realization: 'Реализация',
        answer: 'Ответ',
        pendingSurveys: 'Ожидающие опросы',
        surveys: 'Опросы',
        systemSurvey: 'Системный опрос',
        addPage: 'Добавить страницу',
        createSurvey: 'Создать опрос',
        addSection: 'Добавить раздел',
        addQuestion: 'Добавить вопрос',
        questionContent: 'Содержание вопроса',
        answerOption: 'Вариант ответа',
        addQuestionAnswer: 'Добавить вариант ответа',
        surveyName: 'Название опроса',
        sectionName: 'Название раздела',
        noSurveyAnswers: 'Нет ответов',
        removeSection: 'Удалить раздел',
        published: 'Опубликовано',
        unpublished: 'Неопубликовано',
        pendingPublication: 'Ожидают публикации',
        removeQuestion: 'Удалить вопрос',
        removeAnswer: 'Удалить вариант ответа',
        publish: 'Опубликовать',
        unpublish: 'Отменить публикацию',
        previewResults: 'Просмотреть результаты',
        supervisior: 'Руководитель',
        surveyResults: 'Результаты опроса',
        surveysEvents: 'Конфигурация событий - опросы',
        operatedBy: 'Управляется',
        confirmReadInformations: 'Я подтверждаю, что ознакомился с информацией',
        noticeContent: 'Содержание уведомления',
        notice: 'Уведомление',
        surveyEventName: 'Название события',
        survey: 'Опрос',
        newIssue: 'Новая проблема',
        surveyEventConfigurationAlreadyExists:
            'Конфигурация события уже существует',
        lastComment: 'Последний комментарий ответственного лица',
        surveyNotConfigured: 'Опрос не настроен',
        fillSurvey: 'Заполнить опрос',
        singleSurveyEntries: 'единичные результаты',
        groupedSurveyEntries: 'Сгруппированные результаты',
        publicationId: 'Идентификатор публикации',
        publicationDate: 'Дата публикации',
        versionId: 'идентификатор версии',
        answersCount: 'Количество ответов',
        answers: 'Ответы',
        withoutSupervisor: 'Нет супервайзера',
        defaultSupervisor: 'Супервайзер по умолчанию',
        flowStateFlags: 'Флаги состояния',
        qrActionsSettings: 'Настройки действия QR-кода',
        loginRequired: 'Требуется авторизация',
        questionContext: 'контекст вопроса',
        currentSurveyVersionPreview:
            'Предварительный просмотр текущей версии опроса',
        surveyDescription: 'Описание опроса',
        sectionDescription: 'Описание раздела',
    },
    orders: {
        order: 'Заказ',
        releaseDate: 'Дата завершения',
        orderDate: 'Дата заказа',
    },
    production: {
        good: 'Количество хороших',
        bad: 'Количество плохих',
        productionRecords: 'Регистр производства',
    },
};
