export const SETTINGS = [
    {
        icon: 'mdi-hospital-marker',
        label: 'common.locationsTitle',
        to: { name: 'settings.locations' },
        access: 'settings.locations',
    },

    {
        icon: 'mdi-book-open-variant',
        label: 'common.dictionary',
        to: { name: 'settings.dictionary' },
        access: 'settings.dictionary',
    },
    {
        icon: 'mdi-folder',
        label: 'common.categories',
        to: { name: 'settings.categories' },
        access: 'settings.categories',
    },
    {
        icon: 'mdi-cash-fast',
        label: 'costCenter.costCenter',
        to: { name: 'settings.cost-centers' },
        access: 'settings.cost-centers',
    },
    {
        icon: 'mdi-domain',
        label: 'common.companyData',
        to: { name: 'settings.companyManagement' },
        access: 'settings.companyManagement',
    },
    {
        icon: 'mdi-tablet-dashboard',
        label: 'common.terminals',
        to: { name: 'terminals' },
        access: 'terminals',
    },
    {
        icon: 'mdi-sync',
        label: 'common.synchronization',
        to: { name: 'settings.synchronization' },
        access: 'settings.synchronization',
    },
    {
        icon: 'mdi-source-repository',
        label: 'common.externalSystems',
        to: { name: 'settings.systems' },
        access: 'settings.systems',
    },
    {
        icon: 'mdi-badge-account-horizontal-outline',
        label: 'common.staffProfiles',
        to: { name: 'settings.userProfiles' },
        access: 'settings.staff',
    },
    {
        icon: 'mdi-file-document-check',
        label: 'common.controlsTemplates',
        to: { name: 'settings.controls_templates' },
        access: 'settings.controls',
    },
    {
        icon: 'mdi-draw',
        label: 'common.statementsTemplates',
        to: { name: 'settings.statements_templates' },
        access: 'statement-templates',
    },

    {
        icon: 'mdi-meter-electric-outline',
        label: 'common.metersSettings',
        to: { name: 'settings.counters-settings' },
        access: 'settings.counters',
    },
    {
        icon: 'mdi-tshirt-crew-outline',
        label: 'common.handoversTemplates',
        to: { name: 'settings.handover_templates' },
        access: 'settings.handover_templates',
    },
    {
        icon: 'mdi-matrix',
        label: 'common.matrices',
        to: { name: 'settings.matrices' },
        access: 'settings.matrices',
    },
    {
        icon: 'mdi-account-details-outline',
        label: 'common.skillmatrix',
        to: { name: 'settings.skillmatrix' },
        access: 'skillmatrix',
    },
    {
        icon: 'mdi-factory',
        label: 'common.plants',
        to: { name: 'settings.plants' },
        access: 'settings.plants',
    },
    {
        icon: 'mdi-list-status',
        label: 'tpm.instructions',
        to: { name: 'settings.instructions' },
        access: 'settings.instructions',
    },
    // {
    //     icon: 'help-box-multiple',
    //     label: 'common.quizzes',
    //     to: { name: 'settings.quizzes' },
    //     // access: 'settings.quizzes',
    //     access: 'settings.instructions', //TODO
    // },
    // {
    //     icon: 'mdi-calendar-clock',
    //     label: 'common.statementsEvents',
    //     value: 'settings.statements_events',
    //     to: { name: 'settings.statements_events' },
    //     access: 'settings.survey_events', //TODO
    // },
    // {
    //     icon: 'mdi-qrcode-edit',
    //     label: 'common.qrActionsSettings',
    //     value: 'settings.qr_actions',
    //     to: { name: 'settings.qr_actions' },
    //     access: 'settings.qr_actions', 
    // },
];
