<!--Ważne w polach z isFieldHidden używać v-show zamiast v-if bo gdy wszystkie pola są ukryte dataForm jest not valid-->
<template>
    <SignaloPageLoading v-if="loading" />
    <v-form
        v-else
        ref="dataForm"
        v-model="dataValid"
        lazy-validation
        @keyup.enter.prevent="saveClicked"
        :key="item?.category_id"
    >
        <v-row> 
            <v-col cols="12" md="12" xl="12" v-show="!category?.id">
                <SignaloCategoryPicker
                    v-model="item.category_id"
                    class="issue-category-picker"
                    v-slot="props"
                    :disableAboveRootCategories="false"
                    :openOnCategoryId="
                        dataStore?.categories_alter_structure?.getById(
                            item?.category_id
                        )?.parent_id
                    "
                    :source="dataStore.categories_alter_structure.items"
                >
                    <v-text-field
                        variant="outlined"
                        color="var(--greyscale-100)"
                        :modelValue="props.text"
                        @click:control="props.onClick"
                        @click:clear="props.onClear"
                        readonly
                        clearable
                        :label="$t('searchConfig.category')"
                        :rules="[$REQUIRED_RULE]"
                        class="required-field"
                    >
                    </v-text-field>
                </SignaloCategoryPicker>
            </v-col>
            <v-col
                cols="12"
                md="12"
                xl="12"
                v-show="!isFieldHidden('title')"
                v-if="titleRules?.length"
            >
                <v-text-field
                    v-model="item.title"
                    variant="outlined"
                    :label="$t('tableHeaders.title')"
                    :rules="[...titleRules]"
                    class="required-field"
                >
                </v-text-field>
            </v-col>
            <v-col cols="12" md="6" xl="6" v-show="!isFieldHidden('equipment')">
                <SignaloNewValueSelect
                    :selectableType="SELECTABLE_TYPES.EQUIPMENT"
                    :label="
                        preferedEquipmentKind === null
                            ? $t('tableHeaders.equipment')
                            : $t(`common.${preferedEquipmentKind}`)
                    "
                    v-model="item.equipment_id"
                    :equipmentKind="preferedEquipmentKind"
                    :required="!!isFieldRequired('equipment')"
                    :clearable="true"
                />
            </v-col>
            <v-col
                cols="12"
                md="6"
                xl="6"
                v-show="
                    authStore?.tenant?.attributes?.create_issue_criticality !==
                        'hide' && !isFieldHidden('criticality')
                "
            >
                <SignaloNewValueSelect
                    :selectableType="SELECTABLE_TYPES.DICTIONARY"
                    :dictionary="'issue_criticality'"
                    :label="$t('searchConfig.criticality')"
                    v-model="item.criticality"
                    :errorMessages="errors.criticality"
                    :required="
                        authStore?.tenant?.attributes
                            ?.create_issue_criticality == 'require' ||
                        isFieldRequired('criticality')
                    "
                    :clearable="true"
                    :rules="[
                        ...criticalityRules,
                        isFieldRequired('criticality'),
                    ]"
                />
            </v-col>
            <v-col
                cols="12"
                md="6"
                xl="6"
                v-show="
                    authStore?.tenant?.attributes?.issue_kind !== 'hide' &&
                    !isFieldHidden('kind')
                "
            >
                <v-select
                    variant="outlined"
                    color="var(--greyscale-100)"
                    :label="$t('tableHeaders.kind')"
                    :error-messages="errors.kind"
                    v-model="item.kind"
                    :items="
                        Object.values(
                            dataStore.dictionary
                                .group('issue_kind')
                                ?.filter((d) => d.pickable)
                        )
                    "
                    item-title="value"
                    item-value="keyname"
                    :class="
                        authStore?.tenant?.attributes?.issue_kind ==
                            'require' || isFieldRequired('kind')
                            ? 'required-field'
                            : ''
                    "
                    :clearable="true"
                    :rules="[...kindRules, isFieldRequired('criticality')]"
                >
                </v-select
            ></v-col>
            <v-col cols="12" md="6" xl="6" v-show="!isFieldHidden('prod_line')">
                <SignaloNewValueSelect
                    :selectableType="SELECTABLE_TYPES.DICTIONARY"
                    :dictionary="'prod_lines'"
                    :label="$t('common.productionLine')"
                    v-model="item.prod_line"
                    :loading="prodLineLoading"
                    :required="!!isFieldRequired('prod_line')"
                    clearable
                />
            </v-col>
            <v-col
                cols="12"
                md="6"
                xl="6"
                v-show="!isFieldHidden('department')"
            >
                <SignaloNewValueSelect
                    :selectableType="SELECTABLE_TYPES.DICTIONARY"
                    :dictionary="'departments'"
                    :label="$t('tableHeaders.department')"
                    v-model="item.department"
                    :required="!!isFieldRequired('department')"
                    clearable
                />
            </v-col>
            <v-col
                cols="12"
                md="6"
                xl="6"
                v-show="showHelp && !isFieldHidden('groups')"
            >
                <SignaloNewValueSelect
                    :selectableType="SELECTABLE_TYPES.GROUPS"
                    :groupTypes="['service']"
                    :label="$t('common.service')"
                    multiple
                    v-model="item.groups"
                    :rules="[...requiredHelpRules, isFieldRequired('groups')]"
                    :required="
                        requiredHelpRules?.length || isFieldRequired('groups')
                    "
                />
            </v-col>

            <v-col
                cols="12"
                md="12"
                xl="12"
                v-show="!isFieldHidden('user_description')"
            >
                <v-textarea
                    :label="$t('common.description')"
                    variant="outlined"
                    v-model="item.user_description"
                    :rules="[isFieldRequired('user_description')]"
                    :class="
                        !!isFieldRequired('user_description')
                            ? 'required-field'
                            : ''
                    "
                ></v-textarea>
            </v-col>
        </v-row>

        <v-tabs color="primary" v-model="tab" height="auto">
            <v-tab value="images">{{ $t('common.images') }}</v-tab>
            <v-tab value="documents">{{ $t('common.documents') }}</v-tab>
            <v-tab
                value="parameters"
                v-if="item?.category_id && categoryParameters?.length"
                >{{ $t('common.parameters') }}
                <span
                    class="Required-Asterisk"
                    v-if="categoryParameters.some((cp) => cp.required)"
                    >*</span
                >
            </v-tab>
        </v-tabs>
        <v-row class="new-issue-tabs-content-row">
            <v-window
                v-model="tab"
                class="additional-content-v-window"
                :key="tab"
            >
                <v-window-item value="images">
                    <SignaloFilesImagesContainer
                        allow-upload
                        allow-remove
                        allow-edit
                        :newItemImages="true"
                        ref="newIssueImages"
                        :allowImagesFromDeviceCamera="true"
                        :allowDraw="true"
                    />
                </v-window-item>
                <v-window-item value="documents">
                    <SignaloFilesDocumentsContainer
                        ref="newIssueDocuments"
                        :newItemDocuments="!this.editedItem?.id"
                        :allowUpload="true"
                        :allowRemove="true"
                        :addDocumentButtonLabel="`${$t('common.add')} ${$t(
                            'common.attachment'
                        )}`"
                    />
                </v-window-item>
                <v-window-item
                    value="parameters"
                    v-if="categoryParameters?.length"
                >
                    <SignaloItemParametersManage
                        :key="item.category_id"
                        :categoryId="item.category_id"
                        @everyRequiredParamHasValue="
                            onEveryRequiredParamHasValue
                        "
                        :modelValue="
                            parameters?.length ? parameters : categoryParameters
                        "
                        :hideEvaluated="true"
                        :dialogSearchable="true"
                    />
                </v-window-item>
            </v-window>
        </v-row>
        <SignaloErrorMessage
            v-if="paramsError"
            :errorMessage="paramsError"
            class="v-field--error"
        />
    </v-form>
</template>

<script>
import * as DATA_STORE from 'signalo-vue-data-store';
import * as SIGNALO_VUE_HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';

export default {
    setup() {
        const dataStore = DATA_STORE.default();
        const helpersStore = SIGNALO_VUE_HELPERS_STORE.default();
        const authStore = AUTH_STORE.default();
        return { dataStore, helpersStore, authStore };
    },
    props: ['category', 'shouldLogoutTemp', 'disableSnackbar'],
    emits: ['update:open', 'item:created'],
    data() {
        return {
            loading: false,
            saveLoading: false,
            dataValid: false,
            item: {},
            categoryParameters: [],
            parameters: [],
            everyRequiredParamHasValue: true,
            tab: 'parameters',
            paramsError: null,
            shouldLogoutAfterIssueCreated: !!this.shouldLogoutTemp,
            titleRules: [
                (v) => !!v || this.$t('common.fieldRequired'),
                (v) => v.length <= 400 || this.$t('errors.maxLength') + ' 400',
            ],
            errors: {},
            selectedCategory: null,
        };
    },
    async mounted() {
        //Tak trzeba ładować 2 razy kategorie;
        this.loading = true;
        this.$emit('setLoading', this.loading);
        await this.dataStore.categories.promise();
        await this.dataStore.categories_alter_structure.promise();
        if (this.category?.id) {
            this.item.category_id = this.category.id;
            this.selectedCategory = this.category;
            this.loadCategoryParameters(this.category?.id);
        }
        this.getDefaults();
        this.loading = false;
        this.$emit('setLoading', this.loading);
    },
    methods: {
        getDefaults() {
            if (!this.item?.category_id) return;
            this.item.title = this.getDefaultValue('title');
            this.item.user_description =
                this.getDefaultValue('user_description');
            this.item.equipment_id = this.getDefaultValue('equipment');
            this.item.prod_line = this.getDefaultValue('prod_line');
            this.item.kind = this.getDefaultValue('kind');
            this.item.criticality = this.getDefaultValue('criticality');
            this.item.groups = this.getDefaultValue('groups');
            this.item.department = this.getDefaultValue('department');
        },
        isFieldHidden(fieldName) {
            return (
                this.findCategoryNearestDefaults(this.item?.category_id)
                    ?.hiddenFields?.[fieldName] === true
            );
        },
        getDefaultValue(fieldName) {
            if (!this.item?.category_id) return;
            return (
                this.findCategoryNearestDefaults(this.item?.category_id)
                    ?.defaults?.[fieldName] || null
            );
        },
        isFieldRequired(fieldName) {
            if (
                this.findCategoryNearestDefaults(this.item?.category_id)
                    ?.requiredFields?.[fieldName] === true
            ) {
                return this.$REQUIRED_RULE;
            } else {
                return null;
            }
        },
        onEveryRequiredParamHasValue(everyRequiredParamHasValue, parameters) {
            this.everyRequiredParamHasValue = everyRequiredParamHasValue;
            this.parameters = [...parameters];
        },
        async loadCategoryParameters(id) {
            this.paramsError = null;
            if (!id) {
                this.categoryParameters = [];
                this.everyRequiredParamHasValue = true;
                if (this.tab === 'parameters') {
                    this.tab = 'images';
                }
                return;
            }
            this.parameters = [];
            const categoryParametersData = await this.axios.post(
                'issues/new/parameters',
                {
                    category_id: id,
                }
            );
            const categoryParameters =
                categoryParametersData?.data?.items || [];
            this.categoryParameters = Array.isArray(categoryParameters)
                ? [...categoryParameters]
                : [];
            this.everyRequiredParamHasValue = !this.categoryParameters.some(
                (p) => p.required && !p.hidden
            );
            if (!this.categoryParameters?.length) {
                this.everyRequiredParamHasValue = true;
                if (this.tab === 'parameters') {
                    this.tab = 'images';
                }
            } else {
                this.tab = 'parameters';
            }
        },
        onClose(shouldRefetch = false) {
            this.$emit('close', shouldRefetch);
        },
        async saveClicked($event = null) {
            if (this.saveLoading) return;
            if ($event) {
                $event.preventDefault();
                $event.stopPropagation();
            }
            try {
                await this.$refs.dataForm.validate();
                if (!this.dataValid) {
                    const inputsWithErrors =
                        document.getElementsByClassName('v-field--error');
                    if (inputsWithErrors) {
                        inputsWithErrors[0].scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                            inline: 'nearest',
                        });
                    }
                    return;
                }
                if (
                    this.categoryParameters?.length &&
                    !this.everyRequiredParamHasValue
                ) {
                    this.paramsError = this.$t(
                        'errors.fillAllRequiredParameters'
                    );
                    this.helpersStore.snackbarWarning(this.paramsError);
                    this.$nextTick(() => {});
                    return;
                }
                this.paramsError = null;
                this.saveLoading = true;
                this.$forceUpdate();
                const additionalContentRequests = [];
                let res;
                this.$emit('setLoading', true);
                if (
                    this.authStore.isTerminal &&
                    typeof window?.FFApi?.getSessionProperty === 'function'
                ) {
                    const code =
                        window?.FFApi?.getSessionProperty('operatorPin');
                    if (code) {
                        try {
                            await this.axios.post(
                                'auth/temporary',
                                {
                                    password: code,
                                },
                                { disableSAD: true }
                            );
                            this.shouldLogoutAfterIssueCreated = true;
                        } catch (err) {
                            console.error(err);
                        }
                    }
                }

                if (this.parameters?.length) {
                    this.item = {
                        ...this.item,
                        parameters: this.parameters
                            .filter((p) => p.value !== undefined)
                            .map((p) => {
                                return {
                                    parameter_id: p.id,
                                    value: p.value,
                                };
                            }),
                    };
                }

                if (this.editedItem?.id) {
                    res = await this.axios.put(
                        `issues/${this.editedItem.id}`,
                        this.item
                    );
                } else {
                    this.item.version = 1;
                    res = await this.axios.post(`issues`, this.item);
                }
                if (
                    this.$refs.newIssueImages?.$refs?.filesContainer?.files
                        ?.length &&
                    res?.data?.item?.id
                ) {
                    this.$refs.newIssueImages.$refs.filesContainer.files.forEach(
                        (file) => {
                            additionalContentRequests.push({
                                url: `issues/${res.data.item.id}/photos`,
                                method: 'POST',
                                data: {
                                    file: file.uploadFile,
                                },
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            });
                        }
                    );
                }
                if (
                    this.$refs.newIssueDocuments?.$refs?.fContainer?.files
                        ?.length &&
                    res?.data?.item?.id
                ) {
                    this.$refs.newIssueDocuments?.$refs?.fContainer?.files?.forEach(
                        (file) => {
                            additionalContentRequests.push({
                                url: `issues/${res.data.item.id}/documents`,
                                method: 'POST',
                                data: {
                                    file: file.uploadFile,
                                },
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            });
                        }
                    );
                }
                await this.axios.all(
                    additionalContentRequests.map((r) => this.axios.request(r))
                );
                if (this.shouldLogoutAfterIssueCreated) {
                    try {
                        await this.axios.delete('auth/temporary');
                    } catch (err) {
                        console.error(err);
                    }
                }
                if (!this.disableSnackbar) {
                    this.helpersStore.snackbar(
                        this.$t('common.issueCreated'),
                        'success'
                    );
                }
                this.$emit('response', res);
                this.$emit('item:created', true);
                this.$emit('update:open', false);
                this.$emit('setLoading', false);
                if (
                    this.authStore.isTerminal &&
                    typeof window?.FFApi?.closeView === 'function'
                ) {
                    setTimeout(() => {
                        window.FFApi.closeView();
                    }, 1500);
                }
            } catch (err) {
                this.$emit('setLoading', false);
                if (err == 'SAD_CLOSE') return;
                this.helpersStore.snackbarError(err);
                if (err?.response?.data?.message?.includes('Limit exceeded')) {
                    const limitForGroupId = Number(
                        err?.response?.data?.message
                            ?.split('group_id:')?.[1]
                            ?.split(',')?.[0]
                    );
                    const limitForFunction = Number(
                        err?.response?.data?.message
                            ?.split('function:')?.[1]
                            ?.split(',')?.[0]
                    );
                    const functionName = this.$getDictionaryItemName(
                        'user_functions',
                        limitForFunction
                    );
                    if (!Number.isNaN(limitForGroupId) || limitForFunction) {
                        const groupName =
                            this.$getPropertyTranslation(
                                this.dataStore.groups.items.find(
                                    (g) => g.id === limitForGroupId
                                )?.name
                            ) || '';
                        this.limitError = this.$t('errors.issueLimitError');

                        if (groupName) {
                            this.limitError += `${this.$t(
                                'common.group'
                            )} - ${groupName}`;
                        }
                        if (functionName) {
                            if (groupName) {
                                this.limitError += ', ';
                            }
                            this.limitError += ` ${this.$t(
                                'common.function'
                            )} - ${functionName}`;
                        }
                    }
                } else if (err?.response) {
                    if (err?.response?.status === 422) {
                        this.errors = err.response.data.errors;
                    }
                }
                console.error(err);
                this.saveLoading = false;
            }
        },
        scrollToInvalidInput() {
            const inputsWithErrors =
                document.getElementsByClassName('v-field--error');
            if (inputsWithErrors) {
                inputsWithErrors[0].scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest',
                });
            }
        },
        userDelete(id) {
            this.selectedUsers = this.selectedUsers.filter((x) => x.id !== id);
        },
        findCategoryNearestDefaults(categoryId) {
            if (!categoryId) return null;
            let context;
            if (
                this.authStore?.user?.attributes?.inherit_all_default_values ||
                this.authStore?.terminal?.attributes?.inherit_all_default_values
            ) {
                context = 'tenant';
            } else if (
                this.authStore?.terminal &&
                this.authStore.user?.authenticatable === 'User'
            ) {
                context = 'terminal';
            } else if (this.authStore.user?.authenticatable === 'Terminal') {
                context = 'user';
            } else {
                context = 'tenant';
            }

            let defaults = this.authStore?.[context]?.attributes?.issueDefaults;

            const currentCategory =
                this.dataStore.categories.getById(categoryId);
            const shouldUseTenantDefaults =
                defaults?.[currentCategory?.path]?.defaults
                    ?.inheritValuesFromTenantSettings;
            if (shouldUseTenantDefaults) {
                context = 'tenant';
                defaults = this.authStore?.[context]?.attributes?.issueDefaults;
            }
            const categoryDefaults = defaults?.[currentCategory?.path];
            if (categoryDefaults) return categoryDefaults;
            if (!categoryDefaults && !currentCategory?.parent_id) {
                return null;
            }
            let parentDefaults;
            let parentCategory = this.dataStore.categories.getById(
                currentCategory.parent_id
            );
            while (!parentDefaults) {
                parentDefaults = defaults?.[parentCategory?.path];
                if (parentDefaults) return parentDefaults;
                let parentId = parentCategory?.parent_id;
                if (!parentId) return null;
                parentCategory = this.dataStore.categories.getById(parentId);
            }
            return null;
        },
    },
    watch: {
        'item.category_id': {
            handler(val, prevVal) {
                if (val === prevVal) return;
                this.parameters = [];
                this.categoryParameters = [];
                this.selectedCategory = this.dataStore.categories.getById(val);
                this.loadCategoryParameters(val);
                this.getDefaults();
                //Bo jakiś błąd leciał cannot get property of undefined .width - pewnie któraś z kategorii miała jakieś pole ukryte i vue nie ogarniał;
                this.$forceUpdate();
            },
            deep: true,
        },
    },
    computed: {
        showHelp() {
            if (this.authStore.isTerminal) {
                return (
                    this.authStore?.tenant?.attributes?.issue_help_terminal !==
                    'hide'
                );
            } else {
                return (
                    this.authStore?.tenant?.attributes?.issue_help_webapp !==
                    'hide'
                );
            }
        },
        criticalityRules() {
            if (
                this.authStore?.tenant?.attributes?.create_issue_criticality ==
                'require'
            ) {
                return [(v) => !!v || this.$t('common.fieldRequired')];
            }
            return [];
        },
        kindRules() {
            if (this.authStore?.tenant?.attributes?.issue_kind == 'require') {
                return [(v) => !!v || this.$t('common.fieldRequired')];
            }
            return [];
        },
        requiredHelpRules() {
            if (
                this.authStore.isTerminal &&
                this.authStore?.tenant?.attributes?.issue_help_terminal ==
                    'require'
            ) {
                return [(v) => !!v?.length || this.$t('common.fieldRequired')];
            } else if (
                !this.authStore.isTerminal &&
                this.authStore?.tenant?.attributes?.issue_help_webapp ==
                    'require'
            ) {
                return [(v) => !!v?.length || this.$t('common.fieldRequired')];
            }
            return [];
        },
        preferedEquipmentKind() {
            if (
                !this.authStore?.tenant?.attributes?.[
                    'new_issue_equipment_kind'
                ] ||
                this.authStore?.tenant?.attributes?.[
                    'new_issue_equipment_kind'
                ] === 'all'
            )
                return null;
            return this.authStore?.tenant?.attributes?.[
                'new_issue_equipment_kind'
            ];
        },
    },
};
</script>

<style scoped>
.additional-content-v-window {
    width: 100%;
    margin: 24px 12px;
}
.responsible-user-container,
.assigned-employees-container {
    display: flex;
}
.assigned-employees-container {
    justify-content: space-between;
}
.responsible-user-select-button,
.assign-employees-add-button {
    margin-left: auto;
}
.responsible-user,
.assigned-users-list-container {
    font-weight: bold;
}
</style>
