import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import 'vue3-timepicker/dist/VueTimepicker.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import axios from 'axios';
import VueAxios from 'vue-axios';

import App from './App.vue';
import router from './router';
import mitt from 'mitt';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import 'echarts';
import 'video.js/dist/video-js.css';

import tenant from './tenant.js';

/* VUETIFY */
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
/* END VUETIFY */

import CODEABLE_TYPES from './CONSTANTS/CODEABLE_TYPES';
/* COMPONENTS */
import PageLoading from '@/components/global/PageLoading.vue';
import PageNotFound from '@/components/global/PageNotFound.vue';
import PageNotPermitted from '@/components/global/PageNotPermitted.vue';
import PageError from '@/components/global/PageError.vue';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import VueTimepicker from 'vue3-timepicker';
import DateTimePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue3-easy-data-table/dist/style.css';
import cronVuetify from '@vue-js-cron/vuetify';
import VueBarcode from '@chenfengyuan/vue-barcode';
import Camera from 'simple-vue-camera';
/* END COMPONENTS */

import QUESTION_TYPES_ENUM from './CONSTANTS/QUESTION_TYPES_ENUM';
import { getTableHeaders } from './CONSTANTS/tableHeaders/tableHeaders';

import SignaloBreadCrumbs from 'signalo-vue-breadcrumbs';
import SignaloDialog from 'signalo-vue-dialog';
import SignaloPageLoading from 'signalo-vue-page-loading';
import SignaloImagePreview from 'signalo-vue-image-preview';
import SignaloTranslatableTabs from 'signalo-vue-translatable-tabs';
import SignaloDynamicMenu from 'signalo-vue-dynamic-menu';
import SignaloConfirmationDialog from 'signalo-vue-confirmation-dialog';
import SignaloImageUpload from 'signalo-vue-image-upload';
import SignaloTabsToggle from 'signalo-vue-tabs-toggle';
import SignaloStepper from 'signalo-vue-stepper';
import SignaloUserPicker from 'signalo-vue-user-picker';
import SignaloDatePicker from 'signalo-vue-date-picker';
import SignaloRegexBuilder from 'signalo-vue-regex-builder';
import SignaloSingleValueDialog from 'signalo-vue-single-value-dialog';
import SignaloPartPicker from 'signalo-vue-part-picker';
import SignaloColorPicker from 'signalo-vue-color-picker';
import SignaloIconPicker from 'signalo-vue-icon-picker';
import SignaloEquipmentPicker from 'signalo-vue-equipment-picker';
import SignaloCategoryPicker from 'signalo-vue-category-picker';
import SignaloEquipmentTypePicker from 'signalo-vue-equipment-type-picker';
import SignaloDictionaryTreePicker from 'signalo-vue-dictionary-tree-picker';
import SignaloBarCodeComponent from 'signalo-vue-barcode';
import LocationAddEditModal from 'signalo-vue-location-picker';
import { SignaloSearchContainer } from 'signalo-vue-search-container';
import SignaloAppBar from 'signalo-vue-app-bar';
import SignaloAppAccessComponent from 'signalo-vue-app-access-component';
import SignaloExportDialog from 'signalo-vue-export-dialog';
import SignaloFilesContainerItem from 'signalo-vue-files-container-item';
import SignaloFilesContainer from 'signalo-vue-files-container';
import SignaloFilesDocumentsContainer from 'signalo-vue-files-documents-container';
import SignaloFilesImagesContainer from 'signalo-vue-files-images-container';
import SignaloErrorReportDialog from 'signalo-vue-error-report-dialog';
import SignaloProcessProgressBar from 'signalo-vue-process-progress-bar';
import SignaloLanguageChanger from 'signalo-vue-language-changer';
import SignaloParametersManageDialog from 'signalo-vue-parameters-manage-dialog';
import SignaloBarcodeScanner from 'signalo-vue-barcode-scanner';
import SignaloBarcodeScanningInterceptor from 'signalo-vue-barcode-scanning-interceptor';
import SignaloCommentDialog from 'signalo-vue-comment-dialog';
import SignaloNavDrawer from 'signalo-vue-nav-drawer';
import SignaloNewValueSelect from 'signalo-vue-new-value-select';
import SignaloManageTableColumnsVisibilityDialog from 'signalo-vue-manage-table-columns-visibility-dialog';
import EquipmentCommon from 'signalo-vue-equipment-common';
import SignaloDataTile from 'signalo-vue-data-tile';
import SignaloPartsComponents from 'signalo-vue-parts-components';
import SignaloAuthComponents from 'signalo-vue-auth-components';
import { SignaloTagsManagement } from 'signalo-vue-tags-management';
import SignaloItemParametersManage from 'signalo-vue-item-parameters-manage';
import SignaloErrorMessage from 'signalo-vue-error-message';
import SignaloNotificationsManagement from 'signalo-vue-notifications-management';
import SignaloSignatureComponent from 'signalo-vue-signature-component';
import SignaloPartEntryChangeLocationDialog from 'signalo-vue-part-entry-change-location-dialog';
import SignaloLocationPicker from 'signalo-vue-location-picker';
import { SignaloCounters } from 'signalo-vue-counters-view';
import SignaloSimpleValues from 'signalo-vue-simple-values';
import { SignaloCountersSettings } from 'signalo-vue-settings-counters-view';
import { SettingsView } from 'signalo-vue-settings-view';
import * as ShiftsSettings from 'signalo-vue-settings-shifts-view';
import * as CrewsSettings from 'signalo-vue-settings-crews-view';
import * as ScheduleEventTypesSettings from 'signalo-vue-settings-schedule-event-types-view';
import * as ScheduleEventsSettings from 'signalo-vue-settings-schedule-events-view';
import * as ProductsSettings from 'signalo-vue-settings-products-view';
import * as TerminalRegister from 'signalo-vue-terminal-register-view';
// import * as SurveyEventsSettings from 'signalo-vue-settings-surveys-events-view';
import SignaloSurveyPicker from 'signalo-vue-survey-picker';
// import * as QRActionsSettings from 'signalo-vue-settings-qr-actions-view';
// import * as SurveySettings from 'signalo-vue-settings-surveys-view';
import SignaloGroupableTable from 'signalo-vue-groupable-table';
// import * as SignaloSurveysResults from 'signalo-vue-surveys-results-view';
// import SignaloVueSurveys from 'signalo-vue-surveys';
// import SignaloQRAction from 'signalo-vue-qr-action';
import SignaloGroupsSettings from 'signalo-vue-settings-groups-view';
import SignaloTerminalsGroupsSettings from 'signalo-vue-settings-terminals-groups-view';
import * as SignaloFlowsSettings from 'signalo-vue-settings-flows-view';
import * as SignaloParametersSettings from 'signalo-vue-settings-parameters-view';
import * as SignaloCompaniesSettings from 'signalo-vue-settings-companies-view';
import * as SignaloLicensesSettings from 'signalo-vue-settings-staff-licenses-view';
import * as SignaloCustomersSettings from 'signalo-vue-settings-customers-view';
import * as SignaloIssuesSettings from 'signalo-vue-settings-issues-view';
import { SignaloFlagsSettings } from 'signalo-vue-settings-flags-view';
import SignaloISO8601Component from 'signalo-vue-iso8601';
import * as SignaloMeasurementTypes from 'signalo-vue-settings-measurement-types-view';
import * as SignaloMeasurables from 'signalo-vue-measurables-view';
import { SignaloTransitionActionsManager } from 'signalo-vue-settings-flows-view';
import SignaloItemPicker from 'signalo-vue-item-picker';
import * as SignaloProductionLinesSettings from 'signalo-vue-settings-production-lines-view';
import SignaloDropdownAddButton from 'signalo-vue-dropdown-add-button';
import SignaloVueChecklists from 'signalo-vue-checklists';
import * as SignaloVueSettingsChecklists from 'signalo-vue-settings-checklists-view';
import { SignaloSocketService } from 'signalo-vue-socket-service';
import SignaloBrowserVersionWarning from 'signalo-vue-browser-version-warning';
import { SignaloDictionariesPlugin } from 'signalo-vue-settings-dictionaries-view';
import SignaloTranslationSelector from 'signalo-vue-translation-selector';
const SignaloComponents = [
    SignaloBreadCrumbs,
    SignaloDialog,
    SignaloPageLoading,
    SignaloImagePreview,
    SignaloTranslatableTabs,
    SignaloDynamicMenu,
    SignaloConfirmationDialog,
    SignaloImageUpload,
    SignaloTabsToggle,
    SignaloStepper,
    SignaloUserPicker,
    SignaloDatePicker,
    SignaloRegexBuilder,
    SignaloSingleValueDialog,
    SignaloPartPicker,
    SignaloColorPicker,
    SignaloIconPicker,
    SignaloEquipmentPicker,
    SignaloCategoryPicker,
    SignaloEquipmentTypePicker,
    SignaloDictionaryTreePicker,
    SignaloBarCodeComponent,
    LocationAddEditModal,
    SignaloSearchContainer,
    SignaloAppBar,
    SignaloAppAccessComponent,
    SignaloExportDialog,
    SignaloFilesContainerItem,
    SignaloFilesContainer,
    SignaloFilesDocumentsContainer,
    SignaloFilesImagesContainer,
    SignaloErrorReportDialog,
    SignaloProcessProgressBar,
    SignaloLanguageChanger,
    SignaloParametersManageDialog,
    SignaloBarcodeScanner,
    SignaloBarcodeScanningInterceptor,
    SignaloCommentDialog,
    SignaloNavDrawer,
    SignaloNewValueSelect,
    SignaloManageTableColumnsVisibilityDialog,
    SignaloPartsComponents,
    EquipmentCommon,
    SignaloDataTile,
    SignaloAuthComponents,
    SignaloTagsManagement,
    SignaloItemParametersManage,
    SignaloErrorMessage,
    SignaloNotificationsManagement,
    SignaloSignatureComponent,
    SignaloPartEntryChangeLocationDialog,
    SignaloLocationPicker,
    SignaloCounters,
    SignaloSimpleValues,
    SignaloCountersSettings,
    SettingsView,
    ShiftsSettings,
    CrewsSettings,
    ScheduleEventTypesSettings,
    ScheduleEventsSettings,
    ProductsSettings,
    TerminalRegister,
    // SurveyEventsSettings,
    SignaloSurveyPicker,
    // QRActionsSettings,
    // SurveySettings,
    SignaloGroupableTable,
    // SignaloSurveysResults,
    // SignaloVueSurveys,
    // SignaloQRAction,
    SignaloGroupsSettings,
    SignaloTerminalsGroupsSettings,
    SignaloFlowsSettings,
    SignaloParametersSettings,
    SignaloCompaniesSettings,
    SignaloLicensesSettings,
    SignaloCustomersSettings,
    SignaloIssuesSettings,
    SignaloFlagsSettings,
    SignaloISO8601Component,
    SignaloMeasurementTypes,
    SignaloMeasurables,
    SignaloTransitionActionsManager,
    SignaloItemPicker,
    SignaloProductionLinesSettings,
    SignaloDropdownAddButton,
    SignaloVueChecklists,
    SignaloVueSettingsChecklists,
    SignaloSocketService,
    SignaloBrowserVersionWarning,
    SignaloDictionariesPlugin,
    SignaloTranslationSelector
];

async function initFingerprint() {
    try {
        //XDT nie może się zmieniać!
        if (localStorage.getItem('xdt')) return;
        const fpPromise = await FingerprintJS.load();
        const fp = await fpPromise;
        const result = await fp.get();
        if (result?.visitorId) {
            localStorage.setItem('xdt', result.visitorId);
        }
    } catch (err) {
        console.error('Failed to get fingerprint', err);
    }
}

await initFingerprint();

import axiosRetry from 'axios-retry';
axiosRetry(axios, {
    retries: 1,
    retryCondition: (res) => {
        const DoNotRetryStatusCodes = [
            503, 500, 422, 409, 403, 405, 404, 400, 0, 412,
        ];
        if (DoNotRetryStatusCodes.includes(Number(res?.response?.status)))
            return false;
        if (
            res?.request?.responseURL.includes(
                `${tenant.getApiUrl(tenant.getSubdomain())}/auth`
            )
        )
            return false;

        return (
            !helpersStore.maintenance &&
            (localStorage.getItem('authToken') ||
                localStorage.getItem('terminalToken'))
        );
    },
    onRetry: async function () {
        if (authStore.isTerminal) {
            return authStore.loginTerminal();
        } else {
            return authStore.getAuth();
        }
    },
});

import i18n from './i18n.js';

import { GetTerminalLoginData } from 'signalo-vue-utils';

import * as _ from 'lodash';

if (process.env.VUE_APP_RUN_AS_TERMINAL == 'true') {
    window.FFApi = {};
}

const app = createApp(App);
const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.component('DateTimePicker', DateTimePicker);
app.config.unwrapInjectedRef = true; // TEMP; It will no longer be required after Vue 3.3.
const pinia = createPinia();
app.use(pinia);

import * as HELPERS_STORE from 'signalo-vue-helpers-store';
import * as AUTH_STORE from 'signalo-vue-auth-store';
const authStore = AUTH_STORE.default();
import { customSVGs } from './components/customSvgs';
const helpersStore = HELPERS_STORE.default();
import * as DATA_STORE from 'signalo-vue-data-store';
const dataStore = DATA_STORE.default();

router.beforeResolve(async (to, from, next) => {
    const terminalToken = localStorage.getItem('terminalToken');

    if (!authStore?.user?.access && terminalToken) {
        try {
            const authData = await authStore.getAuth();
            if (authData?.data) {
                authStore.login(authData.data);
            }
        } catch (err) {
            if (err?.response?.status === 401) {
                try {
                    const secret = new TextEncoder().encode(terminalToken);
                    const terminalLoginData = await GetTerminalLoginData(
                        secret,
                        axios
                    );
                    authStore.login(terminalLoginData);
                } catch (error) {
                    if (error?.response?.status === 401) {
                        try {
                            localStorage.removeItem('authToken');
                            const secret = new TextEncoder().encode(
                                terminalToken
                            );
                            const terminalLoginData =
                                await GetTerminalLoginData(secret, axios);
                            authStore.login(terminalLoginData);
                        } catch (error2) {
                            console.error(error2);
                        }
                    }
                    console.error(error);
                }
            }
            console.error(err);
        }
    } else if (!authStore?.user?.access && localStorage.getItem('authToken')) {
        try {
            const authData = await axios.get('/auth');
            if (authData?.data) {
                authStore.login(authData.data);
            }
        } catch (err) {
            console.error(err);
        }
    }
    to.meta.permitted = false;
    if (to.name === 'storage') {
        to.meta.permitted = !!authStore.user?.storage_access?.find(
            (sa) => sa.access === 'storage.read' && sa.allowed === true
        );
    } else {
        await authStore.checkUserPermittedForRoute(to);
    }

    next();
});
app.use(router);

const vuetify = createVuetify({
    theme: {
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: '#9761f4', //var(--primary-main)
                    secondary: '#ca2e5a',
                    accent: '#35c0ca',
                    background: '#eaecf0', // Not automatically applied
                    surface: '#FFFFFF',
                },
            },
        },
        defaultTheme: 'light',
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
            custom: customSVGs,
        },
    },
    components,
    directives,
});

const tenantSubdomain = tenant.getSubdomain();
axios.defaults.baseURL = tenant.getApiUrl(tenantSubdomain);
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.put['Accept'] = 'application/json';
axios.defaults.headers.patch['Accept'] = 'application/json';

async function waitForGetAuthComplete(resolve) {
    await new Promise((res) => setTimeout(res, 10));
    return await new Promise(() => {
        if (!authStore.getAuthInProgress) {
            resolve(true);
        } else {
            return waitForGetAuthComplete(resolve);
        }
    });
}
window.axiosControllers = new Map();

axios.interceptors.request.use(
    async function (config) {
        authStore.addAbortControllerToRequest(config);

        if (!config?.url?.includes('auth') && authStore.getAuthInProgress) {
            await new Promise((resolve) => {
                waitForGetAuthComplete(resolve);
            });
        }
        // Do something before request is sent
        let token = localStorage.getItem('authToken');
        if (token && config.headers) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        //XDT nie może się zmieniać!
        let device_id;
        if (typeof window?.FFApi?.deviceId === 'function') {
            device_id = window?.FFApi?.deviceId();
        }
        const fingerPrint = localStorage.getItem('xdt');

        //HEADER XDT to albo device_id z androida albo XDT wygenerowane przez fingerpring.js;
        if (device_id || fingerPrint) {
            config.headers['xdt'] = device_id || fingerPrint;
        } else {
            try {
                const fpPromise = await FingerprintJS.load();
                const fp = await fpPromise;
                const result = await fp.get();
                if (result?.visitorId) {
                    localStorage.setItem('xdt', result.visitorId);
                }
            } catch (err) {
                console.error('Failed to get fingerprint', err);
            }
        }
        const deviceId = localStorage.getItem('device_id');
        if (deviceId) {
            config.headers['device_id'] = deviceId;
        }
        const hash = authStore.hash;
        if (hash && authStore?.user?.id) {
            config.headers['cxt'] = hash;
        }

        // && !window.location.href.includes('localhost')
        if (
            process.env.VUE_APP_VERSION &&
            !window.location.href.includes('localhost')
        ) {
            config.headers['x-app-ver'] = process.env.VUE_APP_VERSION;
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

app.use(i18n);
helpersStore.setI18n(i18n);

axios.interceptors.response.use(
    (response) => {
        if (response?.config?.signal?.id) {
            authStore.removeAbortController(response.config.signal.id);
        }

        const anySurveysToRun =
            response?.data?.survey_results?.length ||
            response?.data?.user_surveys?.length;
        if (anySurveysToRun) {
            const surveysToRun =
                response.data?.survey_results || response?.data?.user_surveys;
            helpersStore.setPendingSurveys(surveysToRun);
            helpersStore.runSurvey(
                surveysToRun[0].survey,
                null,
                surveysToRun[0].id
            );
        }
        return response;
    },
    (error) => {
        if (error?.response?.status === 412) {
            helpersStore.setMaintenance(false);
            helpersStore.setIsCorrectAppVersion(false);
            return;
        }
        helpersStore.setIsCorrectAppVersion(true);
        if (error?.response?.status === 503) {
            helpersStore.setMaintenance(true);
            return;
        } else if (
            error?.response?.status === 409 &&
            String(error?.response?.data?.message).includes(
                'Model has been changed during update.'
            )
        ) {
            helpersStore.snackbar(
                i18n.global.messages.value[i18n?.global?.locale?.value || 'pl'][
                    'errors'
                ]['versionErrorMessage'],
                'warning',
                5000
            );
            throw new Error('Model has been changed during update.');
        }
        if (
            error?.response?.status == 401 &&
            (!localStorage.getItem('terminalToken') ||
                !localStorage.getItem('authToken'))
        ) {
            if (error?.response?.data?.status === 'wrong_context') {
                if (authStore?.terminal?.requires_user_login) {
                    authStore.logout();
                } else {
                    //TYLKO wywal ze stora, tak co na drugim tabie bedzie zalogowany świeższy user;
                    authStore.forget(true);
                }
                return;
            }
            if (authStore._token) {
                helpersStore.snackbar(
                    i18n.global.messages.value[
                        i18n?.global?.locale?.value || 'pl'
                    ]['errors']['sessionExpired'],
                    'warning'
                );
            }
            authStore.forget();
            throw error;
        }

        if (error?.response?.status === 403) {
            if (error?.config?.disableSAD === true) {
                return Promise.reject(error);
            } else {
                return authStore
                    .openSAD(error?.config?.persist)
                    .catch((error2) => {
                        console.log('openSad catch');
                        return Promise.reject(error2);
                    })
                    .then(() => {
                        console.log('openSad axios rerequest');
                        return axios(error.config);
                    });
            }
        }
        if (
            error?.code !== 'ERR_CANCELED' &&
            error?.response?.status !== 400 &&
            error?.response?.status !== 401 &&
            error?.response?.status !== 406 &&
            error?.response?.status !== 503 &&
            error?.response?.status !== 412 &&
            error?.response?.status !== 422 &&
            error?.response?.status !== 400 &&
            !String(error?.request?.responseURL).includes('/auth')
        ) {
            helpersStore.snackbarError(error);
        }

        return Promise.reject(error);
    }
);

app.mixin({
    data: function () {
        return {
            // eslint-disable-next-line
            _emailRules: [
                (v) =>
                    !v ||
                    String(v)
                        .toLowerCase()
                        .match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        ) ||
                    this.$t('errors.wrongEmail'),
                (v) => helpersStore.USER_EMAIL_RULE(v),
            ], //INFO - w cmms w ustawieniach tenanta mozna sobie zdefiniować reguły dla nazw lokalizacji - dlatego potrzebuje tutaj ten rule;
            // eslint-disable-next-line
            _locationNameRules: [(v) => !!v || this.$t('common.fieldRequired')],
            // eslint-disable-next-line
            _isEmailRule: [
                (v) => {
                    return (
                        !v ||
                        String(v)
                            .toLowerCase()
                            .match(
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            ) ||
                        this.$t('errors.wrongEmail')
                    );
                },
            ],
        };
    },
    methods: {
        $displayDateWithTimezone: function (value, returnedValue) {
            //DATE | TIME | NULL
            const date = new Date(value);
            if (!value || date === 'Invalid Date') return '-';

            if (returnedValue === 'date') {
                return new Date(value).toLocaleDateString(i18n.locale, {
                    timeZone: authStore.tenant.default_timezone,
                });
            }

            if (returnedValue === 'date') {
                return new Date(value).toLocaleTimeString(i18n.locale, {
                    timeZone: authStore.tenant.default_timezone,
                });
            }

            return new Date(value).toLocaleString(i18n.locale, {
                timeZone: authStore.tenant.default_timezone,
            });
        },
        $extractISO8601: function (value) {
            if (!value || typeof value !== 'string') return null;

            const yearsRegex = new RegExp(/\d{1,}Y/gm);
            const monthsRegex = new RegExp(/\d{1,}M/gm);
            const daysRegex = new RegExp(/\d{1,}D/gm);

            const y = yearsRegex.exec(value)?.[0]?.split('Y')?.[0] || 0;
            const m = monthsRegex.exec(value)?.[0]?.split('M')?.[0] || 0;
            const d = daysRegex.exec(value)?.[0]?.split('D')?.[0] || 0;

            return { years: Number(y), months: Number(m), days: Number(d) };
        },
        $displayISO8601: function (value) {
            if (!value || typeof value !== 'string') return value;

            const yearsRegex = new RegExp(/\d{1,}Y/gm);
            const monthsRegex = new RegExp(/\d{1,}M/gm);
            const daysRegex = new RegExp(/\d{1,}D/gm);

            let str = '';

            const y = yearsRegex.exec(value)?.[0]?.split('Y')?.[0] || 0;
            const m = monthsRegex.exec(value)?.[0]?.split('M')?.[0] || 0;
            const d = daysRegex.exec(value)?.[0]?.split('D')?.[0] || 0;
            if (y) {
                str += `${y} ${i18n?.global.t('common.years')}`;
            }
            if (m) {
                if (y) {
                    str += ', ';
                }
                str += `${m} ${i18n?.global.t('common.months')}`;
            }
            if (d) {
                if (m || y) {
                    str += ', ';
                }
                str += `${d} ${i18n?.global.t('common.days')}`;
            }

            return str;
        },
        $clearWebviewCacheAndForceReloadApp() {
            console.log('$clearWebviewCacheAndForceReloadApp')
            try {
                if (typeof window?.FFApi?.clearCache === 'function') {
                    console.log('$clearWebviewCacheAndForceReloadApp clearCache')
                    window.FFApi.clearCache(true);
                }
            } catch (err) {
                console.log('$clearWebviewCacheAndForceReloadApp catch 1 reload!')
                console.error(err);
                typeof window?.FFApi?.reload === 'function'
                    ? window.FFApi.reload()
                    : location.reload();
            }
            try {
                console.log('$clearWebviewCacheAndForceReloadApp reload')
                typeof window?.FFApi?.reload === 'function'
                ? window.FFApi.reload()
                : location.reload();
            } catch (err) {
                console.log('$clearWebviewCacheAndForceReloadApp catch 2 reload')
                console.error(err);
                location.reload();
            }
        },
        $getPropertyTranslation: function (
            translations,
            lang = null,
            autoTranslate = null,
            fieldName = null,
            inline = false
        ) {
            if (!translations) return '';
            if (lang) {
                const translation =
                    translations?.[lang] ||
                    translations?.[i18n?.global?.locale?.value] ||
                    translations?.[authStore?.tenant?.default_locale] ||
                    translations?.['pl'];

                if (!translation) {
                    return `<b style="color: var(--warning-80);">[${i18n.global.t(
                        'common.awaitingAutoTranslation'
                    )}]</b>`;
                }

                if (
                    fieldName &&
                    (autoTranslate?.requested?.[lang]?.[fieldName] ||
                        autoTranslate?.awaiting?.[lang]?.includes(fieldName))
                ) {
                    return `<b style="color: var(--warning-80);">[${i18n.global.t(
                        'common.awaitingAutoTranslation'
                    )}]:</b><br> ${translation}`;
                }

                if (_.isEmpty(translations)) return '';
                return `<b style="color: var(--warning-80);">[${i18n.global.t(
                    'common.autoTranslate'
                )}]:</b>${!inline ? '<br>' : ''} ${translation}`; 
            }
            if (typeof translations === 'string') return translations;
            if (_.isEmpty(translations)) return '';
            if (translations?.[i18n?.global?.locale?.value])
                return translations[i18n?.global?.locale?.value];
            if (translations?.[authStore?.tenant?.default_locale])
                return translations[authStore?.tenant?.default_locale];
            if (translations?.['pl']) return translations['pl'];
            if (translations?.['en']) return translations['en'];
            return Object.values(translations)[0];
        },
        $displayDimension: function (d) {
            if (d?.includes('^')) {
                const splitted = d.split('^');
                return `<span >${splitted[0]}<sup>${splitted[1]}</sup></span>`;
            }
            const translatableDimensions = ['no', 'st', 'pr'];
            if (translatableDimensions.includes(d)) {
                return this.$t(`units.${d}`);
            }
            return d;
        },
        $isMobile: function () {
            let check = false;
            /* eslint-disable */
            (function (a) {
                if (
                    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                        a
                    ) ||
                    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                        a.substr(0, 4)
                    )
                )
                    check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        },
        $isTablet: function () {
            const userAgent = navigator.userAgent.toLowerCase();
            return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
                userAgent
            );
        },
        $dataURLtoFile(dataurl, filename) {
            let arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        },
        $getPinInputType() {
            return (
                authStore?.tenant?.attributes?.['pin_code_field_type'] || 'text'
            );
        },
        $getServerOptions: function (tableName) {
            let search = location.search;
            if (search.includes('?')) {
                search = search.replace('?', '');
            }
            const query = search.split('&');

            let routeParams = {};

            query.forEach((q) => {
                const keyValuePair = q.split('=');
                routeParams[keyValuePair[0]] = keyValuePair[1];
            });
            return {
                page:
                    Number.isInteger(Number(routeParams?.p)) &&
                    routeParams.p > 0
                        ? Number(routeParams?.p)
                        : 1,
                rowsPerPage:
                    Number.isInteger(Number(routeParams?.pp)) &&
                    routeParams.pp > 0
                        ? Number(routeParams?.pp)
                        : authStore?.getTableDefaultSize(tableName) || 5,

                sortBy: routeParams?.sort_by || 'id',
                sortType:
                    routeParams?.sort_dir || //TODO coś mądrzejszego wymyślić
                    (tableName == 'issuesIndexTable' ||
                    tableName == 'myIssuesIndexTable' ||
                    tableName == 'jobStatusesIndexTable' ||
                    tableName == 'toolTakeLogTable'
                        ? 'desc'
                        : 'asc'),
                query: routeParams?.query || '',
            };
        },
        $pickTextColorBasedOnBgColor(bgColor, lightColor, darkColor) {
            var color =
                bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
            var r = parseInt(color.substring(0, 2), 16); // hexToR
            var g = parseInt(color.substring(2, 4), 16); // hexToG
            var b = parseInt(color.substring(4, 6), 16); // hexToB
            return r * 0.299 + g * 0.587 + b * 0.114 > 186
                ? darkColor
                : lightColor;
        },
        $shouldOpenImagePreview(image) {
            const MAX_FILE_SIZE = 20 * 1000000;
            if (image?.url && image.size > MAX_FILE_SIZE) {
                // >20MB
                helpersStore.snackbarWarning(
                    i18n.global.t('errors.fileTooBigToOpenWillBeDownloaded')
                );
                window.open(image.url, '_blank');
                return false;
            }
            return true;
        },
        $getDictionaryItemName(dictionaryName, idOrKeyname) {
            if (!idOrKeyname) return '';
            return (
                dataStore.dictionary
                    .group(dictionaryName)
                    .find(
                        (groupItem) =>
                            groupItem?.id == idOrKeyname ||
                            groupItem?.keyname == idOrKeyname
                    )?.value || idOrKeyname
            );
        },
        $hasStorageLocationPermission(locationId) {
            //TODO cos jest nie tak dla itemów bez lokalizacji
            if (!locationId) return false;
            const requestedLocation = dataStore.locations.getById(locationId);
            if (!requestedLocation?.path) return false;
            const splittedPath = requestedLocation.path
                .split('/')
                .filter((id) => id)
                .map((id) => Number(id));

            const pathLocations = dataStore.locations.items.filter((l) =>
                splittedPath.includes(Number(l.id))
            );
            if (pathLocations.every((pl) => pl.storage_root === null))
                return true;

            if (
                pathLocations.some((pl) =>
                    authStore.user?.storage_access.find(
                        (usa) =>
                            usa.allowed &&
                            usa.access === 'storage.read' &&
                            usa.storage === pl.storage_root
                    )
                )
            )
                return true;

            return false;
        },
    },
});
import { SELECTABLE_TYPES } from 'signalo-vue-new-value-select';
const MyPlugins = {
    install: (app /*, options*/) => {
        app.config.globalProperties.getFileStorageUrl = () => {
            return tenant.getStorageUrl(tenantSubdomain);
        };
        app.config.globalProperties.getAvailableLocales = () => {
            return Object.values(authStore?.tenant?.supportedLocales).filter(
                (sl) => i18n?.global?.availableLocales.includes(sl)
            );
        };
        app.config.globalProperties.$QUESTION_TYPES_ENUM =
            Object.freeze(QUESTION_TYPES_ENUM);
        app.config.globalProperties.$PHONE_NUMBER_RULE =
            helpersStore.PHONE_NUMBER_RULE;
        app.config.globalProperties.$REQUIRED_RULE = helpersStore.REQUIRED_RULE;
        app.config.globalProperties.$MIN_RULE = helpersStore.MIN_RULE;
        app.config.globalProperties.$MAX_RULE = helpersStore.MAX_RULE;
        app.config.globalProperties.$INTEGER_RULE = helpersStore.INTEGER_RULE;
        app.config.globalProperties.$MAX_LENGTH_RULE = (v, max) => {
            if (v == '' || v == null || v == undefined) return true;
            return (
                v?.length <= max ||
                i18n?.global.t('errors.maxLength') + ` ${max}`
            );
        };
        app.config.globalProperties.$EMAIL_RULE = (v) => {
            if (!v) return true;
            return (
                String(v)
                    .toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ) || i18n?.global.t('errors.wrongEmail')
            );
        };
        app.config.globalProperties.$TRANSLATABLE_RULE = (translatable) => {
            let value = translatable;
            if (typeof translatable === 'object') {
                value = { ...translatable };
                delete value.auto_translation;
            }
            if (
                !value ||
                JSON.stringify(value) === '{}' ||
                Object.values(value).every((v) => !v)
            )
                return true;
            return (
                authStore.tenant.supportedLocales.every(
                    (lang) => value?.[lang]
                ) || i18n?.global.t('errors.allLanguagesRequired')
            );
        };
        app.config.globalProperties.$openManageTableColumnsvisibilityDialog =
            helpersStore.openManageTableColumnsvisibilityDialog;
        app.config.globalProperties.$getPreferredTableColumns = (
            TABLE_NAME
        ) => {
            const userPreferencesForTable =
                authStore.user?.attributes?.[TABLE_NAME];
            if (!i18n?.locale) {
                i18n.locale =
                    authStore.user?.preferred_locale ||
                    authStore.terminal?.preferred_locale ||
                    localStorage.getItem('locale');
            }
            return getTableHeaders(TABLE_NAME)
                .filter((th) => {
                    return (
                        userPreferencesForTable?.[th.value]?.visible !== false
                    );
                })
                .map((th) => {
                    return {
                        ...th,
                        text: th.text ? i18n.global.t(th.text) : '',
                    };
                });
        };
        app.config.globalProperties.SELECTABLE_TYPES = SELECTABLE_TYPES;
        app.config.globalProperties.CODEABLE_TYPES = CODEABLE_TYPES;
    },
};
app.use(MyPlugins);

SignaloComponents.forEach((sc) => {
    app.use(sc);
});

app.use(VueAxios, axios);
app.use(vuetify);
app.use(cronVuetify);
app.component('PageNotFound', PageNotFound);
app.component('PageNotPermitted', PageNotPermitted);
app.component('PageLoading', PageLoading);
app.component('PageError', PageError);
app.component('EasyDataTable', Vue3EasyDataTable);
app.component('VueTimepicker', VueTimepicker);
app.component(VueBarcode.name, VueBarcode);
app.component('Camera', Camera);

import StorageTerminalDefaultValues from '@/views/settings/Terminals/StorageTerminalDefaultValues.vue';
app.component(StorageTerminalDefaultValues.name, StorageTerminalDefaultValues);

import StorageTerminalDefaultsDialog from '@/views/settings/Terminals/StorageTerminalDefaultsDialog.vue';
app.component(
    StorageTerminalDefaultsDialog.name,
    StorageTerminalDefaultsDialog
);

import SignaloFilesManager from './components/FilesManager/FilesManager.vue';
app.component(SignaloFilesManager.name, SignaloFilesManager);

import BanEmployeeDialog from './views/GateHouse/BanEmployeeDialog.vue';
app.component(BanEmployeeDialog.name, BanEmployeeDialog);

app.mount('#app');
